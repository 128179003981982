import React, { createContext, useContext, useEffect, useState } from "react";
import { TokenContext } from "./TokenController";
import { useMutation, useQuery } from "react-query";
import { setForgot, setLogin, setLogout, setReset } from "./api/AuthApi";
import { getProfile } from "./api/ProfileApi";
import { useToast } from "@chakra-ui/react";
import { cookiesVars } from "../../utils/cookies";
import Cookies from "js-cookie";
import { privateSlug } from "../../utils/privateSlug";
import { useNavigate, useSearchParams } from "react-router-dom";
import { publicSlug } from "../../utils/publicSlug";
import { useTranslation } from "react-i18next";
import { getUnread } from "./api/UnreadApi";
import { getRefreshAuthToken, getRefreshToken, getToken } from "./api/TokenApi";
import moment from "moment";

export const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthController = ({ children }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token, setToken, setIsTokenVerified, isLoginPage } =
    useContext(TokenContext);
  const _islogin = Cookies.get(cookiesVars._islogin);
  let [searchParams] = useSearchParams();

  const [isLogin, setIsLogin] = useState(false);
  const [loginPayload, setLoginPayload] = useState([]);
  const [isVisibleForgotDialog, setIsVisibleForgotDialog] = useState(false);
  const [isVisibleResetDialog, setIsVisibleResetDialog] = useState(false);
  const [roleIdUser, setRoleIdUser] = useState();
  const [listAccess, setListAccess] = useState();

  const { isLoading: isLoginLoading, mutate: mutateLogin } =
    useMutation(setLogin);
  const { mutate: mutateLogout } = useMutation(setLogout);
  const { isLoading: isLoadingForgot, mutate: mutateForgot } =
    useMutation(setForgot);

  const { isLoading: isLoadingReset, mutate: mutateReset } =
    useMutation(setReset);

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isFetching: isFetchingProfileData,
    isError: errorProfileData,
    refetch,
  } = useQuery(["profile", token, language], () =>
    getProfile({ token, language })
  );

  const {
    data: unreadData,
    isLoading: isLoadingUnreadData,
    refetch: refetchUnreadData,
  } = useQuery(["unread", token, language], () =>
    getUnread({ token, language })
  );

  const { mutate: mutateRefreshLogin } = useMutation(getRefreshAuthToken);

  const { data: tokenData } = useQuery(["token", language], () =>
    getToken({ language })
  );

  const handleLogin = (payload) => {    
    mutateLogin(
      {
        payload,
        token:
          tokenData?.data?.token != Cookies.get(cookiesVars._credentials)
            ? Cookies.get(cookiesVars._credentials)
            : tokenData?.data?.token,
        language,
      },
      {
        onSuccess: (data) => {
          if (
            data?.message?.response_code == "60" ||
            data?.message?.response_code == "61" ||
            data?.message?.response_code == "62" ||
            data?.message?.response_code == "63" ||
            data?.message?.response_code == "64"
          ) {
            mutateRefreshLogin(
              { data: Cookies.get(cookiesVars._refreshCredentials), language },
              {
                onSuccess: (data) => {
                  Cookies.set(cookiesVars._credentials, data?.data?.token);
                  Cookies.set(
                    cookiesVars._exp,
                    moment(data?.data?.token_expired).format("L")
                  );

                  setToken(data?.data?.token);

                  setIsLogin(true);
                  setLoginPayload(payload);
                  refetch();

                  Cookies.set(cookiesVars._islogin, payload);
                  toast({
                    description: "success",
                    position: "top-right",
                    status: "success",
                    duration: 1500,
                    isClosable: true,
                  });
                  refetchUnreadData();
                  // Redirect To Dashboard
                  navigate(privateSlug.DASHBOARD);
                  setIsTokenVerified(true);
                },
              },
              {
                onError: (data) => {
                  console.log(data);
                },
              }
            );
          } else {
            setIsLogin(true);
            setLoginPayload(payload);
            refetch();
            Cookies.set(cookiesVars._islogin, payload);
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "success",
              duration: 1500,
              isClosable: true,
            });
            refetchUnreadData();
            // Redirect To Dashboard
            navigate(privateSlug.DASHBOARD);
          }
        },
      },
      {
        onError: (data) => {
          console.log(data);
        },
      }
    );
  };

  const handleLogout = () => {
    mutateLogout(
      { token, language },
      {
        onSuccess: (data) => {
          Cookies.remove(cookiesVars._credentials);
          Cookies.remove(cookiesVars._exp);
          Cookies.remove(cookiesVars._islogin);
          setIsLogin(false);
          setLoginPayload([]);
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
          // Redirect To Login
          window.location.reload();
          navigate(publicSlug.LOGIN);
        },
      }
    );
  };

  useEffect(() => {
    if (token && _islogin !== undefined) {
      setIsLogin(true);
      setLoginPayload(_islogin);
    }
  }, [token, _islogin]);

  const handleForgot = (payload) => {
    mutateForgot(
      { payload, token, language },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "error",
              duration: 1500,
              isClosable: true,
            });
          } else {
            // Redirect To Login
            setIsVisibleForgotDialog(true);
            navigate(publicSlug.LOGIN);
          }
        },
      }
    );
  };

  useEffect(() => {
    setRoleIdUser(profileData?.data?.role?.role_id);
  }, [profileData]);

  const handleReset = (payload) => {
    let reset_token = searchParams.get("token");

    mutateReset(
      { payload, token, language, reset_token },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "error",
              duration: 1500,
              isClosable: true,
            });
          } else {
            // Redirect To Login
            setIsVisibleResetDialog(true);
            navigate(publicSlug.LOGIN);
          }
        },
      }
    );
  };

  return (
    <Provider
      value={{
        handleLogin,
        loginPayload,
        isLogin,
        isLoginLoading,
        handleLogout,
        profileData,
        isLoadingProfileData,
        isFetchingProfileData,
        isVisibleForgotDialog,
        setIsVisibleForgotDialog,
        handleForgot,
        isLoadingForgot,
        roleIdUser,
        setRoleIdUser,
        listAccess,
        setListAccess,
        handleReset,
        isLoadingReset,
        isVisibleResetDialog,
        setIsVisibleResetDialog,
        unreadData,
      }}
    >
      {children}
    </Provider>
  );
};

export default AuthController;
