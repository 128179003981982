import { api } from "../../../utils/axios";

export const setLogin = ({ payload, token, language }) =>
  api
    .post(
      "/authorization/user-backoffice/login",
      {
        email: payload?.email,
        password: payload?.password,
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

export const setLogout = ({ token, language }) =>
  api
    .post(
      "/authorization/user-backoffice/logout",
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

export const setForgot = ({ payload, token, language }) =>
  api
    .post(
      "/authorization/user-backoffice/forgot-password",
      { email: payload?.email },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

export const setReset = ({ payload, token, language, reset_token }) =>
  api
    .put(
      "/authorization/user-backoffice/reset-password",
      {
        new_password: payload?.newpassword,
        confirm_new_password: payload?.confirmpassword,
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
          "nihao-reset-password-token": reset_token,
        },
      }
    )
    .then((res) => res?.data);
