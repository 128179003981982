import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cookiesVars } from "../../utils/cookies";
import { publicSlug } from "../../utils/publicSlug";
import { AuthContext } from "./AuthController";

const PrivateAuth = () => {
  const navigate = useNavigate();
  const { isLogin } = useContext(AuthContext);
  const _islogin = Cookies.get(cookiesVars._islogin);

  useEffect(() => {
    if (isLogin === false && !_islogin) {
      navigate(publicSlug.LOGIN);
    }
  }, [isLogin, _islogin]);

  return <></>;
};

export default PrivateAuth;
