import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { Button, Switch, useToast } from "@chakra-ui/react";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import {
  deleteUserAgent,
  getUserAgent,
  getUserAgentSearch,
  setUserAgentActive,
  getUserAgents,
  setUserAgentTemporary,
  setTopUpSaldo,
  getListTransaction,
  getListTransactionSearch,
} from "./api/UserAgentApi";
import { getProfile } from "../../../../../controllers/public/api/ProfileApi";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { getRole } from "../UserAdminRole/api/RoleApi";
import CurrencyFormat from "react-currency-format";
import moment from "moment";

export const UserAgentContext = createContext();
const { Provider } = UserAgentContext;

const UserAgentController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: userAgentData,
    isLoading: isLoadingUserAgentData,
    refetch,
  } = useQuery(["useragents", token, language], () =>
    getUserAgents({ token, language })
  );

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isFetching: isFetchingProfileData,
    isError: errorProfileData,
  } = useQuery(["profile", token, language], () =>
    getProfile({ token, language })
  );

  const {
    isLoading: isLoadingSetUserSearch,
    mutate: mutateSetUserAgentSearch,
  } = useMutation(getUserAgentSearch);

  const { isLoading: isLoadingGetUsers, mutate: mutateGetUserAgents } =
    useMutation(getUserAgents);
  const { mutate: mutateSetUserAgentActive } = useMutation(setUserAgentActive);
  const { mutate: mutateSetUserAgentTemporary } = useMutation(
    setUserAgentTemporary
  );
  const { isLoading: isLoadingDeleteUser, mutate: mutateDeleteUserAgent } =
    useMutation(deleteUserAgent);
  const { mutate: mutateGetUserAgent } = useMutation(getUserAgent);
  const {
    isLoading: isLoadingListTransaction,
    mutate: mutateGetListTransaction,
  } = useMutation(getListTransaction);

  const {
    isLoading: isLoadingListTransactionSearch,
    mutate: mutateGetListTransactionSearch,
  } = useMutation(getListTransactionSearch);

  const { isLoading: isLoadingTopUpBalance, mutate: mutateTopUpBalance } =
    useMutation(setTopUpSaldo);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalFormResetOpen, setIsModalFormResetOpen] = useState(false);
  const [isModalFormTopUpOpen, setIsModalFormTopUpOpen] = useState(false);
  const [isModalFormTransactionOpen, setIsModalFormTransactionOpen] =
    useState(false);
  const [isVisibleDeleteUser, setIsVisibleDeleteUser] = useState(false);
  const [isVisibleDeleteInfoUser, setIsVisibleDeleteInfoUser] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [urlProfilePicture, setUrlProfilePicture] = useState();
  const [isVisibleTopUpBalanceInfo, setIsVisibleTopUpBalanceInfo] =
    useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isShowTemporaryClose, setIsShowTemporaryClose] = useState(false);

  const [newUserAgentData, setNewUserAgentData] = useState();
  const [userId, setUserId] = useState();
  const [agentId, setAgentId] = useState();

  const [newUserAdminAgentSearch, setNewUserAdminAgentSearch] = useState();

  const [isShowType, setIsShowType] = useState(false);

  let newUserData = [];
  let newTransactionData = [];

  const [isDeleteAccessUserAgent, setIsDeleteAccessUserAgent] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const [newTransactions, setNewTransactions] = useState();
  const [transactionData, setTransactionData] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //User Agent
          const deleteAccessUserAgent = data?.data?.access
            ?.filter((data) => data?.page === "agent")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessUserAgent(deleteAccessUserAgent?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Modal
  // -------------------------------
  const formModal = useForm();
  const resetformModal = (newForm) => {
    if (newForm) {
      console.log(newForm);
      formModal.reset({
        profile_picture_image_url: newForm?.profile_picture_image_url,
        firstname: newForm?.firstname,
        lastname: newForm?.name,
        phone: newForm?.phone,
        email: newForm?.email,
        address: newForm?.address,

        code_bank_manual: newForm?.code_bank_manual,
        account_name_manual: newForm?.account_name_manual,
        account_number_manual: newForm?.account_number_manual,

        account_name_alipay: newForm?.account_name_alipay,
        account_number_alipay: newForm?.account_number_alipay,
        qr_code_alipay: newForm?.qr_code_alipay, // URL image
        account_name_wechat: newForm?.account_name_wechat,
        account_number_wechat: newForm?.account_number_wechat,
        qr_code_wechat: newForm?.qr_code_wechat, // URL image
      });
    }
  };

  // -------------------------------
  // Form Modal Reset
  // -------------------------------
  const formModalReset = useForm();
  const resetformModalReset = (newForm) => {
    if (newForm) {
      formModalReset.reset({
        newpassword: newForm?.newpassword,
        confirmpassword: newForm?.confirmpassword,
      });
    }
  };

  // -------------------------------
  // Form Modal TopUp
  // -------------------------------
  const formTopUp = useForm();
  const resetformTopUp = (newForm) => {
    if (newForm) {
      formTopUp.reset({
        amount: newForm?.amount,
      });
    }
  };

  // -------------------------------
  // Form Modal Transaction
  // -------------------------------
  const formTransaction = useForm();
  const resetformTransaction = (newForm) => {
    if (newForm) {
      formTransaction.reset({
        firstname: newForm?.firstname,
        lastname: newForm?.lastname,
        description: newForm?.description,
        address: newForm?.address,
        balance: newForm?.balance,
        last_login: newForm?.last_login,
      });
    }
  };

    // -------------------------------
  // Form Search Filter
  // -------------------------------
  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_temporary: newForm?.keyword_temporary,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    mutateGetUserAgent(
      { guid, token },
      {
        onSuccess: (data) => {
          isEdit && resetformModal(data?.data);
        },
      }
    );
    isCreate && resetformModal({});
  };

  const handleUserActive = (guid, isActive) => {
    mutateSetUserAgentActive(
      { guid, isActive, token, language },
      {
        onSuccess: (data) => {
          refetch();
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
        },
      }
    );
  };

  const handleUserTemporary = (guid, isTemporary) => {
    mutateSetUserAgentTemporary(
      { guid, isTemporary, token, language },
      {
        onSuccess: (data) => {
          refetch();
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
        },
      }
    );
  };

  const handleDialogTopUpBalance = (isOpen, guid) => {
    setIsModalFormTopUpOpen(isOpen);
    setAgentId(guid);
    setIsVisibleTopUpBalanceInfo(false);
  };

  const handleTopUpBalance = (payload) => {
    const newPayload = {
      amount: payload,
      guid: agentId,
    };
    mutateTopUpBalance(
      { newPayload, token, language },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              title: 'Topup Saldo Masih Belum Lengkap',
              description: data?.message?.response_message,
              // description: "Topup saldo belum lengkap",
              position: "top-right",
              status: "warning",
              duration: 2000,
              isClosable: true,
            });
          } else {
            refetch();
            setIsModalFormTopUpOpen(false);
            setIsVisibleTopUpBalanceInfo(true);
          }
        },
      }
    );
  };

  const handleDialogTransaction = (isOpen, guid) => {
    setIsModalFormTransactionOpen(isOpen);
    setAgentId(guid);
    // setIsVisibleTopUpBalanceInfo(false);
    setIsShowType(true);

    mutateGetUserAgent(
      { guid, token },
      {
        onSuccess: (data) => {
          // resetformTransaction(data?.data)
          resetformTransaction(data.data);
        },
      }
    );

    mutateGetListTransaction(
      { guid, token, language },
      {
        onSuccess: (data) => {
          newTransactionData = [];

          data?.data?.map((value) => {
            newTransactionData.push({
              id: value?.id,
              user_agent_guid: value?.user_agent_guid,
              transaction_type: value?.transaction_type,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              message: value?.message,
              credit_by: value?.credit_by,
              transaction_date_time: moment(
                value?.transaction_date_time
              ).format("MMMM Do YYYY, h:mm:ss a"),
            });
          });

          setTransactionData(data);
          setNewTransactions(newTransactionData);
        },
      }
    );
  };

  // -------------------------------
  // Filter List Transaction
  // -------------------------------

  const handleSearchListTransactionTable = (keyword) => {
    let lang = t("LANGUAGE");
    let isType = keyword?.keyword_filter === "1" && true;
    let isMessage = keyword?.keyword_filter === "2" && true;

    setIsShowType(isType);
    setIsSearch(true);

    let payload = {
      isType: isType,
      isMessage: isMessage,
      search: keyword?.keyword_search,
      searchType: keyword?.keyword_type === "0" ? "credit" : "debit",
      // lang: lang,
      token: token,
      guid: agentId,
    };

    mutateGetListTransactionSearch(
      { payload, token, language },
      {
        onSuccess: (data) => {
          newTransactionData = [];

          data?.data?.map((value) => {
            newTransactionData.push({
              id: value?.id,
              user_agent_guid: value?.user_agent_guid,
              transaction_type: value?.transaction_type,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              message: value?.message,
              credit_by: value?.credit_by,
              transaction_date_time: moment(
                value?.transaction_date_time
              ).format("MMMM Do YYYY, h:mm:ss a"),
            });
          });

          setTransactionData(data);
          setNewTransactions(newTransactionData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination List Transaction
  // -------------------------------

  const handlePaginationListTransactionTable = (page, limit) => {
    let lang = t("LANGUAGE");
    const guid = agentId;
    mutateGetListTransaction(
      { page, limit, guid, token, language },
      {
        onSuccess: (data) => {
          newTransactionData = [];

          data?.data?.map((value) => {
            newTransactionData.push({
              id: value?.id,
              user_agent_guid: value?.user_agent_guid,
              transaction_type: value?.transaction_type,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              message: value?.message,
              credit_by: value?.credit_by,
              transaction_date_time: moment(
                value?.transaction_date_time
              ).format("MMMM Do YYYY, h:mm:ss a"),
            });
          });

          setTransactionData(data);
          setNewTransactions(newTransactionData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries List Transaction
  // -------------------------------

  const handleShowEntriesListTransactionTable = (limit) => {
    let lang = t("LANGUAGE");
    const guid = agentId;
    mutateGetListTransaction(
      { limit, guid, token, language },
      {
        onSuccess: (data) => {
          newTransactionData = [];

          data?.data?.map((value) => {
            newTransactionData.push({
              id: value?.id,
              user_agent_guid: value?.user_agent_guid,
              transaction_type: value?.transaction_type,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              message: value?.message,
              credit_by: value?.credit_by,
              transaction_date_time: moment(
                value?.transaction_date_time
              ).format("MMMM Do YYYY, h:mm:ss a"),
            });
          });

          setTransactionData(data);
          setNewTransactions(newTransactionData);
        },
      }
    );
  };

  const handleDialogDeleteUserAgent = (isOpen, guid) => {
    setIsVisibleDeleteUser(isOpen);
    setUserId(guid);
    setIsVisibleDeleteInfoUser(false);
  };

  const handleDeleteUser = (guid) => {
    mutateDeleteUserAgent(
      { guid, token, language },
      {
        onSuccess: () => {
          refetch();
          setIsVisibleDeleteUser(false);
          setIsVisibleDeleteInfoUser(true);
        },
      }
    );
  };

  const handleResetPasswordUser = (isOpen, guid) => {
    setIsModalFormResetOpen(isOpen);
    console.log(guid);
    // mutateDeleteUser(
    //   { guid, token },
    //   {
    //     onSuccess: () => {
    //       refetch();
    //       setIsVisibleDeleteUser(false);
    //       setIsVisibleDeleteInfoUser(true);
    //     },
    //   }
    // );
    // console.log(isOpen);
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isName = keyword?.keyword_filter === "1" && true;
    // let isPhone = keyword?.keyword_filter === "2" && true;
    // let isEmail = keyword?.keyword_filter === "3" && true;
    // let isStatus = keyword?.keyword_filter === "4" && true;
    // let isTemporary = keyword?.keyword_filter === "5" && true;

    let isName              = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isPhone             = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isEmail             = keyword?.keyword_filter?.value === "3" || (keyword?.keyword_filter === "3" && true);
    let isStatus            = keyword?.keyword_filter?.value === "4" || (keyword?.keyword_filter === "4" && true);
    let isTemporary         = keyword?.keyword_filter?.value === "5" || (keyword?.keyword_filter === "5" && true);
    let isSearchTemporary   = keyword?.keyword_temporary === "1" && true;
    let searchKeyword       = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    let lang = t("LANGUAGE");

    if (searchKeyword) {
      localStorage.setItem("search-user-agent", searchKeyword);
    } else {
      localStorage.removeItem("search-user-agent");
    }

    setIsShowStatus(isStatus);
    setIsShowTemporaryClose(isTemporary);
    setIsSearch(true);

    let payload = {
      isName: isName,
      isPhone: isPhone,
      isEmail: isEmail,
      isStatus: isStatus,
      isTemporary: isTemporary,
      search: searchKeyword,
      searchStatus: keyword?.keyword_status === "0" ? false : true,
      searchTemporary: isSearchTemporary,
      lang: lang,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };
    mutateSetUserAgentSearch(
      { payload },
      {
        onSuccess: (data) => {
          newUserData = [];

          data?.data?.map((value) => {
            setNewUserAdminAgentSearch(data)
            newUserData.push({
              email: value?.email,
              firstname: value?.firstname,
              lastname: value?.lastname,
              phone: value?.phone,
              address: value?.address,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              created_by: value?.created_by,
              is_status: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleUserActive(
                      value?.id,
                      value?.is_active === true ? false : true
                    )
                  }
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              is_temporary: (
                <Switch
                  colorScheme={
                    value?.bussines_hours?.is_temporary_closed === true
                      ? "green"
                      : "red"
                  }
                  isChecked={
                    value?.bussines_hours?.is_temporary_closed === true
                      ? true
                      : false
                  }
                  onChange={() =>
                    handleUserTemporary(
                      value?.id,
                      value?.bussines_hours?.is_temporary_closed === false
                        ? true
                        : false
                    )
                  }
                />
              ),
              temporary:
                value?.bussines_hours?.is_temporary_closed === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.TEMPORARY")}
                  </div>
                ) : value?.bussines_hours?.is_temporary_closed === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_TEMPORARY")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              transaction: (
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#1FB3A7]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() => handleDialogTransaction(true, value?.id)}
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.TOPUP_SALDO")}
                  </div>
                </Button>
              ),
              action: (
                <div className="flex gap-2">
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleResetPasswordUser(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Reset Password
                    </div>
                  </Button> */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF6627]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Edit
                    </div>
                  </Button> */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#46BC78]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleDialogTopUpBalance(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.TOPUP_SALDO")}
                    </div>
                  </Button>
                  {/* {(isDeleteAccessUserAgent === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteUserAgent(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {value?.is_active !== true || value?.is_active !== false ? (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#1FB3A7]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.BANNED")}
                      </div>
                    </Button>
                  ) : (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#18AB56]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Un{t("LABEL.BANNED")}
                      </div>
                    </Button>
                  )} */}
                </div>
              ),
            });
          });
          setNewUserAgentData(newUserData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    setIsSearch(false);
    mutateGetUserAgents(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newUserData = [];

          data?.data?.map((value) => {
            newUserData.push({
              email: value?.email,
              firstname: value?.firstname,
              lastname: value?.lastname,
              phone: value?.phone,
              address: value?.address,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              created_by: value?.created_by,
              is_status: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleUserActive(
                      value?.id,
                      value?.is_active === true ? false : true
                    )
                  }
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              is_temporary: (
                <Switch
                  colorScheme={
                    value?.bussines_hours?.is_temporary_closed === true
                      ? "green"
                      : "red"
                  }
                  isChecked={
                    value?.bussines_hours?.is_temporary_closed === true
                      ? true
                      : false
                  }
                  onChange={() =>
                    handleUserTemporary(
                      value?.id,
                      value?.bussines_hours?.is_temporary_closed === false
                        ? true
                        : false
                    )
                  }
                />
              ),
              temporary:
                value?.bussines_hours?.is_temporary_closed === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.TEMPORARY")}
                  </div>
                ) : value?.bussines_hours?.is_temporary_closed === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_TEMPORARY")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              transaction: (
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#1FB3A7]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() => handleDialogTransaction(true, value?.id)}
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.TOPUP_SALDO")}
                  </div>
                </Button>
              ),
              action: (
                <div className="flex gap-2">
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleResetPasswordUser(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Reset Password
                    </div>
                  </Button> */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF6627]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Edit
                    </div>
                  </Button> */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#46BC78]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleDialogTopUpBalance(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.TOPUP_SALDO")}
                    </div>
                  </Button>
                  {/* {(isDeleteAccessUserAgent === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteUserAgent(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                      </div>
                    </Button>
                   {/* )} */}
                  {/* {value?.is_active !== true || value?.is_active !== false ? (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#1FB3A7]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.BANNED")}
                      </div>
                    </Button>
                  ) : (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#18AB56]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Un{t("LABEL.BANNED")}
                      </div>
                    </Button>
                  )} */}
                </div>
              ),
            });
          });
          setNewUserAgentData(newUserData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    setIsSearch(false);
    mutateGetUserAgents(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newUserData = [];

          data?.data?.map((value) => {
            newUserData.push({
              email: value?.email,
              firstname: value?.firstname,
              lastname: value?.lastname,
              phone: value?.phone,
              address: value?.address,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              created_by: value?.created_by,
              is_status: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleUserActive(
                      value?.id,
                      value?.is_active === true ? false : true
                    )
                  }
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              is_temporary: (
                <Switch
                  colorScheme={
                    value?.bussines_hours?.is_temporary_closed === true
                      ? "green"
                      : "red"
                  }
                  isChecked={
                    value?.bussines_hours?.is_temporary_closed === true
                      ? true
                      : false
                  }
                  onChange={() =>
                    handleUserTemporary(
                      value?.id,
                      value?.bussines_hours?.is_temporary_closed === false
                        ? true
                        : false
                    )
                  }
                />
              ),
              temporary:
                value?.bussines_hours?.is_temporary_closed === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.TEMPORARY")}
                  </div>
                ) : value?.bussines_hours?.is_temporary_closed === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_TEMPORARY")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              transaction: (
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#1FB3A7]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() => handleDialogTransaction(true, value?.id)}
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.TOPUP_SALDO")}
                  </div>
                </Button>
              ),
              action: (
                <div className="flex gap-2">
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleResetPasswordUser(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Reset Password
                    </div>
                  </Button> */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF6627]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Edit
                    </div>
                  </Button> */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#46BC78]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleDialogTopUpBalance(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.TOPUP_SALDO")}
                    </div>
                  </Button>
                  {/* {(isDeleteAccessUserAgent === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteUserAgent(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                      </div>
                    </Button>
                   {/* )} */}
                  {/* {value?.is_active !== true || value?.is_active !== false ? (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#1FB3A7]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.BANNED")}
                      </div>
                    </Button>
                  ) : (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#18AB56]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Un{t("LABEL.BANNED")}
                      </div>
                    </Button>
                  )} */}
                </div>
              ),
            });
          });
          setNewUserAgentData(newUserData);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem("search-user-agent");
    newUserData = [];
    setIsSearch(false);

    // console.log(profileData?.data?.email);

    userAgentData?.data?.map((value) => {
      newUserData.push({
        email: value?.email,
        firstname: value?.firstname,
        lastname: value?.lastname,
        phone: value?.phone,
        address: value?.address,
        created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
        created_by: value?.created_by,
        is_status: (
          <Switch
            colorScheme={value?.is_active === true ? "green" : "red"}
            isChecked={value?.is_active === true ? true : false}
            onChange={() =>
              handleUserActive(
                value?.id,
                value?.is_active === true ? false : true
              )
            }
          />
        ),
        status:
          value?.is_active === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.ACTIVE")}
            </div>
          ) : value?.is_active === false ? (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NOT_ACTIVE")}
            </div>
          ) : (
            <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.BANNED")}
            </div>
          ),
        is_temporary: (
          <Switch
            colorScheme={
              value?.bussines_hours?.is_temporary_closed === true
                ? "green"
                : "red"
            }
            isChecked={
              value?.bussines_hours?.is_temporary_closed === true ? true : false
            }
            onChange={() =>
              handleUserTemporary(
                value?.id,
                value?.bussines_hours?.is_temporary_closed === false
                  ? true
                  : false
              )
            }
          />
        ),
        temporary:
          value?.bussines_hours?.is_temporary_closed === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.TEMPORARY")}
            </div>
          ) : value?.bussines_hours?.is_temporary_closed === false ? (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NOT_TEMPORARY")}
            </div>
          ) : (
            <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.BANNED")}
            </div>
          ),
        transaction: (
          <Button
            size="md"
            colorScheme="new"
            className="bg-[#1FB3A7]"
            style={{ borderRadius: "10px", height: "23px" }}
            onClick={() => handleDialogTransaction(true, value?.id)}
          >
            <div className=" text-white leading-[15px] font-[600] text-[12px]">
              {t("LABEL.VIEW")}
            </div>
          </Button>
        ),
        action: (
          <div className="flex gap-2">
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleResetPasswordUser(true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Reset Password
              </div>
            </Button> */}
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF6627]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleModalFormOpen(false, true, true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Edit
              </div>
            </Button> */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#46BC78]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleDialogTopUpBalance(true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.TOPUP_SALDO")}
              </div>
            </Button>
            {/* {(isDeleteAccessUserAgent === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#FF0F0F]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() => handleDialogDeleteUserAgent(true, value?.id)}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.DELETE")}
                </div>
              </Button>
            {/* )} */}
            {/* {value?.is_active !== true || value?.is_active !== false ? (
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#1FB3A7]"
                style={{ borderRadius: "10px", height: "23px" }}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.BANNED")}
                </div>
              </Button>
            ) : (
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#18AB56]"
                style={{ borderRadius: "10px", height: "23px" }}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  Un{t("LABEL.BANNED")}
                </div>
              </Button>
            )} */}
          </div>
        ),
      });
    });
    setNewUserAgentData(newUserData);
  }, [userAgentData, token, isDeleteAccessUserAgent, isViewAllAccess]);

  return (
    <Provider
      value={{
        formModal,
        formModalReset,
        formTopUp,
        formTransaction,

        resetformModal,
        resetformModalReset,
        resetformTopUp,
        resetformTransaction,

        isModalFormOpen,
        isModalFormResetOpen,
        isVisibleDeleteUser,
        isVisibleDeleteInfoUser,
        isEdit,

        userAgentData,
        userId,
        newUserAgentData,

        isLoadingUserAgentData,
        isLoadingGetUsers,
        isLoadingDeleteUser,

        handleModalFormOpen,
        handleDialogDeleteUserAgent,
        handleResetPasswordUser,
        handleDeleteUser,
        handlePaginationTable,
        handleShowEntriesTable,
        handleSearch,

        urlProfilePicture,
        setUrlProfilePicture,

        isDeleteAccessUserAgent,
        isViewAllAccess,
        isSearch,
        isShowTemporaryClose,

        isLoadingTopUpBalance,
        isModalFormTopUpOpen,
        isVisibleTopUpBalanceInfo,
        agentId,
        handleTopUpBalance,
        handleDialogTopUpBalance,

        isModalFormTransactionOpen,
        handleDialogTransaction,
        newTransactions,
        setNewTransactions,
        isLoadingListTransaction,
        transactionData,
        setTransactionData,
        handlePaginationListTransactionTable,
        handleShowEntriesListTransactionTable,
        handleSearchListTransactionTable,

        isShowType,
        newUserAdminAgentSearch,
        setIsShowTemporaryClose,
        formKeywordSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default UserAgentController;
