import { api } from "../../../../../utils/axios";

//Create Info
export const setInfo = (payload, token) =>
  api
    .post("/backoffice/info", payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Get List Info
export const getInfos = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/info/list",
      {
        filter: {
          set_is_active: false,
          is_active: false,
          set_title: false,
          title: "",
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Info
export const getInfo = ({ guid, token, language }) =>
  api
    .get(`/backoffice/info/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Get Search Info
export const getInfoSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/info/list",
      {
        filter: {
          set_is_active: payload?.isStatus === true ? true : false,
          is_active: payload?.searchStatus ? payload?.searchStatus : false,
          set_title: payload?.isTitle === true ? true : false,
          title: payload?.isTitle ? payload?.search : "",
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Delete Info
export const deleteInfo = ({ guid, token, language }) =>
  api
    .delete(`/backoffice/info/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update Info
export const updateInfo = (payload, token) =>
  api
    .put(
      `/backoffice/info/${payload?.payloadPublish?.[0]?.id}`,
      payload?.payloadPublish?.[0],
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);
