import React, { createContext, useContext, useEffect, useState } from "react";
import { TokenContext } from "../../../../../../controllers/public/TokenController";
import { useQuery, useMutation } from "react-query";
import {
  getVouchers,
  setVoucher,
  // setVoucher,
  // setVoucherEdit,
} from "../../api/VoucherApi";
import { VoucherContext } from "../../Controller";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { setUploadProfilePicture } from "../../../../../../controllers/public/api/MediaApi";

export const ModalVoucherContext = createContext();
const { Provider } = ModalVoucherContext;

const ModalVoucherController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();
  const {
    formModalInsert,
    handleModalFormOpen,
    handleModalRedeemOpen,
    refetchVoucherData,
    bankLogoUrl,
    setBankLogoUrl,
    urlProfilePicture,
    setUrlProfilePicture,
    qrCodeUrl,
    urlQrCode,
    setUrlQrCode,
    isQrCode,
    setQrCodeUrl,
    isVisibleInfoVoucher,
    setIsVisibleInfoVoucher,
  } = useContext(VoucherContext);

  const { refetch } = useQuery(["vouchers", token], () =>
    getVouchers({ token })
  );

  const {
    isLoading: isLoadingUploadLogo,
    mutate: mutateSetUploadProfilePicture,
  } = useMutation(setUploadProfilePicture);

  const { isLoading: isLoadingUploadQrCode, mutate: mutateSetUploadQrCode } =
    useMutation(setUploadProfilePicture);

  const { isLoading: isLoadingSetVoucher, mutate: mutateSetVoucher } =
    useMutation(setVoucher);

  const [isVisible, setIsVisible] = useState(false);

  const handleCreateVoucher = (payload) => {
    let payloadPublish = {
      title: payload?.title,
      nominal: payload?.nominal,
    };

    mutateSetVoucher(
      { payloadPublish, token, language },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "error",
              duration: 1500,
              isClosable: true,
            });
          } else {
            refetchVoucherData();
            // setUrlProfilePicture([]);
            // setBankLogoUrl();
            handleModalFormOpen(false);
            setIsVisibleInfoVoucher(true);
            // handleModalRedeemOpen(false);
            // setIsVisible(true);
          }
        },
      }
    );
  };

  const handleEditVoucher = (payload) => {
    const guid = payload?.guid;

    console.log(bankLogoUrl);
    console.log(qrCodeUrl);

    let payloadEditPublish = {
      is_qr_code: isQrCode,
      bank_code: payload?.bank_code,
      bank_name: payload?.bank_name,
      bank_logo_url: urlProfilePicture || bankLogoUrl,
      bank_qr_code_url: urlQrCode || qrCodeUrl,
      account_number: payload?.account_number,
      account_name: payload?.account_name,
      description: payload?.description,
    };

    // mutateSetVoucherEdit(
    //   { payloadEditPublish, guid, token, language },
    //   {
    //     onSuccess: (data) => {
    //       // console.log(data?.data)
    //       refetchVoucherData();
    //       // setUrlProfilePicture([]);
    //       // setUrlQrCode([]);
    //       // setBankLogoUrl();
    //       // setQrCodeUrl();
    //       handleModalFormOpen(false);
    //       setIsVisible(true);
    //     },
    //   }
    // );
    handleModalFormOpen(false, false, false);
    console.log(payloadEditPublish);
  };

  const handleUploadProfilePicture = (payload) => {
    let lang = t("LANGUAGE");
    const profilePicture = payload[0];
    // console.log(profilePicture);

    let dataProfilePicture = new FormData();
    dataProfilePicture.append("file", profilePicture);
    dataProfilePicture.append("old_file_url", "");
    dataProfilePicture.append("token", token);
    dataProfilePicture.append("lang", lang);

    mutateSetUploadProfilePicture(dataProfilePicture, {
      onSuccess: (data) => {
        // if (isEdit) {
        //   setUrlProfilePictureEdit(data?.data);
        //   toast({
        //     description: data?.message?.response_message,
        //     position: "top-right",
        //     status: "success",
        //     duration: 1500,
        //     isClosable: true,
        //   });
        // } else {
        setUrlProfilePicture(data?.data);
        setBankLogoUrl();
        toast({
          description: data?.message?.response_message,
          position: "top-right",
          status: "success",
          duration: 1500,
          isClosable: true,
        });
        // }
      },
    });
  };

  const handleUploadQrCode = (payload) => {
    let lang = t("LANGUAGE");
    const qrCode = payload[0];
    // console.log(profilePicture);

    let dataQrCode = new FormData();
    dataQrCode.append("file", qrCode);
    dataQrCode.append("old_file_url", "");
    dataQrCode.append("token", token);
    dataQrCode.append("lang", lang);

    mutateSetUploadQrCode(dataQrCode, {
      onSuccess: (data) => {
        // if (isEdit) {
        //   setUrlProfilePictureEdit(data?.data);
        //   toast({
        //     description: data?.message?.response_message,
        //     position: "top-right",
        //     status: "success",
        //     duration: 1500,
        //     isClosable: true,
        //   });
        // } else {
        setUrlQrCode(data?.data);
        setQrCodeUrl();
        toast({
          description: data?.message?.response_message,
          position: "top-right",
          status: "success",
          duration: 1500,
          isClosable: true,
        });
        // }
      },
    });
  };

  const handleVisibleConfirmDialog = (isOpen) => {
    setIsVisible(isOpen);
    refetch();
  };

  return (
    <Provider
      value={{
        // bankAccountListData,
        isLoadingSetVoucher,
        // isLoadingVoucher,

        handleCreateVoucher,
        handleEditVoucher,
        isVisible,
        handleVisibleConfirmDialog,

        urlProfilePicture,
        isLoadingUploadLogo,
        handleUploadProfilePicture,

        urlQrCode,
        isLoadingUploadQrCode,
        handleUploadQrCode,
      }}
    >
      {children}
    </Provider>
  );
};

export default ModalVoucherController;
