import React, { lazy, Suspense } from "react";
// import ButtonNew from "./components/ButtonNew";
// import Filter from "./components/Filter";
// import { motion } from "framer-motion";
// // import ModalUserHandheld from "./components/Modal";
// import TableUserHandheld from "./components/TableUserHandheld";
import UserHandheldController from "./Controller";
// import DeleteConfirmationDialog from "./components/Dialog/DeleteConfirmationDialog";
// import DeleteInformationDialog from "./components/Dialog/DeleteInformationDialog";
// import ModalDetail from "./components/ModalView";
// import ModalOverwrite from "./components/ModalOverwrite";

const UserHandheld = () => {
  const ButtonNew = lazy(() => import("./components/ButtonNew"));
  const Filter = lazy(() => import("./components/Filter"));
  // const ModalUserHandheld = lazy(() => import("./components/Modal")) ;
  const TableUserHandheld = lazy(() =>
    import("./components/TableUserHandheld")
  );
  const DeleteConfirmationDialog = lazy(() =>
    import("./components/Dialog/DeleteConfirmationDialog")
  );
  const DeleteInformationDialog = lazy(() =>
    import("./components/Dialog/DeleteInformationDialog")
  );
  const ModalDetail = lazy(() => import("./components/ModalView"));
  const ModalOverwrite = lazy(() => import("./components/ModalOverwrite"));

  return (
    <div>
      <UserHandheldController>
        <Suspense
          fallback={
            <div className="h-screen flex items-center">
              <div className="mx-auto">
                <div
                  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex gap-4">
            <ButtonNew />
          </div>
          <div className="pt-4">
            <Filter />
          </div>
          <div className="pt-4">
            <div className="overflow-auto h-screen">
              <TableUserHandheld />
            </div>
          </div>
          {/* <ModalUserHandheld /> */}
          <ModalDetail />
          <ModalOverwrite />
          <DeleteConfirmationDialog />
          <DeleteInformationDialog />
        </Suspense>
      </UserHandheldController>
    </div>
  );
};

export default UserHandheld;
