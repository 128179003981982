import React, { useContext } from "react";

import IconSuccess from "../../../../../../assets/img/success.png";
import { ConfirmDialog } from "primereact/confirmdialog";
import { VoucherContext } from "../../Controller";


const VoucherInfoDialog = () => {
  const { isVisibleInfoVoucher } = useContext(VoucherContext);
  return (
    <div>
      <ConfirmDialog
        visible={isVisibleInfoVoucher}
        message={
          <div className="grid grid-cols-1 font-poppins">
            <div className="flex justify-center items-center">
              <img src={IconSuccess} alt="" />,
            </div>
            <span className="py-4 leading-[24px] text-[16px] font-[700] text-[#171717]">
              Voucher telah berhasil di input
            </span>
          </div>
        }
        closable={true}
        footer={<div></div>}
      />
    </div>
  );
};

export default VoucherInfoDialog;
