import React, { lazy, Suspense } from "react";

// import DashboardSection from "./components";
import DashboardController from "./Controller";
import { motion } from "framer-motion";

const Dashboard = () => {
  const DashboardSection = lazy(() => import("./components"));
  return (
    <div>
      <DashboardController>
        <Suspense
          fallback={
            <div class="h-screen flex items-center">
              <div class="mx-auto">
                <div
                  class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <motion.div
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 100 }}
            className="bg-white"
          >
            <DashboardSection />
          </motion.div>
        </Suspense>
      </DashboardController>
    </div>
  );
};

export default Dashboard;
