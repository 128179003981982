import { api } from "../../../../../../utils/axios";

//Create QNA Master
export const setQnaMaster = (payload, token) =>
  api
    .post("/backoffice/question-answer", payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Get List QNA Master
export const getQnaMasters = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/question-answer/list",
      {
        filter: {
          set_topic_id: false,
          topic_id: "",
          set_category_id: false,
          category_id: "",
          set_question: false,
          question: "",
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get QNA Master
export const getQnaMaster = ({ guid, token, language }) =>
  api
    .get(`/backoffice/question-answer/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Get Search QNA Master
export const getQnaMasterSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/question-answer/list",
      {
        filter: {
          set_topic_id: payload?.isTopicId,
          topic_id: payload?.isTopicId ? payload?.searchTopic : "",
          set_category_id: payload?.isCategoryId,
          category_id: payload?.isCategoryId ? payload?.searchCategory : "",
          set_question: payload?.isQuestion,
          question: payload?.isQuestion ? payload?.searchQuestion : "",
        },
        limit: 1000,
        page: 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Delete QNA Master
export const deleteQnaMaster = ({ guid, token, language }) =>
  api
    .delete(`/backoffice/question-answer/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update QNA Master
export const updateQnaMaster = (payload, token) =>
  api
    .put(
      `/backoffice/question-answer/${payload?.payloadPublish?.[0]?.id}`,
      payload?.payloadPublish?.[0],
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);
