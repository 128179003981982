import React from "react";
import id from "../../assets/img/indonesia.png";
import cn from "../../assets/img/china.png";
import gb from "../../assets/img/united-kingdom.png";

const SelectedCountries = (option, props) => {
  if (option) {
    return (
      <div className="flex gap-2">
        <img
          src={
            option?.value === "gb"
              ? gb
              : option?.value === "id"
              ? id
              : option?.value === "cn"
              ? cn
              : ""
          }
          alt=""
          className="h-7 w-7"
        />
        <div>{option?.label}</div>
      </div>
    );
  }
  return <span>{props.placeholder}</span>;
};

export default SelectedCountries;
