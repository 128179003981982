import { api } from "../../../../../../utils/axios";

//Get List TransactionList
export const getTransactionList = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/transaction/ppob/list",
      {
        filter: {
          set_category: false,
          category: "",
          set_brand: false,
          brand: "",
          set_type: false,
          type: "",
          set_seller_name: false,
          seller_name: "",
          set_buyer_sku_code: false,
          buyer_sku_code: "",
          set_slug_name: false,
          slug_name: "",
          set_product_name: false,
          product_name: "",
          set_description: false,
          description: "",
          set_biller_ref_id: false,
          biller_ref_id: "",
          set_payment_status: false,
          payment_status: "",
          set_payment_method_id: false,
          payment_method_id: 0,
          set_payment_channel: false,
          payment_channel: "",
          set_use_point: false,
          use_point: false,
          set_transaction_id: false,
          transaction_id: "",
          set_user_guid: false,
          user_guid: "",
          set_user_phone: false,
          user_phone: "",
          set_user_email: false,
          user_email: "",
          set_user_fullname: false,
          user_fullname: "",
          set_date_range: false,
          date_from: null,
          date_to: null,
        },
        limit: limit || 10,
        page: page || 1,
        is_all_data: false,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get List TransactionList
export const getTransactionListSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/transaction/ppob/list",
      {
        filter: {
          set_category: false,
          category: "",
          set_brand: false,
          brand: "",
          set_type: false,
          type: "",
          set_seller_name: false,
          seller_name: "",
          set_buyer_sku_code: false,
          buyer_sku_code: "",
          set_slug_name: false,
          slug_name: "",
          set_product_name: payload?.isProduct === true ? true : false,
          product_name: payload?.isProduct ? payload?.search : "",
          set_description: false,
          description: "",
          set_biller_ref_id: false,
          biller_ref_id: "",
          set_payment_status: payload?.isPaymentStatus === true ? true : false,
          payment_status: payload?.isPaymentStatus ? payload?.search : "",
          set_payment_method_id: false,
          payment_method_id: 0,
          set_payment_channel: false,
          payment_channel: "",
          set_use_point: false,
          use_point: false,
          set_transaction_id: payload?.isTransactionID === true ? true : false,
          transaction_id: payload?.isTransactionID ? payload?.search : "",
          set_user_guid: payload?.isUserID === true ? true : false,
          user_guid: payload?.isUserID ? payload?.search : "",
          set_user_phone: false,
          user_phone: "",
          set_user_email: payload?.isUserEmail === true ? true : false,
          user_email: payload?.isUserEmail ? payload?.search : "",
          set_user_fullname: false,
          user_fullname: "",
          set_username: payload?.isUsername === true ? true : false,
          username: payload?.isUsername ? payload?.search : "",
          set_target: payload?.isTarget === true ? true : false,
          target: payload?.isTarget ? payload?.search : "", //Target from destination_number billing
          set_date_range: payload?.isRange,
          date_from: payload?.publishFrom,
          date_to: payload?.publishTo,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        is_all_data: false,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get List TransactionList Export Excel
export const getTransactionListExport = ({ token, language, payloadPublish }) =>
  api
    .post(
      "/backoffice/transaction/ppob/list",
      {
        filter: {
          set_category: false,
          category: "",
          set_brand: false,
          brand: "",
          set_type: false,
          type: "",
          set_seller_name: false,
          seller_name: "",
          set_buyer_sku_code: false,
          buyer_sku_code: "",
          set_slug_name: false,
          slug_name: "",
          set_product_name: false,
          product_name: "",
          set_description: false,
          description: "",
          set_biller_ref_id: false,
          biller_ref_id: "",
          set_payment_status: false,
          payment_status: "",
          set_payment_method_id: false,
          payment_method_id: 0,
          set_payment_channel: false,
          payment_channel: "",
          set_use_point: false,
          use_point: false,
          set_transaction_id: false,
          transaction_id: "",
          set_user_guid: false,
          user_guid: "",
          set_user_phone: false,
          user_phone: "",
          set_user_email: false,
          user_email: "",
          set_user_fullname: false,
          user_fullname: "",
          set_date_range: true,
          date_from: payloadPublish?.publish_from,
          date_to: payloadPublish?.publish_to,
        },
        limit: 10,
        page: 1,
        is_all_data: true,
        order: "created_at",
        sort: "ASC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Transaction By ID
export const getTransactionById = ({ guid, token, language }) =>
  api
    .get(`/backoffice/transaction/ppob/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
