import { api } from "../../../../../utils/axios";

//Update Minimum Top Up
export const updateMinimumTopUp = ({ token, language, payload }) =>
  api
    .put(`/backoffice/user-app/point/minimum-topup`, payload, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Minimum Top Up
export const getMinimumTopUp = ({ token, language }) =>
  api
    .get(`/backoffice/user-app/point/minimum-topup`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update Maximum Top Up
export const updateMaximumTopUp = ({ token, language, payload }) =>
  api
    .put(`/backoffice/user-app/point/maximum-topup`, payload, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Maximum Top Up
export const getMaximumTopUp = ({ token, language }) =>
  api
    .get(`/backoffice/user-app/point/maximum-topup`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
