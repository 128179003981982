import { useEffect, useRef, useState } from "react";
import { BsCardImage, BsUpload } from "react-icons/bs";
import { GrAttachment } from "react-icons/gr";

const UploadImageChat = ({
  label = "",
  isDisabled,
  onChange = () => "",
  ...props
}) => {
  const inputRef = useRef(null);
  const [currentLabel, setCurrentLabel] = useState(label);
  const [currentLabelArray, setCurrentLabelArray] = useState([]);

  useEffect(() => {
    // setCurrentLabel(props?.fileList?.[0]?.name || label);
    // console.log(label)
    setCurrentLabelArray(label);
  }, [label, props?.fileList]);

  const handleInput = () => {
    inputRef.current.click();
  };

  const uploadLimit = 10_000_000; // 10 MB
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setCurrentLabelArray(files);

    files?.forEach((listImage) => {
      if (listImage?.size > uploadLimit) {
        alert("Size Maksimal 10MB");
        return;
      }

      // setCurrentLabelArray(listImage?.name);
    });

    // if (e.target.files?.length) {
    //   const file = e.target.files[0];

    //   if (file?.size > uploadLimit) {
    //     alert("Size Maksimal 10MB");
    //     return;
    //   }

    //   // if (file?.type !== "image/png") {
    //   //   alert("Format Image Salah");
    //   //   return;
    //   // }

    //   setCurrentLabel(file.name);
    // } else {
    //   setCurrentLabel(label);
    // }

    onChange(e.target.files?.length ? e.target.files : null);
  };

  return (
    <div>
      {/* {console.log(currentLabelArray)} */}
      <button
        // className="default-chakra-input text-sm text-gray-600"
        className={`text-sm text-gray-600 justify-center items-center break-words`}
        onClick={handleInput}
        disabled={isDisabled}
      >
        <BsCardImage
          className={`${isDisabled && "text-gray-300"}`}
          style={{
            height: "1.5em",
            width: "1.5em",
            color: "#767D87",
          }}
        />
        {/* {!currentLabelArray && (
          <GrAttachment
            className={`${isDisabled && "text-gray-300"}`}
            style={{
              height: "2em",
              width: "2em",
              color: "#767D87",
            }}
          />
        )} */}
        {/* <div className="w-full">
          <span className={`${isDisabled && "text-gray-300"}`}></span>
          <ul>
            {currentLabelArray &&
              currentLabelArray.map((image, index) => (
                <li key={index}>{image.name}</li>                
              ))}
          </ul>
        </div> */}
      </button>
      <input
        type="file"
        className="hidden"
        ref={inputRef}
        onChange={handleFileChange}
        accept="image/*"
        multiple
      />
    </div>
  );
};

export default UploadImageChat;
