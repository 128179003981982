import { Button, Switch, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import {
  getQnaTickets,
  getQnaTicket,
  getQnaTicketSearch,
  setQnaTicketChat,
  updateAssignTicket,
} from "./api/QnaTicketApi";
import Cookies from "js-cookie";

import html2canvas from "html2canvas";
import { setUploadImageWebsite } from "../../../../../controllers/public/api/MediaApi";
import { getTopics } from "./api/TopicApi";

export const QnaTicketContext = createContext();
const { Provider } = QnaTicketContext;

const QnaTicketController = ({ children }) => {
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  let language = t("LANGUAGE");

  const componentRef = useRef(null);
  const chatContainerRef = useRef(null);

  const toast = useToast();

  const [newQnaTicket, setNewQnaTicket] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isModalFormUpdateSellPriceOpen, setIsModalFormUpdateSellPriceOpen] =
    useState(false);
  const [qnaTicketId, setQnaTicketId] = useState();
  const [isVisibleUpdateSellPriceInfo, setIsVisibleUpdateSellPriceInfo] =
    useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isVisibleDownloadTransaction, setIsVisibleDownloadTransaction] =
    useState(false);
  const [
    isVisibleDownloadInfoTransaction,
    setIsVisibleDownloadInfoTransaction,
  ] = useState(false);

  const [transaksiId, setTransaksiId] = useState();
  const [isVisibleApproveTransaksi, setIsVisibleApproveTransaksi] =
    useState(false);
  const [isVisibleApproveTransaksiInfo, setIsVisibleApproveTransaksiInfo] =
    useState(false);
  const [isVisibleRejectTransaksi, setIsVisibleRejectTransaksi] =
    useState(false);
  const [isVisibleRejectTransaksiInfo, setIsVisibleRejectTransaksiInfo] =
    useState(false);

  const [isVisibleTransactionTime, setIsVisibleTransactionTime] =
    useState(false);
  const [isVisibleTransactionTimeInfo, setIsVisibleTransactionTimeInfo] =
    useState(false);
  const [isVisibleAmount, setIsVisibleAmount] = useState(false);
  const [isVisibleAmountInfo, setIsVisibleAmountInfo] = useState(false);
  // const [imageUrl, setImageUrl] = useState([]);
  const [imagesUrl, setimagesUrl] = useState([]);
  const [loadingUploadImage, setIsLoadingUploadImage] = useState(false);
  const [isChatRoom, setIsChatRoom] = useState(false);

  const [pres, setPres] = useState();
  const [uniqueNumber, setUniqueNumber] = useState();
  const [isTopicSearch, setIsTopicSearch] = useState(true);
  const [isCategorySearch, setIsCategorySearch] = useState(false);
  const [isQuestionSearch, setIsQuestionSearch] = useState(false);
  const [filterCategoryData, setFilterCategoryData] = useState();
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [urlImageOpen, setUrlImageOpen] = useState();

  const {
    data: FilterTopicsData,
    isLoading: isLoadingFilterTopicsData,
    refetch: refetchFilterTopicsData,
  } = useQuery(["filtertopics", token, language], () =>
    getTopics({ token, language })
  );

  const {
    data: qnaTicketList,
    isLoading: isLoadingQnaTicketList,
    refetch: refecthQnaTicketData,
  } = useQuery(["confirmationapproval", token, language], () =>
    getQnaTickets({ token, language })
  );

  const {
    isLoading: isLoadingSetQnaTicketSearch,
    mutate: mutateSetQnaTicketSearch,
  } = useMutation(getQnaTicketSearch);

  const {
    isLoading: isLoadingGetQnaTicketList,
    mutate: mutateGetConfirmationApproval,
    refetch: refetchGetQnaTicketList,
  } = useMutation(getQnaTickets);

  const { isLoading: isLoadingGetQnaTicket, mutate: mutateGetQnaTicket } =
    useMutation(getQnaTicket);

  const { isLoading: isLoadingAssignTicket, mutate: mutateSetAssignTicket } =
    useMutation(updateAssignTicket);

  const {
    isLoading: isLoadingSetQnaTicketChat,
    mutate: mutateSetQnaTicketChat,
  } = useMutation(setQnaTicketChat);

  let newQnaTicketListsData = [];
  let currentPage = [];
  let currentLimit = [];
  // let imageUrl = [];

  const [isGetAccessPrabayarTransaction, setIsGetAccessPrabayarTransaction] =
    useState();
  const [
    isDeleteAccessPrabayarTransaction,
    setIsDeleteAccessPrabayarTransaction,
  ] = useState();
  const [
    isUpdateAccessPrabayarTransaction,
    setIsUpdateAccessPrabayarTransaction,
  ] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Prabayar Transaction
          const getAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessPrabayarTransaction(
            getAccessPrabayarTransaction?.length
          );

          const deleteAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessPrabayarTransaction(
            deleteAccessPrabayarTransaction?.length
          );

          const updateAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessPrabayarTransaction(
            updateAccessPrabayarTransaction?.length
          );
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Publish
  // -------------------------------
  const formPublish = useForm();
  const resetFormPublish = (newForm) => {
    const dateFrom = new Date();
    const dateTo = new Date();
    const publishFrom = dateFrom.getDate() + 1;
    const publishTo = dateTo.getDate() + 2;
    dateFrom.setDate(publishFrom);
    dateTo.setDate(publishTo);
    const defaultDatePublishFrom = moment(dateFrom).format("YYYY-MM-DDTHH:mm");
    const defaultDatePublishTo = moment(dateTo).format("YYYY-MM-DDTHH:mm");

    const publishFromEdit = moment(newForm?.publish_from).format(
      "YYYY-MM-DDTHH:mm"
    );
    const publishToEdit = moment(newForm?.publish_to).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formPublish.reset({
        publish_from: newForm?.publish_from,
        publish_to: newForm?.publish_to,
        status: 1,
      });
    }
  };

  // -------------------------------
  // Form Chat Detail
  // -------------------------------
  const formChatDetail = useForm();
  const resetformChatDetail = (newForm) => {
    if (newForm) {
      formChatDetail.reset({
        guid: newForm?.guid,
        ticket_number: newForm?.ticket_number,
        topic: newForm?.topic,
        question: newForm?.question,
        user: newForm?.user,
        customer_service: newForm?.customer_service,
        url_images: newForm?.url_images,
        status: newForm?.status,
        created_at: moment(newForm?.created_at)
          .utc()
          .add(7, "hours")
          .format("DD/MM/YYYY HH:mm:ss"),
        created_by: newForm?.created_by,
        done_at: moment(newForm?.done_at)
          .utc()
          .add(7, "hours")
          .format("DD/MM/YYYY HH:mm:ss"),
        done_by: newForm?.done_by,
        chat: newForm?.chat,
      });
    }
  };

  // -------------------------------
  // Form Chat Publish
  // -------------------------------
  const formChatPublish = useForm();
  const resetformChatPublish = (newForm) => {
    if (newForm) {
      formChatPublish.reset({
        ticket_guid: newForm?.guid,
        chat_text: newForm?.chat_text,
        image_url: newForm?.image_url,
      });
    }
  };

  let images = [];

  const handlePreviewImageClick = (url) => {
    // console.log(url)
    setUrlImageOpen(url);
    setPreviewOpen(true);
  };

  const handleClosePreviewImage = () => {
    setPreviewOpen(false);
  };

  const handleUploadImageUrl = async (payload) => {
    const filesArray = Array.from(payload);
    // setImageUrl(filesArray);
    setIsLoadingUploadImage(true);
    try {
      await Promise.all(
        filesArray.map(async (dataImage, idx) => {
          let dataImageUrl = new FormData();
          dataImageUrl.append("file", dataImage);
          dataImageUrl.append("old_file_url", "");
          dataImageUrl.append("token", token);

          const res = await setUploadImageWebsite(dataImageUrl);
          const url = res?.data;
          setIsLoadingUploadImage(false);

          // images.push(url);
          setimagesUrl([...imagesUrl, url]);
          // const newArray = imagesUrl.map((item) => item);
          //  // Add a new element to the new array
          // newArray.push(url);

          // // Update the state with the new array
          // setimagesUrl(newArray);
          // imagesUrl.push(url);
        })
      );
    } catch (error) {
      console.error("Error uploading images:", error);
    }
    // console.log(images)
    // console.log(imagesUrl);
  };

  const handleChatRoomDetail = (isOpen, guid) => {
    // setIsModalFormOpen(isOpen);
    setQnaTicketId(guid);
    setIsChatRoom(isOpen);

    mutateGetQnaTicket(
      { guid, token },
      {
        onSuccess: (data) => {
          resetformChatDetail(data.data);
        },
      }
    );
  };

  const handleChatAssign = (isOpen, guid) => {
    // setIsModalFormOpen(isOpen);
    setQnaTicketId(guid);
    setIsChatRoom(isOpen);

    mutateSetAssignTicket(
      { guid, token, language },
      {
        onSuccess: (data) => {
          resetformChatDetail(data.data);
        },
      }
    );
  };

  const handleCreateChat = async (payload) => {
    // let images = [];

    // console.log(payload);
    // try {
    //   await Promise.all(
    //     imageUrl.map(async (dataImage, idx) => {
    //       let dataImageUrl = new FormData();
    //       dataImageUrl.append("file", dataImage);
    //       dataImageUrl.append("old_file_url", "");
    //       dataImageUrl.append("token", token);

    //       const res = await setUploadImageWebsite(dataImageUrl);
    //       const url = res?.data;
    //       images.push(url);
    //     })
    //   );
    // } catch (error) {
    //   console.error("Error uploading images:", error);
    // }

    const payloadPublish = [
      {
        ticket_guid: qnaTicketId,
        chat_text: payload?.chat_text,
        image_url: imagesUrl,
      },
    ];

    // console.log(payloadPublish);
    // images = [""]

    mutateSetQnaTicketChat(
      { payloadPublish, token, language },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              title: "Data Chat Masih Belum Lengkap",
              description: data?.message?.response_message,
              position: "top-right",
              status: "warning",
              duration: 1500,
              isClosable: true,
            });
          } else {
            images = [""];
            formChatPublish.setValue("chat_text", "");
            formChatPublish.setValue("image_url", "");
            resetformChatPublish();
            setimagesUrl([]);
            mutateGetQnaTicket(
              { guid: qnaTicketId, token },
              {
                onSuccess: (data) => {
                  // formChatPublish.reset();
                  resetformChatDetail(data.data);
                },
              }
            );
          }
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword) => {
    let isTopicId = keyword?.keyword_topic && true;
    // let isExternalOrderNo = keyword === "2" && true;
    // let isShopName = keyword === "3" && true;
    // let isCashier = keyword === "4" && true;
    // let isType = keyword === "5" && true;
    // let isTransactionTime = keyword === "6" && true;
    // let isAmount = keyword === "7" && true;
    // let isMessage = keyword === "8" && true;
    // let isStatus = keyword === "9" && true;

    setIsSearch(true);

    let payload = {
      isTopicId: isTopicId,
      // isExternalOrderNo: isExternalOrderNo,
      // isShopName: isShopName,
      // isCashier: isCashier,
      // isType: isType,
      // isTransactionTime: isTransactionTime,
      // isAmount: isAmount,
      // isMessage: isMessage,
      // isStatus: isStatus,
      search: keyword?.keyword_topic,
      language: language,
      token: token,
    };

    currentPage.push(1);
    currentLimit.push(100);
    mutateSetQnaTicketSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            newQnaTicketListsData.push({
              guid: value?.guid,
              ticket_number: value?.ticket_number,
              user: value?.user?.username,
              customer_service: value?.customer_service?.name,
              status:
                value?.status === "done" ? (
                  <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "new" ? (
                  <div className="bg-blue-500 text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#faf089] text-gray-800 leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              created_at: moment(value?.created_at)
                .utc()
                .local()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                  <div className="flex gap-2">
                    {value?.status === "new" ? (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleChatAssign(true, value?.guid)}
                      >
                        {value?.status === "new" && (
                          <span class="absolute right-3/4 -top-2 rounded-full bg-red-600 px-1 leading-4 text-xs font-medium text-white">
                            !
                          </span>
                        )}
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.ASSIGNE")}
                        </div>
                      </Button>
                    ) : (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleChatRoomDetail(true, value?.guid)}
                      >
                        {!value?.is_read && (
                          <span class="absolute right-3/4 -top-2 rounded-full bg-red-600 px-1 leading-4 text-xs font-medium text-white">
                            !
                          </span>
                        )}
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.VIEW")}
                        </div>
                      </Button>
                    )}
                  </div>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewQnaTicket(newQnaTicketListsData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    // console.log(limit)
    mutateGetConfirmationApproval(
      { limit, token },
      {
        onSuccess: (data) => {
          newQnaTicketListsData = [];

          data?.data?.map((value) => {
            newQnaTicketListsData.push({
              guid: value?.guid,
              ticket_number: value?.ticket_number,
              user: value?.user?.username,
              customer_service: value?.customer_service?.name,
              status:
                value?.status === "done" ? (
                  <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "new" ? (
                  <div className="bg-blue-500 text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#faf089] text-gray-800 leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              created_at: moment(value?.created_at)
                .utc()
                .local()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                  <div className="flex gap-2">
                    {value?.status === "new" ? (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleChatAssign(true, value?.guid)}
                      >
                        {value?.status === "new" && (
                          <span class="absolute right-3/4 -top-2 rounded-full bg-red-600 px-1 leading-4 text-xs font-medium text-white">
                            !
                          </span>
                        )}
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.ASSIGNE")}
                        </div>
                      </Button>
                    ) : (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleChatRoomDetail(true, value?.guid)}
                      >
                        {!value?.is_read && (
                          <span class="absolute right-3/4 -top-2 rounded-full bg-red-600 px-1 leading-4 text-xs font-medium text-white">
                            !
                          </span>
                        )}
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.VIEW")}
                        </div>
                      </Button>
                    )}
                  </div>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewQnaTicket(newQnaTicketListsData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);

    mutateGetConfirmationApproval(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newQnaTicketListsData = [];

          data?.data?.map((value) => {
            newQnaTicketListsData.push({
              guid: value?.guid,
              ticket_number: value?.ticket_number,
              user: value?.user?.username,
              customer_service: value?.customer_service?.name,
              status:
                value?.status === "done" ? (
                  <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "new" ? (
                  <div className="bg-blue-500 text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#faf089] text-gray-800 leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              created_at: moment(value?.created_at)
                .utc()
                .local()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                  <div className="flex gap-2">
                    {value?.status === "new" ? (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleChatAssign(true, value?.guid)}
                      >
                        {value?.status === "new" && (
                          <span class="absolute right-3/4 -top-2 rounded-full bg-red-600 px-1 leading-4 text-xs font-medium text-white">
                            !
                          </span>
                        )}
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.ASSIGNE")}
                        </div>
                      </Button>
                    ) : (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleChatRoomDetail(true, value?.guid)}
                      >
                        {!value?.is_read && (
                          <span class="absolute right-3/4 -top-2 rounded-full bg-red-600 px-1 leading-4 text-xs font-medium text-white">
                            !
                          </span>
                        )}
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.VIEW")}
                        </div>
                      </Button>
                    )}
                  </div>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewQnaTicket(newQnaTicketListsData);
        },
      }
    );
  };

  useEffect(() => {
    Cookies.remove("topic_search");
    Cookies.remove("topic_category");
    newQnaTicketListsData = [];
    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    qnaTicketList?.data?.map((value) => {
      newQnaTicketListsData.push({
        guid: value?.guid,
        ticket_number: value?.ticket_number,
        user: value?.user?.username,
        customer_service: value?.customer_service?.name,
        status:
          value?.status === "done" ? (
            <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "new" ? (
            <div className="bg-blue-500 text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : (
            <div className="bg-[#faf089] text-gray-800 leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ),
        created_at: 
          moment(value?.created_at)
          .utc()
          .local()
          .add(7, "hours")
          .format("DD/MM/YYYY HH:mm:ss"),
        action: (
          <div className="flex items-center justify-center">
            {/* {(isGetAccessPrabayarTransaction === 1 ||
              isViewAllAccess === true) && ( */}
            <div className="flex gap-2">
              {value?.status === "new" ? (
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#504178]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() => handleChatAssign(true, value?.guid)}
                >
                  {value?.status === "new" && (
                    <span class="absolute right-3/4 -top-2 rounded-full bg-red-600 px-1 leading-4 text-xs font-medium text-white">
                      !
                    </span>
                  )}
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.ASSIGNE")}
                  </div>
                </Button>
              ) : (
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#504178]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() => handleChatRoomDetail(true, value?.guid)}
                >
                  {!value?.is_read && (
                    <span class="absolute right-3/4 -top-2 rounded-full bg-red-600 px-1 leading-4 text-xs font-medium text-white">
                      !
                    </span>
                  )}
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.VIEW")}
                  </div>
                </Button>
              )}
            </div>
            {/* )} */}
          </div>
        ),
      });
    });
    setNewQnaTicket(newQnaTicketListsData);
  }, [
    qnaTicketList,
    token,
    isGetAccessPrabayarTransaction,
    isDeleteAccessPrabayarTransaction,
    isUpdateAccessPrabayarTransaction,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formChatDetail,

        qnaTicketList,
        isLoadingQnaTicketList,
        refecthQnaTicketData,
        newQnaTicketListsData,
        newQnaTicket,

        isLoadingSetQnaTicketSearch,
        isSearch,
        setIsSearch,
        handleSearch,
        handleShowEntriesTable,
        handlePaginationTable,
        isModalFormOpen,
        handleChatRoomDetail,
        isGetAccessPrabayarTransaction,
        isDeleteAccessPrabayarTransaction,
        isUpdateAccessPrabayarTransaction,
        isViewAllAccess,
        isVisibleDownloadTransaction,
        isVisibleDownloadInfoTransaction,
        formPublish,
        isVisibleApproveTransaksi,
        isVisibleApproveTransaksiInfo,
        isVisibleRejectTransaksi,
        isVisibleRejectTransaksiInfo,
        transaksiId,
        uniqueNumber,
        setUniqueNumber,
        isVisibleTransactionTime,
        isVisibleTransactionTimeInfo,
        isVisibleAmount,
        isVisibleAmountInfo,
        handleCreateChat,
        formChatPublish,
        resetformChatPublish,
        chatContainerRef,
        handleChatRoomDetail,
        handleUploadImageUrl,
        isLoadingGetQnaTicket,
        isChatRoom,
        setIsChatRoom,
        imagesUrl,
        setimagesUrl,
        loadingUploadImage,
        qnaTicketId,
        setQnaTicketId,

        FilterTopicsData,
        isLoadingFilterTopicsData,
        isTopicSearch,
        setIsTopicSearch,
        isCategorySearch,
        setIsCategorySearch,
        isQuestionSearch,
        setIsQuestionSearch,

        filterCategoryData,
        setFilterCategoryData,
        // setIsCategoryGuid,
        // isCategoryGuid
        isPreviewOpen,
        setPreviewOpen,
        handlePreviewImageClick,
        handleClosePreviewImage,

        urlImageOpen,
      }}
    >
      {children}
    </Provider>
  );
};

export default QnaTicketController;
