import { api } from "../../../../../../utils/axios";

//Create Advertising
export const setTopic = (payload, token) =>
  api
    .post("/backoffice/topic", payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Get List Advertising Banner
export const getTopics = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/topic/list",
      {
        filter: {
          set_title: false,
          title: "",
          set_descriptions: false,
          descriptions: "",
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Topic
export const getTopic = ({ guid, token, language }) =>
  api
    .get(`/backoffice/topic/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Get Search Topic
export const getTopicSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/topic/list",
      {
        filter: {
          set_title: payload?.isTitle === true ? true : false,
          title: payload?.isTitle ? payload?.search : "",
          set_descriptions: payload?.isDescription === true ? true : false,
          descriptions: payload?.isDescription ? payload?.search : "",
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Delete Topic
export const deleteTopic = ({ guid, token, language }) =>
  api
    .delete(`/backoffice/topic/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update Topic
export const updateTopic = (payload, token) =>
  api
    .put(
      `/backoffice/topic/${payload?.payloadPublish?.[0]?.id}`,
      payload?.payloadPublish?.[0],
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Update Toggle Topic
export const updateToggleTopic = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/topic/is-active/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
