import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import * as BiIcon from "react-icons/bi";
import Sidebar from "./Sidebar";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { RiLockPasswordLine } from "react-icons/ri";
import { AuthContext } from "../../../../controllers/public/AuthController";
import { privateSlug } from "../../../../utils/privateSlug";
import { useTranslation } from "react-i18next";

const TabletBar = () => {
  const { handleLogout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleDrawerOpen = (isOpen) => {
    setIsOpen(isOpen);
  };

  return (
    <div
      className="w-full fixed bottom-0 flex bg-white"
      style={{ boxShadow: "0px 0px 20px rgba(0,0,0,0.05)" }}
    >
      <Link
        style={{ textDecoration: "none" }}
        to={"dashboard"}
        className="py-3 flex-1 flex justify-center border-t"
      >
        <BiIcon.BiHome className="text-2xl" />
      </Link>
      <div className="py-3 flex-1 flex justify-center border-t">
        <Menu>
          <MenuButton>
            <BiIcon.BiUser className="text-2xl" />
          </MenuButton>
          <MenuList>
            <Link
              to={privateSlug.PROFILE}
              className="hover:no-underline hover:text-slate-800"
            >
              <MenuItem icon={<BiIcon.BiUser />}>{t("LABEL.PROFILE")}</MenuItem>
            </Link>
            <Link
              to={privateSlug.CHANGEPASSWORD}
              className="hover:no-underline hover:text-slate-800"
            >
              <MenuItem icon={<RiLockPasswordLine />}>
                {t("LABEL.CHANGE_PASSWORD")}
              </MenuItem>
            </Link>
            <MenuItem icon={<BiIcon.BiLogOut />} onClick={() => handleLogout()}>
              {t("LABEL.LOGOUT")}
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
      <button
        onClick={() => handleDrawerOpen(true)}
        className="py-3 flex-1 flex justify-center border-t"
      >
        <BiIcon.BiMenu className="text-2xl" />
      </button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => handleDrawerOpen(false)}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <Sidebar />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default TabletBar;
