import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import MainRoutes from "./routes";

// Create a client React Query
const queryClient = new QueryClient();

const theme = extendTheme({
  colors: {
    global: {
      100: "#504178",
    },
    new: {
      100: "#C7C4CF",
    },
  },
});

function App() {
  return (
    <div className="poppins">
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <MainRoutes />
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </div>
  );
}

export default App;
