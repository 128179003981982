import { api } from "../../../../../../utils/axios";

//Get List Customs Declaration
export const getCustomsDeclarationList = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/custom-declaration",
      {
        filter: {
          set_status: false,
          status: "",
          set_date_range: false,
          date_from: null,
          date_to: null,
        },
        limit: limit || 10,
        page: page || 1,
        is_all_data: false,
        order: "approval_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get List Custom Declaration Search
export const getCustomsDeclarationListSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/custom-declaration",
      {
        filter: {
          set_status:
            payload?.isWaitingApprovePayment === true ||
            payload?.isProcess === true ||
            payload?.isPaymentReject === true ||
            payload?.isSuccess === true ||
            payload?.isProcessFailed === true
              ? true
              : false,
          status:
            payload?.isWaitingApprovePayment === true
              ? "waiting approve payment"
              : payload?.isProcess === true
              ? "process"
              : payload?.isPaymentReject === true
              ? "payment reject"
              : payload?.isSuccess === true
              ? "success"
              : payload?.isProcessFailed === true
              ? "process failed"
              : "",
          set_date_range: payload?.isRange === true ? true : false,
          date_from: payload?.publish_from,
          date_to: payload?.publish_to,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        is_all_data: false,
        order: "approval_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get Customs Declaration By ID
export const getCustomsDeclarationById = ({ guid, token, language }) =>
  api
    .get(`/backoffice/custom-declaration/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Approve Customs Declaration
export const approveCustomsDeclaration = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/custom-declaration/approve/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Reject Customs Declaration
export const rejectCustomsDeclaration = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/custom-declaration/reject/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
