import { api } from "../../../../../../utils/axios";

//Set Bank Account Edit
export const setOverwriteSaldo = ({ token, language, payload }) =>
  api
    .post(`/backoffice/user-app/point/manual-input`, payload, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
