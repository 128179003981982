import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TokenContext } from "../../../../../controllers/public/TokenController";

import { Button, Switch, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import {
  getTopicsCategory,
  getAdvertisingBannerSearch,
  deleteTopicCategory,
  updateToggleTopicCategory,
  getTopicCategory,
  getTopicCategorySearch,
} from "./api/TopicCategoryApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import Cookies from "js-cookie";
import { getTopics } from "../Topic/api/TopicApi";

export const TopicCategoryContext = createContext();
const { Provider } = TopicCategoryContext;

const TopicCategoryController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: TopicsData,
    isLoading: isLoadingTopicsData,
    refetch: refetchTopicsData,
  } = useQuery(["topics", token, language], () =>
    getTopics({ token, language })
  );

  const {
    data: TopicsCategoryData,
    isLoading: isLoadingTopicsCategoryData,
    refetch: refetchTopicsCategoryData,
  } = useQuery(["topiccategory", token, language], () =>
    getTopicsCategory({ token, language })
  );

  const { mutate: mutateGetTopicsCategory } = useMutation(getTopicsCategory);

  const { mutate: mutateGetTopicCategory } = useMutation(getTopicCategory);

  const {
    isLoading: isLoadingSetAdvertisingBannerSearch,
    mutate: mutateSetTopicCategorySearch,
  } = useMutation(getTopicCategorySearch);

  const {
    isLoading: isLoadingDeleteTopicCategory,
    mutate: mutateDeleteTopicCategory,
  } = useMutation(deleteTopicCategory);

  const {
    isLoading: isLoadingUpdateToggleTopicCategory,
    mutate: mutateUpdateToggleTopicCategory,
  } = useMutation(updateToggleTopicCategory);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleTopicCategoryDialog, setIsVisibleTopicCategoryDialog] =
    useState(false);
  const [isVisibleDeleteTopicCategory, setIsVisibleDeleteTopicCategory] =
    useState(false);
  const [
    isVisibleDeleteInfoTopicCategory,
    setIsVisibleDeleteInfoTopicCategory,
  ] = useState(false);

  const [isPublishTopicCategoryOpen, setIsPublishTopicCategoryOpen] =
    useState(false);
  const [newTopicCategory, setnewTopicCategory] = useState();

  const [topicCategoryId, setTopicCategoryId] = useState();

  const [payloadTopicCategoryEdit, setPayloadTopicCategoryEdit] = useState();
  const [indexTabActive, setIndexTabActive] = useState();

  const [enDescription, setEnDescription] = useState("");
  const [idDescription, setIdDescription] = useState("");
  const [cnDescription, setCnDescription] = useState("");
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [newMasterTopicCategorySearch, setNewMasterTopicCategorySearch] =  useState();

  let newTopicsCategoryData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessBanner, setIsGetAccessBanner] = useState();
  const [isDeleteAccessBanner, setIsDeleteAccessBanner] = useState();
  const [isUpdateAccessBanner, setIsUpdateAccessBanner] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Banner
          const getAccessTopic = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessBanner(getAccessTopic?.length);

          const deleteAccessTopic = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessBanner(deleteAccessTopic?.length);

          const updateAccessTopic = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessBanner(updateAccessTopic?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadTopicCategoryEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formEn.reset({
        date: defaultValue,
        topic_id: newForm?.topic_id,
        category: newForm?.category,
        description: newForm?.description,
      });
    }

    if (newForm && isEdit === true) {
      formEn.reset({
        date: dateEdit,
        topic_id: payloadTopicCategoryEdit?.topic_id,
        category: payloadTopicCategoryEdit?.category?.en,
        description: payloadTopicCategoryEdit?.description?.en,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, [isEdit, payloadTopicCategoryEdit]);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadTopicCategoryEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formId.reset({
        date: defaultValue,
        topic_id: newForm?.topic_id,
        category: newForm?.category,
        description: newForm?.description,
      });
    }

    if (newForm && isEdit === true) {
      formId.reset({
        date: dateEdit,
        topic_id: payloadTopicCategoryEdit?.topic_id,
        category: payloadTopicCategoryEdit?.category?.id,
        description: payloadTopicCategoryEdit?.description?.id,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, [isEdit, payloadTopicCategoryEdit]);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadTopicCategoryEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formCn.reset({
        date: defaultValue,
        topic_id: newForm?.topic_id,
        category: newForm?.category,
        description: newForm?.description,
      });
    }

    if (newForm && isEdit === true) {
      formCn.reset({
        date: dateEdit,
        topic_id: payloadTopicCategoryEdit?.topic_id,
        category: payloadTopicCategoryEdit?.category?.cn,
        description: payloadTopicCategoryEdit?.description?.cn,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, [isEdit, payloadTopicCategoryEdit]);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      // console.log(newForm?.en?.banner?.thumbnail_web?.en)
      formPreviewEn.reset({
        date: defaultValue,
        category: newForm?.en?.category?.[0]?.en,
        description: newForm?.en?.description?.[0]?.en,
        logo_url: newForm?.logo_url,
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewId.reset({
        date: defaultValue,
        category: newForm?.id?.category?.[0]?.id,
        description: newForm?.id?.description?.[0]?.id,
        // banner: newForm?.id?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.id?.banner?.thumbnail_web?.id,
        banner_thumbnail: newForm?.id?.banner?.thumbnail?.id,
        banner_image: newForm?.id?.banner?.image?.id,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewCn.reset({
        date: defaultValue,
        category: newForm?.cn?.category?.[0]?.cn,
        description: newForm?.cn?.description?.[0]?.cn,
        // banner: newForm?.cn?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.cn?.banner?.thumbnail_web?.cn,
        banner_thumbnail: newForm?.cn?.banner?.thumbnail?.cn,
        banner_image: newForm?.cn?.banner?.image?.cn,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    setIndexTabActive(0);
    isOpen === false && handlePublishTopicCategoryOpen(false);

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieen"));
        index++
      ) {
        Cookies.remove("keyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieid"));
        index++
      ) {
        Cookies.remove("keyid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookiecn"));
        index++
      ) {
        Cookies.remove("keycn" + index);
      }

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieediten"));
        index++
      ) {
        Cookies.remove("keyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditid"));
        index++
      ) {
        Cookies.remove("keyeditid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditcn"));
        index++
      ) {
        Cookies.remove("keyeditcn" + index);
      }
    }

    if (isEdit === true) {
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieen"));
        index++
      ) {
        Cookies.remove("keyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieid"));
        index++
      ) {
        Cookies.remove("keyid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookiecn"));
        index++
      ) {
        Cookies.remove("keycn" + index);
      }

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieediten"));
        index++
      ) {
        Cookies.remove("keyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditid"));
        index++
      ) {
        Cookies.remove("keyeditid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditcn"));
        index++
      ) {
        Cookies.remove("keyeditcn" + index);
      }
    }

    isEdit &&
      mutateGetTopicCategory(
        { guid, token, language },
        {
          onSuccess: (data) => {
            setEnDescription(data?.data?.description);
            setIdDescription(data?.data?.description);
            setCnDescription(data?.data?.description);
            setPayloadTopicCategoryEdit(data?.data);
          },
        }
      );
  };

  const handlePublishTopicCategoryOpen = (isOpen) => {
    setIsPublishTopicCategoryOpen(isOpen);
    // resetFormPublish({});
  };

  const handleDialogDeleteTopicCategory = (isOpen, guid) => {
    setIsVisibleDeleteTopicCategory(isOpen);
    setTopicCategoryId(guid);
    setIsVisibleDeleteInfoTopicCategory(false);
  };

  const handleDeleteTopicCategory = (guid) => {
    mutateDeleteTopicCategory(
      { guid, token, language },
      {
        onSuccess: () => {
          refetchTopicsCategoryData();
          setIsVisibleDeleteTopicCategory(false);
          setIsVisibleDeleteInfoTopicCategory(true);
        },
      }
    );
  };

  const handleUpdateToggleTopicCategory = (guid) => {
    mutateUpdateToggleTopicCategory(
      { guid, token, language },
      {
        onSuccess: (data) => {
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
          handlePaginationTable(currentPage?.[0], currentLimit?.[0]);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isTopicId = keyword?.keyword_filter === "1" && true;
    // let isCategory = keyword?.keyword_filter === "2" && true;
    // let isDescription = keyword?.keyword_filter === "3" && true;

    let isCategory     = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isDescription  = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isSearchStatus = keyword?.keyword_status === "1" && true;
    let searchKeyword  = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-topic-category", searchKeyword);
    } else {
      localStorage.removeItem("search-topic-category");
    }


    setIsSearch(true);

    let payload = {
      isTopicId: "isTopicId", // Tidak digunakan jadi value nya di bikin string
      isCategory: isCategory,
      isDescription: isDescription,
      search: searchKeyword,
      searchStatus: isSearchStatus,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(10);
    mutateSetTopicCategorySearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setNewMasterTopicCategorySearch(data)
            newTopicsCategoryData.push({
              topic_title: value?.topic_title,
              topic_logo_url: (
                <img src={value?.topic_logo_url} alt="" className="w-24" />
              ),
              category: value?.category,
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUpdateToggleTopicCategory(value?.id)}
                />
              ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(false, true, true, value?.id, true)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteTopicCategory(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewTopicCategory(newTopicsCategoryData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetTopicsCategory(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newTopicsCategoryData = [];

          data?.data?.map((value) => {
            newTopicsCategoryData.push({
              topic_title: value?.topic_title,
              topic_logo_url: (
                <img src={value?.topic_logo_url} alt="" className="w-24" />
              ),
              category: value?.category,
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUpdateToggleTopicCategory(value?.id)}
                />
              ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(false, true, true, value?.id, true)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteTopicCategory(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewTopicCategory(newTopicsCategoryData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetTopicsCategory(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newTopicsCategoryData = [];

          data?.data?.map((value) => {
            newTopicsCategoryData.push({
              topic_title: value?.topic_title,
              topic_logo_url: (
                <img src={value?.topic_logo_url} alt="" className="w-24" />
              ),
              category: value?.category,
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUpdateToggleTopicCategory(value?.id)}
                />
              ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(false, true, true, value?.id, true)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteTopicCategory(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewTopicCategory(newTopicsCategoryData);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem("search-topic-category");
    newTopicsCategoryData = [];

    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    TopicsCategoryData?.data?.map((value) => {
      newTopicsCategoryData.push({
        topic_title: value?.topic_title,
        topic_logo_url: (
          <img src={value?.topic_logo_url} alt="" className="w-24" />
        ),
        category: value?.category,
        is_active: (
          <Switch
            colorScheme={value?.is_active === true ? "green" : "red"}
            isChecked={value?.is_active === true ? true : false}
            onChange={() => handleUpdateToggleTopicCategory(value?.id)}
          />
        ),
        action: (
          <div className="flex gap-2 items-center justify-center">
            {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleModalFormOpen(false, true, true, value?.id, true)
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.VIEW")}
                </div>
              </Button>
            {/* )} */}
            {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#FF0F0F]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() => handleDialogDeleteTopicCategory(true, value?.id)}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.DELETE")}
                </div>
              </Button>
            {/* )} */}
          </div>
        ),
      });
    });
    setnewTopicCategory(newTopicsCategoryData);
  }, [
    TopicsCategoryData,
    token,
    isGetAccessBanner,
    isDeleteAccessBanner,
    isViewAllAccess,
    t("LANGUAGE"),
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,

        resetFormEn,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,

        topicCategoryId,
        isEdit,
        setIsEdit,
        isCreate,
        isModalFormOpen,
        isVisibleTopicCategoryDialog,
        setIsVisibleTopicCategoryDialog,
        isVisibleDeleteTopicCategory,
        setIsVisibleDeleteTopicCategory,
        isVisibleDeleteInfoTopicCategory,
        setIsVisibleDeleteInfoTopicCategory,

        isLoadingDeleteTopicCategory,

        isLoadingTopicsCategoryData,
        // isLoadingGetAdvertisings,

        TopicsCategoryData,
        newTopicCategory,
        newTopicsCategoryData,
        handleDialogDeleteTopicCategory,
        handleModalFormOpen,
        handleShowEntriesTable,
        handlePaginationTable,
        refetchTopicsCategoryData,
        handleDeleteTopicCategory,
        handleSearch,

        payloadTopicCategoryEdit,
        setPayloadTopicCategoryEdit,
        isView,
        setIsView,

        indexTabActive,
        setIndexTabActive,
        isPublishTopicCategoryOpen,
        setIsPublishTopicCategoryOpen,
        handlePublishTopicCategoryOpen,

        enDescription,
        idDescription,
        cnDescription,

        isUpdateAccessBanner,
        isGetAccessBanner,
        isDeleteAccessBanner,
        isViewAllAccess,

        isShowStatus,
        isSearch,

        TopicsData,
        isLoadingTopicsData,
        refetchTopicsData,

        formKeywordSearch,
        setIsShowStatus,
        newMasterTopicCategorySearch
      }}
    >
      {children}
    </Provider>
  );
};

export default TopicCategoryController;
