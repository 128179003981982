import { api } from "../../../../../../utils/axios";

//Get List User App
export const getUsersApp = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/user-app/list",
      {
        filter: {
          set_first_name: false,
          first_name: "",
          set_last_name: false,
          last_name: "",
          set_username: false,
          username: "",
          set_phone: false,
          phone: "",
          set_email: false,
          email: "",
          set_role_id: false,
          role_id: 0,
          set_is_active: false,
          is_active: false,
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get User App By ID
export const getUserApp = ({ guid, token, language }) =>
  api
    .get(`/backoffice/user-app/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Set User App Active
export const setUserAppActive = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/user-app/is-active/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Delete User App
export const deleteUserApp = ({ guid, token, language }) =>
  api
    .delete(`/backoffice/user-app/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Search User App
export const getUserSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/user-app/list",
      {
        filter: {
          set_first_name: payload?.isFirstName === true ? true : false,
          first_name: payload?.isFirstName ? payload?.search : "",
          set_last_name: payload?.isLastName === true ? true : false,
          last_name: payload?.isLastName ? payload?.search : "",
          set_username: payload?.isUsername === true ? true : false,
          username: payload?.isUsername ? payload?.search : "",
          set_phone: payload.isPhone === true ? true : false,
          phone: payload?.isPhone ? payload?.search : null,
          set_email: payload.isEmail === true ? true : false,
          email: payload?.isEmail ? payload?.search : null,
          set_role_id: payload.isRole === true ? true : false,
          role_id: payload?.isRole ? payload?.searchRole : 0,
          set_is_active: payload?.isStatus === true ? true : false,
          is_active: payload?.isStatus ? payload?.searchStatus : false,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get List Point
export const getPoints = ({ page, limit, token, language, guid }) =>
  api
    .post(
      "/backoffice/user-app/point/list",
      {
        filter: {
          set_user_app_guid: true,
          user_app_guid: guid,
          set_transaction_type: false,
          transaction_type: "",
          set_message: false,
          message: "",
        },
        limit: limit || 10,
        page: page || 1,
        // order: "created_at",
        // sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
