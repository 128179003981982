import React, { lazy, Suspense } from "react";
// import ButtonNew from "./components/ButtonNew";
// import Filter from "./components/Filter";
// import ModalUserAdmin from "./components/Modal";
// import TableUserAdmin from "./components/TableUserAdmin";
import UserAdminController from "./Controller";
// import DeleteConfirmationDialog from "./components/Dialog/DeleteConfirmationDialog";
// import DeleteInformationDialog from "./components/Dialog/DeleteInformationDialog";

const UserAdmin = () => {
  const ButtonNew = lazy(() => import("./components/ButtonNew"));
  const Filter = lazy(() => import("./components/Filter"));
  const ModalUserAdmin = lazy(() => import("./components/Modal"));
  const TableUserAdmin = lazy(() => import("./components/TableUserAdmin"));
  const DeleteConfirmationDialog = lazy(() =>
    import("./components/Dialog/DeleteConfirmationDialog")
  );
  const DeleteInformationDialog = lazy(() =>
    import("./components/Dialog/DeleteInformationDialog")
  );

  return (
    <div>
      <UserAdminController>
        <Suspense
          fallback={
            <div className="h-screen flex items-center">
              <div className="mx-auto">
                <div
                  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex gap-4">
            <ButtonNew />
          </div>
          <div className="pt-4">
            <Filter />
          </div>
          <div className="pt-4">
            <div className="overflow-auto h-screen">
              <TableUserAdmin />
            </div>
          </div>
          <ModalUserAdmin />
          <DeleteConfirmationDialog />
          <DeleteInformationDialog />
        </Suspense>
      </UserAdminController>
    </div>
  );
};

export default UserAdmin;
