import { api } from "../../../../../utils/axios";

//Get List Point
export const getPoints = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/user-app/point/list",
      {
        // filter: {
        //   set_bank_code: false,
        //   bank_code: "",
        //   set_bank_name: false,
        //   bank_name: "",
        //   set_account_number: false,
        //   account_number: "",
        //   set_account_name: false,
        //   account_name: "",
        //   set_is_active: false,
        //   is_active: false,
        // },
        limit: limit || 10,
        page: page || 1,
        // order: "created_at",
        // sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Bank Account By ID
export const getPoint = ({ guid, token, language }) =>
  api
    .get(`/backoffice/user-app/point/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

// //Set Bank Account Active
// export const setBankAccountActive = ({ guid, isActive, token, language }) =>
//   api
//     .put(
//       `/backoffice/bank-account/is-active/${guid}`,
//       {
//         // guid: guid,
//         // active: isActive,
//       },
//       {
//         headers: {
//           language: language,
//           "nihao-token": token,
//         },
//       }
//     )
//     .then((res) => res?.data);

// //Create Bank Account
// export const setBankAccount = ({ payloadPublish, token, language }) =>
//   api
//     .post("/backoffice/bank-account", payloadPublish, {
//       headers: {
//         language: language,
//         "nihao-token": token,
//       },
//     })
//     .then((res) => res?.data);

// //Delete Bank Account
// export const deleteBankAccount = ({ guid, token, language }) =>
//   api
//     .delete(`/backoffice/bank-account/${guid}`, {
//       headers: {
//         language: language,
//         "nihao-token": token,
//       },
//     })
//     .then((res) => res?.data);

// //Get Search Bank Account
// export const getBankAccountSearch = ({ payload }) =>
//   api
//     .post(
//       "/backoffice/bank-account/list",
//       {
//         filter: {
//           set_bank_code: payload?.isBankCode === true ? true : false,
//           bank_code: payload?.isBankCode ? payload?.search : "",
//           set_bank_name: payload.isBankName === true ? true : false,
//           bank_name: payload?.isBankName ? payload?.search : "",
//           set_account_number: payload.isAccountNumber === true ? true : false,
//           account_number: payload?.isAccountNumber ? payload?.search : "",
//           set_account_name: payload?.isAccountName === true ? true : false,
//           account_name: payload?.isAccountName ? payload?.search : "",
//           set_is_active: payload?.isStatus === true ? true : false,
//           is_active: payload?.isStatus ? payload?.searchStatus : false,
//         },
//         limit: 1000,
//         page: 1,
//         order: "created_at",
//         sort: "DESC",
//       },
//       {
//         headers: {
//           language: payload?.lang,
//           "nihao-token": payload?.token,
//         },
//       }
//     )
//     .then((res) => res?.data);

//Get List Point Search Range
export const getPointSearchRange = ({ payload }) =>
  api
    .post(
      "/backoffice/user-app/point/list",
      {
        filter: {
          set_user_app_guid: false,
          user_app_guid: "",
          set_transaction_type: false,
          transaction_type: "",
          set_message: false,
          message: "",
          set_date_range: payload?.isRange === true ? true : false,
          date_from: payload?.publish_from,
          date_to: payload?.publish_to,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        // order: "created_at",
        // sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

// //Set Bank Account Edit
// export const setBankAccountEdit = ({ payloadEditPublish, guid, token, language }) =>
//   api
//     .put(`/backoffice/bank-account/${guid}`, payloadEditPublish, {
//       headers: {
//         language: language,
//         "nihao-token": token,
//       },
//     })
//     .then((res) => res?.data);
