import { api } from "../../../../../../utils/axios";

//Create Advertising
export const setAdvertisingRow = (payload, token) =>
  api
    .post("/backoffice/advertising-row", payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Get List Advertisings
export const getAdvertisingsRow = ({ page, limit, token , language}) =>
  api
    .post(
      "/backoffice/advertising-row/list",
      {
        filter: {
          set_title: false,
          title: "",
          set_status: false,
          status: 0,
          set_is_draft: false,
          is_draft: false,
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Search Advertising Row
export const getAdvertisingsRowSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/advertising-row/list",
      {
        filter: {
          set_title: payload?.isTitle === true ? true : false,
          title: payload?.isTitle ? payload?.search : "",
          set_status: payload?.isStatus === true ? true : false,
          status: payload?.isStatus ? payload?.searchStatus : 0,
          set_is_draft: payload?.isDraft === true ? true : false,
          is_draft: payload?.isDraft ? payload?.searchDraft : false,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get Advertising Row By ID
export const getAdvertisingRow = ({ guid, token, language }) =>
  api
    .get(`/backoffice/advertising-row/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Update Advertising Row
export const updateAdvertisingRow = (payload, token) =>
  api
    .put(
      `/backoffice/advertising-row/${payload?.guid}`,
      payload?.payloadPublish?.[0],
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Update Publish Advertising Row
export const updatePublishAdvertisingRow = (payload, token) =>
  api
    .put(
      `/backoffice/advertising-row/status/${payload?.guid}`,
      payload?.payloadPublish,
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Delete Advertising Row
export const deleteAdvertisingRow = ({ guid, token, language }) =>
  api
    .delete(`/backoffice/advertising-row/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
