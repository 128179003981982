import { api } from "../../../../../utils/axios";

//Update Yuan Conversion
export const updateAmountBC22 = ({ token, language, payload }) =>
  api
    .put(`/backoffice/custom-declaration/amount`, payload, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Yuan Conversion
export const getAmountBC22 = ({ token, language }) =>
  api
    .get(`/backoffice/custom-declaration/amount`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
