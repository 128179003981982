import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TokenContext } from "../../../../../controllers/public/TokenController";

import { Button, Switch, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import {
  getTopics,
  getAdvertisingBannerSearch,
  deleteTopic,
  updateToggleTopic,
  getTopic,
  getTopicSearch,
} from "./api/TopicApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import Cookies from "js-cookie";

export const TopicContext = createContext();
const { Provider } = TopicContext;

const TopicController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: TopicsData,
    isLoading: isLoadingTopicsData,
    refetch: refetchTopicsData,
  } = useQuery(["topics", token, language], () =>
    getTopics({ token, language })
  );

  const { mutate: mutateGetTopics } = useMutation(getTopics);

  const { mutate: mutateGetTopic } =
    useMutation(getTopic);

  const {
    isLoading: isLoadingSetTopicSearch,
    mutate: mutateSetTopicSearch,
  } = useMutation(getTopicSearch);

  const {
    isLoading: isLoadingDeleteTopic,
    mutate: mutateDeleteTopic,
  } = useMutation(deleteTopic);

  const {
    isLoading: isLoadingUpdateToggleAdvertisingBanner,
    mutate: mutateUpdateToggleTopic,
  } = useMutation(updateToggleTopic);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleTopicDialog, setIsVisibleTopicDialog] = useState(false);
  const [
    isVisibleDeleteTopic,
    setIsVisibleDeleteTopic,
  ] = useState(false);
  const [
    isVisibleDeleteInfoTopic,
    setIsVisibleDeleteInfoTopic,
  ] = useState(false);

  const [isPublishTopicOpen, setIsPublishTopicOpen] = useState(false);
  const [newTopic, setnewTopic] = useState();

  const [topicId, setTopicId] = useState();

  const [urlBannerThumbnail, setUrlBannerThumbnail] = useState();
  const [urlBannerThumbnailWebsite, setUrlBannerThumbnailWebsite] = useState();
  const [urlBannerThumbnailEdit, setUrlBannerThumbnailEdit] = useState();
  const [urlBannerThumbnailWebsiteEdit, setUrlBannerThumbnailWebsiteEdit] =
    useState();

  const [urlBannerThumbnailEn, setUrlBannerThumbnailEn] = useState();
  const [logoUrl, setLogoUrl] =
    useState();
  const [urlBannerThumbnailEnEdit, setUrlBannerThumbnailEnEdit] = useState();
  const [logoUrlEdit, setLogoUrlEdit] =
    useState();

  const [urlBannerThumbnailId, setUrlBannerThumbnailId] = useState();
  const [urlBannerThumbnailWebsiteId, setUrlBannerThumbnailWebsiteId] =
    useState();
  const [urlBannerThumbnailIdEdit, setUrlBannerThumbnailIdEdit] = useState();
  const [urlBannerThumbnailWebsiteIdEdit, setUrlBannerThumbnailWebsiteIdEdit] =
    useState();

  const [urlBannerThumbnailCn, setUrlBannerThumbnailCn] = useState();
  const [urlBannerThumbnailWebsiteCn, setUrlBannerThumbnailWebsiteCn] =
    useState();
  const [urlBannerThumbnailCnEdit, setUrlBannerThumbnailCnEdit] = useState();
  const [urlBannerThumbnailWebsiteCnEdit, setUrlBannerThumbnailWebsiteCnEdit] =
    useState();

  const [payloadTopicEdit, setPayloadTopicEdit] = useState();
  const [indexTabActive, setIndexTabActive] = useState();

  const [enDescription, setEnDescription] = useState("");
  const [idDescription, setIdDescription] = useState("");
  const [cnDescription, setCnDescription] = useState("");
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [newMasterTopicSearch, setNewMasterTopicSearch] =  useState();

  let newTopicsData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessBanner, setIsGetAccessBanner] = useState();
  const [isDeleteAccessBanner, setIsDeleteAccessBanner] = useState();
  const [isUpdateAccessBanner, setIsUpdateAccessBanner] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const [isFileBanner, setIsFileBanner] = useState(true);
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Banner
          const getAccessTopic = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessBanner(getAccessTopic?.length);

          const deleteAccessTopic = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessBanner(deleteAccessTopic?.length);

          const updateAccessTopic = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessBanner(updateAccessTopic?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadTopicEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formEn.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,
        logo_url: newForm?.banner?.[0]?.thumbnail_web?.en,
      });
    }

    if (newForm && isEdit === true) {
      formEn.reset({
        date: dateEdit,
        title: payloadTopicEdit?.title?.en,
        description: payloadTopicEdit?.descriptions?.en,

        // banner_thumbnail: payloadTopicEdit?.banner?.thumbnail,
        // banner_image: payloadTopicEdit?.banner?.image,

        logo_url: payloadTopicEdit?.logo_url,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, [isEdit, payloadTopicEdit]);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadTopicEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formId.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,

        // banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_web?.id,
        // banner_thumbnail: newForm?.banner?.[0]?.thumbnail?.id,
        // banner_image: newForm?.banner?.[0]?.image?.id,

        logo_url: payloadTopicEdit?.banner?.thumbnail_web?.en,
      });
    }

    const thumbnailBanner = urlBannerThumbnailEdit
      ? urlBannerThumbnailEdit
      : payloadTopicEdit?.banner?.thumbnail;

    // console.log(thumbnailBanner)

    if (newForm && isEdit === true) {
      formId.reset({
        date: dateEdit,
        title: payloadTopicEdit?.title?.id,
        description: payloadTopicEdit?.descriptions?.id,

        // banner_thumbnail_website: payloadTopicEdit?.banner?.thumbnail_web?.id,
        // banner_thumbnail: payloadTopicEdit?.banner?.thumbnail?.id,
        // banner_image: payloadTopicEdit?.banner?.image?.id,

        logo_url: payloadTopicEdit?.logo_url,

        // banner_thumbnail: thumbnailBanner,
        // banner_image: payloadTopicEdit?.banner?.[0]?.image,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, [isEdit, payloadTopicEdit, urlBannerThumbnailEdit]);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadTopicEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formCn.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,
        // banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_web?.cn,
        // banner_thumbnail: newForm?.banner?.[0]?.thumbnail?.cn,
        // banner_image: newForm?.banner?.[0]?.image?.cn,

        logo_url: payloadTopicEdit?.banner?.thumbnail_web?.en,
      });
    }

    const thumbnailBanner = urlBannerThumbnailEdit
      ? urlBannerThumbnailEdit
      : payloadTopicEdit?.banner?.thumbnail;

    if (newForm && isEdit === true) {
      formCn.reset({
        date: dateEdit,
        title: payloadTopicEdit?.title?.cn,
        description: payloadTopicEdit?.descriptions?.cn,

        // banner_thumbnail_website: payloadTopicEdit?.banner?.thumbnail_web?.cn,
        // banner_thumbnail: payloadTopicEdit?.banner?.thumbnail?.cn,
        // banner_image: payloadTopicEdit?.banner?.image?.cn,


        logo_url: payloadTopicEdit?.logo_url,

        // banner_thumbnail: thumbnailBanner,
        // banner_image: payloadTopicEdit?.banner?.[0]?.image,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, [isEdit, payloadTopicEdit, urlBannerThumbnailEdit]);

  // -------------------------------
  // Form Upload
  // -------------------------------
  const formUpload = useForm();
  const resetFormUpload = (newForm) => {
    if (newForm && !isEdit) {
      formUpload.reset({
        banner_thumbnail: newForm?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_web,
        banner_image: newForm?.banner?.[0]?.image,
      });
    }

    if (newForm && isEdit) {
      formUpload.reset({
        banner_thumbnail: payloadTopicEdit?.banner?.thumbnail,
        banner_thumbnail_website: payloadTopicEdit?.banner?.thumbnail_web,
        banner_image: payloadTopicEdit?.banner?.image,
      });
    }
  };

  useEffect(() => {
    resetFormUpload({});
  }, [isEdit, payloadTopicEdit]);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      console.log(newForm)
      // console.log(newForm?.en?.banner?.thumbnail_web?.en)
      formPreviewEn.reset({
        date: defaultValue,
        title: newForm?.en?.title?.[0]?.en,
        description: newForm?.en?.description?.[0]?.en,
        // banner: newForm?.en?.banner?.[0]?.thumbnail,
        // banner_thumbnail_website: newForm?.en?.banner?.thumbnail_web?.en,
        // banner_thumbnail: newForm?.en?.banner?.thumbnail?.en,
        // banner_image: newForm?.en?.banner?.image?.en,
        logo_url: newForm?.logo_url,
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewId.reset({
        date: defaultValue,
        title: newForm?.id?.title?.[0]?.id,
        description: newForm?.id?.description?.[0]?.id,
        // banner: newForm?.id?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.id?.banner?.thumbnail_web?.id,
        banner_thumbnail: newForm?.id?.banner?.thumbnail?.id,
        banner_image: newForm?.id?.banner?.image?.id,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewCn.reset({
        date: defaultValue,
        title: newForm?.cn?.title?.[0]?.cn,
        description: newForm?.cn?.description?.[0]?.cn,
        // banner: newForm?.cn?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.cn?.banner?.thumbnail_web?.cn,
        banner_thumbnail: newForm?.cn?.banner?.thumbnail?.cn,
        banner_image: newForm?.cn?.banner?.image?.cn,
      });
    }
  };

  const formPreviewUpload = useForm();
  const resetFormPreviewUpload = (newForm) => {
    if (newForm) {
      formPreviewUpload.reset({
        banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_website,
        banner_thumbnail: newForm?.banner?.[0]?.thumbnail,
        banner_image: newForm?.banner?.[0]?.image,
      });
    }
  };


  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  
  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    setIndexTabActive(0);
    isOpen === false && handlePublishTopicOpen(false);

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormUpload({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});
      resetFormPreviewUpload({});

      setLogoUrl([]);
      setIsFileBanner(false);

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieen"));
        index++
      ) {
        Cookies.remove("keyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieid"));
        index++
      ) {
        Cookies.remove("keyid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookiecn"));
        index++
      ) {
        Cookies.remove("keycn" + index);
      }

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieediten"));
        index++
      ) {
        Cookies.remove("keyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditid"));
        index++
      ) {
        Cookies.remove("keyeditid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditcn"));
        index++
      ) {
        Cookies.remove("keyeditcn" + index);
      }
    }

    if (isEdit === true) {
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieen"));
        index++
      ) {
        Cookies.remove("keyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieid"));
        index++
      ) {
        Cookies.remove("keyid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookiecn"));
        index++
      ) {
        Cookies.remove("keycn" + index);
      }

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieediten"));
        index++
      ) {
        Cookies.remove("keyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditid"));
        index++
      ) {
        Cookies.remove("keyeditid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditcn"));
        index++
      ) {
        Cookies.remove("keyeditcn" + index);
      }
    }

    isEdit &&
      mutateGetTopic(
        { guid, token, language },
        {
          onSuccess: (data) => {            
            setEnDescription(data?.data?.descriptions);
            setIdDescription(data?.data?.descriptions);
            setCnDescription(data?.data?.descriptions);
            setPayloadTopicEdit(data?.data);
          },
        }
      );
  };

  const handlePublishTopicOpen = (isOpen) => {
    setIsPublishTopicOpen(isOpen);
    // resetFormPublish({});
  };

  const handleDialogDeleteTopic = (isOpen, guid) => {
    setIsVisibleDeleteTopic(isOpen);
    setTopicId(guid);
    setIsVisibleDeleteInfoTopic(false);
  };

  const handleDeleteTopic = (guid) => {
    mutateDeleteTopic(
      { guid, token, language },
      {
        onSuccess: () => {
          refetchTopicsData();
          setIsVisibleDeleteTopic(false);
          setIsVisibleDeleteInfoTopic(true);
        },
      }
    );
  };

  const handleUpdateToggleTopic = (guid) => {
    mutateUpdateToggleTopic(
      { guid, token, language },
      {
        onSuccess: (data) => {
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
          handlePaginationTable(currentPage?.[0], currentLimit?.[0]);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isTitle = keyword?.keyword_filter === "1" && true;
    // let isDescription = keyword?.keyword_filter === "2" && true;

    let isTitle        = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isDescription  = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isSearchStatus = keyword?.keyword_status === "1" && true;
    let searchKeyword  = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-topic", searchKeyword);
    } else {
      localStorage.removeItem("search-topic");
    }

    // setIsShowStatus(isDescription);
    setIsSearch(true);

    let payload = {
      isTitle: isTitle,
      isDescription: isDescription,
      search: searchKeyword,
      searchStatus: isSearchStatus,
      language: language,
      token: token,      
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(10);
    mutateSetTopicSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setNewMasterTopicSearch(data)
            newTopicsData.push({
              title: value?.title,
              logo_url: <img src={value?.logo_url} alt="" className="w-24" />,
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleUpdateToggleTopic(value?.id)
                  }
                />
              ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(false, true, true, value?.id, true)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteTopic(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewTopic(newTopicsData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetTopics(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newTopicsData = [];

          data?.data?.map((value) => {
            newTopicsData.push({
              title: value?.title,
              logo_url: <img src={value?.logo_url} alt="" className="w-24" />,
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleUpdateToggleTopic(value?.id)
                  }
                />
              ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(false, true, true, value?.id, true)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteTopic(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewTopic(newTopicsData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetTopics(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newTopicsData = [];

          data?.data?.map((value) => {
            newTopicsData.push({
              title: value?.title,
              logo_url: <img src={value?.logo_url} alt="" className="w-24" />,
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleUpdateToggleTopic(value?.id)
                  }
                />
              ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(false, true, true, value?.id, true)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteTopic(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewTopic(newTopicsData);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem("search-topic");
    newTopicsData = [];

    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    TopicsData?.data?.map((value) => {
      newTopicsData.push({
        title: value?.title,
        logo_url: <img src={value?.logo_url} alt="" className="w-24" />,
        is_active: (
          <Switch
            colorScheme={value?.is_active === true ? "green" : "red"}
            isChecked={value?.is_active === true ? true : false}
            onChange={() => handleUpdateToggleTopic(value?.id)}
          />
        ),
        action: (
          <div className="flex gap-2 items-center justify-center">
            {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleModalFormOpen(false, true, true, value?.id, true)
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.VIEW")}
                </div>
              </Button>
            {/* )} */}
            {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#FF0F0F]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleDialogDeleteTopic(true, value?.id)
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.DELETE")}
                </div>
              </Button>
            {/* )} */}
          </div>
        ),
      });
    });
    setnewTopic(newTopicsData);
  }, [
    TopicsData,
    token,
    isGetAccessBanner,
    isDeleteAccessBanner,
    isViewAllAccess,
    t("LANGUAGE"),
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,
        formUpload,

        resetFormEn,
        resetFormUpload,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        formPreviewUpload,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,
        resetFormPreviewUpload,

        topicId,
        isEdit,
        setIsEdit,
        isCreate,
        isModalFormOpen,
        isVisibleTopicDialog,
        setIsVisibleTopicDialog,
        isVisibleDeleteTopic,
        setIsVisibleDeleteTopic,
        isVisibleDeleteInfoTopic,
        setIsVisibleDeleteInfoTopic,

        isLoadingDeleteTopic,

        isLoadingTopicsData,
        // isLoadingGetAdvertisings,

        TopicsData,
        newTopic,
        newTopicsData,
        handleDialogDeleteTopic,
        handleModalFormOpen,
        handleShowEntriesTable,
        handlePaginationTable,
        refetchTopicsData,
        handleDeleteTopic,
        handleSearch,

        payloadTopicEdit,
        setPayloadTopicEdit,
        urlBannerThumbnail,
        setUrlBannerThumbnail,
        urlBannerThumbnailEdit,
        setUrlBannerThumbnailEdit,
        isView,
        setIsView,

        indexTabActive,
        setIndexTabActive,
        isPublishTopicOpen,
        setIsPublishTopicOpen,
        handlePublishTopicOpen,

        enDescription,
        idDescription,
        cnDescription,

        isUpdateAccessBanner,
        isGetAccessBanner,
        isDeleteAccessBanner,
        isViewAllAccess,

        isShowStatus,
        isSearch,
        isFileBanner,
        setIsFileBanner,

        urlBannerThumbnailWebsite,
        setUrlBannerThumbnailWebsite,
        urlBannerThumbnailWebsiteEdit,
        setUrlBannerThumbnailWebsiteEdit,

        logoUrl,
        setLogoUrl,
        logoUrlEdit,
        setLogoUrlEdit,
        urlBannerThumbnailEn,
        setUrlBannerThumbnailEn,
        urlBannerThumbnailEnEdit,
        setUrlBannerThumbnailEnEdit,

        urlBannerThumbnailWebsiteId,
        setUrlBannerThumbnailWebsiteId,
        urlBannerThumbnailWebsiteIdEdit,
        setUrlBannerThumbnailWebsiteIdEdit,
        urlBannerThumbnailId,
        setUrlBannerThumbnailId,
        urlBannerThumbnailIdEdit,
        setUrlBannerThumbnailIdEdit,

        urlBannerThumbnailWebsiteCn,
        setUrlBannerThumbnailWebsiteCn,
        urlBannerThumbnailWebsiteCnEdit,
        setUrlBannerThumbnailWebsiteCnEdit,
        urlBannerThumbnailCn,
        setUrlBannerThumbnailCn,
        urlBannerThumbnailCnEdit,
        setUrlBannerThumbnailCnEdit,

        formKeywordSearch,
        setIsShowStatus,
        newMasterTopicSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default TopicController;
