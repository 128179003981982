import { Button, Switch, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../../controllers/public/AuthController";
import { TokenContext } from "../../../../controllers/public/TokenController";
import { getRole } from "../User/UserAdminRole/api/RoleApi";
import {
  getTransactionShList,
  getTransactionSh,
  getTransactionShSearch,
  getTransactionShTimeSearch,
  getTransactionShAmountSearch,
} from "./api/TransactionApi";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Dropdown, IconButton, Popover, Whisper } from "rsuite";
import { FiArrowDown, FiColumns, FiEdit2 } from "react-icons/fi";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";

export const TransactionShContext = createContext();
const { Provider } = TransactionShContext;

const TransactionShController = ({ children }) => {
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  let language = t("LANGUAGE");

  const componentRef = useRef(null);

  const toast = useToast();

  const [newTransactionSh, setNewTransactionSh] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isSearchTransactionTime, setIsSearchTransactionTime] = useState(false);
  const [isSearchTransactionAmount, setIsSearchTransactionAmount] =
    useState(false);
  const [isSearchTransactionTimeStart, setSearchTransactionTimeStart] =
    useState();
  const [isSearchTransactionTimeEnd, setSearchTransactionTimeEnd] = useState();
  const [isModalFormUpdateSellPriceOpen, setIsModalFormUpdateSellPriceOpen] =
    useState(false);
  const [transactionId, setTransactionId] = useState();
  const [isVisibleUpdateSellPriceInfo, setIsVisibleUpdateSellPriceInfo] =
    useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isVisibleDownloadTransaction, setIsVisibleDownloadTransaction] =
    useState(false);
  const [
    isVisibleDownloadInfoTransaction,
    setIsVisibleDownloadInfoTransaction,
  ] = useState(false);

  const [transaksiId, setTransaksiId] = useState();
  const [isVisibleApproveTransaksi, setIsVisibleApproveTransaksi] =
    useState(false);
  const [isVisibleApproveTransaksiInfo, setIsVisibleApproveTransaksiInfo] =
    useState(false);
  const [isVisibleRejectTransaksi, setIsVisibleRejectTransaksi] =
    useState(false);
  const [isVisibleRejectTransaksiInfo, setIsVisibleRejectTransaksiInfo] =
    useState(false);

  const [isVisibleTransactionTime, setIsVisibleTransactionTime] =
    useState(false);
  const [isVisibleTransactionTimeInfo, setIsVisibleTransactionTimeInfo] =
    useState(false);
  const [isVisibleAmount, setIsVisibleAmount] = useState(false);
  const [isVisibleAmountInfo, setIsVisibleAmountInfo] = useState(false);

  const [pres, setPres] = useState();
  const [uniqueNumber, setUniqueNumber] = useState();
  const [newTransactionShSearch, setNewTransactionShSearch] = useState();
  const [newTransactionShSearchTime, setNewTransactionShSearchTime] =
    useState();
  const [newTransactionShSearchAmount, setNewTransactionShSearchAmount] =
    useState();

  const {
    data: transactionShList,
    isLoading: isLoadingTransactionShList,
    refetch: refecthTransactionShData,
  } = useQuery(["confirmationapproval", token, language], () =>
    getTransactionShList({ token, language })
  );

  const {
    isLoading: isLoadingSetTransactionShSearch,
    mutate: mutateSetTransactionShSearch,
  } = useMutation(getTransactionShSearch);

  const {
    isLoading: isLoadingSetTransactionShTimeSearch,
    mutate: mutateSetTransactionShTimeSearch,
  } = useMutation(getTransactionShTimeSearch);

  const {
    isLoading: isLoadingSetTransactionShAmountSearch,
    mutate: mutateSetTransactionShAmountSearch,
  } = useMutation(getTransactionShAmountSearch);

  const {
    isLoading: isLoadingGetTransactionShList,
    mutate: mutateGetConfirmationApproval,
    refetch: refetchGetTransactionShList,
  } = useMutation(getTransactionShList);

  const {
    isLoading: isLoadingGetTransactionSh,
    mutate: mutateGetTransactionSh,
  } = useMutation(getTransactionSh);

  let newTransactionShListsData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessPrabayarTransaction, setIsGetAccessPrabayarTransaction] =
    useState();
  const [
    isDeleteAccessPrabayarTransaction,
    setIsDeleteAccessPrabayarTransaction,
  ] = useState();
  const [
    isUpdateAccessPrabayarTransaction,
    setIsUpdateAccessPrabayarTransaction,
  ] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Prabayar Transaction
          const getAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessPrabayarTransaction(
            getAccessPrabayarTransaction?.length
          );

          const deleteAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessPrabayarTransaction(
            deleteAccessPrabayarTransaction?.length
          );

          const updateAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessPrabayarTransaction(
            updateAccessPrabayarTransaction?.length
          );
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Publish
  // -------------------------------
  const formPublish = useForm();
  const resetFormPublish = (newForm) => {
    const dateFrom = new Date();
    const dateTo = new Date();
    const publishFrom = dateFrom.getDate() + 1;
    const publishTo = dateTo.getDate() + 2;
    dateFrom.setDate(publishFrom);
    dateTo.setDate(publishTo);
    const defaultDatePublishFrom = moment(dateFrom).format("YYYY-MM-DDTHH:mm");
    const defaultDatePublishTo = moment(dateTo).format("YYYY-MM-DDTHH:mm");

    const publishFromEdit = moment(newForm?.publish_from).format(
      "YYYY-MM-DDTHH:mm"
    );
    const publishToEdit = moment(newForm?.publish_to).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formPublish.reset({
        publish_from: newForm?.publish_from,
        publish_to: newForm?.publish_to,
        status: 1,
      });
    }
  };

  // -------------------------------
  // Form Modal Transaction
  // -------------------------------
  const formTransactionDetail = useForm();
  const resetformTransactionDetail = (newForm) => {
    if (newForm) {
      formTransactionDetail.reset({
        guid: newForm?.guid,
        transaction_id: newForm?.transaction_id,
        external_order_no: newForm?.external_order_no,
        shop_name: newForm?.shop_name,
        cashier: newForm?.cashier,
        type: newForm?.type,
        transaction_time: moment(newForm?.transaction_time)
          .utc()
          .add(7, "hours")
          .format("DD/MM/YYYY HH:mm:ss"),
        amount: (
          <CurrencyFormat
            value={newForm?.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        status: newForm?.status,
        user_source: newForm?.user_source,
        remarks: newForm?.remarks,
        created_at: newForm?.created_at,
        updated_at: newForm?.updated_at,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
      });
    }
  };

  const handleDialogTransaction = (isOpen, guid) => {
    setIsModalFormOpen(isOpen);
    setTransactionId(guid);

    mutateGetTransactionSh(
      { guid, token },
      {
        onSuccess: (data) => {
          // resetformTransaction(data?.data)
          resetformTransactionDetail(data.data);
        },
      }
    );
  };

  const handleDialogTransactionTime = (isOpen, guid) => {
    setIsVisibleTransactionTime(isOpen);
    setIsVisibleTransactionTimeInfo(false);
  };

  const handleDialogAmount = (isOpen, guid) => {
    setIsVisibleAmount(isOpen);
    setIsVisibleAmountInfo(false);
  };

  const handleCapture = (guid) => {
    const receipt = newTransactionShListsData?.find(
      (item) => item.guid === guid
    );

    if (receipt) {
      const receiptElement = document.getElementById(`receipt-${guid}`);
      if (componentRef.current) {
        // Show the component temporarily for capturing
        receiptElement.style.display = "block";

        html2canvas(receiptElement, {
          width: 230,
          height: 320,
        }).then((canvas) => {
          // Convert canvas to an image URL
          const image = canvas.toDataURL("image/jpg");

          // Hide the component again after capturing
          receiptElement.style.display = "none";

          // Create a download link
          const a = document.createElement("a");
          a.href = image;
          a.download = "receipt.jpg";
          document.body.appendChild(a);

          // Trigger the download
          a.click();

          // Clean up the download link
          document.body.removeChild(a);
        });
      }
    }
  };

  const handleDialogDownloadTransaction = (isOpen, guid) => {
    // console.log(isPublish)
    setIsVisibleDownloadTransaction(isOpen);
    // setAdvertisingRowId(guid);
    // setIsPublish(isPublish);
    setIsVisibleDownloadInfoTransaction(false);

    // mutateGetAdvertisingRow(
    //   { guid, token, language },
    //   {
    //     onSuccess: (data) => {
    resetFormPublish({});
    //       setPublishPayloadClassifiedAdsEdit(data?.data);
    //     },
    //   }
    // );
  };

  const handleDownloadTransaction = (payload) => {
    let isTransactionTime = formPublish?.getValues("publish_from")?.[1] ? true : false;

    const defaultDatePublishFrom = moment(payload?.publish_from?.[0]).utc().format();
    const defaultDatePublishTo = moment(payload?.publish_from?.[1]).utc().format();

    let payloadPublish = {
      isTransactionTime: isTransactionTime,
      transaction_time_start:
        defaultDatePublishFrom === "Invalid date"
          ? null
          : defaultDatePublishFrom,
      transaction_time_end:
        defaultDatePublishTo === "Invalid date" ? null : defaultDatePublishTo,
      language: language,
      token: token,
      page: 1,
      limit: 10000,
    };

    console.log(payloadPublish);

    mutateSetTransactionShTimeSearch(
      { payload: payloadPublish },
      {
        onSuccess: (data) => {
          // refetchAdvertisingRowData();
          // setIsVisiblePublishAdvertisingClassified(false);
          // setIsVisiblePublishInfoAdvertisingClassified(true);
          // console.log(data?.data);

          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";

          const customHeadings = data?.data?.map((item, key) => {
            // const amount = new Intl.NumberFormat("id-ID", {
            //   style: "currency",
            //   currency: "IDR",
            // }).format(item?.amount);

            return {
              Tanggal: moment(item?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY, h:mm:ss a"),
              "Transaction ID Alipay": item?.transaction_id,
              Amount: parseInt(item?.amount),
              Status: item?.status,
            };
          });

          const ws = XLSX.utils.json_to_sheet(customHeadings);

          ws["!merges"] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
            { s: { r: 1, c: 0 }, e: { r: 1, c: 0 } },
            { s: { r: 1, c: 1 }, e: { r: 1, c: 1 } },
            { s: { r: 1, c: 2 }, e: { r: 1, c: 2 } },
            { s: { r: 1, c: 3 }, e: { r: 1, c: 3 } },
          ];

          ws["A1"] = {
            v: "ALIPAY MUTATION",
            s: {
              alignment: { horizontal: "center" },
              fill: { bgColor: { indexed: 22 }, fgColor: { indexed: 22 } },
            },
          };
          ws["A2"] = {
            v: "Tanggal",
            s: { alignment: { horizontal: "center" } },
          };
          ws["B2"] = {
            v: "Transaction ID Alipay",
            s: { alignment: { horizontal: "center" } },
          };
          ws["C2"] = {
            v: "Amount",
            s: { alignment: { horizontal: "center" } },
          };
          ws["D2"] = {
            v: "Status",
            s: { alignment: { horizontal: "center" } },
          };

          // Set column width (adjust the width as needed)
          ws["!cols"] = [
            { width: 29 },
            { width: 29 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
          ];

          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const datas = new Blob([excelBuffer], { type: fileType });

          FileSaver.saveAs(datas, "Alipay Mutation" + fileExtension);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isTransactionId = keyword?.keyword_filter === "1" && true;
    let isExternalOrderNo = keyword === "2" && true;
    let isShopName = keyword === "3" && true;
    let isCashier = keyword === "4" && true;
    let isType = keyword === "5" && true;
    let isTransactionTime = keyword === "6" && true;
    let isAmount = keyword === "7" && true;
    let isMessage = keyword === "8" && true;
    let isStatus = keyword === "9" && true;

    let isTransactionId =
      keyword?.keyword_filter?.value === "1" ||
      (keyword?.keyword_filter === "1" && true);
    let searchKeyword = !keyword?.value?.keyword_search
      ? ""
      : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-alipay", searchKeyword);
    } else {
      localStorage.removeItem("search-alipay");
    }

    setIsSearch(true);
    setIsSearchTransactionAmount(false);
    setIsSearchTransactionTime(false);

    let payload = {
      isTransactionId: isTransactionId,
      isExternalOrderNo: isExternalOrderNo,
      isShopName: isShopName,
      isCashier: isCashier,
      isType: isType,
      isTransactionTime: isTransactionTime,
      isAmount: isAmount,
      isMessage: isMessage,
      isStatus: isStatus,
      search: searchKeyword,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(100);
    mutateSetTransactionShSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setNewTransactionShSearch(data);
            newTransactionShListsData.push({
              guid: value?.guid,
              transaction_id: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() => handleDialogTransaction(true, value?.guid)}
                >
                  {value?.transaction_id}
                </div>
              ),
              external_order_no: value?.external_order_no,
              shop_name: value?.shop_name,
              cashier: value?.cashier,
              type: value?.type,
              transaction_time: moment(value?.transaction_time)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              user_source: value?.user_source,
              remarks: value?.remarks,
              status:
                value?.status === "Success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                  <div>
                    {/* <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleDialogTransaction(true, value?.guid)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button> */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#faf089]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleCapture(value?.guid)}
                    >
                      <div className=" text-[#b7791f] leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.RECEIPTS")}
                      </div>
                    </Button>
                    <div
                      ref={componentRef}
                      style={{ display: "none" }}
                      className="w-full"
                      key={value?.guid}
                      id={`receipt-${value?.guid}`}
                    >
                      <h1 className="text-gray-800 flex justify-center items-center w-full">
                        {value?.shop_name}
                      </h1>
                      <hr />
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Status
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.status}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Type
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.type}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Time
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {moment(value?.transaction_time)
                            .utc()
                            .add(7, "hours")
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </span>
                      </div>
                      <div className="mb-5">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Transaction ID
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.transaction_id}
                        </span>
                      </div>
                      <div className="border border-t-gray-300 border-dashed" />
                      <div className="mt-2 mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Received
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          IDR {value?.amount}
                        </span>
                      </div>
                      <hr />
                    </div>
                  </div>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewTransactionSh(newTransactionShListsData);
        },
      }
    );
  };

  // -------------------------------
  // Filter Transaction Time
  // -------------------------------

  const handleSearchTransactionTime = (payloads, page, limit) => {
    const defaultDateTimeStart = moment(
      payloads?.transaction_time_start
    ).format();
    const defaultDateTimeEnd = moment(payloads?.transaction_time_end).format();

    localStorage.setItem(
      "search-alipay-time-start",
      moment(payloads?.transaction_time_start).format()
    );
    localStorage.setItem(
      "search-alipay-time-end",
      moment(payloads?.transaction_time_end).format()
    );

    setIsSearch(false);
    setIsSearchTransactionAmount(false);
    setIsSearchTransactionTime(true);

    let payload = {
      isTransactionTime: true,
      transaction_time_start: defaultDateTimeStart,
      transaction_time_end: defaultDateTimeEnd,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(100);
    mutateSetTransactionShTimeSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setNewTransactionShSearchTime(data);
            newTransactionShListsData.push({
              guid: value?.guid,
              transaction_id: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() => handleDialogTransaction(true, value?.guid)}
                >
                  {value?.transaction_id}
                </div>
              ),
              external_order_no: value?.external_order_no,
              shop_name: value?.shop_name,
              cashier: value?.cashier,
              type: value?.type,
              transaction_time: moment(value?.transaction_time)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              user_source: value?.user_source,
              remarks: value?.remarks,
              status:
                value?.status === "Success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                  <div>
                    {/* <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleDialogTransaction(true, value?.guid)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button> */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#faf089]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleCapture(value?.guid)}
                    >
                      <div className=" text-[#b7791f] leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.RECEIPTS")}
                      </div>
                    </Button>
                    <div
                      ref={componentRef}
                      style={{ display: "none" }}
                      className="w-full"
                      key={value?.guid}
                      id={`receipt-${value?.guid}`}
                    >
                      <h1 className="text-gray-800 flex justify-center items-center w-full">
                        {value?.shop_name}
                      </h1>
                      <hr />
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Status
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.status}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Type
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.type}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Time
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {moment(value?.transaction_time)
                            .utc()
                            .add(7, "hours")
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </span>
                      </div>
                      <div className="mb-5">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Transaction ID
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.transaction_id}
                        </span>
                      </div>
                      <div className="border border-t-gray-300 border-dashed" />
                      <div className="mt-2 mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Received
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          IDR {value?.amount}
                        </span>
                      </div>
                      <hr />
                    </div>
                  </div>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewTransactionSh(newTransactionShListsData);
          handleDialogTransactionTime(false);
        },
      }
    );
  };

  // -------------------------------
  // Filter Amount
  // -------------------------------

  const handleSearchAmount = (payloads, page, limit) => {
    let payload = {
      isAmount: true,
      amount_start: parseInt(payloads?.amount_start),
      amount_end: parseInt(payloads?.amount_end),
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    localStorage.setItem("search-amount-start", payloads?.amount_start);
    localStorage.setItem("search-amount-end", payloads?.amount_end);

    setIsSearch(false);
    setIsSearchTransactionAmount(true);
    setIsSearchTransactionTime(false);

    currentPage.push(1);
    currentLimit.push(100);
    mutateSetTransactionShAmountSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setNewTransactionShSearchAmount(data);
            newTransactionShListsData.push({
              guid: value?.guid,
              transaction_id: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() => handleDialogTransaction(true, value?.guid)}
                >
                  {value?.transaction_id}
                </div>
              ),
              external_order_no: value?.external_order_no,
              shop_name: value?.shop_name,
              cashier: value?.cashier,
              type: value?.type,
              transaction_time: moment(value?.transaction_time)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              user_source: value?.user_source,
              remarks: value?.remarks,
              status:
                value?.status === "Success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                  <div>
                    {/* <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleDialogTransaction(true, value?.guid)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button> */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#faf089]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleCapture(value?.guid)}
                    >
                      <div className=" text-[#b7791f] leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.RECEIPTS")}
                      </div>
                    </Button>
                    <div
                      ref={componentRef}
                      style={{ display: "none" }}
                      className="w-full"
                      key={value?.guid}
                      id={`receipt-${value?.guid}`}
                    >
                      <h1 className="text-gray-800 flex justify-center items-center w-full">
                        {value?.shop_name}
                      </h1>
                      <hr />
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Status
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.status}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Type
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.type}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Time
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {moment(value?.transaction_time)
                            .utc()
                            .add(7, "hours")
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </span>
                      </div>
                      <div className="mb-5">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Transaction ID
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.transaction_id}
                        </span>
                      </div>
                      <div className="border border-t-gray-300 border-dashed" />
                      <div className="mt-2 mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Received
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          IDR {value?.amount}
                        </span>
                      </div>
                      <hr />
                    </div>
                  </div>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewTransactionSh(newTransactionShListsData);
          handleDialogAmount(false);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    // console.log(limit)
    mutateGetConfirmationApproval(
      { limit, token },
      {
        onSuccess: (data) => {
          newTransactionShListsData = [];

          data?.data?.map((value) => {
            newTransactionShListsData.push({
              guid: value?.guid,
              transaction_id: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() => handleDialogTransaction(true, value?.guid)}
                >
                  {value?.transaction_id}
                </div>
              ),
              external_order_no: value?.external_order_no,
              shop_name: value?.shop_name,
              cashier: value?.cashier,
              type: value?.type,
              transaction_time: moment(value?.transaction_time)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              user_source: value?.user_source,
              remarks: value?.remarks,
              status:
                value?.status === "Success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                  <div>
                    {/* <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleDialogTransaction(true, value?.guid)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button> */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#faf089]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleCapture(value?.guid)}
                    >
                      <div className=" text-[#b7791f] leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.RECEIPTS")}
                      </div>
                    </Button>
                    <div
                      ref={componentRef}
                      style={{ display: "none" }}
                      className="w-full"
                      key={value?.guid}
                      id={`receipt-${value?.guid}`}
                    >
                      <h1 className="text-gray-800 flex justify-center items-center w-full">
                        {value?.shop_name}
                      </h1>
                      <hr />
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Status
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.status}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Type
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.type}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Time
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {moment(value?.transaction_time)
                            .utc()
                            .add(7, "hours")
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </span>
                      </div>
                      <div className="mb-5">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Transaction ID
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.transaction_id}
                        </span>
                      </div>
                      <div className="border border-t-gray-300 border-dashed" />
                      <div className="mt-2 mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Received
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          IDR {value?.amount}
                        </span>
                      </div>
                      <hr />
                    </div>
                  </div>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewTransactionSh(newTransactionShListsData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);

    mutateGetConfirmationApproval(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newTransactionShListsData = [];

          data?.data?.map((value) => {
            newTransactionShListsData.push({
              guid: value?.guid,
              transaction_id: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() => handleDialogTransaction(true, value?.guid)}
                >
                  {value?.transaction_id}
                </div>
              ),
              external_order_no: value?.external_order_no,
              shop_name: value?.shop_name,
              cashier: value?.cashier,
              type: value?.type,
              transaction_time: moment(value?.transaction_time)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              user_source: value?.user_source,
              remarks: value?.remarks,
              status:
                value?.status === "Success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                  <div>
                    {/* <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleDialogTransaction(true, value?.guid)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button> */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#faf089]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleCapture(value?.guid)}
                    >
                      <div className=" text-[#b7791f] leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.RECEIPTS")}
                      </div>
                    </Button>
                    <div
                      ref={componentRef}
                      style={{ display: "none" }}
                      className="w-full"
                      key={value?.guid}
                      id={`receipt-${value?.guid}`}
                    >
                      <h1 className="text-gray-800 flex justify-center items-center w-full">
                        {value?.shop_name}
                      </h1>
                      <hr />
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Status
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.status}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Type
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.type}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Time
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {moment(value?.transaction_time)
                            .utc()
                            .add(7, "hours")
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </span>
                      </div>
                      <div className="mb-5">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Transaction ID
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          {value?.transaction_id}
                        </span>
                      </div>
                      <div className="border border-t-gray-300 border-dashed" />
                      <div className="mt-2 mb-3">
                        <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                          Received
                        </span>
                        <span className="text-gray-800 pl-2 flex justify-start w-full">
                          IDR {value?.amount}
                        </span>
                      </div>
                      <hr />
                    </div>
                  </div>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewTransactionSh(newTransactionShListsData);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem("search-alipay");
    localStorage.removeItem("search-alipay-time-start");
    localStorage.removeItem("search-alipay-time-end");
    localStorage.removeItem("search-amount-start");
    localStorage.removeItem("search-amount-end");
    newTransactionShListsData = [];
    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    transactionShList?.data?.map((value) => {
      newTransactionShListsData.push({
        guid: value?.guid,
        transaction_id: (
          <div
            className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
            onClick={() => handleDialogTransaction(true, value?.guid)}
          >
            {value?.transaction_id}
          </div>
        ),
        external_order_no: value?.external_order_no,
        shop_name: value?.shop_name,
        cashier: value?.cashier,
        type: value?.type,
        transaction_time: moment(value?.transaction_time)
          .utc()
          .add(7, "hours")
          .format("DD/MM/YYYY HH:mm:ss"),
        amount: (
          <CurrencyFormat
            value={value?.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        user_source: value?.user_source,
        remarks: value?.remarks,
        status:
          value?.status === "Success" ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "rejected" ? (
            <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : (
            <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ),
        action: (
          <div className="flex gap-2 items-center justify-center">
            {/* {(isGetAccessPrabayarTransaction === 1 ||
              isViewAllAccess === true) && ( */}
            <div>
              {/* <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() => handleDialogTransaction(true, value?.guid)}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.VIEW")}
                </div>
              </Button> */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#faf089]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() => handleCapture(value?.guid)}
              >
                <div className=" text-[#b7791f] leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.RECEIPTS")}
                </div>
              </Button>
              <div
                ref={componentRef}
                style={{ display: "none" }}
                className="w-full"
                key={value?.guid}
                id={`receipt-${value?.guid}`}
              >
                <h1 className="text-gray-800 flex justify-center items-center w-full">
                  {value?.shop_name}
                </h1>
                <hr />
                <div className="mb-3">
                  <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                    Status
                  </span>
                  <span className="text-gray-800 pl-2 flex justify-start w-full">
                    {value?.status}
                  </span>
                </div>
                <div className="mb-3">
                  <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                    Type
                  </span>
                  <span className="text-gray-800 pl-2 flex justify-start w-full">
                    {value?.type}
                  </span>
                </div>
                <div className="mb-3">
                  <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                    Time
                  </span>
                  <span className="text-gray-800 pl-2 flex justify-start w-full">
                    {moment(value?.transaction_time)
                      .utc()
                      .add(7, "hours")
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                </div>
                <div className="mb-5">
                  <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                    Transaction ID
                  </span>
                  <span className="text-gray-800 pl-2 flex justify-start w-full">
                    {value?.transaction_id}
                  </span>
                </div>
                <div className="border border-t-gray-300 border-dashed" />
                <div className="mt-2 mb-3">
                  <span className="text-gray-400 pl-2 flex justify-start text-[9px] w-full">
                    Received
                  </span>
                  <span className="text-gray-800 pl-2 flex justify-start w-full">
                    IDR {value?.amount}
                  </span>
                </div>
                <hr />
              </div>
            </div>
            {/* )} */}
          </div>
        ),
      });
    });
    setNewTransactionSh(newTransactionShListsData);
  }, [
    transactionShList,
    token,
    isGetAccessPrabayarTransaction,
    isDeleteAccessPrabayarTransaction,
    isUpdateAccessPrabayarTransaction,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formTransactionDetail,

        transactionShList,
        isLoadingTransactionShList,
        refecthTransactionShData,
        newTransactionShListsData,
        newTransactionSh,

        isLoadingSetTransactionShSearch,
        isSearch,
        setIsSearch,
        handleSearch,
        handleShowEntriesTable,
        handlePaginationTable,
        isModalFormOpen,
        handleDialogTransaction,
        isGetAccessPrabayarTransaction,
        isDeleteAccessPrabayarTransaction,
        isUpdateAccessPrabayarTransaction,
        isViewAllAccess,
        isVisibleDownloadTransaction,
        isVisibleDownloadInfoTransaction,
        formPublish,
        isVisibleApproveTransaksi,
        isVisibleApproveTransaksiInfo,
        isVisibleRejectTransaksi,
        isVisibleRejectTransaksiInfo,
        transaksiId,
        uniqueNumber,
        setUniqueNumber,
        handleDialogTransactionTime,
        handleDialogAmount,
        isVisibleTransactionTime,
        isVisibleTransactionTimeInfo,
        isVisibleAmount,
        isVisibleAmountInfo,
        handleSearchTransactionTime,
        handleSearchAmount,
        isLoadingSetTransactionShTimeSearch,
        isLoadingSetTransactionShAmountSearch,
        handleDownloadTransaction,
        handleDialogDownloadTransaction,

        newTransactionShSearch,
        formKeywordSearch,
        isSearchTransactionTime,
        isSearchTransactionAmount,
        setSearchTransactionTimeStart,
        setSearchTransactionTimeEnd,
        isSearchTransactionTimeStart,
        isSearchTransactionTimeEnd,
        newTransactionShSearchTime,
        setNewTransactionShSearchTime,
        setNewTransactionShSearchAmount,
        newTransactionShSearchAmount,
      }}
    >
      {children}
    </Provider>
  );
};

export default TransactionShController;
