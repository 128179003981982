import { api } from "../../../../../../utils/axios";

//Get List Advertising Category
export const getAdvertisingCategory = ({ token, language }) =>
  api
    .post(
      "/backoffice/advertising/category",
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
          // "is-all-language": 1,
        },
      }
    )
    .then((res) => res?.data);

//Get Advertising By ID
export const getAdvertisingCategoryId = ({ guid, token, lang }) =>
  api
    .get(`/backoffice/advertising/category/${guid}`, {
      headers: {
        language: lang,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);
