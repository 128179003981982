import { api } from "../../../../../utils/axios";

//Update Alipay Callback
export const updateAlipayCallbackStatus = ({ token, language }) =>
  fetch(
    process.env.REACT_APP_BACKOFFICE_API + "backoffice/alipay/control-switch",
    {
      method: "PUT",
      headers: {
        language: language,
        "nihao-token": token,
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((resp) => resp);

//Get Alipay Callback
export const getAlipayCallbackStatus = ({ token, language }) =>
  api
    .get(`/backoffice/alipay/control-status`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
