import { api } from "../../../../../../utils/axios";

//Get QnA Ticket List
export const getQnaTickets = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/question-answer/ticket/list",
      {
        filter: {
          set_topic_id: false,
          topic_id: "",
        },
        limit: limit || 10,
        page: page || 1,
        order: "DESC",
        sort: "created_at",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get QnA Ticket Search
export const getQnaTicketSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/question-answer/ticket/list",
      {
        filter: {
          set_topic_qna_id: payload?.isTopicId === true ? true : false,
          topic_qna_id: payload?.isTopicId ? payload?.search : "",
        },
        limit: 10000,
        page: 1,
        order: "DESC",
        sort: "created_at",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get Qna Ticket
export const getQnaTicket = ({ guid, token, language }) =>
  api
    .get(`/backoffice/question-answer/ticket/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get QnA Ticket Chat
export const setQnaTicketChat = ({ payloadPublish, token, language }) =>
  api
    .post("/backoffice/question-answer/ticket/chat", payloadPublish?.[0], {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update QNA Assign Ticket
export const updateAssignTicket = ({ guid, token, language }) =>
  fetch(
    process.env.REACT_APP_BACKOFFICE_API +
      `backoffice/question-answer/ticket/assigned/${guid}`,
    {
      method: "PUT",
      headers: {
        language: language,
        "nihao-token": token,
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((resp) => resp);
