export const ListCountries = [
  {
    label: "English",
    code: "gb",
  },
  {
    label: "Bahasa",
    code: "id",
  },
  {
    label: "Chinese",
    code: "cn",
  },
];
