import { api } from "../../../../../utils/axios";

//Update Yuan Conversion
export const updateYuanConversion = ({ token, language, payload }) =>
  api
    .put(`/backoffice/yuan-conversion`, payload, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Yuan Conversion
export const getYuanConversion = ({ token, language }) =>
  api
    .get(`/backoffice/yuan-conversion`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
