import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { Button } from "@chakra-ui/react";
import { TokenContext } from "../../../../controllers/public/TokenController";
import { getPoint, getPoints, getPointSearchRange } from "./api/PointApi";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/public/AuthController";
import moment from "moment";
import { getRole } from "../User/UserAdminRole/api/RoleApi";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const PointContext = createContext();
const { Provider } = PointContext;

const PointController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);

  const {
    data: pointData,
    isLoading: isLoadingPointData,
    refetch: refetchPointData,
  } = useQuery(["points", token, language], () =>
    getPoints({ token, language })
  );

  const { isLoading: isLoadingGetPoints, mutate: mutateGetPoints } =
    useMutation(getPoints);
  const { mutate: mutateGetPoint } = useMutation(getPoint);

  const {
    isLoading: isLoadingSetPointListSearch,
    mutate: mutateSetPointListSearch,
  } = useMutation(getPointSearchRange);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isShowType, setIsShowType] = useState(false);

  const [newPointData, setNewPointData] = useState();
  const [newPointDataSearch, setNewPointDataSearch] = useState();

  let newPointCodeData = [];

  const [isDeleteAccessUserAgent, setIsDeleteAccessUserAgent] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const [isVisibleFilterRange, setIsVisibleFilterRange] = useState(false);
  const [isVisibleFilterRangeInfo, setIsVisibleFilterRangeInfo] =
    useState(false);
  const [isVisibleDownloadTransaction, setIsVisibleDownloadTransaction] =
    useState(false);
  const [
    isVisibleDownloadInfoTransaction,
    setIsVisibleDownloadInfoTransaction,
  ] = useState(false);

  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //User Agent
          const deleteAccessUserAgent = data?.data?.access
            ?.filter((data) => data?.page === "agent")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessUserAgent(deleteAccessUserAgent?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Modal
  // -------------------------------
  const formModal = useForm();
  const resetformModal = (newForm) => {
    if (newForm) {
      formModal.reset({
        transaction_type: newForm?.transaction_type,
        amount: new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(newForm?.amount),
        message: newForm?.message,
        status: newForm?.status,
      });
    }
  };

  // -------------------------------
  // Form Publish
  // -------------------------------
  const formPublish = useForm();
  const resetFormPublish = (newForm) => {
    const dateFrom = new Date();
    const dateTo = new Date();
    const publishFrom = dateFrom.getDate() + 1;
    const publishTo = dateTo.getDate() + 2;
    dateFrom.setDate(publishFrom);
    dateTo.setDate(publishTo);
    const defaultDatePublishFrom = moment(dateFrom).format("YYYY-MM-DDTHH:mm");
    const defaultDatePublishTo = moment(dateTo).format("YYYY-MM-DDTHH:mm");

    const publishFromEdit = moment(newForm?.publish_from).format(
      "YYYY-MM-DDTHH:mm"
    );
    const publishToEdit = moment(newForm?.publish_to).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formPublish.reset({
        publish_from: newForm?.publish_from,
        publish_to: newForm?.publish_to,
        status: 1,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid) => {
    // setUrlProfilePicture();
    setIsModalFormOpen(isOpen);
    // setIsEdit(isEdit);
    mutateGetPoint(
      { guid, token },
      {
        onSuccess: (data) => {
          // console.log(data?.data);
          isEdit && resetformModal(data?.data);
          // isEdit && setBankLogoUrl(data?.data?.bank_logo_url);
          // isEdit && setQrCodeUrl(data?.data?.bank_qr_code_url);
        },
      }
    );
    // isCreate && resetformModal({});
  };

  const handleDialogFilterRange = (isOpen, guid) => {
    setIsVisibleFilterRange(isOpen);
    setIsVisibleFilterRangeInfo(false);
  };

  const handleFilterRange = (payload) => {
    const defaultDatePublishFrom = moment(payload?.publish_from).format();
    const defaultDatePublishTo = moment(payload?.publish_to).format();

    const payloadPublish = {
      publish_from: defaultDatePublishFrom,
      publish_to: defaultDatePublishTo,
    };
  };

  const handleDialogDownloadTransaction = (isOpen, guid) => {
    // console.log(isPublish)
    setIsVisibleDownloadTransaction(isOpen);
    // setAdvertisingRowId(guid);
    // setIsPublish(isPublish);
    setIsVisibleDownloadInfoTransaction(false);

    // mutateGetAdvertisingRow(
    //   { guid, token, language },
    //   {
    //     onSuccess: (data) => {
    resetFormPublish({});
    //       setPublishPayloadClassifiedAdsEdit(data?.data);
    //     },
    //   }
    // );
  };

  const handleDownloadTransaction = (payload) => {
    let isRange = formPublish?.getValues("publish_from")?.[1] ? true : false;

    const defaultDatePublishFrom = moment(payload?.publish_from?.[0]).utc().format();
    const defaultDatePublishTo = moment(payload?.publish_from?.[1]).utc().format();

    let payloadPublish = {
      isRange: isRange,
      publish_from:
        defaultDatePublishFrom === "Invalid date"
          ? null
          : defaultDatePublishFrom,
      publish_to:
        defaultDatePublishTo === "Invalid date" ? null : defaultDatePublishTo,
      language: language,
      token: token,
      page: 1,
      limit: 10000,
    };

    // console.log(payloadPublish);

    mutateSetPointListSearch(
      { payload: payloadPublish },
      {
        onSuccess: (data) => {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";

          const customHeadings = data?.data?.map((item, key) => {
            // const nominal = new Intl.NumberFormat("id-ID", {
            //   style: "currency",
            //   currency: "IDR",
            // }).format(item?.amount);

            const debit = item?.transaction_type === "debet" ? parseInt(item?.amount) : 0;
            const kredit = item?.transaction_type === "credit" ? parseInt(item?.amount) : 0;

            return {
              Type: item?.transaction_type,
              Tanggal: moment(item?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY, h:mm:ss a"),
              Username: item?.user_app_data?.username,
              Debit: debit,
              Kredit: kredit,
              Bank: item?.payment_channel_data?.bank_name,
              "Keterangan/Nomor trx Nihao": item?.id,
              Status: item?.status,
            };
          });

          console.log(customHeadings);

          const ws = XLSX.utils.json_to_sheet(customHeadings, {
            skipHeader: true, // Skip the header row
            origin: 3, // Start from the fourth row (0-based index)
          });

          ws["!merges"] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
            { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
            { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
            { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
            { s: { r: 1, c: 3 }, e: { r: 1, c: 4 } },
            { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
            { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },
            { s: { r: 1, c: 7 }, e: { r: 2, c: 7 } },
          ];

          ws["A1"] = {
            v: "TRANSACTION POINT",
            s: {
              alignment: { horizontal: "center" },
              fill: { bgColor: { indexed: 22 }, fgColor: { indexed: 22 } },
            },
          };
          ws["A2"] = { v: "Type", s: { alignment: { horizontal: "center" } } };
          ws["B2"] = {
            v: "Tanggal",
            s: { alignment: { horizontal: "center" } },
          };
          ws["C2"] = {
            v: "Username",
            s: { alignment: { horizontal: "center" } },
          };
          ws["D2"] = {
            v: "Nominal",
            s: { alignment: { horizontal: "center" } },
          };
          ws["D3"] = { v: "Debit", s: { alignment: { horizontal: "center" } } };
          ws["E3"] = {
            v: "Credit",
            s: { alignment: { horizontal: "center" } },
          };
          ws["F2"] = { v: "Bank", s: { alignment: { horizontal: "center" } } };
          ws["G2"] = {
            v: "Keterangan / Nomor Trx Nihao",
            s: { alignment: { horizontal: "center" } },
          };
          ws["H2"] = {
            v: "Status",
            s: { alignment: { horizontal: "center" } },
          };

          // Set column width (adjust the width as needed)
          ws["!cols"] = [
            { width: 10 },
            { width: 29 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 30 },
            { width: 15 },
          ];

          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const datas = new Blob([excelBuffer], { type: fileType });

          FileSaver.saveAs(datas, "Transaction Point" + fileExtension);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearchRange = (page, limit) => {
    let isRange = formPublish?.getValues("publish_from")?.[1] ? true : false;

    const defaultDatePublishFrom = moment(
      formPublish?.getValues("publish_from")?.[0]
    )
      .utc()
      .format();

    const defaultDatePublishTo = moment(formPublish?.getValues("publish_from")?.[1])
      .utc()
      .format();

    setIsSearch(true);

    let payload = {
      isRange: isRange,
      publish_from:
        defaultDatePublishFrom === "Invalid date"
          ? null
          : defaultDatePublishFrom,
      publish_to:
        defaultDatePublishTo === "Invalid date" ? null : defaultDatePublishTo,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    mutateSetPointListSearch(
      { payload },
      {
        onSuccess: (data) => {
          newPointCodeData = [];

          setNewPointDataSearch(data);
          data?.data?.map((value) => {
            newPointCodeData.push({
              transaction_type: value?.transaction_type,
              created_at: moment(value?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              username: value?.user_app_data?.username,
              amount: new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(value?.amount),
              bank_logo_url: (
                <img
                  src={value?.payment_channel_data?.bank_logo_url}
                  alt=""
                  width={50}
                />
              ),
              is_voucher: value?.is_voucher ? (
                <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  {t("LABEL.YES")}
                </div>
              ) : (
                <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  {t("LABEL.NO")}
                </div>
              ),
              id: value?.id,
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ||
                  value?.status === "reject" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              action: (
                <div className="flex items-center justify-center gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setIsVisibleFilterRange(false);
          setNewPointData(newPointCodeData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    setIsSearch(false);
    mutateGetPoints(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newPointCodeData = [];

          data?.data?.map((value) => {
            newPointCodeData.push({
              transaction_type: value?.transaction_type,
              created_at: moment(value?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              username: value?.user_app_data?.username,
              amount: new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(value?.amount),
              bank_logo_url: (
                <img
                  src={value?.payment_channel_data?.bank_logo_url}
                  alt=""
                  width={50}
                />
              ),
              is_voucher: value?.is_voucher ? (
                <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  {t("LABEL.YES")}
                </div>
              ) : (
                <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  {t("LABEL.NO")}
                </div>
              ),
              id: value?.id,
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ||
                  value?.status === "reject" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              action: (
                <div className="flex items-center justify-center gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewPointData(newPointCodeData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    setIsSearch(false);
    mutateGetPoints(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newPointCodeData = [];

          data?.data?.map((value) => {
            newPointCodeData.push({
              transaction_type: value?.transaction_type,
              created_at: moment(value?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              username: value?.user_app_data?.username,
              amount: new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(value?.amount),
              bank_logo_url: (
                <img
                  src={value?.payment_channel_data?.bank_logo_url}
                  alt=""
                  width={50}
                />
              ),
              is_voucher: value?.is_voucher ? (
                <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  {t("LABEL.YES")}
                </div>
              ) : (
                <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  {t("LABEL.NO")}
                </div>
              ),
              id: value?.id,
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ||
                  value?.status === "reject" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              action: (
                <div className="flex items-center justify-center gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewPointData(newPointCodeData);
        },
      }
    );
  };

  useEffect(() => {
    newPointCodeData = [];
    setIsSearch(false);

    pointData?.data?.map((value) => {
      newPointCodeData.push({
        transaction_type: value?.transaction_type,
        created_at: moment(value?.created_at)
          .utc()
          .add(7, "hours")
          .format("DD/MM/YYYY HH:mm:ss"),
        username: value?.user_app_data?.username,
        amount: new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(value?.amount),
        bank_logo_url: (
          <img
            src={value?.payment_channel_data?.bank_logo_url}
            alt=""
            width={50}
          />
        ),
        is_voucher: value?.is_voucher ? (
          <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
            {t("LABEL.YES")}
          </div>
        ) : (
          <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
            {t("LABEL.NO")}
          </div>
        ),
        id: value?.id,
        status:
          value?.status === "approved" || value?.status === "success" ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "payment reject" ||
            value?.status === "rejected" ? (
            <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "paid" || value?.status === "expired" ? (
            <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : (
            <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ),

        action: (
          <div className="flex items-center justify-center gap-2">
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleModalFormOpen(false, true, true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.VIEW")}
              </div>
            </Button>
          </div>
        ),
      });
    });
    setNewPointData(newPointCodeData);
  }, [pointData, token, isDeleteAccessUserAgent, isViewAllAccess]);

  return (
    <Provider
      value={{
        formModal,

        resetformModal,

        isModalFormOpen,
        isEdit,

        pointData,
        newPointData,

        isLoadingPointData,
        isLoadingGetPoints,

        handleModalFormOpen,
        handlePaginationTable,
        handleShowEntriesTable,

        isViewAllAccess,
        setIsSearch,
        isSearch,

        isShowType,
        isShowStatus,
        refetchPointData,
        handleDialogFilterRange,
        handleFilterRange,
        isVisibleFilterRange,
        setIsVisibleFilterRange,
        isVisibleFilterRangeInfo,
        setIsVisibleFilterRangeInfo,
        formPublish,
        resetFormPublish,
        handleSearchRange,
        isLoadingSetPointListSearch,
        newPointDataSearch,
        handleDownloadTransaction,
        handleDialogDownloadTransaction,
        isVisibleDownloadTransaction,
        setIsVisibleDownloadTransaction,
        isVisibleDownloadInfoTransaction,
        setIsVisibleDownloadInfoTransaction,
      }}
    >
      {children}
    </Provider>
  );
};

export default PointController;
