import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Switch, Tag, TagLabel, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import {
  deleteFaq,
  getFaq,
  getFaqList,
  getFaqSearch,
  updateOrderFaq,
} from "./api/FaqApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/public/AuthController";
// import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";

export const FaqContext = createContext();
const { Provider } = FaqContext;

const FaqController = ({ children }) => {
  const { t } = useTranslation();
  const { token } = useContext(TokenContext);

  let language = t("LANGUAGE");
  const toast = useToast();

  const {
    data: FaqData,
    isLoading: isLoadingFaqData,
    refetch: refetchFaqData,
  } = useQuery(["faq", token, language], () => getFaqList({ language, token }));

  const { mutate: mutateGetFaq } = useMutation(getFaq);

  const {
    isLoading: isLoadingSetHomeSectionSearch,
    mutate: mutateSetFaqSearch,
  } = useMutation(getFaqSearch);

  const { isLoading: isLoadingDeleteFaq, mutate: mutateDeleteFaq } =
    useMutation(deleteFaq);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleFaqDialog, setIsVisibleFaqDialog] = useState(false);
  const [isVisibleDeleteFaq, setIsVisibleDeleteFaq] = useState(false);
  const [isVisibleDeleteInfoFaq, setIsVisibleDeleteInfoFaq] = useState(false);
  const [isPublishHomeSectionOpen, setIsPublishHomeSectionOpen] =
    useState(false);
  const { isLoading: isLoadingUpdateOrderFaq, mutate: mutateUpdateOrderFaq } =
    useMutation(updateOrderFaq);
  const [newFaq, setnewFaq] = useState();

  const [faqId, setFaqId] = useState();

  const [payloadFaqEdit, setPayloadFaqEdit] = useState();
  const [isActiveSubmitOrder, setIsActiveSubmitOrder] = useState(false);
  const [isActiveDrag, setIsActiveDrag] = useState(false);

  const [indexTabActive, setIndexTabActive] = useState();

  const [orderSection, setOrderSection] = useState();

  const [newAdvertisings, setNewAdvertisings] = useState();
  const [isOpenInsertModalAds, setIsOpenInsertModalAds] = useState(false);
  const [selectedAdsId, setSelectedAdsId] = useState([]);
  const [isPublishFaqOpen, setIsPublishFaqOpen] = useState(false);

  
  const [isSearch, setIsSearch] = useState(false);
  const [newFaqSearch, setNewFaqSearch] = useState();

  let newFaqData = [];

  const [isGetAccessFaq, setIsGetAccessFaq] = useState();
  const [isDeleteAccessFaq, setIsDeleteAccessFaq] = useState();
  const [isUpdateAccessFaq, setIsUpdateAccessFaq] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //FAQ
          const getAccessFaq = data?.data?.access
            ?.filter((data) => data?.page === "faq")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessFaq(getAccessFaq?.length);

          const deleteAccessFaq = data?.data?.access
            ?.filter((data) => data?.page === "faq")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessFaq(deleteAccessFaq?.length);

          const updateAccessFaq = data?.data?.access
            ?.filter((data) => data?.page === "faq")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessFaq(updateAccessFaq?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    if (newForm) {
      formEn.reset({
        question: newForm?.question,
        answer: newForm?.answer,
      });
    }

    if (newForm && isEdit) {
      formEn.reset({
        question: payloadFaqEdit?.question?.en,
        answer: payloadFaqEdit?.answer?.en,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, [isEdit, payloadFaqEdit]);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    if (newForm) {
      formId.reset({
        question: newForm?.question,
        answer: newForm?.answer,
      });
    }

    if (newForm && isEdit) {
      formId.reset({
        question: payloadFaqEdit?.question?.id,
        answer: payloadFaqEdit?.answer?.id,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, [isEdit, payloadFaqEdit]);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    if (newForm) {
      formCn.reset({
        question: newForm?.question,
        answer: newForm?.answer,
      });
    }

    if (newForm && isEdit) {
      formCn.reset({
        question: payloadFaqEdit?.question?.cn,
        answer: payloadFaqEdit?.answer?.cn,

        // image_thumbnail_url: thumbnailHomeSection,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, [isEdit, payloadFaqEdit]);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    if (newForm) {
      formPreviewEn.reset({
        question: newForm?.en?.question?.[0]?.en,
        answer: newForm?.en?.answer?.[0]?.en,
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    if (newForm) {
      formPreviewId.reset({
        question: newForm?.id?.question?.[0]?.id,
        answer: newForm?.id?.answer?.[0]?.id,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    if (newForm) {
      formPreviewCn.reset({
        question: newForm?.cn?.question?.[0]?.cn,
        answer: newForm?.cn?.answer?.[0]?.cn,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    setIndexTabActive(0);
    isOpen === false && handlePublishHomeSectionOpen(false);
    let lang = t("LANGUAGE");

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});
    }

    isEdit &&
      mutateGetFaq(
        { guid, token, lang },
        {
          onSuccess: (data) => {
            setPayloadFaqEdit(data?.data);
          },
        }
      );
  };

  const handlePublishHomeSectionOpen = (isOpen) => {
    setIsPublishHomeSectionOpen(isOpen);
    // resetFormPublish({});
  };

  const handleDialogDeleteFaq = (isOpen, guid) => {
    setIsVisibleDeleteFaq(isOpen);
    setFaqId(guid);
    setIsVisibleDeleteInfoFaq(false);
  };

  const handleDeleteFaq = (guid) => {
    let lang = t("LANGUAGE");
    mutateDeleteFaq(
      { guid, token, lang },
      {
        onSuccess: () => {
          refetchFaqData();
          setIsVisibleDeleteFaq(false);
          setIsVisibleDeleteInfoFaq(true);
        },
      }
    );
  };

  const handleActiveSubmitOrder = (isActive, isDrag) => {
    setIsActiveSubmitOrder(isActive);
    setIsActiveDrag(isDrag);
  };

  const handleSubmitOrder = (isDrag) => {
    setIsActiveSubmitOrder(isDrag);
    setIsActiveDrag(isDrag);
    // let lang = t("LANGUAGE");

    let payloadOrderSection = [];
    orderSection?.map((newOrder) => {
      payloadOrderSection.push({
        id: newOrder?.uid,
      });
    });

    let payloadOrder = {
      orders: payloadOrderSection,
    };
    // console.log(payloadOrder);

    mutateUpdateOrderFaq(
      { payloadOrder, token, language },
      {
        onSuccess: (data) => {
          toast({
            description: "Data Order Updated",
            position: "top-right",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        },
      }
    );
  };

  const handlePublishFaqOpen = (isOpen) => {
    setIsPublishFaqOpen(isOpen);
    // resetFormPublish({});
  };

  // const handleHomeSectionActive = (guid) => {
  //   // mutateSetHomeSectionActive(
  //   //   { guid, token },
  //   //   {
  //   //     onSuccess: (data) => {
  //   //       refetchFaqData();
  //   //       toast({
  //   //         answer: data?.message?.response_message,
  //   //         position: "top-right",
  //   //         status: "success",
  //   //         duration: 1500,
  //   //         isClosable: true,
  //   //       });
  //   //     },
  //   //   }
  //   // );
  // };

  const handleSearch = (keyword, page, limit) => {
    // let isQuestion = keyword?.keyword_filter === "1" && true;
    
    let isQuestion  = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isAnswer    = keyword?.keyword_filter === "2" && true;
    let isSearchStatus = keyword?.keyword_status === "1" && true;
    let searchKeyword  = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-faq", searchKeyword);
    } else {
      localStorage.removeItem("search-faq");
    }
    
    setIsSearch(true);    

    let lang = t("LANGUAGE");

    let payload = {
      isQuestion: isQuestion,
      isAnswer: isAnswer,
      search: searchKeyword,
      lang: lang,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    mutateSetFaqSearch(
      { payload },
      {
        onSuccess: (data) => {
          newFaqData = [];

          data?.data?.map((value) => {
            setNewFaqSearch(data);
            newFaqData.push({
              uid: value?.guid,
              id: value?.no,
              question: value?.question,
              answer: value?.answer,

              action: (
                <div className="flex items-center justify-center gap-2">
                  {/* {(isGetAccessFaq === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(
                          false,
                          true,
                          true,
                          value?.guid,
                          true
                        )
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}

                  {/* {(isDeleteAccessFaq === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleDialogDeleteFaq(true, value?.guid)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          // console.log(newFaqData);
          setnewFaq(newFaqData);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem("search-faq");
    newFaqData = [];

    FaqData?.data?.map((value) => {
      newFaqData.push({
        uid: value?.guid,
        id: value?.no,
        question: value?.question,
        answer: value?.answer,

        action: (
          <div className="flex items-center justify-center gap-2">
            {/* {(isGetAccessFaq === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleModalFormOpen(false, true, true, value?.guid, true)
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.VIEW")}
                </div>
              </Button>
            {/* )} */}

            {/* {(isDeleteAccessFaq === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#FF0F0F]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() => handleDialogDeleteFaq(true, value?.guid)}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.DELETE")}
                </div>
              </Button>
             {/* )} */}
          </div>
        ),
      });
    });
    // console.log(newFaqData);
    setnewFaq(newFaqData);
  }, [
    FaqData,
    token,
    // isGetAccessSection,
    // isDeleteAccessSection,
    // isUpdateAccessSection,
    // isViewAllAccess,
    isGetAccessFaq,
    isDeleteAccessFaq,
    isUpdateAccessFaq,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,

        faqId,
        isEdit,
        isCreate,
        isModalFormOpen,
        isVisibleFaqDialog,
        setIsVisibleFaqDialog,
        isVisibleDeleteFaq,
        setIsVisibleDeleteFaq,
        isVisibleDeleteInfoFaq,
        setIsVisibleDeleteInfoFaq,

        isLoadingDeleteFaq,

        isLoadingFaqData,
        // isLoadingGetAdvertisings,

        FaqData,
        newFaq,
        newFaqData,
        handleDialogDeleteFaq,
        handleModalFormOpen,
        // handleShowEntriesTable,
        // handlePaginationTable,
        refetchFaqData,
        handleDeleteFaq,
        handleSearch,
        handleSubmitOrder,
        orderSection,
        setOrderSection,

        handleActiveSubmitOrder,
        isActiveSubmitOrder,
        setIsActiveSubmitOrder,
        isActiveDrag,
        setIsActiveDrag,

        payloadFaqEdit,
        setPayloadFaqEdit,
        isView,
        setIsView,

        isOpenInsertModalAds,
        setIsOpenInsertModalAds,
        newAdvertisings,
        setNewAdvertisings,

        selectedAdsId,
        setSelectedAdsId,

        indexTabActive,
        setIndexTabActive,

        handlePublishHomeSectionOpen,
        isPublishHomeSectionOpen,
        setIsPublishHomeSectionOpen,

        isGetAccessFaq,
        isDeleteAccessFaq,
        isUpdateAccessFaq,
        isViewAllAccess,
        handlePublishFaqOpen,
        isPublishFaqOpen,
        setIsPublishFaqOpen,
        isSearch, setIsSearch,
        newFaqSearch, setNewFaqSearch,
        formKeywordSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default FaqController;
