import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

// Tailwind
import "./assets/css";

// PrimeReact
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

// Font Family
import "@fontsource/poppins";

// Rsuite
import "rsuite/dist/rsuite.min.css";

// i18n Translations
import "./i18n/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
