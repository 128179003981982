import React, { lazy, Suspense } from "react";
import PublicAuth from "../../../../controllers/public/PublicAuth";
// import ForgetForm from "./components/ForgetForm";

const Forget = () => {
  const ForgetForm = lazy(() => import("./components/ForgetForm"));

  return (
    <>
      <Suspense
        fallback={
          <div class="h-screen flex items-center">
            <div class="mx-auto">
              <div
                class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          </div>
        }
      >
        <PublicAuth />
        <ForgetForm />
      </Suspense>
    </>
  );
};

export default Forget;
