import { api } from "../../../utils/axios";

//Get Profile
export const getProfile = ({ token, language }) =>
  api
    .get("/backoffice/user-backoffice/profile", {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update Profile
export const updateProfile = (payload, token) =>
  api
    .put("/backoffice/user-backoffice/profile", payload?.newPayload, {
      headers: {
        language: payload?.lang,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Change Password
export const updatePassword = (payload, token) =>
  api
    .put(
      "/backoffice/user-backoffice/profile/change-password",
      payload?.newPayload,
      {
        headers: {
          language: payload?.lang,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);
