import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TokenContext } from "../../../../controllers/public/TokenController";

import { Button, Switch, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import {
  getAdvertisingsBanner,
  getAdvertisingBannerSearch,
  getAdvertisingBanner,
  deleteAdvertisingBanner,
  updateToggleAdvertisingBanner,
} from "./api/BannerApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/public/AuthController";
import { getRole } from "../User/UserAdminRole/api/RoleApi";
import Cookies from "js-cookie";

export const BannerAdsContext = createContext();
const { Provider } = BannerAdsContext;

const BannerAdsController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: AdvertisingsBannerData,
    isLoading: isLoadingAdvertisingsBannerData,
    refetch: refetchAdvertisingsBannerData,
  } = useQuery(["advertisingbanner", token, language], () =>
    getAdvertisingsBanner({ token, language })
  );

  const { mutate: mutateGetAdvertisingsBanner } = useMutation(
    getAdvertisingsBanner
  );

  const { mutate: mutateGetAdvertisingBanner } =
    useMutation(getAdvertisingBanner);

  const {
    isLoading: isLoadingSetAdvertisingBannerSearch,
    mutate: mutateSetAdvertisingBannerSearch,
  } = useMutation(getAdvertisingBannerSearch);

  const {
    isLoading: isLoadingDeleteAdvertisingBanner,
    mutate: mutateDeleteAdvertisingBanner,
  } = useMutation(deleteAdvertisingBanner);

  const {
    isLoading: isLoadingUpdateToggleAdvertisingBanner,
    mutate: mutateUpdateToggleAdvertisingBanner,
  } = useMutation(updateToggleAdvertisingBanner);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleBannerDialog, setIsVisibleBannerDialog] = useState(false);
  const [
    isVisibleDeleteAdvertisingBanner,
    setIsVisibleDeleteAdvertisingBanner,
  ] = useState(false);
  const [
    isVisibleDeleteInfoAdvertisingBanner,
    setIsVisibleDeleteInfoAdvertisingBanner,
  ] = useState(false);

  const [isPublishBannerAdsOpen, setIsPublishBannerAdsOpen] = useState(false);
  const [newAdvertisingsBanner, setnewAdvertisingsBanner] = useState();
  const [newAdvertisingBannerSearch, setNewAdvertisingBannerSearch] =
    useState();

  const [advertisingBannerId, setAdvertisingBannerId] = useState();

  const [urlBannerThumbnail, setUrlBannerThumbnail] = useState();
  const [urlBannerThumbnailWebsite, setUrlBannerThumbnailWebsite] = useState();
  const [urlBannerThumbnailEdit, setUrlBannerThumbnailEdit] = useState();
  const [urlBannerThumbnailWebsiteEdit, setUrlBannerThumbnailWebsiteEdit] =
    useState();

  const [urlBannerThumbnailEn, setUrlBannerThumbnailEn] = useState();
  const [urlBannerThumbnailWebsiteEn, setUrlBannerThumbnailWebsiteEn] =
    useState();
  const [urlBannerThumbnailEnEdit, setUrlBannerThumbnailEnEdit] = useState();
  const [urlBannerThumbnailWebsiteEnEdit, setUrlBannerThumbnailWebsiteEnEdit] =
    useState();

  const [urlBannerThumbnailId, setUrlBannerThumbnailId] = useState();
  const [urlBannerThumbnailWebsiteId, setUrlBannerThumbnailWebsiteId] =
    useState();
  const [urlBannerThumbnailIdEdit, setUrlBannerThumbnailIdEdit] = useState();
  const [urlBannerThumbnailWebsiteIdEdit, setUrlBannerThumbnailWebsiteIdEdit] =
    useState();

  const [urlBannerThumbnailCn, setUrlBannerThumbnailCn] = useState();
  const [urlBannerThumbnailWebsiteCn, setUrlBannerThumbnailWebsiteCn] =
    useState();
  const [urlBannerThumbnailCnEdit, setUrlBannerThumbnailCnEdit] = useState();
  const [urlBannerThumbnailWebsiteCnEdit, setUrlBannerThumbnailWebsiteCnEdit] =
    useState();

  const [payloadBannerEdit, setPayloadBannerEdit] = useState();
  const [indexTabActive, setIndexTabActive] = useState();

  const [enDescription, setEnDescription] = useState("");
  const [idDescription, setIdDescription] = useState("");
  const [cnDescription, setCnDescription] = useState("");
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  let newAdvertisingsBannerData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessBanner, setIsGetAccessBanner] = useState();
  const [isDeleteAccessBanner, setIsDeleteAccessBanner] = useState();
  const [isUpdateAccessBanner, setIsUpdateAccessBanner] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const [isFileBanner, setIsFileBanner] = useState(true);
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Banner
          const getAccessBanner = data?.data?.access
            ?.filter((data) => data?.page === "banner")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessBanner(getAccessBanner?.length);

          const deleteAccessBanner = data?.data?.access
            ?.filter((data) => data?.page === "banner")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessBanner(deleteAccessBanner?.length);

          const updateAccessBanner = data?.data?.access
            ?.filter((data) => data?.page === "banner")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessBanner(updateAccessBanner?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadBannerEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formEn.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,
        banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_web?.en,
        banner_thumbnail: newForm?.banner?.[0]?.thumbnail?.en,
        banner_image: newForm?.banner?.[0]?.image?.en,
      });
    }

    if (newForm && isEdit === true) {
      formEn.reset({
        date: dateEdit,
        title: payloadBannerEdit?.title?.en,
        description: payloadBannerEdit?.description?.en,

        // banner_thumbnail: payloadBannerEdit?.banner?.thumbnail,
        // banner_image: payloadBannerEdit?.banner?.image,

        banner_thumbnail_website: payloadBannerEdit?.banner?.thumbnail_web?.en,
        banner_thumbnail: payloadBannerEdit?.banner?.thumbnail?.en,
        banner_image: payloadBannerEdit?.banner?.image?.en,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, [isEdit, payloadBannerEdit]);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadBannerEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formId.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,

        banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_web?.id,
        banner_thumbnail: newForm?.banner?.[0]?.thumbnail?.id,
        banner_image: newForm?.banner?.[0]?.image?.id,
      });
    }

    const thumbnailBanner = urlBannerThumbnailEdit
      ? urlBannerThumbnailEdit
      : payloadBannerEdit?.banner?.thumbnail;

    // console.log(thumbnailBanner)

    if (newForm && isEdit === true) {
      formId.reset({
        date: dateEdit,
        title: payloadBannerEdit?.title?.id,
        description: payloadBannerEdit?.description?.id,

        banner_thumbnail_website: payloadBannerEdit?.banner?.thumbnail_web?.id,
        banner_thumbnail: payloadBannerEdit?.banner?.thumbnail?.id,
        banner_image: payloadBannerEdit?.banner?.image?.id,

        // banner_thumbnail: thumbnailBanner,
        // banner_image: payloadBannerEdit?.banner?.[0]?.image,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, [isEdit, payloadBannerEdit, urlBannerThumbnailEdit]);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadBannerEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formCn.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,
        banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_web?.cn,
        banner_thumbnail: newForm?.banner?.[0]?.thumbnail?.cn,
        banner_image: newForm?.banner?.[0]?.image?.cn,
      });
    }

    const thumbnailBanner = urlBannerThumbnailEdit
      ? urlBannerThumbnailEdit
      : payloadBannerEdit?.banner?.thumbnail;

    if (newForm && isEdit === true) {
      formCn.reset({
        date: dateEdit,
        title: payloadBannerEdit?.title?.cn,
        description: payloadBannerEdit?.description?.cn,

        banner_thumbnail_website: payloadBannerEdit?.banner?.thumbnail_web?.cn,
        banner_thumbnail: payloadBannerEdit?.banner?.thumbnail?.cn,
        banner_image: payloadBannerEdit?.banner?.image?.cn,

        // banner_thumbnail: thumbnailBanner,
        // banner_image: payloadBannerEdit?.banner?.[0]?.image,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, [isEdit, payloadBannerEdit, urlBannerThumbnailEdit]);

  // -------------------------------
  // Form Upload
  // -------------------------------
  const formUpload = useForm();
  const resetFormUpload = (newForm) => {
    if (newForm && !isEdit) {
      formUpload.reset({
        banner_thumbnail: newForm?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_web,
        banner_image: newForm?.banner?.[0]?.image,
      });
    }

    if (newForm && isEdit) {
      formUpload.reset({
        banner_thumbnail: payloadBannerEdit?.banner?.thumbnail,
        banner_thumbnail_website: payloadBannerEdit?.banner?.thumbnail_web,
        banner_image: payloadBannerEdit?.banner?.image,
      });
    }
  };

  useEffect(() => {
    resetFormUpload({});
  }, [isEdit, payloadBannerEdit]);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      // console.log(newForm?.en?.banner?.thumbnail_web?.en)
      formPreviewEn.reset({
        date: defaultValue,
        title: newForm?.en?.title?.[0]?.en,
        description: newForm?.en?.description?.[0]?.en,
        // banner: newForm?.en?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.en?.banner?.thumbnail_web?.en,
        banner_thumbnail: newForm?.en?.banner?.thumbnail?.en,
        banner_image: newForm?.en?.banner?.image?.en,
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewId.reset({
        date: defaultValue,
        title: newForm?.id?.title?.[0]?.id,
        description: newForm?.id?.description?.[0]?.id,
        // banner: newForm?.id?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.id?.banner?.thumbnail_web?.id,
        banner_thumbnail: newForm?.id?.banner?.thumbnail?.id,
        banner_image: newForm?.id?.banner?.image?.id,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewCn.reset({
        date: defaultValue,
        title: newForm?.cn?.title?.[0]?.cn,
        description: newForm?.cn?.description?.[0]?.cn,
        // banner: newForm?.cn?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.cn?.banner?.thumbnail_web?.cn,
        banner_thumbnail: newForm?.cn?.banner?.thumbnail?.cn,
        banner_image: newForm?.cn?.banner?.image?.cn,
      });
    }
  };

  const formPreviewUpload = useForm();
  const resetFormPreviewUpload = (newForm) => {
    if (newForm) {
      formPreviewUpload.reset({
        banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_website,
        banner_thumbnail: newForm?.banner?.[0]?.thumbnail,
        banner_image: newForm?.banner?.[0]?.image,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    setIndexTabActive(0);
    isOpen === false && handlePublishBannerAdsOpen(false);

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormUpload({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});
      resetFormPreviewUpload({});

      setUrlBannerThumbnail([]);
      setUrlBannerThumbnailWebsite([]);
      setUrlBannerThumbnailEn([]);
      setUrlBannerThumbnailWebsiteEn([]);
      setUrlBannerThumbnailId([]);
      setUrlBannerThumbnailWebsiteId([]);
      setUrlBannerThumbnailCn([]);
      setUrlBannerThumbnailWebsiteCn([]);
      setIsFileBanner(false);

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieen"));
        index++
      ) {
        Cookies.remove("keyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieid"));
        index++
      ) {
        Cookies.remove("keyid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookiecn"));
        index++
      ) {
        Cookies.remove("keycn" + index);
      }

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieediten"));
        index++
      ) {
        Cookies.remove("keyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditid"));
        index++
      ) {
        Cookies.remove("keyeditid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditcn"));
        index++
      ) {
        Cookies.remove("keyeditcn" + index);
      }
    }

    if (isEdit === true) {
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieen"));
        index++
      ) {
        Cookies.remove("keyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieid"));
        index++
      ) {
        Cookies.remove("keyid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookiecn"));
        index++
      ) {
        Cookies.remove("keycn" + index);
      }

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieediten"));
        index++
      ) {
        Cookies.remove("keyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditid"));
        index++
      ) {
        Cookies.remove("keyeditid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditcn"));
        index++
      ) {
        Cookies.remove("keyeditcn" + index);
      }
    }

    isEdit &&
      mutateGetAdvertisingBanner(
        { guid, token, language },
        {
          onSuccess: (data) => {
            setEnDescription(data?.data?.description);
            setIdDescription(data?.data?.description);
            setCnDescription(data?.data?.description);
            setPayloadBannerEdit(data?.data);
          },
        }
      );
  };

  const handlePublishBannerAdsOpen = (isOpen) => {
    setIsPublishBannerAdsOpen(isOpen);
    // resetFormPublish({});
  };

  const handleDialogDeleteAdvertisingBanner = (isOpen, guid) => {
    setIsVisibleDeleteAdvertisingBanner(isOpen);
    setAdvertisingBannerId(guid);
    setIsVisibleDeleteInfoAdvertisingBanner(false);
  };

  const handleDeleteAdvertisingBanner = (guid) => {
    mutateDeleteAdvertisingBanner(
      { guid, token, language },
      {
        onSuccess: () => {
          refetchAdvertisingsBannerData();
          setIsVisibleDeleteAdvertisingBanner(false);
          setIsVisibleDeleteInfoAdvertisingBanner(true);
        },
      }
    );
  };

  const handleUpdateToggleAdvertisingBanner = (guid) => {
    mutateUpdateToggleAdvertisingBanner(
      { guid, token, language },
      {
        onSuccess: (data) => {
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
          handlePaginationTable(currentPage?.[0], currentLimit?.[0]);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    let isTitle        = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isStatus       = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isSearchStatus = keyword?.keyword_status === "1" && true;
    let searchKeyword  = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    // setIsShowStatus(isShowStatus)
    setIsSearch(true);

    let payload = {
      isTitle: isTitle,
      isStatus: isStatus,
      search: searchKeyword,
      searchStatus: isSearchStatus,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(10);
    mutateSetAdvertisingBannerSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setNewAdvertisingBannerSearch(data);
            newAdvertisingsBannerData.push({
              title: value?.title,
              description: value?.description,
              draft:
                value?.is_draft === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleUpdateToggleAdvertisingBanner(value?.id)
                  }
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id, true)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleDialogDeleteAdvertisingBanner(true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setnewAdvertisingsBanner(newAdvertisingsBannerData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetAdvertisingsBanner(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newAdvertisingsBannerData = [];

          data?.data?.map((value) => {
            newAdvertisingsBannerData.push({
              title: value?.title,
              description: value?.description,
              draft:
                value?.is_draft === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleUpdateToggleAdvertisingBanner(value?.id)
                  }
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id, true)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleDialogDeleteAdvertisingBanner(true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setnewAdvertisingsBanner(newAdvertisingsBannerData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetAdvertisingsBanner(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newAdvertisingsBannerData = [];

          data?.data?.map((value) => {
            newAdvertisingsBannerData.push({
              title: value?.title,
              description: value?.description,
              draft:
                value?.is_draft === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleUpdateToggleAdvertisingBanner(value?.id)
                  }
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id, true)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>

                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleDialogDeleteAdvertisingBanner(true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setnewAdvertisingsBanner(newAdvertisingsBannerData);
        },
      }
    );
  };

  useEffect(() => {
    newAdvertisingsBannerData = [];

    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    AdvertisingsBannerData?.data?.map((value) => {
      newAdvertisingsBannerData.push({
        title: value?.title,
        description: value?.description,
        draft:
          value?.is_draft === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.YES")}
            </div>
          ) : (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NO")}
            </div>
          ),
        is_active: (
          <Switch
            colorScheme={value?.is_active === true ? "green" : "red"}
            isChecked={value?.is_active === true ? true : false}
            onChange={() => handleUpdateToggleAdvertisingBanner(value?.id)}
          />
        ),
        status:
          value?.is_active === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.ACTIVE")}
            </div>
          ) : (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NOT_ACTIVE")}
            </div>
          ),
        action: (
          <div className="flex gap-2">
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleModalFormOpen(false, true, true, value?.id, true)
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.VIEW")}
              </div>
            </Button>
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF0F0F]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleDialogDeleteAdvertisingBanner(true, value?.id)
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.DELETE")}
              </div>
            </Button>
          </div>
        ),
      });
    });
    setnewAdvertisingsBanner(newAdvertisingsBannerData);
  }, [
    AdvertisingsBannerData,
    token,
    isGetAccessBanner,
    isDeleteAccessBanner,
    isViewAllAccess,
    t("LANGUAGE"),
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,
        formUpload,

        resetFormEn,
        resetFormUpload,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        formPreviewUpload,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,
        resetFormPreviewUpload,

        advertisingBannerId,
        isEdit,
        setIsEdit,
        isCreate,
        isModalFormOpen,
        isVisibleBannerDialog,
        setIsVisibleBannerDialog,
        isVisibleDeleteAdvertisingBanner,
        setIsVisibleDeleteAdvertisingBanner,
        isVisibleDeleteInfoAdvertisingBanner,
        setIsVisibleDeleteInfoAdvertisingBanner,

        isLoadingDeleteAdvertisingBanner,
        isLoadingAdvertisingsBannerData,

        AdvertisingsBannerData,
        newAdvertisingsBanner,
        newAdvertisingsBannerData,
        handleDialogDeleteAdvertisingBanner,
        handleModalFormOpen,
        handleShowEntriesTable,
        handlePaginationTable,
        refetchAdvertisingsBannerData,
        handleDeleteAdvertisingBanner,
        handleSearch,

        payloadBannerEdit,
        setPayloadBannerEdit,
        urlBannerThumbnail,
        setUrlBannerThumbnail,
        urlBannerThumbnailEdit,
        setUrlBannerThumbnailEdit,
        isView,
        setIsView,

        indexTabActive,
        setIndexTabActive,
        isPublishBannerAdsOpen,
        setIsPublishBannerAdsOpen,
        handlePublishBannerAdsOpen,

        enDescription,
        idDescription,
        cnDescription,

        isUpdateAccessBanner,
        isGetAccessBanner,
        isDeleteAccessBanner,
        isViewAllAccess,

        isShowStatus,
        isSearch,
        isFileBanner,
        setIsFileBanner,

        urlBannerThumbnailWebsite,
        setUrlBannerThumbnailWebsite,
        urlBannerThumbnailWebsiteEdit,
        setUrlBannerThumbnailWebsiteEdit,

        urlBannerThumbnailWebsiteEn,
        setUrlBannerThumbnailWebsiteEn,
        urlBannerThumbnailWebsiteEnEdit,
        setUrlBannerThumbnailWebsiteEnEdit,
        urlBannerThumbnailEn,
        setUrlBannerThumbnailEn,
        urlBannerThumbnailEnEdit,
        setUrlBannerThumbnailEnEdit,

        urlBannerThumbnailWebsiteId,
        setUrlBannerThumbnailWebsiteId,
        urlBannerThumbnailWebsiteIdEdit,
        setUrlBannerThumbnailWebsiteIdEdit,
        urlBannerThumbnailId,
        setUrlBannerThumbnailId,
        urlBannerThumbnailIdEdit,
        setUrlBannerThumbnailIdEdit,

        urlBannerThumbnailWebsiteCn,
        setUrlBannerThumbnailWebsiteCn,
        urlBannerThumbnailWebsiteCnEdit,
        setUrlBannerThumbnailWebsiteCnEdit,
        urlBannerThumbnailCn,
        setUrlBannerThumbnailCn,
        urlBannerThumbnailCnEdit,
        setUrlBannerThumbnailCnEdit,

        setIsShowStatus,
        newAdvertisingBannerSearch,
        formKeywordSearch,
      }}
    >
      {children}
    </Provider>
  );
};

export default BannerAdsController;
