import React, { lazy, Suspense, useContext, useState } from "react";
// import Filter from "./components/Filter";
// import Header from "./components/Header";
// import ModalQnaTicketDetail from "./components/Modal";
// import TableQnaTicket from "./components/TableQnaTicket";
import QnaTicketController, { QnaTicketContext } from "./Controller";
import FormChatRoom from "./components/Form/FormChatRoom";
// import FilterTransactionTimeDialog from "./components/Dialog/FilterTransactionTimeDialog";
// import FilterAmountDialog from "./components/Dialog/FilterAmountDialog";

const QnaTicket = () => {
  const Filter = lazy(() => import("./components/Filter"));
  const Header = lazy(() => import("./components/Header"));
  const ModalQnaTicketDetail = lazy(() => import("./components/Modal"));
  const Topic = lazy(() => import("./components/FilterQna/Topic"));
  const TableQnaTicket = lazy(() => import("./components/TableQnaTicket"));
  const FilterTransactionTimeDialog = lazy(() =>
    import("./components/Dialog/FilterTransactionTimeDialog")
  );
  const FilterAmountDialog = lazy(() =>
    import("./components/Dialog/FilterAmountDialog")
  );

  const { isChatRoom } = useContext(QnaTicketContext);

  return (
    <div>
      <Suspense
        fallback={
          <div className="h-screen flex items-center">
            <div className="mx-auto">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          </div>
        }
      >
        {isChatRoom ? (
          <FormChatRoom />
        ) : (
          <div>
            <div className="flex gap-4">
              {/* <ButtonNew /> */}
              <Header />
            </div>
            {/* <div className="pt-4 pb-4">
              <div className="flex gap-4">
                <div className="w-full"></div>
              </div>
            </div> */}

            <div cla ssName="border border-gray-300 p-4 rounded-xl">
              <div>
                <div className="overflow-auto h-screen">
                  <div className="py-4">
                    {/* <Filter /> */}
                    <Topic />
                  </div>
                  <TableQnaTicket />
                </div>
              </div>
            </div>
            <ModalQnaTicketDetail />
            <FilterTransactionTimeDialog />
            <FilterAmountDialog />
          </div>
        )}
      </Suspense>
    </div>
  );
};

export default () => (
  <QnaTicketController>
    <QnaTicket />
  </QnaTicketController>
);
