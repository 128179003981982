import { Button, Switch, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import {
  getTransactionById,
  getTransactionList,
  getTransactionListExport,
  getTransactionListSearch,
} from "./api/TransactionApi";
// import {
//   getBrand,
//   getCategoryPpob,
//   getPricelist,
//   getPricelistId,
//   getPricelistSearch,
//   getSyncPriceList,
//   getTypes,
//   updateSellPrice,
// } from "./api/ppobApi";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { getPoints, getUserApp } from "./api/UserAppApi";
import { getProfile } from "../../User/UserHandheld/api/ProfileApi";
import { setOverwriteSaldo } from "./api/PointApi";

export const TransactionPrabayarContext = createContext();
const { Provider } = TransactionPrabayarContext;

const TransactionPrabayarController = ({ children }) => {
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  let language = t("LANGUAGE");

  const toast = useToast();

  const [newTransactionLists, setNewTransactionLists] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isModalFormUpdateSellPriceOpen, setIsModalFormUpdateSellPriceOpen] =
    useState(false);
  const [transactionId, setTransactionId] = useState();
  const [isVisibleUpdateSellPriceInfo, setIsVisibleUpdateSellPriceInfo] =
    useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalOverwriteFormOpen, setIsModalOverwriteFormOpen] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isVisibleDownloadTransaction, setIsVisibleDownloadTransaction] =
    useState(false);
  const [
    isVisibleDownloadInfoTransaction,
    setIsVisibleDownloadInfoTransaction,
  ] = useState(false);
  const [isVisibleFilterRange, setIsVisibleFilterRange] = useState(false);
  const [isVisibleFilterRangeInfo, setIsVisibleFilterRangeInfo] =
    useState(false);

  const [newTransactionListSearch, setNewTransactionListSearch] = useState();

  const [pres, setPres] = useState();
  const [userAppGuid, setUserAppGuid] = useState();
  const [isModalFormDetailOpen, setIsModalFormDetailOpen] = useState(false);
  const [listPoints, setListPoints] = useState();

  const { mutate: mutateGetUserApp } = useMutation(getUserApp);
  const {
    data: pointsData,
    isLoading: isLoadingGetListPoint,
    mutate: mutateGetListPoint,
  } = useMutation(getPoints);
  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isFetching: isFetchingProfileData,
    isError: errorProfileData,
  } = useQuery(["profile", token, language], () =>
    getProfile({ token, language })
  );

  const {
    data: transactionListData,
    isLoading: isLoadingtransactionListData,
    refetch: refecthTransactionListData,
  } = useQuery(["transactionlist", token, language], () =>
    getTransactionList({ token, language })
  );

  const {
    isLoading: isLoadingOverwriteSaldo,
    mutate: mutateSetOverwriteSaldo,
  } = useMutation(setOverwriteSaldo);

  // const {
  //   data: ppobBrandData,
  //   isLoading: isLoadingppobBrandData,
  //   refetch: brandData,
  // } = useQuery(["brand", token], () => getBrand({ token }));

  // const {
  //   data: ppobTypesData,
  //   isLoading: isLoadingppobTypesData,
  //   refetch: typesData,
  // } = useQuery(["types", token], () => getTypes({ token }));

  // const {
  //   data: PpobData,
  //   isLoading: isLoadingPpobData,
  //   refetch: refetchPpobData,
  // } = useQuery(["ppob", token], () => getPricelist({ token }));

  const {
    isLoading: isLoadingSetTransactionListDataSearch,
    mutate: mutateSetTransactionListDataSearch,
  } = useMutation(getTransactionListSearch);

  const {
    isLoading: isLoadingGetTransactionList,
    mutate: mutateGetTransactionList,
    refetch: refetchGetTransactionList,
  } = useMutation(getTransactionList);

  const {
    isLoading: isLoadingGetTransactionListExport,
    mutate: mutateGetTransactionListExport,
    refetch: refetchGetTransactionListExport,
  } = useMutation(getTransactionListExport);

  // const { isLoading: isLoadingUpdateSellPrice, mutate: mutateUpdateSellPrice } =
  //   useMutation(updateSellPrice);

  const { mutate: mutateGetTransactionId } = useMutation(getTransactionById);

  // const {
  //   isLoading: isLoadingGetSyncPriceList,
  //   mutate: mutateGetSyncPriceList,
  // } = useMutation(getSyncPriceList);

  let newTransactionListsData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessPrabayarTransaction, setIsGetAccessPrabayarTransaction] =
    useState();
  const [
    isDeleteAccessPrabayarTransaction,
    setIsDeleteAccessPrabayarTransaction,
  ] = useState();
  const [
    isUpdateAccessPrabayarTransaction,
    setIsUpdateAccessPrabayarTransaction,
  ] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Prabayar Transaction
          const getAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessPrabayarTransaction(
            getAccessPrabayarTransaction?.length
          );

          const deleteAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessPrabayarTransaction(
            deleteAccessPrabayarTransaction?.length
          );

          const updateAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessPrabayarTransaction(
            updateAccessPrabayarTransaction?.length
          );
        },
      }
    );
  }, [roleIdUser]);

  // // -------------------------------
  // // Form Modal Update Sell Price
  // // -------------------------------
  // const formUpdateSellPrice = useForm();
  // const resetformUpdateSellPrice = (newForm) => {
  //   if (newForm) {
  //     formUpdateSellPrice.reset({
  //       slug_name: newForm?.slug_name,
  //       sell_price: newForm?.sell_price,
  //     });
  //   }
  // };

  // -------------------------------
  // Form Publish
  // -------------------------------
  const formPublish = useForm();
  const resetFormPublish = (newForm) => {
    const dateFrom = new Date();
    const dateTo = new Date();
    const publishFrom = dateFrom.getDate() + 1;
    const publishTo = dateTo.getDate() + 2;
    dateFrom.setDate(publishFrom);
    dateTo.setDate(publishTo);
    const defaultDatePublishFrom = moment(dateFrom).format("YYYY-MM-DDTHH:mm");
    const defaultDatePublishTo = moment(dateTo).format("YYYY-MM-DDTHH:mm");

    const publishFromEdit = moment(newForm?.publish_from).format(
      "YYYY-MM-DDTHH:mm"
    );
    const publishToEdit = moment(newForm?.publish_to).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formPublish.reset({
        publish_from: newForm?.publish_from,
        publish_to: newForm?.publish_to,
        status: 1,
      });
    }
  };

  // -------------------------------
  // Form Modal Overwrote
  // -------------------------------
  const formModalOverwrite = useForm();
  const resetformModalOverwrite = (newForm) => {
    if (newForm) {
      formModalOverwrite.reset({
        transaction_type: newForm?.transaction_type,
        amount: newForm?.amount,
      });
    }
  };

  const handleModalOverwriteFormOpen = (isOpen) => {
    if (isOpen === false) {
      formModalOverwrite.reset({
        transaction_type: "",
        amount: null,
      });
    }
    setIsModalOverwriteFormOpen(isOpen);
  };

  const handleSubmitOverwriteSaldo = (payload) => {
    const newPayload = {
      user_app_guid: userAppGuid,
      transaction_type: payload?.transaction_type, // debet , credit
      amount: payload?.amount.toString(),
      description: payload?.description,
    };

    mutateSetOverwriteSaldo(
      { token, language, payload: newPayload },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "error",
              duration: 1500,
              isClosable: true,
            });
          } else {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "success",
              duration: 1500,
              isClosable: true,
            });
            setIsModalOverwriteFormOpen(false);
            mutateGetListPoint(
              { token, language, guid: userAppGuid },
              {
                onSuccess: (data) => {
                  let newListPoints = [];

                  data?.data?.map((value) => {
                    newListPoints.push({
                      transaction_type: value?.transaction_type,
                      amount: new Intl.NumberFormat().format(value?.amount),
                      message: value?.message,
                      status:
                        value?.status === "approved" ||
                        value?.status === "success" ? (
                          <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                            {value?.status}
                          </div>
                        ) : value?.status === "payment reject" ||
                          value?.status === "rejected" ? (
                          <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                            {value?.status}
                          </div>
                        ) : value?.status === "paid" ||
                          value?.status === "expired" ? (
                          <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                            {value?.status}
                          </div>
                        ) : (
                          <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                            {value?.status}
                          </div>
                        ),
                    });
                  });
                  setListPoints(newListPoints);
                  formModalOverwrite.reset({
                    transaction_type: "",
                    amount: null,
                  });
                },
              }
            );
          }
        },
      }
    );
  };

  const handleDialogDownloadTransaction = (isOpen, guid) => {
    // console.log(isPublish)
    setIsVisibleDownloadTransaction(isOpen);
    // setAdvertisingRowId(guid);
    // setIsPublish(isPublish);
    setIsVisibleDownloadInfoTransaction(false);

    // mutateGetAdvertisingRow(
    //   { guid, token, language },
    //   {
    //     onSuccess: (data) => {
    resetFormPublish({});
    //       setPublishPayloadClassifiedAdsEdit(data?.data);
    //     },
    //   }
    // );
  };

  const handleDialogFilterRange = (isOpen, guid) => {
    // console.log(isPublish)
    setIsVisibleFilterRange(isOpen);
    // setAdvertisingRowId(guid);
    // setIsPublish(isPublish);
    setIsVisibleFilterRangeInfo(false);

    // mutateGetAdvertisingRow(
    //   { guid, token, language },
    //   {
    //     onSuccess: (data) => {
    // resetFormPublish({});
    //       setPublishPayloadClassifiedAdsEdit(data?.data);
    //     },
    //   }
    // );
  };

  const handleDownloadTransaction = (payload) => {
    // const dateFrom = new Date();
    // const dateTo = new Date();
    // const publishFrom = dateFrom.getDate() + 1;
    // const publishTo = dateTo.getDate() + 2;
    // dateFrom.setDate(publishFrom);
    // dateTo.setDate(publishTo);
    const defaultDatePublishFrom = moment(payload?.publish_from?.[0]).utc().format();
    const defaultDatePublishTo = moment(payload?.publish_from?.[1]).utc().format();

    const payloadPublish = {
      publish_from: defaultDatePublishFrom,
      publish_to: defaultDatePublishTo,
    };

    // const guid = advertisingRowId;

    // console.log(payloadPublish);

    mutateGetTransactionListExport(
      { token, language, payloadPublish },
      {
        onSuccess: (data) => {
          // refetchAdvertisingRowData();
          // setIsVisiblePublishAdvertisingClassified(false);
          // setIsVisiblePublishInfoAdvertisingClassified(true);
          // console.log(data?.data);

          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";

          const customHeadings = data?.data?.map((item, key) => ({
            // "Transaksi ID": item?.transaction_id,
            // Tanggal: moment(item?.created_at).format("MMMM Do YYYY, h:mm:ss a"),
            // "Nama Produk": item?.product_name,
            // "Buyer Transaksi ID": item?.buyer_sku_code,
            // "Kode Buyer": item?.buyer_sku_code,
            // "No Pelanggan": item?.user_phone,
            // "Nama Pelanggan": item?.user_full_name,
            // "Tagihan Pelanggan": new Intl.NumberFormat("id-ID", {
            //   style: "currency",
            //   currency: "IDR",
            // }).format(item?.sell_price),
            // Harga: new Intl.NumberFormat("id-ID", {
            //   style: "currency",
            //   currency: "IDR",
            // }).format(item?.price),
            // SN: "serial_number",
            // "Seller Name": item.seller_name,
            // "Seller Transaksi ID": item.biller_ref_id,
            // "Kode Seller": item.buyer_sku_code,
            // Info: item.payment_status,
            // Status: item.status,

            No: key + 1,
            "Transaksi ID": item?.transaction_id,
            Tanggal: moment(item?.created_at)
              .utc()
              .add(7, "hours")
              .format("DD/MM/YYYY, h:mm:ss a"),
            "No Pelanggan": item?.user_phone,
            "Nama Pelanggan": item?.user_full_name,
            "Target": item?.destination_number,
            "Buyer Transaksi ID": item?.buyer_sku_code,
            "Kode Buyer": item?.buyer_sku_code,
            "Nama Produk": item?.product_name,
            "Harga Asli": item?.price,
            // new Intl.NumberFormat("id-ID", {
            //   style: "currency",
            //   currency: "IDR",
            // }).format(item?.price),
            "Harga Jual": parseInt(item?.sell_price),
            // new Intl.NumberFormat("id-ID", {
            //   style: "currency",
            //   currency: "IDR",
            // }).format(item?.sell_price),
            "Uniq Code": parseInt(item?.unique_code),
            Keuntungan:
              parseInt(item?.sell_price) -
              item?.price +
              parseInt(item?.unique_code),
            // new Intl.NumberFormat("id-ID", {
            //   style: "currency",
            //   currency: "IDR",
            // }).format(
            //   parseInt(item?.sell_price) -
            //     parseInt(item?.price) +
            //     parseInt(item?.unique_code)
            // ),
            Info: item.payment_status,
            Status: item.status,
            "Seller Name": item.seller_name,
            "Seller Transaksi": item.biller_ref_id,
          }));

          const ws = XLSX.utils.json_to_sheet(customHeadings);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const datas = new Blob([excelBuffer], { type: fileType });

          FileSaver.saveAs(datas, "Transaction" + fileExtension);
        },
      }
    );
  };

  const handleFilterRange = (payload) => {
    const defaultDatePublishFrom = moment(payload?.publish_from).format();
    const defaultDatePublishTo = moment(payload?.publish_to).format();

    const payloadPublish = {
      publish_from: defaultDatePublishFrom,
      publish_to: defaultDatePublishTo,
    };

    console.log(payloadPublish);
    // mutateGetTransactionListExport(
    //   { token, language, payloadPublish },
    //   {
    //     onSuccess: (data) => {
    //       console.log(data);
    //     },
    //   }
    // );
  };

  // const handleModalFormOpen = (isOpen, guid) => {
  //   setIsModalFormOpen(isOpen);
  //   setIsEdit(isEdit);
  //   setTransactionId(guid);
  //   mutateGetPricelistId(
  //     { guid, token },
  //     {
  //       onSuccess: (data) => {
  //         console.log(data);
  //         resetformUpdateSellPrice(data?.data);
  //       },
  //     }
  //   );
  //   // isCreate && resetformModal({});
  // };

  // const handleDialogUpdateSellPrice = (isOpen, guid) => {
  //   setIsModalFormOpen(isOpen);
  //   setPriceId(guid);
  //   setIsVisibleUpdateSellPriceInfo(false);
  // };

  // const handleUpdateSellPrice = (payload) => {
  //   const newPayload = {
  //     slug_name: payload?.slug_name,
  //     sell_price: payload?.sell_price,
  //   };
  //   // console.log(newPayload);
  //   mutateUpdateSellPrice(
  //     { newPayload, token },
  //     {
  //       onSuccess: (data) => {
  //         if (data?.message?.response_code !== "00") {
  //           toast({
  //             description: "Update sell price belum lengkap",
  //             position: "top-right",
  //             status: "warning",
  //             duration: 2000,
  //             isClosable: true,
  //           });
  //         } else {
  //           refetchPpobData();
  //           setIsModalFormOpen(false);
  //           setIsVisibleUpdateSellPriceInfo(true);
  //         }
  //       },
  //     }
  //   );
  // };

  // const handleSyncPriceList = () => {
  //   mutateGetSyncPriceList(
  //     { token },
  //     {
  //       onSuccess: (data) => {
  //         if (data?.message?.response_code !== "00") {
  //           toast({
  //             description: "Sync Price Gagal",
  //             position: "top-right",
  //             status: "warning",
  //             duration: 2000,
  //             isClosable: true,
  //           });
  //         } else {
  //           refetchPpobData();
  //           toast({
  //             description: data?.message?.response_message,
  //             position: "top-right",
  //             status: "success",
  //             duration: 2000,
  //             isClosable: true,
  //           });
  //         }
  //       },
  //     }
  //   );
  // };

  // -------------------------------
  // Form Modal Transaction
  // -------------------------------
  const formTransactionDetail = useForm();
  const resetformTransactionDetail = (newForm) => {
    if (newForm) {
      formTransactionDetail.reset({
        transaction_id: newForm?.transaction_id,
        user_guid: newForm?.user_guid,
        user_phone: newForm?.user_phone,
        user_email: newForm?.user_email,
        user_full_name: newForm?.user_full_name,
        category: newForm?.category,
        brand: newForm?.brand,
        type: newForm?.type,
        seller_name: newForm?.seller_name,
        buyer_sku_code: newForm?.buyer_sku_code,
        slug_name: newForm?.slug_name,
        product_name: newForm?.product_name,
        description: newForm?.description,
        admin: newForm?.admin,
        commission: newForm?.commission,
        price: newForm?.price,
        sell_price: newForm?.sell_price,
        internal_fee: newForm?.internal_fee,
        amount: newForm?.amount,
        biller_ref_id: newForm?.biller_ref_id,
        biller_rc: newForm?.biller_rc,
        biller_status: newForm?.biller_status,
        biller_data: newForm?.biller_data,

        payment_status: newForm?.payment_status,
        status: newForm?.status,
        is_inquiry: newForm?.is_inquiry,
        inquiry_at: newForm?.inquiry_at,
        is_payment: newForm?.is_payment,
        payment_at: newForm?.payment_at,
        payment_method_id: newForm?.payment_method_id,
        payment_method_data: newForm?.payment_method_data,
        payment_channel: newForm?.payment_channel,
        payment_channel_data: newForm?.payment_channel_data,
        created_at: newForm?.created_at,
        confirm_payment_at: newForm?.confirm_payment_at,
        approved_at: newForm?.approved_at,
        rejected_at: newForm?.rejected_at,
        updated_at: newForm?.updated_at,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
      });
    }
  };

  const handleDialogTransaction = (isOpen, guid) => {
    setIsModalFormOpen(isOpen);
    setTransactionId(guid);
    // setIsVisibleTopUpBalanceInfo(false);
    // setIsShowType(true);

    mutateGetTransactionId(
      { guid, token },
      {
        onSuccess: (data) => {
          // resetformTransaction(data?.data)
          resetformTransactionDetail(data.data);
        },
      }
    );
  };

  // -------------------------------
  // Form Detail Modal
  // -------------------------------
  const formDetailModal = useForm();
  const resetformDetailModal = (newForm) => {
    if (newForm) {
      formDetailModal.reset({
        username: newForm?.username,
        first_name: newForm?.first_name,
        last_name: newForm?.last_name,
        phone: newForm?.phone,
        email: newForm?.email,
        region: newForm?.region,
        address: newForm?.address,
        balance: newForm?.point,
        total_qty_ppob: newForm?.total_qty_ppob,
        total_amount_ppob: newForm?.total_amount_ppob,
      });
    }
  };

  const handleModalFormDetailOpen = (isOpen, isEdit, guid) => {
    setUserAppGuid(guid);
    setIsModalFormDetailOpen(isOpen);
    // setIsEdit(isEdit);
    setIsSearch(false);

    mutateGetUserApp(
      { guid, token, language },
      {
        onSuccess: (data) => {
          isEdit && resetformDetailModal(data?.data);
        },
      }
    );

    mutateGetListPoint(
      { token, language, guid },
      {
        onSuccess: (data) => {
          let newListPoints = [];

          data?.data?.map((value) => {
            newListPoints.push({
              transaction_type: value?.transaction_type,
              amount: new Intl.NumberFormat().format(value?.amount),
              message: value?.message,
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
            });
          });
          setListPoints(newListPoints);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries Table Point
  // -------------------------------

  const handleShowEntriesTablePoint = (limit) => {
    setIsSearch(false);
    mutateGetUserApp(
      { guid: userAppGuid, token, language },
      {
        onSuccess: (data) => {
          isEdit && resetformDetailModal(data?.data);
        },
      }
    );

    mutateGetListPoint(
      { token, language, guid: userAppGuid, limit },
      {
        onSuccess: (data) => {
          let newListPoints = [];

          data?.data?.map((value) => {
            newListPoints.push({
              transaction_type: value?.transaction_type,
              amount: new Intl.NumberFormat().format(value?.amount),
              message: value?.message,
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
            });
          });
          setListPoints(newListPoints);
        },
      }
    );
  };

  // -------------------------------
  // Pagination Table Point
  // -------------------------------

  const handlePaginationTablePoint = (page, limit) => {
    setIsSearch(false);
    mutateGetUserApp(
      { guid: userAppGuid, token, language },
      {
        onSuccess: (data) => {
          isEdit && resetformDetailModal(data?.data);
        },
      }
    );

    mutateGetListPoint(
      { token, language, guid: userAppGuid, page, limit },
      {
        onSuccess: (data) => {
          let newListPoints = [];

          data?.data?.map((value) => {
            newListPoints.push({
              transaction_type: value?.transaction_type,
              amount: new Intl.NumberFormat().format(value?.amount),
              message: value?.message,
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
            });
          });
          setListPoints(newListPoints);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    let isProduct =
      keyword?.keyword_filter?.value === "1" ||
      (keyword?.keyword_filter === "1" && true);
    let isPaymentStatus =
      keyword?.keyword_filter?.value === "2" ||
      (keyword?.keyword_filter === "2" && true);
    let isTransactionID =
      keyword?.keyword_filter?.value === "3" ||
      (keyword?.keyword_filter === "3" && true);
    let isUsername =
      keyword?.keyword_filter?.value === "4" ||
      (keyword?.keyword_filter === "4" && true);
    let isUserEmail =
      keyword?.keyword_filter?.value === "5" ||
      (keyword?.keyword_filter === "5" && true);
    let isTarget =
      keyword?.keyword_filter?.value === "6" ||
      (keyword?.keyword_filter === "6" && true);

    let isRange = formPublish?.getValues("publish_from")?.[1] ? true : false;
    let searchKeyword = !keyword?.value?.keyword_search
      ? ""
      : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-ppob", searchKeyword);
    } else {
      localStorage.removeItem("search-ppob");
    }

    const defaultDatePublishFrom = moment(
      formPublish?.getValues("publish_from")?.[0]
    )
      .utc()
      .format();
    const defaultDatePublishTo = moment(formPublish?.getValues("publish_from")?.[1])
      .utc()
      .format();

    setIsSearch(true);

    let payload = {
      isProduct: isProduct,
      isPaymentStatus: isPaymentStatus,
      isTransactionID: isTransactionID,
      isUsername: isUsername,
      isUserEmail: isUserEmail,
      isTarget: isTarget,
      isRange: isRange,
      search: searchKeyword,
      publishFrom:
        defaultDatePublishFrom === "Invalid date"
          ? null
          : defaultDatePublishFrom,
      publishTo:
        defaultDatePublishTo === "Invalid date" ? null : defaultDatePublishTo,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(100);
    mutateSetTransactionListDataSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setNewTransactionListSearch(data);
            newTransactionListsData.push({
              use_point:
                value?.use_point === true ? (
                  <div className="flex items-center justify-center">
                    <div className="w-10 bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] h-[31px] p-2 rounded-[10px]">
                      {t("LABEL.YES")}
                    </div>
                  </div>
                ) : value?.use_point === false ? (
                  <div className="flex items-center justify-center">
                    {/* <div className="w-10 bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] h-[31px] p-2 rounded-[10px]"> */}
                    {/* {t("LABEL.NO")} */}
                    <img
                      src={value?.payment_channel_data?.bank_logo_url}
                      alt=""
                      width={50}
                    />
                    {/* </div> */}
                  </div>
                ) : (
                  ""
                ),
              transaction_id: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() =>
                    handleDialogTransaction(true, value?.transaction_id)
                  }
                >
                  {value?.transaction_id}
                </div>
              ),
              created_at: moment(value?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY, HH:mm:ss"),
              product_name: value?.product_name,
              buyer_sku_code: value?.buyer_sku_code,
              buyer_sku_code: value?.buyer_sku_code,
              user_phone: value?.user_phone,
              username: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() =>
                    handleModalFormDetailOpen(true, true, value?.user_guid)
                  }
                >
                  {value?.username}
                </div>
              ),
              sell_price: (
                <CurrencyFormat
                  value={value?.sell_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              target: value?.destination_number,
              price: (
                <CurrencyFormat
                  value={value?.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              serial_number: "serial_number",
              seller_name: value?.seller_name,
              biller_ref_id: value?.biller_ref_id,
              buyer_sku_code: value?.buyer_sku_code,
              payment_status:
                value?.payment_status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ) : value?.payment_status === "failed" ||
                  value?.payment_status === "rejected" ||
                  value?.payment_status === "reject" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ) : value?.payment_status === "pending" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ),
              status:
                value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "failed" ||
                  value?.status === "rejected" ||
                  value?.status === "reject" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "pending" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              action: (
                <div className="flex gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleDialogTransaction(true, value?.transaction_id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewTransactionLists(newTransactionListsData);
          handleDialogFilterRange(false);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetTransactionList(
      { limit, token },
      {
        onSuccess: (data) => {
          newTransactionListsData = [];

          data?.data?.map((value) => {
            newTransactionListsData.push({
              use_point:
                value?.use_point === true ? (
                  <div className="flex items-center justify-center">
                    <div className="w-10 bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] h-[31px] p-2 rounded-[10px]">
                      {t("LABEL.YES")}
                    </div>
                  </div>
                ) : value?.use_point === false ? (
                  <div className="flex items-center justify-center">
                    {/* <div className="w-10 bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] h-[31px] p-2 rounded-[10px]"> */}
                    {/* {t("LABEL.NO")} */}
                    <img
                      src={value?.payment_channel_data?.bank_logo_url}
                      alt=""
                      width={50}
                    />
                    {/* </div> */}
                  </div>
                ) : (
                  ""
                ),
              transaction_id: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() =>
                    handleDialogTransaction(true, value?.transaction_id)
                  }
                >
                  {value?.transaction_id}
                </div>
              ),
              created_at: moment(value?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY, HH:mm:ss"),
              product_name: value?.product_name,
              username: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() =>
                    handleModalFormDetailOpen(true, true, value?.user_guid)
                  }
                >
                  {value?.username}
                </div>
              ),
              target: value?.destination_number,
              price: (
                <CurrencyFormat
                  value={value?.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              sell_price: (
                <CurrencyFormat
                  value={value?.sell_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              unique_code: value?.unique_code,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              payment_status:
                value?.payment_status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ) : value?.payment_status === "failed" ||
                  value?.payment_status === "rejected" ||
                  value?.payment_status === "reject" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ) : value?.payment_status === "pending" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ),
              status:
                value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "failed" ||
                  value?.status === "rejected" ||
                  value?.status === "reject" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "pending" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleDialogTransaction(true, value?.transaction_id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewTransactionLists(newTransactionListsData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);

    mutateGetTransactionList(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newTransactionListsData = [];

          data?.data?.map((value) => {
            newTransactionListsData.push({
              use_point:
                value?.use_point === true ? (
                  <div className="flex items-center justify-center">
                    <div className="w-10 bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] h-[31px] p-2 rounded-[10px]">
                      {t("LABEL.YES")}
                    </div>
                  </div>
                ) : value?.use_point === false ? (
                  <div className="flex items-center justify-center">
                    {/* <div className="w-10 bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] h-[31px] p-2 rounded-[10px]"> */}
                    {/* {t("LABEL.NO")} */}
                    <img
                      src={value?.payment_channel_data?.bank_logo_url}
                      alt=""
                      width={50}
                    />
                    {/* </div> */}
                  </div>
                ) : (
                  ""
                ),
              transaction_id: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() =>
                    handleDialogTransaction(true, value?.transaction_id)
                  }
                >
                  {value?.transaction_id}
                </div>
              ),
              created_at: moment(value?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YYYY, HH:mm:ss"),
              product_name: value?.product_name,
              username: (
                <div
                  className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
                  onClick={() =>
                    handleModalFormDetailOpen(true, true, value?.user_guid)
                  }
                >
                  {value?.username}
                </div>
              ),
              target: value?.destination_number,
              price: (
                <CurrencyFormat
                  value={value?.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              sell_price: (
                <CurrencyFormat
                  value={value?.sell_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              unique_code: value?.unique_code,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              payment_status:
                value?.payment_status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ) : value?.payment_status === "failed" ||
                  value?.payment_status === "rejected" ||
                  value?.payment_status === "reject" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ) : value?.payment_status === "pending" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.payment_status}
                  </div>
                ),
              status:
                value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "failed" ||
                  value?.status === "rejected" ||
                  value?.status === "reject" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "pending" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleDialogTransaction(true, value?.transaction_id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewTransactionLists(newTransactionListsData);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem("search-ppob");
    newTransactionListsData = [];
    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    transactionListData?.data?.map((value) => {
      newTransactionListsData.push({
        use_point:
          value?.use_point === true ? (
            <div className="flex items-center justify-center">
              <div className="w-10 bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] h-[31px] p-2 rounded-[10px]">
                {t("LABEL.YES")}
              </div>
            </div>
          ) : value?.use_point === false ? (
            <div className="flex items-center justify-center">
              {/* <div className="w-10 bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] h-[31px] p-2 rounded-[10px]"> */}
              {/* {t("LABEL.NO")} */}
              <img
                src={value?.payment_channel_data?.bank_logo_url}
                alt=""
                width={50}
              />
              {/* </div> */}
            </div>
          ) : (
            ""
          ),
        transaction_id: (
          <div
            className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
            onClick={() => handleDialogTransaction(true, value?.transaction_id)}
          >
            {value?.transaction_id}
          </div>
        ),
        created_at: moment(value?.created_at)
          .utc()
          .add(7, "hours")
          .format("DD/MM/YYYY, HH:mm:ss"),
        product_name: value?.product_name,
        username: (
          <div
            className="text-blue-600 bg-slate-300 p-1 rounded-md hover:cursor-pointer hover:text-blue-800"
            onClick={() =>
              handleModalFormDetailOpen(true, true, value?.user_guid)
            }
          >
            {value?.username}
          </div>
        ),
        target: value?.destination_number,
        price: (
          <CurrencyFormat
            value={value?.price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        sell_price: (
          <CurrencyFormat
            value={value?.sell_price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        unique_code: value?.unique_code,
        amount: (
          <CurrencyFormat
            value={value?.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        payment_status:
          value?.payment_status === "approved" ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.payment_status}
            </div>
          ) : value?.payment_status === "failed" ||
            value?.payment_status === "rejected" ||
            value?.payment_status === "reject" ? (
            <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.payment_status}
            </div>
          ) : value?.payment_status === "pending" ? (
            <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.payment_status}
            </div>
          ) : (
            <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.payment_status}
            </div>
          ),
        status:
          value?.status === "success" ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "failed" ||
            value?.status === "rejected" ||
            value?.status === "reject" ? (
            <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "pending" ? (
            <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : (
            <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ),
        action: (
          <div className="flex gap-2">
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleDialogTransaction(true, value?.transaction_id)
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.VIEW")}
              </div>
            </Button>
          </div>
        ),
      });
    });
    setNewTransactionLists(newTransactionListsData);
  }, [
    transactionListData,
    token,
    isGetAccessPrabayarTransaction,
    isDeleteAccessPrabayarTransaction,
    isUpdateAccessPrabayarTransaction,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formTransactionDetail,

        transactionListData,
        isLoadingtransactionListData,
        refecthTransactionListData,
        newTransactionListsData,
        newTransactionLists,

        // ppobCategoryData,
        // isLoadingppobCategoryData,
        // ppobBrandData,
        // isLoadingppobBrandData,
        // ppobTypesData,
        // isLoadingppobTypesData,
        // PpobData,
        // newTransactionLists,
        // isLoadingPpobData,
        isLoadingSetTransactionListDataSearch,
        isSearch,
        setIsSearch,
        handleSearch,
        isLoadingGetTransactionList,
        handleShowEntriesTable,
        handlePaginationTable,
        // handleDialogUpdateSellPrice,
        // handleUpdateSellPrice,
        // isLoadingUpdateSellPrice,
        // formUpdateSellPrice,
        // resetformUpdateSellPrice,
        // isVisibleUpdateSellPriceInfo,
        // // isModalFormUpdateSellPriceOpen,
        // priceId,
        isModalFormOpen,
        // isEdit,
        // handleModalFormOpen,
        handleDialogTransaction,
        // handleSyncPriceList,
        // isLoadingGetSyncPriceList,
        isGetAccessPrabayarTransaction,
        isDeleteAccessPrabayarTransaction,
        isUpdateAccessPrabayarTransaction,
        isViewAllAccess,
        isVisibleDownloadTransaction,
        isVisibleDownloadInfoTransaction,
        handleDialogDownloadTransaction,
        handleDownloadTransaction,
        formPublish,
        isLoadingGetTransactionListExport,
        handleFilterRange,
        handleDialogFilterRange,
        isVisibleFilterRange,
        isVisibleFilterRangeInfo,
        // exportFile,
        formKeywordSearch,
        newTransactionListSearch,

        listPoints,
        setListPoints,
        isLoadingGetListPoint,
        pointsData,
        profileData,
        isModalFormDetailOpen,
        formDetailModal,
        handleModalFormDetailOpen,
        handlePaginationTablePoint,
        handleShowEntriesTablePoint,

        isModalOverwriteFormOpen,
        setIsModalOverwriteFormOpen,
        handleModalOverwriteFormOpen,
        resetformModalOverwrite,
        formModalOverwrite,
        isLoadingOverwriteSaldo,
        handleSubmitOverwriteSaldo,
      }}
    >
      {children}
    </Provider>
  );
};

export default TransactionPrabayarController;
