import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import TokenController from "../controllers/public/TokenController";
import AuthController from "../controllers/public/AuthController";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

const MainRoutes = () => {
  const routes = [...PublicRoutes, ...PrivateRoutes];
  const renderRoutes = useRoutes(routes);

  return (
    <TokenController>
      <AuthController>{renderRoutes}</AuthController>;
    </TokenController>
  );
};

export default () => (
  <BrowserRouter>
    <MainRoutes />
  </BrowserRouter>
);
