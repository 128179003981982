import { api } from "../../../../../utils/axios";

//Create Home Section
export const setHomeSection = (payload, token) =>
  api
    .post("/backoffice/home-section", payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.lang,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Get List Home Section
export const getHomeSectionList = ({ page, limit, token, lang }) =>
  api
    .post(
      "/backoffice/home-section/list",
      {
        filter: {
          set_title: false,
          title: "",
          set_description: false,
          description: "",
        },
        limit: limit || 10,
        page: page || 1,
        order: "order_number",
        sort: "ASC",
      },
      {
        headers: {
          language: lang,
          "nihao-token": token,
          "is-all-language": 1,
        },
      }
    )
    .then((res) => res?.data);

//Get Home Section
export const getHomeSection = ({ guid, token, lang }) =>
  api
    .get(`/backoffice/home-section/${guid}`, {
      headers: {
        language: lang,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Delete Home Section
export const deleteHomeSection = ({ guid, token, lang }) =>
  api
    .delete(`/backoffice/home-section/${guid}`, {
      headers: {
        language: lang,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

// Update Home Section
export const updateHomeSection = (payload, token) =>
  api
    .put(
      `/backoffice/home-section/${payload?.payloadPublish?.[0]?.id}`,
      payload?.payloadPublish?.[0],
      {
        headers: {
          language: payload?.lang,
          "nihao-token": payload?.token,
          "is-all-language": 0,
        },
      }
    )
    .then((res) => res?.data);

//Update Order Home Section
export const updateOrderHomeSection = (payload, token) =>
  api
    .put("/backoffice/home-section/order-number", payload?.payloadOrder, {
      headers: {
        language: payload?.lang,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Set Home Section Active
export const setHomeSectionActive = ({ guid, token }) =>
  api
    .put(
      `/backoffice/home-section/is-active/${guid}`,
      {},
      {
        headers: {
          language: "EN",
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Create Insert Ads Home Section
export const setInsertAdsHomeSection = ({
  selectedHomeSectionId,
  advertisingId,
  token,
  lang,
}) =>
  api
    .post(
      "/backoffice/home-section/advertising",
      {
        home_section_id: selectedHomeSectionId,
        advertising_id: advertisingId,
      },
      {
        headers: {
          language: lang,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Search Insert Ads Advertising List
export const getInsertAdsAdvertisingSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/advertising/list",
      {
        filter: {
          set_title: payload?.isTitle === true ? true : false,
          title: payload?.isTitle ? payload?.search : "",
        },
        limit: 1000,
        page: 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.lang,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get Search Home Section
export const getHomeSectionSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/home-section/list",
      {
        filter: {
          set_title: payload?.isTitle === true ? true : false,
          title: payload?.isTitle ? payload?.search : "",
          set_description: payload?.isDescription === true ? true : false,
          description: payload?.isDescription ? payload?.search : "",
        },
        limit: 1000,
        page: 1,
        order: "order_number",
        sort: "ASC",
      },
      {
        headers: {
          language: payload?.lang,
          "nihao-token": payload?.token,
          "is-all-language": 1,
        },
      }
    )
    .then((res) => res?.data);
