import React, { useContext, useEffect, useRef } from "react";
import { Button } from "@chakra-ui/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import UploadImageChat from "../../../../../../../components/Uploader/UploadImageChat";
import ProfileDummy from "../../../../../../../assets/img/profile.png";
import { QnaTicketContext } from "../../Controller";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BsFillTrashFill } from "react-icons/bs";
import { useQuery } from "react-query";
// import Filter from "../../Filter";

const FormChatRoom = () => {
  const {
    formChatDetail,
    formChatPublish,
    handleCreateChat,
    handleUploadImageUrl,
    isLoadingGetQnaTicket,
    setIsChatRoom,
    refecthQnaTicketData,
    imagesUrl,
    setimagesUrl,
    loadingUploadImage,
    handleChatRoomDetail,
    qnaTicketId,
    isPreviewOpen,
    setPreviewOpen,
    handlePreviewImageClick,
    handleClosePreviewImage,
    urlImageOpen
  } = useContext(QnaTicketContext);

  const chatContainerRef = useRef(null);

  const { t } = useTranslation();
  const {
    // register,
    // control,
    getValues,
    formState: { errors },
  } = formChatDetail;

  const {
    data: chatRoomMessage,
    isLoading: isLoadingChatRoomMessage,
    refetch: refecthChatRoomMessage,
  } = useQuery(
    ["chatroommessage"],
    async () => handleChatRoomDetail(true, qnaTicketId),
    {
      // Refetch the data every 1 second
      refetchInterval: 30000,
    }
  );

  const {
    register,
    control,
    // getValues,
    // formState: { errors },
  } = formChatPublish;

  const onSubmit = (payload) => {
    handleCreateChat(payload);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatContainerRef, isLoadingGetQnaTicket, imagesUrl]);

  return (
    <div>
      <div className="w-full grid grid-cols-2 pt-6 gap-6">
        <div className="flex justify-start">
          <span className="font-[700] text-[32px] leadind-[48px] text-[#504178]">
            {t("LABEL.QNA_TICKET")}
          </span>
        </div>
        <button
          onClick={() => {
            refecthQnaTicketData();
            setIsChatRoom(false);
          }}
          className="flex gap-2 items-center justify-end"
        >
          <div className="py-2 px-2 font-blod text-xl flex items-center gap-2 hover:bg-slate-200 hover:rounded-lg hover:px-2">
            <IoMdArrowRoundBack />
            {t("LABEL.BACK")}
          </div>
        </button>
      </div>
      <div className="rounded-lg relative shadow-lg w-full mt-2">
        {/* Chat Messages */}
        <div className="flex items-start justify-start font-poppins text-xl bg-gray-200 p-4 rounded-t-lg">
          <div className="font-poppins text-xl bg-gray-200 font-bold pr-2">
            {t("LABEL.PROBLEM_DETAIL")} :
          </div>
          <div className="font-poppins rounded-lg text-white text-base justify-start px-2 py-1 italic relative">
            <div className="px-2 rounded-lg">
              <span className="bg-[#504178] px-2 rounded-lg">{getValues("question")}</span>
            </div>
            <div className="pt-4 flex flex-wrap">
              {getValues("url_images")?.length !== 0 &&
                getValues("url_images")?.map((url, index) => (
                  <div
                    key={index} // Add a unique key for each element in the array
                    onClick={() => handlePreviewImageClick(url)}
                    className="cursor-pointer mr-4 mb-4"
                  >
                    <img src={url} alt="" width={150} height={150} />
                  </div>
                ))}
            </div>

            {/* Modal for Preview */}
            {isPreviewOpen && (
              <div className="fixed inset-0 flex items-center justify-center overflow-y-auto z-50">
                <div
                  className="fixed inset-0 bg-black opacity-50"
                  onClick={handleClosePreviewImage}
                ></div>
                <div className="bg-white p-4 max-w-2xl mx-auto rounded-md z-10 transform transition-transform duration-300">
                  <img
                    // src={getValues("url_images")}
                    src={urlImageOpen}
                    alt=""
                    className="w-full h-auto"
                  />
                  <button
                    onClick={handleClosePreviewImage}
                    className="mt-2 px-3 py-1 bg-[#504178] text-white rounded-md"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {
          // isLoadingGetQnaTicket ? (
          //   <div className="flex items-center justify-center p-2">
          //     Loading getting conversation
          //   </div>
          // ) :
          getValues("chat")?.length > 0 ? (
            <div
              className="chat-messages overflow-auto "
              style={{ maxHeight: "420px" }}
              ref={chatContainerRef}
            >
              <div className="p-5">
                {
                  // Sorting by created_at in descending order
                  getValues("chat")?.[0] &&
                    getValues("chat")
                      ?.sort(
                        (a, b) =>
                          new Date(a.created_at) - new Date(b.created_at)
                      )
                      ?.map((dataChat) =>
                        dataChat?.user?.guid !== null ? (
                          <div className="mb-2 pb-1">
                            <div className="flex items-start ">
                              <div className="flex-shrink-0 ">
                                <img
                                  src={
                                    dataChat?.user?.profile_picture_url
                                      ? dataChat?.user?.profile_picture_url
                                      : ProfileDummy
                                  }
                                  alt="User Avatar"
                                  className="w-8 h-8 rounded-full"
                                />
                              </div>
                              <div className="ml-3">
                                {(dataChat?.chat_text !== "" ||
                                  (dataChat?.image_url?.length > 0 &&
                                    (dataChat?.image_url?.[0] !== undefined ||
                                      dataChat?.image_url?.[0] !== ""))) && (
                                  <div className="text-gray-600">
                                    {dataChat?.user?.username}
                                  </div>
                                )}
                                {dataChat?.chat_text != "" && (
                                  <div className="bg-gray-200 text-gray-800 p-2 rounded-lg">
                                    <p>{dataChat?.chat_text}</p>
                                  </div>
                                )}
                                {dataChat?.image_url?.length > 0 &&
                                  (dataChat?.image_url?.[0] !== undefined ||
                                    dataChat?.image_url?.[0] !== "") &&
                                  dataChat?.image_url?.map(
                                    (url, index) =>
                                      url && (
                                        <img
                                          key={index}
                                          src={url}
                                          alt={`Image ${index + 1}`}
                                          className="w-40"
                                        />
                                      )
                                  )}
                                {(dataChat?.chat_text !== "" ||
                                  (dataChat?.image_url?.length > 0 &&
                                    (dataChat?.image_url?.[0] !== undefined ||
                                      dataChat?.image_url?.[0] !== ""))) && (
                                  <p className="text-xs text-gray-400">
                                    {moment(dataChat?.created_at)
                                      .utc()
                                      .add(7, "hours")
                                      .format("DD/MM/YYYY HH:mm:ss")}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : dataChat?.customer_service?.guid !== null ? (
                          <div className="mb-2 text-right pb-1">
                            <div className="flex justify-end">
                              <div className="mr-3">
                                {(dataChat?.chat_text !== "" ||
                                  (dataChat?.image_url?.length > 0 &&
                                    (dataChat?.image_url?.[0] !== undefined ||
                                      dataChat?.image_url?.[0] !== ""))) && (
                                  <div className="text-gray-600">
                                    {dataChat?.customer_service?.name}
                                  </div>
                                )}
                                {dataChat?.chat_text !== "" && (
                                  <div className="bg-blue-500 text-white p-2 rounded-lg h-auto">
                                    <p>{dataChat?.chat_text}</p>
                                  </div>
                                )}
                                {dataChat?.image_url?.length > 0 &&
                                  (dataChat?.image_url?.[0] !== undefined ||
                                    dataChat?.image_url?.[0] !== "") &&
                                  dataChat?.image_url?.map(
                                    (url, index) =>
                                      url && (
                                        <img
                                          key={index}
                                          src={url}
                                          alt={`Image ${index + 1}`}
                                          className="w-40"
                                        />
                                      )
                                  )}
                                {(dataChat?.chat_text !== "" ||
                                  (dataChat?.image_url?.length > 0 &&
                                    (dataChat?.image_url?.[0] !== undefined ||
                                      dataChat?.image_url?.[0] !== ""))) && (
                                  <p className="text-xs text-gray-400">
                                    {moment(dataChat?.created_at)
                                      .utc()
                                      .add(7, "hours")
                                      .format("DD/MM/YYYY HH:mm:ss")}
                                  </p>
                                )}
                              </div>
                              {(dataChat?.chat_text !== "" ||
                                (dataChat?.image_url?.length > 0 &&
                                  (dataChat?.image_url?.[0] !== undefined ||
                                    dataChat?.image_url?.[0] !== ""))) && (
                                <div className="flex-shrink-0">
                                  <img
                                    src={
                                      dataChat?.customer_service
                                        ?.profile_picture_url
                                        ? dataChat?.customer_service
                                            ?.profile_picture_url
                                        : ProfileDummy
                                    }
                                    alt="User Avatar"
                                    className="w-8 h-8 rounded-full"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )
                }
              </div>
            </div>
          ) : (
            <div>
              <div className="flex items-center justify-center p-6">
                Belum ada chat
              </div>
            </div>
          )
        }

        <div className="bg-gray-200 pl-4 pt-2 flex gap-4 items-center">
          {loadingUploadImage
            ? "Loading...."
            : imagesUrl.map((imageUrl, index) => (
                <img
                  key={index}
                  src={imageUrl}
                  alt={`Image-${index}`}
                  height={200}
                  width={150}
                  className="pb-2 relative "
                />
              ))}
          {imagesUrl?.length !== 0 && (
            <Button
              size="md"
              colorScheme="new"
              className="bg-red-500 hover:bg-red-800"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => setimagesUrl([])}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                <BsFillTrashFill />
              </div>
            </Button>
          )}
        </div>
        <div className="bg-gray-200 pl-4 pt-2 pb-2">
          <UploadImageChat
            {...register("image_url")}
            control={control}
            fileList={formChatPublish.getValues(`image_url`)}
            onChange={(files) => {
              handleUploadImageUrl(files);
            }}
          />
        </div>
        <form onSubmit={formChatPublish.handleSubmit(onSubmit)}>
          <div className="custom-dialog-footer">
            <div className="pl-4 pb-4 pr-4 bg-gray-200">
              <div className="flex gap-3">
                <input
                  type="text"
                  {...register("chat_text")}
                  control={control}
                  placeholder="Type your message..."
                  className="flex-1 px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-500 font-poppins"
                />
                <Button
                  colorScheme="new"
                  className="bg-[#504178] text-[#FFFFFF] hover:bg-[#402b79] font-poppins px-4 py-4 rounded-lg"
                  style={{ borderRadius: "100px" }}
                  type="submit"
                  isDisabled={formChatDetail.getValues("status") === "done" && true}
                >
                  {t("LABEL.SEND")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormChatRoom;
