import { api } from "../../../../../../utils/axios";

//Get Profile
export const getProfile = ({ token, language }) =>
  api
    .get("/backoffice/user-backoffice/profile", {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
