import React, { createContext, useContext, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../../controllers/public/AuthController";
import { TokenContext } from "../../../../controllers/public/TokenController";
import { getRole } from "../User/UserAdminRole/api/RoleApi";
import {
  getYuanConversion,
  updateYuanConversion,
} from "./api/YuanConversionApi";
import { getAmountBC22, updateAmountBC22 } from "./api/BC22Api";
import { getSummaryPoint } from "./api/SummaryPointApi";
import { getApkVersion, updateApkVersion } from "./api/APKVersionApi";
import {
  getAlipayCallbackStatus,
  updateAlipayCallbackStatus,
} from "./api/AlipayCallbackApi";
import { useToast } from "@chakra-ui/react";
import { getReferral, updateReferral } from "./api/ReferralApi";
import {
  getMaximumTopUp,
  getMinimumTopUp,
  updateMaximumTopUp,
  updateMinimumTopUp,
} from "./api/LimitPoint";

export const ConfigContext = createContext();
const { Provider } = ConfigContext;

const ConfigController = ({ children }) => {
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  let language = t("LANGUAGE");
  const toast = useToast();

  const [newTransactionLists, setNewTransactionLists] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleDownloadTransaction, setIsVisibleDownloadTransaction] =
    useState(false);
  const [
    isVisibleDownloadInfoTransaction,
    setIsVisibleDownloadInfoTransaction,
  ] = useState(false);

  const [isVisibleConfigAmountBC22, setIsVisibleConfigAmountBC22] =
    useState(false);
  const [isVisibleConfigAmountBC22Info, setIsVisibleConfigAmountBC22Info] =
    useState(false);

  const [isVisibleConfigApkVersioning, setIsVisibleConfigApkVersioning] =
    useState(false);
  const [
    isVisibleConfigApkVersioningInfo,
    setIsVisibleConfigApkVersioningInfo,
  ] = useState(false);
  const [isVisibleReferral, setIsVisibleReferral] = useState(false);
  const [isVisibleReferralInfo, setIsVisibleReferralInfo] = useState(false);

  const [isVisibleMinimumLimitPoint, setIsVisibleMinimumLimitPoint] =
    useState(false);
  const [isVisibleMinimumLimitPointInfo, setIsVisibleMinimumLimitPointInfo] =
    useState(false);

  const [isVisibleMaximumLimitPoint, setIsVisibleMaximumLimitPoint] =
    useState(false);
  const [isVisibleMaximumLimitPointInfo, setIsVisibleMaximumLimitPointInfo] =
    useState(false);

  const {
    data: yuanData,
    isLoading: isLoadingYuanData,
    refetch: refetchYuanData,
  } = useQuery(["yuandata", token, language], () =>
    getYuanConversion({ token, language })
  );

  const {
    data: summaryPointData,
    isLoading: isLoadingSummaryPoint,
    refetch: refetchSummaryPoint,
  } = useQuery(["summarypoint", token, language], () =>
    getSummaryPoint({ token, language })
  );

  const {
    isLoading: isLoadingYuanConversion,
    mutate: mutateYuanConversion,
    refetch: refetchYuanConversion,
  } = useMutation(updateYuanConversion);

  const {
    data: amountBc22Data,
    isLoading: isLoadingAmountBc22,
    refetch: refetchAmountBc22,
  } = useQuery(["amountbc22", token, language], () =>
    getAmountBC22({ token, language })
  );

  const {
    isLoading: isLoadingSetAmountBc22,
    mutate: mutateSetAmountBc22,
    refetch: refetchSetAmountBc22,
  } = useMutation(updateAmountBC22);

  const {
    data: apkVersionData,
    isLoading: isLoadingApkVersion,
    refetch: refetchApkVersion,
  } = useQuery(["apkversion", token, language], () =>
    getApkVersion({ token, language })
  );

  const {
    isLoading: isLoadingSetApkVersion,
    mutate: mutateSetApkVersion,
    refetch: refetchSetApkVersion,
  } = useMutation(updateApkVersion);

  const {
    data: alipayCallbackStatusData,
    isLoading: isLoadingCallbackStatusData,
    refetch: refetchCallbackStatusData,
  } = useQuery(["alipaycallback", token, language], () =>
    getAlipayCallbackStatus({ token, language })
  );

  const {
    isLoading: isLoadingSetAlipayCallbackStatus,
    mutate: mutateSetAlipayCallbackStatus,
    refetch: refetchSetAlipayCallbackStatus,
  } = useMutation(updateAlipayCallbackStatus);

  const {
    data: referralData,
    isLoading: isLoadingReferral,
    refetch: refetchReferralData,
  } = useQuery(["referral", token, language], () =>
    getReferral({ token, language })
  );

  const {
    isLoading: isLoadingSetReferral,
    mutate: mutateSetReferral,
    refetch: refetchSetReferral,
  } = useMutation(updateReferral);

  const {
    data: minimumLimitPointData,
    isLoading: isLoadingMinimumLimitPoint,
    refetch: refetchMinimumLimitPointData,
  } = useQuery(["minimumlimitpoint", token, language], () =>
    getMinimumTopUp({ token, language })
  );

  const {
    isLoading: isLoadingSetMinimumLimitPoint,
    mutate: mutateSetMinimumLimitPoint,
    refetch: refetchSetMinimumLimitPoint,
  } = useMutation(updateMinimumTopUp);

  const {
    data: maximumLimitPointData,
    isLoading: isLoadingMaximumLimitPoint,
    refetch: refetchMaximumLimitPointData,
  } = useQuery(["maximumlimitpoint", token, language], () =>
    getMaximumTopUp({ token, language })
  );

  const {
    isLoading: isLoadingSetMaximumLimitPoint,
    mutate: mutateSetMaximumLimitPoint,
    refetch: refetchSetMaximumLimitPoint,
  } = useMutation(updateMaximumTopUp);

  let newTransactionListsData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessPrabayarTransaction, setIsGetAccessPrabayarTransaction] =
    useState();
  const [
    isDeleteAccessPrabayarTransaction,
    setIsDeleteAccessPrabayarTransaction,
  ] = useState();
  const [
    isUpdateAccessPrabayarTransaction,
    setIsUpdateAccessPrabayarTransaction,
  ] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Prabayar Transaction
          const getAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessPrabayarTransaction(
            getAccessPrabayarTransaction?.length
          );

          const deleteAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessPrabayarTransaction(
            deleteAccessPrabayarTransaction?.length
          );

          const updateAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessPrabayarTransaction(
            updateAccessPrabayarTransaction?.length
          );
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form YuanConversion
  // -------------------------------
  const formYuanConversion = useForm();

  const handleDialogYuanConversion = (isOpen, guid) => {
    setIsVisibleDownloadTransaction(isOpen);
    setIsVisibleDownloadInfoTransaction(false);
  };

  const handleYuanConversion = (payload) => {
    mutateYuanConversion(
      { token, language, payload },
      {
        onSuccess: (data) => {
          setIsVisibleDownloadTransaction(false);
          setIsVisibleDownloadInfoTransaction(true);
          refetchYuanData();
        },
      }
    );
  };

  // -------------------------------
  // Form Config Amount BC 2.2
  // -------------------------------
  const formAmountBC22 = useForm();

  const handleDialogAmountBC22 = (isOpen, guid) => {
    setIsVisibleConfigAmountBC22(isOpen);
    setIsVisibleConfigAmountBC22Info(false);
  };

  const handleAmountBC22 = (payload) => {
    mutateSetAmountBc22(
      { token, language, payload },
      {
        onSuccess: (data) => {
          setIsVisibleConfigAmountBC22(false);
          setIsVisibleConfigAmountBC22Info(true);
          refetchAmountBc22();
        },
      }
    );
  };

  // -------------------------------
  // Form Config APK Version
  // -------------------------------
  const formAPKVersioning = useForm();

  const handleDialogAPKVersioning = (isOpen, guid) => {
    setIsVisibleConfigApkVersioning(isOpen);
    setIsVisibleConfigApkVersioningInfo(false);
  };

  const handleAPKVersioning = (payload) => {
    mutateSetApkVersion(
      { token, language, payload },
      {
        onSuccess: (data) => {
          setIsVisibleConfigApkVersioning(false);
          setIsVisibleConfigApkVersioningInfo(true);
          refetchApkVersion();
        },
      }
    );
  };

  // -------------------------------
  // Form Alipay Callback
  // -------------------------------
  const formAlipayCallback = useForm();

  const handleAlipayCallback = () => {
    mutateSetAlipayCallbackStatus(
      { token, language },
      {
        onSuccess: (data) => {
          // console.log(data);
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
          refetchCallbackStatusData();
        },
      }
    );
  };

  // -------------------------------
  // Form Referral
  // -------------------------------
  const formReferral = useForm();

  const handleDialogReferral = (isOpen, guid) => {
    setIsVisibleReferral(isOpen);
    setIsVisibleReferralInfo(false);
  };

  const handleReferral = (payload) => {
    mutateSetReferral(
      { token, language, payload },
      {
        onSuccess: (data) => {
          setIsVisibleReferral(false);
          setIsVisibleReferralInfo(true);
          refetchReferralData();
        },
      }
    );
  };

  // -------------------------------
  // Form Minimum Limit Point
  // -------------------------------
  const formMinimumLimitPoint = useForm();

  const handleDialogMinimumLimitPoint = (isOpen, guid) => {
    setIsVisibleMinimumLimitPoint(isOpen);
    setIsVisibleMinimumLimitPointInfo(false);
  };

  const handleMinimumLimitPoint = (payload) => {
    mutateSetMinimumLimitPoint(
      { token, language, payload },
      {
        onSuccess: (data) => {
          setIsVisibleMinimumLimitPoint(false);
          setIsVisibleMinimumLimitPointInfo(true);
          refetchMinimumLimitPointData();
        },
      }
    );
  };

  // -------------------------------
  // Form Maximum Limit Point
  // -------------------------------
  const formMaximumLimitPoint = useForm();

  const handleDialogMaximumLimitPoint = (isOpen, guid) => {
    setIsVisibleMaximumLimitPoint(isOpen);
    setIsVisibleMaximumLimitPointInfo(false);
  };

  const handleMaximumLimitPoint = (payload) => {
    mutateSetMaximumLimitPoint(
      { token, language, payload },
      {
        onSuccess: (data) => {
          setIsVisibleMaximumLimitPoint(false);
          setIsVisibleMaximumLimitPointInfo(true);
          refetchMaximumLimitPointData();
        },
      }
    );
  };

  useEffect(() => {
    newTransactionListsData = [];
    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    setNewTransactionLists(newTransactionListsData);
  }, [
    token,
    isGetAccessPrabayarTransaction,
    isDeleteAccessPrabayarTransaction,
    isUpdateAccessPrabayarTransaction,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formYuanConversion,
        newTransactionListsData,
        newTransactionLists,

        isSearch,
        setIsSearch,

        isModalFormOpen,
        isGetAccessPrabayarTransaction,
        isDeleteAccessPrabayarTransaction,
        isUpdateAccessPrabayarTransaction,
        isViewAllAccess,
        isVisibleDownloadTransaction,
        isVisibleDownloadInfoTransaction,
        handleDialogYuanConversion,
        handleYuanConversion,
        isLoadingYuanConversion,
        yuanData,

        formAmountBC22,
        handleDialogAmountBC22,
        handleAmountBC22,
        isVisibleConfigAmountBC22,
        isVisibleConfigAmountBC22Info,

        isLoadingAmountBc22,
        isLoadingSetAmountBc22,
        amountBc22Data,

        formAPKVersioning,
        handleDialogAPKVersioning,
        handleAPKVersioning,
        isVisibleConfigApkVersioning,
        isVisibleConfigApkVersioningInfo,

        isLoadingApkVersion,
        isLoadingSetApkVersion,
        apkVersionData,

        summaryPointData,
        isLoadingSummaryPoint,
        refetchSummaryPoint,

        handleAlipayCallback,
        isLoadingSetAlipayCallbackStatus,
        alipayCallbackStatusData,

        handleDialogReferral,
        handleReferral,
        formReferral,
        isVisibleReferral,
        isVisibleReferralInfo,
        isLoadingReferral,
        isLoadingSetReferral,
        referralData,

        handleDialogMinimumLimitPoint,
        handleMinimumLimitPoint,
        formMinimumLimitPoint,
        isVisibleMinimumLimitPoint,
        isVisibleMinimumLimitPointInfo,
        isLoadingMinimumLimitPoint,
        isLoadingSetMinimumLimitPoint,
        minimumLimitPointData,
        
        handleDialogMaximumLimitPoint,
        handleMaximumLimitPoint,
        formMaximumLimitPoint,
        isVisibleMaximumLimitPoint,
        isVisibleMaximumLimitPointInfo,
        isLoadingMaximumLimitPoint,
        isLoadingSetMaximumLimitPoint,
        maximumLimitPointData,
      }}
    >
      {children}
    </Provider>
  );
};

export default ConfigController;
