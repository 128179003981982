import React, { useContext } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  FormHelperText,
  Switch,
} from "@chakra-ui/react";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import Close from "../../../../../../../assets/svg/glyph/close.svg";
import { Dialog } from "primereact/dialog";
// import TableVoucher from "./TableVoucher";
// import FilterVoucher from "./FilterVoucher";
import { ModalVoucherContext } from "../Controller";
import { VoucherContext } from "../../../Controller";
import InputNumber from "../../../../../../../components/FormInput/InputNumber/InputNumber";

const FormVoucher = () => {
  const { handleCreateVoucher, isLoadingSetVoucher } =
    useContext(ModalVoucherContext);

  const { formModalInsert, isModalFormOpen, handleModalFormOpen } =
    useContext(VoucherContext);

  const { t } = useTranslation();

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = formModalInsert;

  const renderHeader = () => {
    return (
      <div className="grid grid-cols-12 bg-[#504178] p-4 rounded-xl poppins">
        <div className="col-span-11 text-white flex justify-center ml-11">
          {/* {isEdit === true
            ? t("LABEL.EDIT") + " " + t("LABEL.POINT")
            : t("LABEL.ADD") + " " + t("LABEL.POINT")} */}
          {t("LABEL.ADD") + " " + t("LABEL.VOUCHER")}
        </div>
        <div className="text-white flex justify-end">
          <Button
            size={"xs"}
            colorScheme="new"
            className="bg-[#504178]"
            onClick={() => handleModalFormOpen(false)}
          >
            <img src={Close} alt="" />
          </Button>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        {/* <Button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => onHide(name)}
          autoFocus
        /> */}
      </div>
    );
  };

  return (
    <Dialog
      headerStyle={{ backgroundColor: "#504178", padding: "0px" }}
      contentStyle={{ padding: "0px" }}
      draggable={false}
      header={renderHeader}
      visible={isModalFormOpen}
      style={{ width: "50vw" }}
      footer={renderFooter}
      closable={false}
      position={"top"}
    >
      <div className="p-4">
        <div className="grid grid-cols-1 pt-4 gap-y-3">
          <div>
            {/* Title */}
            <FormControl>
              <FormLabel
                className={`font-poppins`}
                style={{
                  fontWeight: 700,
                  fontSize: "12px",
                  lineHeight: "18px",
                }}
              >
                {t("LABEL.TITLE")}
              </FormLabel>
              <InputText
                style={
                  errors?.title && {
                    borderColor: "#f54242",
                    boxShadow: "none",
                  }
                }
                className={`w-full text-sm`}
                placeholder={t("LABEL.TITLE")}
                {...register("title", {
                  required: t("LABEL.TITLE") + " " + t("INVALID.REQUIRED"),
                })}
              />
              {errors?.title && (
                <span className="text-red-400 text-xs">
                  {errors?.title?.message}
                </span>
              )}
            </FormControl>
          </div>
          <div>
            {/* Nominal */}
            <FormControl>
              <FormLabel
                className={`font-poppins`}
                style={{
                  fontWeight: 700,
                  fontSize: "12px",
                  lineHeight: "18px",
                }}
              >
                {t("LABEL.VALUE")}
              </FormLabel>
              <InputNumber
                control={control}
                style={
                  errors?.nominal && {
                    borderColor: "#f54242",
                    boxShadow: "none",
                  }
                }
                className={`w-full text-sm`}
                placeholder={t("LABEL.VALUE")}
                name="nominal"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
              />
              {errors?.nominal && (
                <span className="text-red-400 text-xs">
                  {errors?.nominal?.message}
                </span>
              )}
            </FormControl>
          </div>
        </div>

        <div className="flex justify-center gap-4 pt-10">
          <Button
            size={"md"}
            colorScheme="new"
            className="bg-[#FFFFFF]  border-2 border-[#504178] hover:bg-[#C7C4CF] font-poppins"
            style={{
              borderRadius: "100px",
              width: "182px",
              color: "#504178",
            }}
            onClick={() => handleModalFormOpen(false)}
          >
            {t("LABEL.CANCEL")}
          </Button>
          {
            // !isEdit ? (
            <Button
              size={"md"}
              colorScheme="new"
              className="bg-[#504178] text-[#FFFFFF] hover:bg-[#402b79] font-poppins"
              style={{ borderRadius: "100px", width: "182px" }}
              isLoading={isLoadingSetVoucher}
              onClick={formModalInsert.handleSubmit(handleCreateVoucher)}
            >
              {t("LABEL.SAVE")}
            </Button>
            // ) : (
            //   ""
            // )
            // (
            //   <Button
            //     size={"md"}
            //     colorScheme="new"
            //     className="bg-[#504178] text-[#FFFFFF] hover:bg-[#402b79] font-poppins"
            //     style={{ borderRadius: "100px", width: "182px" }}
            //     isLoading={isLoadingSetVoucher}
            //     onClick={formModal.handleSubmit(handleEditVoucher)}
            //   >
            //     {t("LABEL.SAVE")}
            //   </Button>
            // )
          }
        </div>
        {/* <div>
          <div className="overflow-auto pt-4">
            <FilterVoucher />
          </div>
          <div className="py-4">
            <TableVoucher />
          </div>
        </div> */}
      </div>
    </Dialog>
  );
};

export default FormVoucher;
