import { api } from "../../../../../../utils/axios";

//Create Terms Condition
export const setTermsCondition = (payload, token) =>
  api
    .post("/backoffice/tnc", payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Get List Terms Condition
export const getTermsConditionList = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/tnc/list",
      {
        filter: {
          set_question: false,
          question: "",
          set_answer: false,
          answer: "",
        },
        order: "order_number",
        sort: "ASC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
          "is-all-language": 0,
        },
      }
    )
    .then((res) => res?.data);

//Get Terms Condition
export const getTermsCondition = ({ guid, token, lang }) =>
  api
    .get(`/backoffice/tnc/${guid}`, {
      headers: {
        language: lang,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Delete Terms Condition
export const deleteTermsCondition = ({ guid, token, lang }) =>
  api
    .delete(`/backoffice/tnc/${guid}`, {
      headers: {
        language: lang,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

// Update Home Section
export const updateTermsCondition = (payload, token) =>
  api
    .put(
      `/backoffice/tnc/${payload?.payloadPublish?.[0]?.id}`,
      payload?.payloadPublish?.[0],
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
          "is-all-language": 0,
        },
      }
    )
    .then((res) => res?.data);

//Update Order TermsCondition
export const updateOrderTermsCondition = (payload, token) =>
  api
    .put("/backoffice/tnc/order-number", payload?.payloadOrder, {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

// //Set Home Section Active
// export const setHomeSectionActive = ({ guid, token }) =>
//   api
//     .put(
//       `/backoffice/home-section/is-active/${guid}`,
//       {},
//       {
//         headers: {
//           language: "EN",
//           "nihao-token": token,
//         },
//       }
//     )
//     .then((res) => res?.data);

// //Create Insert Ads Home Section
// export const setInsertAdsHomeSection = ({
//   selectedHomeSectionId,
//   advertisingId,
//   token,
//   lang,
// }) =>
//   api
//     .post(
//       "/backoffice/home-section/advertising",
//       {
//         home_section_id: selectedHomeSectionId,
//         advertising_id: advertisingId,
//       },
//       {
//         headers: {
//           language: lang,
//           "nihao-token": token,
//         },
//       }
//     )
//     .then((res) => res?.data);

// //Get Search Insert Ads Advertising List
// export const getInsertAdsAdvertisingSearch = ({ payload }) =>
//   api
//     .post(
//       "/backoffice/advertising/list",
//       {
//         filter: {
//           set_title: payload?.isTitle === true ? true : false,
//           title: payload?.isTitle ? payload?.search : "",
//         },
//         limit: 1000,
//         page: 1,
//         order: "created_at",
//         sort: "DESC",
//       },
//       {
//         headers: {
//           language: payload?.lang,
//           "nihao-token": payload?.token,
//         },
//       }
//     )
//     .then((res) => res?.data);

//Get Search TermsCondition
export const getTermsConditionSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/tnc/list",
      {
        filter: {
          set_title: payload?.isTitle === true ? true : false,
          title: payload?.isTitle ? payload?.search : "",
          set_description: payload?.isDescription === true ? true : false,
          description: payload?.isDescription ? payload?.search : "",
        },
        order: "order_number",
        sort: "ASC",
      },
      {
        headers: {
          language: payload?.lang,
          "nihao-token": payload?.token,
          "is-all-language": 0,
        },
      }
    )
    .then((res) => res?.data);
