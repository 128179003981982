import { api } from "../../../../../../utils/axios";

//Create Topic Category
export const setTopicCategory = (payload, token) =>
  api
    .post("/backoffice/topic-category", payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Get List Topic Category
export const getTopicsCategory = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/topic-category/list",
      {
        filter: {
          set_title: false,
          title: "",
          set_descriptions: false,
          descriptions: "",
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Topic Category
export const getTopicCategory = ({ guid, token, language }) =>
  api
    .get(`/backoffice/topic-category/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Get Search Topic Category 
export const getTopicCategorySearch = ({ payload }) =>
  api
    .post(
      "/backoffice/topic-category/list",
      {
        filter: {
          set_topic_id: payload?.isTopicId === true ? true : false,
          topic_id: payload?.isTopicId ? payload?.search : "",
          set_category: payload?.isCategory === true ? true : false,
          category: payload?.isCategory ? payload?.search : "",
          set_description: payload?.isDescription === true ? true : false,
          description: payload?.isDescription ? payload?.search : "",
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Delete Topic Category
export const deleteTopicCategory = ({ guid, token, language }) =>
  api
    .delete(`/backoffice/topic-category/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update Topic Category
export const updateTopicCategory = (payload, token) =>
  api
    .put(`/backoffice/topic-category/${payload?.payloadPublish?.[0]?.id}`, payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Update Toggle Topic Category
export const updateToggleTopicCategory = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/topic-category/is-active/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
