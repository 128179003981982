import { api } from "../../../../../../utils/axios";

//Get List User
export const getUsers = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/user-backoffice/list",
      {
        filter: {
          set_name: false,
          name: "",
          set_phone: false,
          phone: "",
          set_email: false,
          email: "",
          set_role_id: false,
          role_id: 0,
          set_is_active: false,
          is_active: false,
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get User By ID
export const getUser = ({ guid, token, language }) =>
  api
    .get(`/backoffice/user-backoffice/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Set User Active
export const setUserActive = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/user-backoffice/is-active/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Create User
export const setUser = ({ payload, token }) =>
  api
    .post(
      "/backoffice/user-backoffice",
      {
        name: payload?.name,
        profile_picture_image_url: "",
        phone: payload?.phone.toString(),
        email: payload?.email,
        role_id: payload?.role,
      },
      {
        headers: {
          language: "EN",
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Delete User
export const deleteUser = ({ guid, token, language }) =>
  api
    .delete(`/backoffice/user-backoffice/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Search User
export const getUserSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/user-backoffice/list",
      {
        filter: {
          set_name: payload?.isName === true ? true : false,
          name: payload?.isName ? payload?.search : "",
          set_phone: payload.isPhone === true ? true : false,
          phone: payload?.isPhone ? payload?.search : null,
          set_email: payload.isEmail === true ? true : false,
          email: payload?.isEmail ? payload?.search : null,
          set_role_id: payload.isRole === true ? true : false,
          role_id: payload?.isRole ? payload?.searchRole : 0,
          set_is_active: payload?.isStatus === true ? true : false,
          is_active: payload?.isStatus ? payload?.searchStatus : false,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Set User Role
export const setUserRole = ({ role, guid, token }) =>
  api
    .put(
      `/backoffice/user-backoffice/change-role/${guid}`,
      {
        role_id: role,
      },
      {
        headers: {
          language: "EN",
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
