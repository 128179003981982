import { api } from "../../../../../utils/axios";

//Get Summary Point
export const getSummaryPoint = ({ token, lang }) =>
  api
    .get(`/backoffice/user-app/point/summary-point`, {
      headers: {
        language: lang,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
