import { api } from "../../../../../../utils/axios";

//Get List User Agent
export const getUserAgents = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/user-agent/list",
      {
        filter: {
          set_name: false,
          name: "",
          set_phone: false,
          phone: "",
          set_email: false,
          email: "",
          set_is_active: false,
          is_active: false,
          set_temporary_close: false,
          is_temporary_close: false,
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get User By ID
export const getUserAgent = ({ guid, token, language }) =>
  api
    .get(`/backoffice/user-agent/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Set User Active
export const setUserAgentActive = ({ guid, isActive, token, language }) =>
  api
    .put(
      `/backoffice/user-agent/active`,
      {
        guid: guid,
        active: isActive,
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Set User Temporary
export const setUserAgentTemporary = ({ guid, isTemporary, token, language }) =>
  api
    .put(
      `/backoffice/user-agent/temporary-close`,
      {
        guid: guid,
        close: isTemporary,
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Create User Agent
export const setUserAgent = ({ payloadPublish, token }) =>
  api
    .post("/backoffice/user-agent", payloadPublish, {
      headers: {
        language: "EN",
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Delete User
export const deleteUserAgent = ({ guid, token, language }) =>
  api
    .delete(`/backoffice/user-agent/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Search User
export const getUserAgentSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/user-agent/list",
      {
        filter: {
          set_name: payload?.isName === true ? true : false,
          name: payload?.isName ? payload?.search : "",
          set_phone: payload.isPhone === true ? true : false,
          phone: payload?.isPhone ? payload?.search : null,
          set_email: payload.isEmail === true ? true : false,
          email: payload?.isEmail ? payload?.search : null,
          set_is_active: payload?.isStatus === true ? true : false,
          is_active: payload?.isStatus ? payload?.searchStatus : false,
          set_temporary_close: payload?.isTemporary === true ? true : false,
          is_temporary_close: payload?.isTemporary
            ? payload?.searchTemporary
            : false,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.lang,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Topup Saldo Balance
export const setTopUpSaldo = ({ newPayload, token, language }) =>
  api
    .post(
      `/backoffice/user-agent/top-up/${newPayload?.guid}`,
      { amount: newPayload?.amount?.amount },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//List Transaction
export const getListTransaction = ({ page, limit, guid, token, language }) =>
  api
    .post(
      `/backoffice/user-agent/transaction-history/${guid}`,
      {
        filter: {
          set_transaction_type: false,
          transaction_type: "",
          set_message: false,
          message: "",
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//List Transaction Search
export const getListTransactionSearch = ({ payload, token , language}) =>
  api
    .post(
      `/backoffice/user-agent/transaction-history/${payload?.guid}`,
      {
        filter: {
          set_transaction_type: payload?.isType === true ? true : false,
          transaction_type: payload?.isType ? payload?.searchType : "",
          set_message: payload?.isMessage === true ? true : false,
          message: payload?.isMessage ? payload?.search : "",
        },
        limit: 100000,
        page: 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);
