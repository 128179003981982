import { api } from "../../../../../../utils/axios";

//Get Privacy Policy
export const getAboutUs = ({ token, language }) =>
  api
    .get(`/backoffice/about-us`, {
      headers: {
        language: language,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

// Update Privacy Policy
export const updateAboutUs = (payload, token) =>
  api
    .put(`/backoffice/about-us`, payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
        "is-all-language": 0,
      },
    })
    .then((res) => res?.data);
