import React, { lazy, Suspense } from "react";
// import SyncConfirmationDialog from "./components/Dialog/SyncConfirmationDialog";
// import SyncInformationDialog from "./components/Dialog/SyncInformationDialog";
// import Filter from "./components/Filter";
// import Brand from "./components/FilterProduct/Brand";
// import Category from "./components/FilterProduct/Category";
// import Types from "./components/FilterProduct/Types";
// import HeaderSaldo from "./components/HeaderSaldo";
// import ModalUpdateSellPrice from "./components/Modal";
// import ModalUpdateProductName from "./components/ModalProduct";
// import FormUpdateSellPrice from "./components/Modal/components/FormUpdateSellPrice";
// import TablePPOB from "./components/TablePPOB";
import PascabayarController from "./Controller";

const Pascabayar = () => {
  const SyncConfirmationDialog = lazy(() =>
    import("./components/Dialog/SyncConfirmationDialog")
  );
  const SyncInformationDialog = lazy(() =>
    import("./components/Dialog/SyncInformationDialog")
  );
  const Filter = lazy(() => import("./components/Filter"));
  const Brand = lazy(() => import("./components/FilterProduct/Brand"));
  // const Category = lazy(() => import("./components/FilterProduct/Category"));
  const Types = lazy(() => import("./components/FilterProduct/Types"));
  const HeaderSaldo = lazy(() => import("./components/HeaderSaldo"));
  const ModalUpdateSellPrice = lazy(() => import("./components/Modal"));
  const ModalUpdateProductName = lazy(() =>
    import("./components/ModalProduct")
  );
  const TablePPOB = lazy(() => import("./components/TablePPOB"));


  return (
    <div>
      <PascabayarController>
        <Suspense
          fallback={
            <div class="h-screen flex items-center">
              <div class="mx-auto">
                <div
                  class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex gap-4">
            {/* <ButtonNew /> */}
            <HeaderSaldo />
          </div>
          <div className="pt-4 pb-4">{/* <Category /> */}</div>
          <div className="border border-gray-300 p-4 rounded-xl">
            <div className="pt-2">
              <Brand />
            </div>
            <div className="border border-t-white border-x-white border-b-gray-300 pt-4"></div>
            <div className="pt-4">
              <Types />
            </div>
            <div className="pt-4">
              <div className="overflow-auto h-screen">
                <div className="py-4">
                  <Filter />
                </div>
                <TablePPOB />
              </div>
            </div>
          </div>
          <ModalUpdateSellPrice />
          <ModalUpdateProductName />
          <SyncConfirmationDialog />
          <SyncInformationDialog />
        </Suspense>
      </PascabayarController>
    </div>
  );
};

export default Pascabayar;
