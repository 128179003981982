import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TokenContext } from "../../../../../controllers/public/TokenController";

import { Button, Switch, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import {
  getQnaMasters,
  deleteQnaMaster,
  // updateToggleQnaMaster,
  getQnaMaster,
  getQnaMasterSearch,
} from "./api/QnaMasterApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import Cookies from "js-cookie";
import { getTopics } from "../../Master/Topic/api/TopicApi";
import { getTopicsCategory } from "../../Master/TopicCategory/api/TopicCategoryApi";
// import { getTopics } from "../Topic/api/TopicApi";

export const QnaMasterContext = createContext();
const { Provider } = QnaMasterContext;

const QnaMasterController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: FilterTopicsData,
    isLoading: isLoadingFilterTopicsData,
    refetch: refetchFilterTopicsData,
  } = useQuery(["filtertopics", token, language], () =>
    getTopics({ token, language })
  );

  // const {
  //   data: FilterCategoryData,
  //   isLoading: isLoadingFilterCategoryData,
  //   refetch: refetchFilterCategoryData,
  // } = useQuery(["filtercategory", token, language], () =>
  //   getTopicsCategory({ token, language })
  // );

  const {
    data: QnaMasterData,
    isLoading: isLoadingQnaMasterData,
    refetch: refetchQnaMasterData,
  } = useQuery(["qnamaster", token, language], () =>
    getQnaMasters({ token, language })
  );

  const { mutate: mutateGetTopicsCategory } = useMutation(getQnaMasters);

  const { mutate: mutateGetQnaMaster } = useMutation(getQnaMaster);

  const {
    isLoading: isLoadingSetQnaMasterSearch,
    mutate: mutateSetQnaMasterSearch,
  } = useMutation(getQnaMasterSearch);

  const { isLoading: isLoadingDeleteQnaMaster, mutate: mutateDeleteQnaMaster } =
    useMutation(deleteQnaMaster);

  // const {
  //   isLoading: isLoadingUpdateToggleQnaMaster,
  //   mutate: mutateUpdateToggleQnaMaster,
  // } = useMutation(updateToggleQnaMaster);

  const [isCategoryGuid, setIsCategoryGuid] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleQnaMasterDialog, setIsVisibleQnaMasterDialog] =
    useState(false);
  const [isVisibleDeleteQnaMaster, setIsVisibleDeleteQnaMaster] =
    useState(false);
  const [isVisibleDeleteInfoQnaMaster, setIsVisibleDeleteInfoQnaMaster] =
    useState(false);

  const [isPublishQnaMasterOpen, setIsPublishQnaMasterOpen] = useState(false);
  const [newQnaMaster, setnewQnaMaster] = useState();

  const [qnaMasterId, setQnaMasterId] = useState();

  const [payloadQnaMasterEdit, setPayloadQnaMasterEdit] = useState();
  const [indexTabActive, setIndexTabActive] = useState();

  const [enDescription, setEnDescription] = useState("");
  const [idDescription, setIdDescription] = useState("");
  const [cnDescription, setCnDescription] = useState("");
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  
  let newQnaMasterData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessBanner, setIsGetAccessBanner] = useState();
  const [isDeleteAccessBanner, setIsDeleteAccessBanner] = useState();
  const [isUpdateAccessBanner, setIsUpdateAccessBanner] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);
  const [isTopicSearch, setIsTopicSearch] = useState(true);
  const [isCategorySearch, setIsCategorySearch] = useState(false);
  const [isQuestionSearch, setIsQuestionSearch] = useState(false);
  const [filterCategoryData, setFilterCategoryData] = useState();

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Banner
          const getAccessTopic = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessBanner(getAccessTopic?.length);

          const deleteAccessTopic = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessBanner(deleteAccessTopic?.length);

          const updateAccessTopic = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessBanner(updateAccessTopic?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadQnaMasterEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formEn.reset({
        date: defaultValue,
        topic_id: newForm?.topic_id,
        category_id: newForm?.category_id,
        question: newForm?.question,
        answers: newForm?.answers,
      });
    }

    if (newForm && isEdit === true) {
      // console.log(payloadQnaMasterEdit)
      formEn.reset({
        id: payloadQnaMasterEdit?.guid,
        date: dateEdit,
        topic_id: payloadQnaMasterEdit?.topic?.guid,
        category_id: payloadQnaMasterEdit?.category?.guid,
        question: payloadQnaMasterEdit?.question?.en,
        answers: payloadQnaMasterEdit?.answers?.en,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, [isEdit, payloadQnaMasterEdit]);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadQnaMasterEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formId.reset({
        date: defaultValue,
        topic_id: newForm?.topic_id,
        category_id: newForm?.category_id,
        question: newForm?.question,
        answers: newForm?.answers,
      });
    }

    if (newForm && isEdit === true) {
      formId.reset({
        id: payloadQnaMasterEdit?.guid,
        date: dateEdit,
        topic_id: payloadQnaMasterEdit?.topic?.guid,
        category_id: payloadQnaMasterEdit?.category?.guid,
        question: payloadQnaMasterEdit?.question?.id,
        answers: payloadQnaMasterEdit?.answers?.id,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, [isEdit, payloadQnaMasterEdit]);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadQnaMasterEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formCn.reset({
        date: defaultValue,
        topic_id: newForm?.topic_id,
        category_id: newForm?.category_id,
        question: newForm?.question,
        answers: newForm?.answers,
      });
    }

    if (newForm && isEdit === true) {
      formCn.reset({
        id: payloadQnaMasterEdit?.guid,
        date: dateEdit,
        topic_id: payloadQnaMasterEdit?.topic?.guid,
        category_id: payloadQnaMasterEdit?.category?.guid,
        question: payloadQnaMasterEdit?.question?.cn,
        answers: payloadQnaMasterEdit?.answers?.cn,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, [isEdit, payloadQnaMasterEdit]);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      // console.log(newForm?.en?.banner?.thumbnail_web?.en)
      formPreviewEn.reset({
        date: defaultValue,
        category: newForm?.en?.category?.[0]?.en,
        description: newForm?.en?.description?.[0]?.en,
        logo_url: newForm?.logo_url,
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewId.reset({
        date: defaultValue,
        category: newForm?.id?.category?.[0]?.id,
        description: newForm?.id?.description?.[0]?.id,
        // banner: newForm?.id?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.id?.banner?.thumbnail_web?.id,
        banner_thumbnail: newForm?.id?.banner?.thumbnail?.id,
        banner_image: newForm?.id?.banner?.image?.id,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewCn.reset({
        date: defaultValue,
        category: newForm?.cn?.category?.[0]?.cn,
        description: newForm?.cn?.description?.[0]?.cn,
        // banner: newForm?.cn?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.cn?.banner?.thumbnail_web?.cn,
        banner_thumbnail: newForm?.cn?.banner?.thumbnail?.cn,
        banner_image: newForm?.cn?.banner?.image?.cn,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView, categoryGuid) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    // console.log(categoryGuid)
    categoryGuid && setIsCategoryGuid(categoryGuid);
    setIndexTabActive(0);
    isOpen === false && handlePublishQnaMasterOpen(false);

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieen"));
        index++
      ) {
        Cookies.remove("keyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieid"));
        index++
      ) {
        Cookies.remove("keyid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookiecn"));
        index++
      ) {
        Cookies.remove("keycn" + index);
      }

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieediten"));
        index++
      ) {
        Cookies.remove("keyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditid"));
        index++
      ) {
        Cookies.remove("keyeditid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditcn"));
        index++
      ) {
        Cookies.remove("keyeditcn" + index);
      }
    }

    if (isEdit === true) {
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieen"));
        index++
      ) {
        Cookies.remove("keyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieid"));
        index++
      ) {
        Cookies.remove("keyid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookiecn"));
        index++
      ) {
        Cookies.remove("keycn" + index);
      }

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieediten"));
        index++
      ) {
        Cookies.remove("keyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditid"));
        index++
      ) {
        Cookies.remove("keyeditid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditcn"));
        index++
      ) {
        Cookies.remove("keyeditcn" + index);
      }
    }

    isEdit &&
      mutateGetQnaMaster(
        { guid, token, language },
        {
          onSuccess: (data) => {
            setEnDescription(data?.data?.answers);
            setIdDescription(data?.data?.answers);
            setCnDescription(data?.data?.answers);            
            setPayloadQnaMasterEdit(data?.data);
          },
        }
      );
  };

  const handlePublishQnaMasterOpen = (isOpen) => {
    setIsPublishQnaMasterOpen(isOpen);
    // resetFormPublish({});
  };

  const handleDialogDeleteQnaMaster = (isOpen, guid) => {
    setIsVisibleDeleteQnaMaster(isOpen);
    setQnaMasterId(guid);
    setIsVisibleDeleteInfoQnaMaster(false);
  };

  const handleDeleteQnaMaster = (guid) => {
    mutateDeleteQnaMaster(
      { guid, token, language },
      {
        onSuccess: () => {
          refetchQnaMasterData();
          setIsVisibleDeleteQnaMaster(false);
          setIsVisibleDeleteInfoQnaMaster(true);
        },
      }
    );
  };

  // const handleUpdateToggleQnaMaster = (guid) => {
  //   mutateUpdateToggleQnaMaster(
  //     { guid, token, language },
  //     {
  //       onSuccess: (data) => {
  //         toast({
  //           description: data?.message?.response_message,
  //           position: "top-right",
  //           status: "success",
  //           duration: 1500,
  //           isClosable: true,
  //         });
  //         handlePaginationTable(currentPage?.[0], currentLimit?.[0]);
  //       },
  //     }
  //   );
  // };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword) => {
    let isTopicId = isTopicSearch || (Cookies.get("topic_search") && true);
    let isCategory =
      isCategorySearch && !Cookies.get("topic_category")
        ? false
        : isCategorySearch && Cookies.get("topic_category")
        ? true
        : false;
    let isQuestion = keyword?.keyword_search ? true : false;
    setIsSearch(true);

    let payload = {
      isTopicId: isTopicId,
      isCategoryId: isCategory,
      isQuestion: isQuestion,
      searchTopic: Cookies.get("topic_search")
        ? Cookies.get("topic_search")
        : "",
      searchCategory: Cookies.get("topic_category")
        ? Cookies.get("topic_category")
        : "",
      searchQuestion: keyword?.keyword_search ? keyword?.keyword_search : "",
      // searchStatus: keyword?.keyword_status === "0" ? false : true,
      language: language,
      token: token,
    };
    console.log(payload);

    currentPage.push(1);
    currentLimit.push(10);
    mutateSetQnaMasterSearch(
      { payload },
      {
        onSuccess: (data) => {
          console.log(data?.data);
          data?.data?.map((value) => {
            // console.log(value);
            newQnaMasterData.push({
              guid: value?.guid,
              question: value?.question?.en,
              answers: value?.answers?.en,
              action: (
                <div className="flex gap-2">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(
                          false,
                          true,
                          true,
                          value?.guid,
                          true,
                          value?.category?.guid
                        )
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteQnaMaster(true, value?.guid)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewQnaMaster(newQnaMasterData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetTopicsCategory(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newQnaMasterData = [];

          data?.data?.map((value) => {
            newQnaMasterData.push({
              guid: value?.guid,
              question: value?.question?.en,
              answers: value?.answers?.en,
              action: (
                <div className="flex gap-2">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(
                          false,
                          true,
                          true,
                          value?.guid,
                          true,
                          value?.category?.guid
                        )
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteQnaMaster(true, value?.guid)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewQnaMaster(newQnaMasterData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetTopicsCategory(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newQnaMasterData = [];

          data?.data?.map((value) => {
            newQnaMasterData.push({
              guid: value?.guid,
              question: value?.question?.en,
              answers: value?.answers?.en,
              action: (
                <div className="flex gap-2">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(
                          false,
                          true,
                          true,
                          value?.guid,
                          true,
                          value?.category?.guid
                        )
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteQnaMaster(true, value?.guid)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewQnaMaster(newQnaMasterData);
        },
      }
    );
  };

  useEffect(() => {
    Cookies.remove("topic_search");
    Cookies.remove("topic_category");
    setIsTopicSearch(false);
    newQnaMasterData = [];

    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    QnaMasterData?.data?.map((value) => {
      newQnaMasterData.push({
        guid: value?.guid,
        question: value?.question?.en,
        answers: value?.answers?.en,
        action: (
          <div className="flex gap-2">
            {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleModalFormOpen(false, true, true, value?.guid, true, value?.category?.guid)
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.VIEW")}
                </div>
              </Button>
            {/* )} */}
            {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#FF0F0F]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() => handleDialogDeleteQnaMaster(true, value?.guid)}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.DELETE")}
                </div>
              </Button>
            {/* )} */}
          </div>
        ),
      });
    });
    setnewQnaMaster(newQnaMasterData);
  }, [
    QnaMasterData,
    token,
    isGetAccessBanner,
    isDeleteAccessBanner,
    isViewAllAccess,
    t("LANGUAGE"),
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,

        resetFormEn,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,

        qnaMasterId,
        isEdit,
        setIsEdit,
        isCreate,
        isModalFormOpen,
        isVisibleQnaMasterDialog,
        setIsVisibleQnaMasterDialog,
        isVisibleDeleteQnaMaster,
        setIsVisibleDeleteQnaMaster,
        isVisibleDeleteInfoQnaMaster,
        setIsVisibleDeleteInfoQnaMaster,

        isLoadingDeleteQnaMaster,

        isLoadingQnaMasterData,
        // isLoadingGetAdvertisings,

        QnaMasterData,
        newQnaMaster,
        newQnaMasterData,
        handleDialogDeleteQnaMaster,
        handleModalFormOpen,
        handleShowEntriesTable,
        handlePaginationTable,
        refetchQnaMasterData,
        handleDeleteQnaMaster,
        handleSearch,

        payloadQnaMasterEdit,
        setPayloadQnaMasterEdit,
        isView,
        setIsView,

        indexTabActive,
        setIndexTabActive,
        isPublishQnaMasterOpen,
        setIsPublishQnaMasterOpen,
        handlePublishQnaMasterOpen,

        enDescription,
        idDescription,
        cnDescription,

        isUpdateAccessBanner,
        isGetAccessBanner,
        isDeleteAccessBanner,
        isViewAllAccess,

        isShowStatus,
        isSearch,

        // TopicsData,
        // isLoadingTopicsData,
        // refetchTopicsData,

        // CategoryTopicsData,
        // isLoadingCategoryTopicsData,
        // refetchCategoryTopicsData,
        FilterTopicsData,
        isLoadingFilterTopicsData,
        isTopicSearch,
        setIsTopicSearch,
        isCategorySearch,
        setIsCategorySearch,
        isQuestionSearch,
        setIsQuestionSearch,

        filterCategoryData,
        setFilterCategoryData,
        setIsCategoryGuid,
        isCategoryGuid
      }}
    >
      {children}
    </Provider>
  );
};

export default QnaMasterController;
