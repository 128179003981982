import { api } from "../../../../../../utils/axios";

//Get List Pricelist
export const getPricelist = ({
  page,
  limit,
  token,
  language,
  valueCategory,
  valueBrand,
}) =>
  api
    .post(
      "/backoffice/biller/price/list",
      {
        filter: {
          set_product_name: false,
          product_name: "",
          set_category: true,
          category: valueCategory,
          set_brand: valueBrand ? true : false,
          brand: valueBrand,
          set_seller_name: false,
          seller_name: "",
          set_is_available: false,
          is_available: false,
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

// //Get List Pricelist Search
// export const getPricelistSearch = ({ payload, limit, token }) =>
//   api
//     .post(
//       "/backoffice/biller/price/list",
//       {
//         filter: {
//           set_product_name: payload?.isProduct === true ? true : false,
//           product_name: payload?.isProduct ? payload?.search : "",
//           set_category:
//             payload?.isCategoryPasca !== "" || payload?.isCategoryPra !== ""
//               ? true
//               : false,
//           category: payload?.isCategoryPasca || payload?.isCategoryPra,
//           set_brand:
//             payload?.isBrandBpjs !== "" ||
//             payload?.isBrandInternet !== "" ||
//             payload?.isBrandHP !== "" ||
//             payload?.isBrandTV !== "" ||
//             payload?.isBrandPDAM !== "" ||
//             payload?.isBrandTELKOMSEL !== "" ||
//             payload?.isBrandPLN !== ""
//               ? true
//               : false,
//           brand:
//             payload?.isBrandBpjs ||
//             payload?.isBrandInternet ||
//             payload?.isBrandHP ||
//             payload?.isBrandTV ||
//             payload?.isBrandPDAM ||
//             payload?.isBrandTELKOMSEL ||
//             payload?.isBrandPLN,
//           set_seller_name: false,
//           seller_name: "",
//           set_is_available: false,
//           is_available: false,
//         },
//         limit: 10000,
//         page: 1,
//         order: "created_at",
//         sort: "DESC",
//       },
//       {
//         headers: {
//           language: payload?.language,
//           "nihao-token": payload?.token,
//         },
//       }
//     )
//     .then((res) => res?.data);

//Get List Pricelist Search
export const getPricelistSearch = ({ payload, language, token }) =>
  api
    .post(
      "/backoffice/biller/price/list",
      payload,
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Category
export const getCategoryPpob = ({ page, limit, token, language }) =>
  api
    .get("/backoffice/biller/price/category", {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Brand
export const getBrand = ({ page, limit, token, language }) =>
  api
    .get("/backoffice/biller/price/brand", {
      params: { category: "Pascabayar" },
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Brand by Search Params
export const getBrandSearch = ({ page, limit, token, language, keyword }) =>
  api
    .get("/backoffice/biller/price/brand", {
      params: { category: keyword },
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Types
export const getTypes = ({ page, limit, token, language }) =>
  api
    .get("/backoffice/biller/price/types", {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Types
export const getTypesSearch = ({ page, limit, token, language, keyword }) =>
  api
    .get("/backoffice/biller/price/types", {
      params: { brand: keyword },
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update Sell Price
export const updateSellPrice = ({ newPayload, token, language }) =>
  api
    .put("/backoffice/biller/price/update", newPayload, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update Product Name
export const updateProductName = ({ payloadPublish, token, language }) =>
  api
    .put("/backoffice/biller/price/product_name", payloadPublish?.[0], {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Price List By ID
export const getPricelistId = ({ guid, token, language }) =>
  api
    .get(`/backoffice/biller/price/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Get Sync Price List
export const getSyncPriceList = ({ token, language }) =>
  api
    .get(`/backoffice/biller/price/sync`, {
      headers: {
        language: "EN",
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);
