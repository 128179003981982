import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TokenContext } from "../../../../controllers/public/TokenController";

import { Button, Switch, Tag, TagLabel, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import {
  deleteHomeSection,
  getHomeSection,
  // getAdvertisingsBanner,
  // getHomeSectionSearch,
  // getHomeSection,
  // deleteHomeSection,
  getHomeSectionList,
  getHomeSectionSearch,
  getInsertAdsAdvertisingSearch,
  setHomeSectionActive,
  setInsertAdsHomeSection,
  updateOrderHomeSection,
} from "./api/HomeSectionApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { map } from "rsuite/esm/utils/ReactChildren";
import { getAdvertisings } from "../Ads/Advertising/api/AdvertisingApi";
import { AuthContext } from "../../../../controllers/public/AuthController";
import { getRole } from "../User/UserAdminRole/api/RoleApi";

export const HomeSectionContext = createContext();
const { Provider } = HomeSectionContext;

const HomeSectionController = ({ children }) => {
  const { t } = useTranslation();
  const { token } = useContext(TokenContext);

  let lang = t("LANGUAGE");
  const toast = useToast();

  const {
    data: HomeSectionData,
    isLoading: isLoadingHomeSectionData,
    refetch: refetchHomeSectionData,
  } = useQuery(["homesection", token], () =>
    getHomeSectionList({ lang, token })
  );

  const { mutate: mutateGetHomeSectionList } = useMutation(getHomeSectionList);

  const { mutate: mutateGetHomeSection } = useMutation(getHomeSection);

  const { mutate: mutateSetHomeSectionActive } =
    useMutation(setHomeSectionActive);

  const { isLoading: isLoadingGetAdvertisings, mutate: mutateGetAdvertisings } =
    useMutation(getAdvertisings);

  const {
    data: AdvertisingsData,
    isLoading: isLoadingAdvertisingsData,
    refetch: refetchAdvertisingData,
  } = useQuery(["advertisings", token], () => getAdvertisings({ token }));

  const { mutate: mutateSetInsertAdsHomeSection } = useMutation(
    setInsertAdsHomeSection
  );

  const {
    isLoading: isLoadingGetInsertAdsAdvertisingSearch,
    mutate: mutateGetInsertAdsAdvertisingSearch,
  } = useMutation(getInsertAdsAdvertisingSearch);

  const {
    isLoading: isLoadingSetHomeSectionSearch,
    mutate: mutateSetHomeSectionSearch,
  } = useMutation(getHomeSectionSearch);

  const {
    isLoading: isLoadingDeleteHomeSection,
    mutate: mutateDeleteHomeSection,
  } = useMutation(deleteHomeSection);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleHomeSectionDialog, setIsVisibleHomeSectionDialog] =
    useState(false);
  const [isVisibleDeleteHomeSection, setIsVisibleDeleteHomeSection] =
    useState(false);
  const [isVisibleDeleteInfoHomeSection, setIsVisibleDeleteInfoHomeSection] =
    useState(false);
  const [isPublishHomeSectionOpen, setIsPublishHomeSectionOpen] =
    useState(false);
  const {
    isLoading: isLoadingUpdateOrderHomeSection,
    mutate: mutateUpdateOrderHomeSection,
  } = useMutation(updateOrderHomeSection);
  const [newHomeSection, setnewHomeSection] = useState();

  const [homeSectionId, setHomeSectionId] = useState();

  const [urlHomeSectionThumbnail, setUrlHomeSectionThumbnail] = useState();
  const [urlHomeSectionThumbnailEdit, setUrlHomeSectionThumbnailEdit] =
    useState();
  const [payloadHomeSectionEdit, setPayloadHomeSectionEdit] = useState();
  const [isActiveSubmitOrder, setIsActiveSubmitOrder] = useState(false);
  const [isActiveDrag, setIsActiveDrag] = useState(false);

  const [indexTabActive, setIndexTabActive] = useState();

  const [orderSection, setOrderSection] = useState();

  const [newAdvertisings, setNewAdvertisings] = useState();
  const [isOpenInsertModalAds, setIsOpenInsertModalAds] = useState(false);
  const [selectedAdsId, setSelectedAdsId] = useState([]);
  const [selectedHomeSectionId, setSelectedHomeSectionId] = useState();

  let newHomeSectionData = [];
  let newAdvertisingsData = [];

  const [isGetAccessSection, setIsGetAccessSection] = useState();
  const [isDeleteAccessSection, setIsDeleteAccessSection] = useState();
  const [isUpdateAccessSection, setIsUpdateAccessSection] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Section
          const getAccessSection = data?.data?.access
            ?.filter((data) => data?.page === "section")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessSection(getAccessSection?.length);

          const deleteAccessSection = data?.data?.access
            ?.filter((data) => data?.page === "section")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessSection(deleteAccessSection?.length);

          const updateAccessSection = data?.data?.access
            ?.filter((data) => data?.page === "section")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessSection(updateAccessSection?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadHomeSectionEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formEn.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.descriptions,
      });
    }

    if (newForm && isEdit) {
      formEn.reset({
        date: dateEdit,
        title: payloadHomeSectionEdit?.title?.en,
        description: payloadHomeSectionEdit?.descriptions?.en,

        // image_thumbnail_url: payloadHomeSectionEdit?.image_thumbnail_url,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, [isEdit, payloadHomeSectionEdit]);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadHomeSectionEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formId.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.descriptions,
      });
    }

    const thumbnailHomeSection = urlHomeSectionThumbnailEdit
      ? urlHomeSectionThumbnailEdit
      : payloadHomeSectionEdit?.image_thumbnail_url;

    // console.log(thumbnailHomeSection)

    if (newForm && isEdit) {
      formId.reset({
        date: dateEdit,
        title: payloadHomeSectionEdit?.title?.id,
        description: payloadHomeSectionEdit?.descriptions?.id,

        // image_thumbnail_url: thumbnailHomeSection,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, [isEdit, payloadHomeSectionEdit, urlHomeSectionThumbnailEdit]);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadHomeSectionEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formCn.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.descriptions,
      });
    }

    const thumbnailHomeSection = urlHomeSectionThumbnailEdit
      ? urlHomeSectionThumbnailEdit
      : payloadHomeSectionEdit?.image_thumbnail_url;

    if (newForm && isEdit) {
      formCn.reset({
        date: dateEdit,
        title: payloadHomeSectionEdit?.title?.cn,
        description: payloadHomeSectionEdit?.descriptions?.cn,

        // image_thumbnail_url: thumbnailHomeSection,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, [isEdit, payloadHomeSectionEdit, urlHomeSectionThumbnailEdit]);

  // -------------------------------
  // Form Upload
  // -------------------------------
  const formUpload = useForm();
  const resetFormUpload = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadHomeSectionEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formUpload.reset({
        image_thumbnail_url: payloadHomeSectionEdit?.image_thumbnail_url,
      });
    }

    // console.log(payloadHomeSectionEdit);
    if (newForm && isEdit) {
      formUpload.reset({
        image_thumbnail_url: payloadHomeSectionEdit?.image_thumbnail_url,
      });
    }
  };

  useEffect(() => {
    resetFormUpload({});
  }, [isEdit, payloadHomeSectionEdit]);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      console.log(newForm);
      formPreviewEn.reset({
        date: defaultValue,
        title: newForm?.en?.title?.[0]?.en,
        description: newForm?.en?.description?.[0]?.en,
        image_thumbnail_url: newForm?.en?.image_thumbnail_url,
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewId.reset({
        date: defaultValue,
        title: newForm?.id?.title?.[0]?.id,
        description: newForm?.id?.description?.[0]?.id,
        image_thumbnail_url: newForm?.id?.image_thumbnail_url,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewCn.reset({
        date: defaultValue,
        title: newForm?.cn?.title?.[0]?.cn,
        description: newForm?.cn?.description?.[0]?.cn,
        image_thumbnail_url: newForm?.cn?.image_thumbnail_url,
      });
    }
  };

  const formPreviewUpload = useForm();
  const resetFormPreviewUpload = (newForm) => {
    if (newForm) {
      formPreviewUpload.reset({
        image_thumbnail_url: newForm?.image_thumbnail_url,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    setIndexTabActive(0);
    isOpen === false && handlePublishHomeSectionOpen(false);
    let lang = t("LANGUAGE");

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});
    }

    isEdit &&
      mutateGetHomeSection(
        { guid, token, lang },
        {
          onSuccess: (data) => {
            setPayloadHomeSectionEdit(data?.data);
          },
        }
      );
  };

  const handlePublishHomeSectionOpen = (isOpen) => {
    setIsPublishHomeSectionOpen(isOpen);
    // resetFormPublish({});
  };

  const handleDialogDeleteHomeSection = (isOpen, guid) => {
    setIsVisibleDeleteHomeSection(isOpen);
    setHomeSectionId(guid);
    setIsVisibleDeleteInfoHomeSection(false);
  };

  const handleDeleteHomeSection = (guid) => {
    let lang = t("LANGUAGE");
    mutateDeleteHomeSection(
      { guid, token, lang },
      {
        onSuccess: () => {
          refetchHomeSectionData();
          setIsVisibleDeleteHomeSection(false);
          setIsVisibleDeleteInfoHomeSection(true);
        },
      }
    );
  };

  const handleActiveSubmitOrder = (isActive, isDrag) => {
    setIsActiveSubmitOrder(isActive);
    setIsActiveDrag(isDrag);
  };

  const handleSubmitOrder = (isDrag) => {
    setIsActiveSubmitOrder(isDrag);
    setIsActiveDrag(isDrag);
    let lang = t("LANGUAGE");

    let payloadOrderSection = [];
    orderSection?.map((newOrder) => {
      payloadOrderSection.push({
        id: newOrder?.uid,
      });
    });

    let payloadOrder = {
      orders: payloadOrderSection,
    };
    console.log(payloadOrder);

    mutateUpdateOrderHomeSection(
      { payloadOrder, token, lang },
      {
        onSuccess: (data) => {
          // if (
          //   data?.message?.response_code === "01" ||
          //   data?.message?.response_code === "50"
          // ) {
          toast({
            description: "Data Order Updated",
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
          //   setIsVisibleHomeSectionDialog(false);
          //   handlePublishBannerAdsOpen(true);
          //   handleModalFormOpen(false, true);
          // } else {
          //   refetchAdvertisingsBannerData();
          //   setIsVisibleHomeSectionDialog(true);
          //   handlePublishBannerAdsOpen(false);
          //   handleModalFormOpen(true, false, true);
          // }
        },
      }
    );
  };

  const handleHomeSectionActive = (guid) => {
    mutateSetHomeSectionActive(
      { guid, token },
      {
        onSuccess: (data) => {
          refetchHomeSectionData();
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
        },
      }
    );
  };

  const handleInsertAdsHomeSection = (isOpen, guid, page, limit) => {
    setSelectedHomeSectionId(guid);
    setIsOpenInsertModalAds(isOpen);

    isOpen &&
      mutateGetAdvertisings(
        { page, limit, token },
        {
          onSuccess: (data) => {
            newAdvertisingsData = [];

            data?.data?.map((value) => {
              newAdvertisingsData.push({
                id: value?.id,
                title: value?.title,
                type: "",
                category: value?.category_name,
                show_at: "",
                tags:
                  value?.tags &&
                  value?.tags?.map((data) => (
                    <Tag
                      size={"md"}
                      borderRadius="full"
                      variant="solid"
                      colorScheme="global"
                      className="bg-[#504178] ml-2"
                    >
                      <TagLabel>{data}</TagLabel>
                    </Tag>
                  )),
                created_at: value?.created_at,
                // author: value?.author_id,
                publish_from: value?.publish_from,
                publish_to: value?.publish_to,
                seen: "",
                likes: "",
                image: (
                  <img
                    src={value?.image_website?.thumbnail}
                    alt=""
                    className="w-24"
                  />
                ),
              });
            });
            setNewAdvertisings(newAdvertisingsData);
          },
        }
      );
  };

  const handleCreateInsertHomeSectionAds = (advertisingId) => {
    mutateSetInsertAdsHomeSection(
      { selectedHomeSectionId, advertisingId, token, lang },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "warning",
              duration: 1500,
              isClosable: true,
            });
          } else {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "success",
              duration: 1500,
              isClosable: true,
            });
            refetchHomeSectionData();
            handleInsertAdsHomeSection(false);
          }
        },
      }
    );
  };

  // -------------------------------
  // Filter Insert Ads
  // -------------------------------

  const handleSearchInsertAds = (keyword) => {
    let isTitle = keyword?.keyword_filter === "1" && true;
    let lang = t("LANGUAGE");

    let payload = {
      isTitle: isTitle,
      search: keyword?.keyword_search,
      lang: lang,
      token: token,
    };

    mutateGetInsertAdsAdvertisingSearch(
      { payload },
      {
        onSuccess: (data) => {
          newAdvertisingsData = [];

          data?.data?.map((value) => {
            newAdvertisingsData.push({
              id: value?.id,
              title: value?.title,
              type: "",
              category: value?.category_name,
              show_at: "",
              tags:
                value?.tags &&
                value?.tags?.map((data) => (
                  <Tag
                    size={"md"}
                    borderRadius="full"
                    variant="solid"
                    colorScheme="global"
                    className="bg-[#504178] ml-2"
                  >
                    <TagLabel>{data}</TagLabel>
                  </Tag>
                )),
              created_at: value?.created_at,
              // author: value?.author_id,
              publish_from: value?.publish_from,
              publish_to: value?.publish_to,
              seen: "",
              likes: "",
              image: (
                <img
                  src={value?.image_website?.thumbnail}
                  alt=""
                  className="w-24"
                />
              ),
            });
          });
          setNewAdvertisings(newAdvertisingsData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries Insert Ads
  // -------------------------------

  const handleShowEntriesInsertAdsTable = (limit) => {
    let lang = t("LANGUAGE");
    mutateGetAdvertisings(
      { limit, token, lang },
      {
        onSuccess: (data) => {
          newAdvertisingsData = [];

          data?.data?.map((value) => {
            newAdvertisingsData.push({
              id: value?.id,
              title: value?.title,
              type: "",
              category: value?.category_name,
              show_at: "",
              tags:
                value?.tags &&
                value?.tags?.map((data) => (
                  <Tag
                    size={"md"}
                    borderRadius="full"
                    variant="solid"
                    colorScheme="global"
                    className="bg-[#504178] ml-2"
                  >
                    <TagLabel>{data}</TagLabel>
                  </Tag>
                )),
              created_at: value?.created_at,
              // author: value?.author_id,
              publish_from: value?.publish_from,
              publish_to: value?.publish_to,
              seen: "",
              likes: "",
              image: (
                <img
                  src={value?.image_website?.thumbnail}
                  alt=""
                  className="w-24"
                />
              ),
            });
          });
          setNewAdvertisings(newAdvertisingsData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination Insert Ads
  // -------------------------------

  const handlePaginationInsertAdsTable = (page, limit) => {
    let lang = t("LANGUAGE");
    mutateGetAdvertisings(
      { page, limit, token, lang },
      {
        onSuccess: (data) => {
          newAdvertisingsData = [];

          data?.data?.map((value) => {
            newAdvertisingsData.push({
              id: value?.id,
              title: value?.title,
              type: "",
              category: value?.category_name,
              show_at: "",
              tags:
                value?.tags &&
                value?.tags?.map((data) => (
                  <Tag
                    size={"md"}
                    borderRadius="full"
                    variant="solid"
                    colorScheme="global"
                    className="bg-[#504178] ml-2"
                  >
                    <TagLabel>{data}</TagLabel>
                  </Tag>
                )),
              created_at: value?.created_at,
              // author: value?.author_id,
              publish_from: value?.publish_from,
              publish_to: value?.publish_to,
              seen: "",
              likes: "",
              image: (
                <img
                  src={value?.image_website?.thumbnail}
                  alt=""
                  className="w-24"
                />
              ),
            });
          });
          setNewAdvertisings(newAdvertisingsData);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword) => {
    let isTitle = keyword?.keyword_filter === "1" && true;
    let isDescription = keyword?.keyword_filter === "2" && true;
    let lang = t("LANGUAGE");

    let payload = {
      isTitle: isTitle,
      isDescription: isDescription,
      search: keyword?.keyword_search,
      lang: lang,
      token: token,
    };

    mutateSetHomeSectionSearch(
      { payload },
      {
        onSuccess: (data) => {
          newHomeSectionData = [];

          data?.data?.map((value) => {
            newHomeSectionData.push({
              uid: value?.id,
              id: value?.no,
              title: value?.title?.en,
              description: value?.descriptions?.en,
              is_active:
                value?.id === "ce12c94a-8a97-4df0-ac7f-aac0c50fd975" ||
                value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ||
                value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ? (
                  ""
                ) : (
                  <Switch
                    colorScheme={value?.is_active === true ? "green" : "red"}
                    isChecked={value?.is_active === true ? true : false}
                    onChange={() => handleHomeSectionActive(value?.id)}
                  />
                ),
              status:
                value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#BAE6CC] text-[#18AB56]  leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ),
              action: (
                <div className="flex items-center justify-center gap-2">
                  {
                    value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ||
                    value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ? (
                      ""
                    ) : (
                      // (isUpdateAccessSection === 1 || isViewAllAccess === true) && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#1FB3A7]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleInsertAdsHomeSection(true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.ADS")}
                        </div>
                      </Button>
                    )
                    // )
                  }
                  {/* {(isGetAccessSection === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id, true)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  {/* )} */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF6627]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleModalFormOpen(false, true, true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Edit
                    </div>
                  </Button> */}
                  {
                    value?.id === "ce12c94a-8a97-4df0-ac7f-aac0c50fd975" ||
                    value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ||
                    value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ? (
                      ""
                    ) : (
                      // (isDeleteAccessSection === 1 || isViewAllAccess === true) && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleDialogDeleteHomeSection(true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    )
                    // )
                  }
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#18AB56]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Approve
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#1FB3A7]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Decline
                    </div>
                  </Button> */}
                </div>
              ),
            });
          });
          setnewHomeSection(newHomeSectionData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    let lang = t("LANGUAGE");
    mutateGetHomeSectionList(
      { limit, token, lang },
      {
        onSuccess: (data) => {
          newHomeSectionData = [];

          data?.data?.map((value) => {
            newHomeSectionData.push({
              uid: value?.id,
              id: value?.no,
              title: value?.title?.en,
              description: value?.descriptions?.en,
              is_active:
                value?.id === "ce12c94a-8a97-4df0-ac7f-aac0c50fd975" ||
                value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ||
                value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ? (
                  ""
                ) : (
                  <Switch
                    colorScheme={value?.is_active === true ? "green" : "red"}
                    isChecked={value?.is_active === true ? true : false}
                    onChange={() => handleHomeSectionActive(value?.id)}
                  />
                ),
              status:
                value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#BAE6CC] text-[#18AB56]  leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ),
              action: (
                <div className="flex items-center justify-center gap-2">
                  {
                    value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ||
                    value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ? (
                      ""
                    ) : (
                      // (isUpdateAccessSection === 1 || isViewAllAccess === true) && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#1FB3A7]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleInsertAdsHomeSection(true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.ADS")}
                        </div>
                      </Button>
                    )
                    // )
                  }
                  {/* {(isGetAccessSection === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id, true)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  {/* )} */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF6627]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleModalFormOpen(false, true, true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Edit
                    </div>
                  </Button> */}
                  {
                    value?.id === "ce12c94a-8a97-4df0-ac7f-aac0c50fd975" ||
                    value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ||
                    value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ? (
                      ""
                    ) : (
                      // (isDeleteAccessSection === 1 || isViewAllAccess === true) && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleDialogDeleteHomeSection(true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    )
                    // )
                  }
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#18AB56]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Approve
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#1FB3A7]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Decline
                    </div>
                  </Button> */}
                </div>
              ),
            });
          });
          setnewHomeSection(newHomeSectionData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    let lang = t("LANGUAGE");
    mutateGetHomeSectionList(
      { page, limit, token, lang },
      {
        onSuccess: (data) => {
          newHomeSectionData = [];

          data?.data?.map((value) => {
            newHomeSectionData.push({
              uid: value?.id,
              id: value?.no,
              title: value?.title?.en,
              description: value?.descriptions?.en,
              is_active:
                value?.id === "ce12c94a-8a97-4df0-ac7f-aac0c50fd975" ||
                value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ||
                value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ? (
                  ""
                ) : (
                  <Switch
                    colorScheme={value?.is_active === true ? "green" : "red"}
                    isChecked={value?.is_active === true ? true : false}
                    onChange={() => handleHomeSectionActive(value?.id)}
                  />
                ),
              status:
                value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#BAE6CC] text-[#18AB56]  leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ),
              action: (
                <div className="flex items-center justify-center gap-2">
                  {
                    value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ||
                    value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ? (
                      ""
                    ) : (
                      // (isUpdateAccessSection === 1 || isViewAllAccess === true) && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#1FB3A7]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleInsertAdsHomeSection(true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.ADS")}
                        </div>
                      </Button>
                    )
                    // )
                  }

                  {/* {(isGetAccessSection === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id, true)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  {/* )} */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF6627]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleModalFormOpen(false, true, true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Edit
                    </div>
                  </Button> */}
                  {
                    value?.id === "ce12c94a-8a97-4df0-ac7f-aac0c50fd975" ||
                    value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ||
                    value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ? (
                      ""
                    ) : (
                      // (isDeleteAccessSection === 1 || isViewAllAccess === true) && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleDialogDeleteHomeSection(true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    )
                    // )
                  }
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#18AB56]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Approve
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#1FB3A7]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Decline
                    </div>
                  </Button> */}
                </div>
              ),
            });
          });
          setnewHomeSection(newHomeSectionData);
        },
      }
    );
  };

  useEffect(() => {
    newHomeSectionData = [];

    HomeSectionData?.data?.map((value) => {
      newHomeSectionData.push({
        uid: value?.id,
        id: value?.no,
        title: value?.title?.en,
        description: value?.descriptions?.en,
        is_active:
          value?.id === "ce12c94a-8a97-4df0-ac7f-aac0c50fd975" ||
          value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ||
          value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ? (
            ""
          ) : (
            <Switch
              colorScheme={value?.is_active === true ? "green" : "red"}
              isChecked={value?.is_active === true ? true : false}
              onChange={() => handleHomeSectionActive(value?.id)}
            />
          ),
        status:
          value?.is_active === false ? (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NOT_ACTIVE")}
            </div>
          ) : (
            <div className="bg-[#BAE6CC] text-[#18AB56]  leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.ACTIVE")}
            </div>
          ),
        action: (
          <div className="flex items-center justify-center gap-2">
            {
              value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ||
              value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ? (
                ""
              ) : (
                // (isUpdateAccessSection === 1 || isViewAllAccess === true) && (
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#1FB3A7]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() => handleInsertAdsHomeSection(true, value?.id)}
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.ADS")}
                  </div>
                </Button>
              )
              // )
            }
            {/* {(isGetAccessSection === 1 || isViewAllAccess === true) && ( */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleModalFormOpen(false, true, true, value?.id, true)
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.VIEW")}
              </div>
            </Button>
            {/* )} */}
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF6627]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleModalFormOpen(false, true, true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Edit
              </div>
            </Button> */}
            {
              value?.id === "ce12c94a-8a97-4df0-ac7f-aac0c50fd975" ||
              value?.id === "ps22c02b-3r97-2zh9-ac7f-dab0a54fa0a3" ||
              value?.id === "ba14c94a-3a97-2df9-ac7f-aac0c50fa123" ? (
                ""
              ) : (
                // (isDeleteAccessSection === 1 || isViewAllAccess === true) && (
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#FF0F0F]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() => handleDialogDeleteHomeSection(true, value?.id)}
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.DELETE")}
                  </div>
                </Button>
              )
              // )
            }
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#18AB56]"
              style={{ borderRadius: "10px", height: "23px" }}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Approve
              </div>
            </Button>
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#1FB3A7]"
              style={{ borderRadius: "10px", height: "23px" }}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Decline
              </div>
            </Button> */}
          </div>
        ),
      });
    });
    // console.log(newHomeSectionData);
    setnewHomeSection(newHomeSectionData);
  }, [
    HomeSectionData,
    token,
    isGetAccessSection,
    isDeleteAccessSection,
    isUpdateAccessSection,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,
        formUpload,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        formPreviewUpload,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,
        resetFormPreviewUpload,

        homeSectionId,
        isEdit,
        isCreate,
        isModalFormOpen,
        isVisibleHomeSectionDialog,
        setIsVisibleHomeSectionDialog,
        isVisibleDeleteHomeSection,
        setIsVisibleDeleteHomeSection,
        isVisibleDeleteInfoHomeSection,
        setIsVisibleDeleteInfoHomeSection,

        // isLoadingDeleteHomeSection,

        isLoadingHomeSectionData,
        // isLoadingGetAdvertisings,

        HomeSectionData,
        newHomeSection,
        newHomeSectionData,
        handleDialogDeleteHomeSection,
        handleModalFormOpen,
        handleShowEntriesTable,
        handlePaginationTable,
        refetchHomeSectionData,
        handleDeleteHomeSection,
        handleSearch,
        handleSubmitOrder,
        orderSection,
        setOrderSection,

        handleActiveSubmitOrder,
        isActiveSubmitOrder,
        setIsActiveSubmitOrder,
        isActiveDrag,
        setIsActiveDrag,

        payloadHomeSectionEdit,
        setPayloadHomeSectionEdit,
        urlHomeSectionThumbnail,
        setUrlHomeSectionThumbnail,
        urlHomeSectionThumbnailEdit,
        setUrlHomeSectionThumbnailEdit,
        isView,
        setIsView,

        AdvertisingsData,
        isLoadingAdvertisingsData,
        isOpenInsertModalAds,
        setIsOpenInsertModalAds,
        isLoadingGetAdvertisings,
        newAdvertisings,
        setNewAdvertisings,

        selectedAdsId,
        setSelectedAdsId,
        handleCreateInsertHomeSectionAds,
        handleSearchInsertAds,
        handlePaginationInsertAdsTable,
        handleShowEntriesInsertAdsTable,

        indexTabActive,
        setIndexTabActive,

        handlePublishHomeSectionOpen,
        isPublishHomeSectionOpen,
        setIsPublishHomeSectionOpen,

        isGetAccessSection,
        isDeleteAccessSection,
        isUpdateAccessSection,
        isViewAllAccess,
      }}
    >
      {children}
    </Provider>
  );
};

export default HomeSectionController;
