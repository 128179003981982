import { api } from "../../../../../../utils/axios";

// //Get List Pricelist
// export const getPricelist = ({
//   page,
//   limit,
//   token,
//   language,
//   valueCategory,
//   valueBrand,
//   valueTypes,
// }) =>
//   api
//     .post(
//       "/backoffice/biller/price/list",
//       {
//         filter: {
//           set_product_name: false,
//           product_name: "",
//           set_category: true,
//           category: valueCategory,
//           set_brand: valueBrand ? true : false,
//           brand: valueBrand,
//           set_type: valueTypes ? true : false,
//           type: valueTypes,
//           set_seller_name: false,
//           seller_name: "",
//           set_is_available: false,
//           is_available: false,
//         },
//         limit: limit || 10,
//         page: page || 1,
//         order: "created_at",
//         sort: "DESC",
//       },
//       {
//         headers: {
//           language: language,
//           "nihao-token": token,
//         },
//       }
//     )
//     .then((res) => res?.data);

//Get List Pricelist
export const getPricelist = ({
  page,
  limit,
  token,
  language,
  valueCategory,
  valueBrand,
  valueTypes,
}) =>
  api
    .post(
      "/backoffice/biller/price/list",
      {
        filter: {
          set_product_name: false,
          product_name: "",
          set_category: false,
          category: "",
          set_brand: false,
          brand: "",
          set_type: false,
          type: "",
          set_seller_name: false,
          seller_name: "",
          set_is_available: false,
          is_available: false,
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get List Pricelist Search
// export const getPricelistSearch = ({ payload, limit, token }) =>
//   api
//     .post(
//       "/backoffice/biller/price/list",
//       {
//         filter: {
//           set_product_name: payload?.isProduct === true ? true : false,
//           product_name: payload?.isProduct ? payload?.search : "",
//           set_category:
//             payload?.isCategoryEMoney !== "" ||
//             payload?.isCategoryPra !== "" ||
//             payload?.isCategoryPln !== "" ||
//             payload?.isCategoryData !== ""
//               ? true
//               : false,
//           category:
//             payload?.isCategoryEMoney ||
//             payload?.isCategoryPra ||
//             payload?.isCategoryPln ||
//             payload?.isCategoryData,
//           set_brand:
//             payload?.isBrandGopay !== "" ||
//             payload?.isBrandOvo !== "" ||
//             payload?.isBrandShopeePay !== "" ||
//             payload?.isBrandPLN !== "" ||
//             payload?.isBrandAXIS !== "" ||
//             payload?.isBrandBYU !== "" ||
//             payload?.isBrandINDOSAT !== "" ||
//             payload?.isBrandSMARTFREN !== "" ||
//             payload?.isBrandTELKOMSEL !== "" ||
//             payload?.isBrandTRI !== "" ||
//             payload?.isBrandXL !== "" ||
//             payload?.isBrandDataIndosat !== "" ||
//             payload?.isBrandDataSmartfren !== "" ||
//             payload?.isBrandDataTelkomsel !== "" ||
//             payload?.isBrandDataTri !== "" ||
//             payload?.isBrandDataXL !== ""
//               ? true
//               : false,
//           brand:
//             payload?.isBrandGopay ||
//             payload?.isBrandOvo ||
//             payload?.isBrandShopeePay ||
//             payload?.isBrandPLN ||
//             payload?.isBrandAXIS ||
//             payload?.isBrandBYU ||
//             payload?.isBrandINDOSAT ||
//             payload?.isBrandSMARTFREN ||
//             payload?.isBrandTELKOMSEL ||
//             payload?.isBrandTRI ||
//             payload?.isBrandXL ||
//             payload?.isBrandDataIndosat ||
//             payload?.isBrandDataSmartfren ||
//             payload?.isBrandDataTelkomsel ||
//             payload?.isBrandDataTri ||
//             payload?.isBrandDataXL,
//           set_type:
//             payload?.isTypeGopayCustomer !== "" ||
//             payload?.isTypeOvoUmum !== "" ||
//             payload?.isTypeShopeePayUmum !== "" ||
//             payload?.isTypePlnUmum !== "" ||
//             payload?.isTypeAxisUmum !== "" ||
//             payload?.isTypeByuUmum !== "" ||
//             payload?.isTypeIndosatFreedomCombo !== "" ||
//             payload?.isTypeIndosatFreedomInternet !== "" ||
//             payload?.isTypeIndosatPulsaTransfer !== "" ||
//             payload?.isTypeIndosatUmum !== "" ||
//             payload?.isTypeSmartfrenIon !== "" ||
//             payload?.isTypeSmartfrenPulsaTransfer !== "" ||
//             payload?.isTypeSmartfrenUmum !== "" ||
//             payload?.isTypeSmartfrenUnlimited !== "" ||
//             payload?.isTypeSmartfrenUnlimitedNonStop !== "" ||
//             payload?.isTypeTelkomselFlash !== "" ||
//             payload?.isTypeTelkomselOmg !== "" ||
//             payload?.isTypeTelkomselOrbit !== "" ||
//             payload?.isTypeTelkomselPulsaTransfer !== "" ||
//             payload?.isTypeTelkomselUmum !== "" ||
//             payload?.isTypeTriAlwaysOn !== "" ||
//             payload?.isTypeTriGetMore !== "" ||
//             payload?.isTypeTriHappy !== "" ||
//             payload?.isTypeTriUmum !== "" ||
//             payload?.isTypeXLHotrod !== "" ||
//             payload?.isTypeXLXtraCombo !== "" ||
//             payload?.isTypeXLUmum !== "" ||
//             payload?.isTypeDataIndosatFreedomCombo !== "" ||
//             payload?.isTypeDataIndosatFreedomInternet !== "" ||
//             payload?.isTypeDataIndosatPulsaTransfer !== "" ||
//             payload?.isTypeDataIndosatUmum !== "" ||
//             payload?.isTypeDataSmartfrenIon !== "" ||
//             payload?.isTypeDataSmartfrenPulsaTransfer !== "" ||
//             payload?.isTypeDataSmartfrenUmum !== "" ||
//             payload?.isTypeDataSmartfrenUnlimited !== "" ||
//             payload?.isTypeDataSmartfrenUnlimitedNonStop !== "" ||
//             payload?.isTypeDataTelkomselFlash !== "" ||
//             payload?.isTypeDataTelkomselOmg !== "" ||
//             payload?.isTypeDataTelkomselOrbit !== "" ||
//             payload?.isTypeDataTelkomselPulsaTransfer !== "" ||
//             payload?.isTypeDataTelkomselUmum !== "" ||
//             payload?.isTypeDataTriAlwaysOn !== "" ||
//             payload?.isTypeDataTriGetMore !== "" ||
//             payload?.isTypeDataTriHappy !== "" ||
//             payload?.isTypeDataTriUmum !== "" ||
//             payload?.isTypeDataXLHotrod !== "" ||
//             payload?.isTypeDataXLXtraCombo !== "" ||
//             payload?.isTypeDataXLUmum !== ""
//               ? true
//               : false,
//           type:
//             payload?.isTypeGopayCustomer ||
//             payload?.isTypeOvoUmum ||
//             payload?.isTypeShopeePayUmum ||
//             payload?.isTypePlnUmum ||
//             payload?.isTypeAxisUmum ||
//             payload?.isTypeByuUmum ||
//             payload?.isTypeIndosatFreedomCombo ||
//             payload?.isTypeIndosatFreedomInternet ||
//             payload?.isTypeIndosatPulsaTransfer ||
//             payload?.isTypeIndosatUmum ||
//             payload?.isTypeSmartfrenIon ||
//             payload?.isTypeSmartfrenPulsaTransfer ||
//             payload?.isTypeSmartfrenUmum ||
//             payload?.isTypeSmartfrenUnlimited ||
//             payload?.isTypeSmartfrenUnlimitedNonStop ||
//             payload?.isTypeTelkomselFlash ||
//             payload?.isTypeTelkomselOmg ||
//             payload?.isTypeTelkomselOrbit ||
//             payload?.isTypeTelkomselPulsaTransfer ||
//             payload?.isTypeTelkomselUmum ||
//             payload?.isTypeTriAlwaysOn ||
//             payload?.isTypeTriGetMore ||
//             payload?.isTypeTriHappy ||
//             payload?.isTypeTriUmum ||
//             payload?.isTypeXLHotrod ||
//             payload?.isTypeXLXtraCombo ||
//             payload?.isTypeXLUmum ||
//             payload?.isTypeDataIndosatFreedomCombo ||
//             payload?.isTypeDataIndosatFreedomInternet ||
//             payload?.isTypeDataIndosatPulsaTransfer ||
//             payload?.isTypeDataIndosatUmum ||
//             payload?.isTypeDataSmartfrenIon ||
//             payload?.isTypeDataSmartfrenPulsaTransfer ||
//             payload?.isTypeDataSmartfrenUmum ||
//             payload?.isTypeDataSmartfrenUnlimited ||
//             payload?.isTypeDataSmartfrenUnlimitedNonStop ||
//             payload?.isTypeDataTelkomselFlash ||
//             payload?.isTypeDataTelkomselOmg ||
//             payload?.isTypeDataTelkomselOrbit ||
//             payload?.isTypeDataTelkomselPulsaTransfer ||
//             payload?.isTypeDataTelkomselUmum ||
//             payload?.isTypeDataTriAlwaysOn ||
//             payload?.isTypeDataTriGetMore ||
//             payload?.isTypeDataTriHappy ||
//             payload?.isTypeDataTriUmum ||
//             payload?.isTypeDataXLHotrod ||
//             payload?.isTypeDataXLXtraCombo ||
//             payload?.isTypeDataXLUmum,
//           set_seller_name: false,
//           seller_name: "",
//           set_is_available: false,
//           is_available: false,
//         },
//         limit: 10000,
//         page: 1,
//         order: "created_at",
//         sort: "DESC",
//       },
//       {
//         headers: {
//           language: payload?.language,
//           "nihao-token": payload?.token,
//         },
//       }
//     )
//     .then((res) => res?.data);

//Get List Pricelist Search
export const getPricelistSearch = ({ payload, language, token }) =>
  api
    .post(
      "/backoffice/biller/price/list",
      payload,
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get List Pricelist Type Search
export const getPricelistTypeSearch = ({ payload, limit, token }) =>
  api
    .post(
      "/backoffice/biller/price/list",
      {
        filter: {
          set_product_name: payload?.isProduct === true ? true : false,
          product_name: payload?.isProduct ? payload?.search : "",
          set_category: true,
          category: payload?.isCategory,
          set_brand: true,
          brand: payload?.isBrand,
          set_type:
            payload?.isTypeGopayCustomer !== "" ||
            payload?.isTypeOvoUmum !== "" ||
            payload?.isTypeShopeePayUmum !== "" ||
            payload?.isTypePlnUmum !== "" ||
            payload?.isTypeAxisUmum !== "" ||
            payload?.isTypeByuUmum !== "" ||
            payload?.isTypeIndosatFreedomCombo !== "" ||
            payload?.isTypeIndosatFreedomInternet !== "" ||
            payload?.isTypeIndosatPulsaTransfer !== "" ||
            payload?.isTypeIndosatUmum !== "" ||
            payload?.isTypeSmartfrenIon !== "" ||
            payload?.isTypeSmartfrenPulsaTransfer !== "" ||
            payload?.isTypeSmartfrenUmum !== "" ||
            payload?.isTypeSmartfrenUnlimited !== "" ||
            payload?.isTypeSmartfrenUnlimitedNonStop !== "" ||
            payload?.isTypeTelkomselFlash !== "" ||
            payload?.isTypeTelkomselOmg !== "" ||
            payload?.isTypeTelkomselOrbit !== "" ||
            payload?.isTypeTelkomselPulsaTransfer !== "" ||
            payload?.isTypeTelkomselUmum !== "" ||
            payload?.isTypeTriAlwaysOn !== "" ||
            payload?.isTypeTriGetMore !== "" ||
            payload?.isTypeTriHappy !== "" ||
            payload?.isTypeTriUmum !== "" ||
            payload?.isTypeXLHotrod !== "" ||
            payload?.isTypeXLXtraCombo !== "" ||
            payload?.isTypeXLUmum !== "" ||
            payload?.isTypeDataIndosatFreedomCombo !== "" ||
            payload?.isTypeDataIndosatFreedomInternet !== "" ||
            payload?.isTypeDataIndosatPulsaTransfer !== "" ||
            payload?.isTypeDataIndosatUmum !== "" ||
            payload?.isTypeDataSmartfrenIon !== "" ||
            payload?.isTypeDataSmartfrenPulsaTransfer !== "" ||
            payload?.isTypeDataSmartfrenUmum !== "" ||
            payload?.isTypeDataSmartfrenUnlimited !== "" ||
            payload?.isTypeDataSmartfrenUnlimitedNonStop !== "" ||
            payload?.isTypeDataTelkomselFlash !== "" ||
            payload?.isTypeDataTelkomselOmg !== "" ||
            payload?.isTypeDataTelkomselOrbit !== "" ||
            payload?.isTypeDataTelkomselPulsaTransfer !== "" ||
            payload?.isTypeDataTelkomselUmum !== "" ||
            payload?.isTypeDataTriAlwaysOn !== "" ||
            payload?.isTypeDataTriGetMore !== "" ||
            payload?.isTypeDataTriHappy !== "" ||
            payload?.isTypeDataTriUmum !== "" ||
            payload?.isTypeDataXLHotrod !== "" ||
            payload?.isTypeDataXLXtraCombo !== "" ||
            payload?.isTypeDataXLUmum !== ""
              ? true
              : false,
          type:
            payload?.isTypeGopayCustomer ||
            payload?.isTypeOvoUmum ||
            payload?.isTypeShopeePayUmum ||
            payload?.isTypePlnUmum ||
            payload?.isTypeAxisUmum ||
            payload?.isTypeByuUmum ||
            payload?.isTypeIndosatFreedomCombo ||
            payload?.isTypeIndosatFreedomInternet ||
            payload?.isTypeIndosatPulsaTransfer ||
            payload?.isTypeIndosatUmum ||
            payload?.isTypeSmartfrenIon ||
            payload?.isTypeSmartfrenPulsaTransfer ||
            payload?.isTypeSmartfrenUmum ||
            payload?.isTypeSmartfrenUnlimited ||
            payload?.isTypeSmartfrenUnlimitedNonStop ||
            payload?.isTypeTelkomselFlash ||
            payload?.isTypeTelkomselOmg ||
            payload?.isTypeTelkomselOrbit ||
            payload?.isTypeTelkomselPulsaTransfer ||
            payload?.isTypeTelkomselUmum ||
            payload?.isTypeTriAlwaysOn ||
            payload?.isTypeTriGetMore ||
            payload?.isTypeTriHappy ||
            payload?.isTypeTriUmum ||
            payload?.isTypeXLHotrod ||
            payload?.isTypeXLXtraCombo ||
            payload?.isTypeXLUmum ||
            payload?.isTypeDataIndosatFreedomCombo ||
            payload?.isTypeDataIndosatFreedomInternet ||
            payload?.isTypeDataIndosatPulsaTransfer ||
            payload?.isTypeDataIndosatUmum ||
            payload?.isTypeDataSmartfrenIon ||
            payload?.isTypeDataSmartfrenPulsaTransfer ||
            payload?.isTypeDataSmartfrenUmum ||
            payload?.isTypeDataSmartfrenUnlimited ||
            payload?.isTypeDataSmartfrenUnlimitedNonStop ||
            payload?.isTypeDataTelkomselFlash ||
            payload?.isTypeDataTelkomselOmg ||
            payload?.isTypeDataTelkomselOrbit ||
            payload?.isTypeDataTelkomselPulsaTransfer ||
            payload?.isTypeDataTelkomselUmum ||
            payload?.isTypeDataTriAlwaysOn ||
            payload?.isTypeDataTriGetMore ||
            payload?.isTypeDataTriHappy ||
            payload?.isTypeDataTriUmum ||
            payload?.isTypeDataXLHotrod ||
            payload?.isTypeDataXLXtraCombo ||
            payload?.isTypeDataXLUmum,
          set_seller_name: false,
          seller_name: "",
          set_is_available: false,
          is_available: false,
        },
        limit: 10000,
        page: 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get Category
export const getCategoryPpob = ({ page, limit, token, language }) =>
  api
    .get("/backoffice/biller/price/category", {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Brand
export const getBrand = ({ page, limit, token, language }) =>
  api
    .get("/backoffice/biller/price/brand", {
      params: { category: "Pulsa" },
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Brand by Search Params
export const getBrandSearch = ({ page, limit, token, language, keyword }) =>
  api
    .get("/backoffice/biller/price/brand", {
      params: { category: keyword },
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Types
export const getTypes = ({ page, limit, token, language }) =>
  api
    .get("/backoffice/biller/price/types", {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Types
export const getTypesSearch = ({ token, language, keyword, valueCategory }) =>
  api
    .get("/backoffice/biller/price/types", {
      params: { brand: keyword, category: valueCategory },
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

export const getTypesSearch2 = ({ token, language, keyword, valueBrand }) =>
  api
    .get("/backoffice/biller/price/types", {
      params: { brand: valueBrand, category: keyword },
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Types Search
export const getTypesParentSearch = ({
  token,
  language,
  valueBrand,
  valueCategory,
}) =>
  api
    .get("/backoffice/biller/price/types", {
      params: { brand: valueBrand, category: valueCategory },
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update Sell Price
export const updateSellPrice = ({ newPayload, token, language }) =>
  api
    .put("/backoffice/biller/price/update", newPayload, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update Product Name
export const updateProductName = ({ payloadPublish, token, language }) =>
  api
    .put("/backoffice/biller/price/product_name", payloadPublish?.[0], {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Price List By ID
export const getPricelistId = ({ guid, token, language }) =>
  api
    .get(`/backoffice/biller/price/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Get Sync Price List
export const getSyncPriceList = ({ token, language }) =>
  api
    .get(`/backoffice/biller/price/sync`, {
      headers: {
        language: "EN",
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);
