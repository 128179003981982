import { api } from "../../../../../utils/axios";

//Create Advertising
export const setAdvertisingBanner = (payload, token) =>
  api
    .post("/backoffice/advertising-banner", payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Get List Advertising Banner
export const getAdvertisingsBanner = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/advertising-banner/list",
      {
        filter: {
          set_title: false,
          title: "",
          set_status: false,
          status: 0,
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Advertising Banner
export const getAdvertisingBanner = ({ guid, token, language }) =>
  api
    .get(`/backoffice/advertising-banner/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Get Search Advertising Banner
export const getAdvertisingBannerSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/advertising-banner/list",
      {
        filter: {
          set_title: payload?.isTitle === true ? true : false,
          title: payload?.isTitle ? payload?.search : "",
          set_status: false,
          status: 0,
          set_is_active: payload?.isStatus === true ? true : false,
          is_active: payload?.isStatus ? payload?.searchStatus : false,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Delete Advertising Banner
export const deleteAdvertisingBanner = ({ guid, token, language }) =>
  api
    .delete(`/backoffice/advertising-banner/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Update Advertising Banner
export const updateAdvertisingBanner = (payload, token) =>
  api
    .put("/backoffice/advertising-banner", payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Update Toggle Banner
export const updateToggleAdvertisingBanner = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/advertising-banner/is-active/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
