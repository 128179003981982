import React, { lazy, Suspense } from "react";
// // import DownloadDialog from "./components/Dialog/DownloadDialog";
// // import DownloadInfoDialog from "./components/Dialog/DownloadInfoDialog";
// import Filter from "./components/Filter";
// import HeaderSaldo from "./components/HeaderSaldo";
// import ModalTransactionDetail from "./components/Modal";
// import TableConfirmationApproval from "./components/TableConfirmationApproval";
import ConfirmationApprovalController from "./Controller";
// import ApproveConfirmationDialog from "./components/Dialog/ApproveConfirmationDialog";
// import ApproveInformationDialog from "./components/Dialog/ApproveInformationDialog";
// import RejectConfirmationDialog from "./components/Dialog/RejectConfirmationDialog";
// import RejectInformationDialog from "./components/Dialog/RejectInformationDialog";

const ConfirmationApproval = () => {
  const Filter = lazy(() => import("./components/Filter"));
  const HeaderSaldo = lazy(() => import("./components/HeaderSaldo"));
  const ModalTransactionDetail = lazy(() => import("./components/Modal"));
  const TableConfirmationApproval = lazy(() =>
    import("./components/TableConfirmationApproval")
  );
  const ApproveConfirmationDialog = lazy(() =>
    import("./components/Dialog/ApproveConfirmationDialog")
  );
  const ApproveInformationDialog = lazy(() =>
    import("./components/Dialog/ApproveInformationDialog")
  );
  const RejectConfirmationDialog = lazy(() =>
    import("./components/Dialog/RejectConfirmationDialog")
  );
  const RejectInformationDialog = lazy(() =>
    import("./components/Dialog/RejectInformationDialog")
  );

  return (
    <div>
      <ConfirmationApprovalController>
        <Suspense
          fallback={
            <div class="h-screen flex items-center">
              <div class="mx-auto">
                <div
                  class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex gap-4">
            {/* <ButtonNew /> */}
            <HeaderSaldo />
          </div>
          {/* <div className="pt-4 pb-4">
            <div className="flex gap-4">
              <div className="w-full"> */}
                {/* <div className="bg-[#504178] text-white p-2 pl-3 leading-[18px] font-[400] text-[12px] w-full h-[61px] rounded-[10px]"> */}
                {/* <HeaderInfo /> */}
                {/* </div> */}
              {/* </div>
            </div>
          </div> */}
          {/* <div className="pt-4 pb-4">
        <Category />
      </div> */}

          <div cla ssName="border border-gray-300 p-4 rounded-xl">
            {/* <div className="pt-2">
          <Brand />
          </div>
          <div className="border border-t-white border-x-white border-b-gray-300 pt-4"></div>
          <div className="pt-4">
          <Types />
        </div> */}
            <div>
              <div className="overflow-auto h-screen">
                <div className="py-4">
                  <Filter />
                </div>
                <TableConfirmationApproval />
              </div>
            </div>
          </div>
          {/* <ModalTransactionDetail />
      <DownloadDialog/>
    <DownloadInfoDialog/> */}

          <ApproveConfirmationDialog />
          <ApproveInformationDialog />
          <RejectConfirmationDialog />
          <RejectInformationDialog />
        </Suspense>
      </ConfirmationApprovalController>
    </div>
  );
};

export default ConfirmationApproval;
