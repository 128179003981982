import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { Button } from "@chakra-ui/react";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { deleteRole, getRole, getRoles, getRoleSearch } from "./api/RoleApi";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/public/AuthController";

export const UserAdminRoleContext = createContext();
const { Provider } = UserAdminRoleContext;

const UserAdminRoleController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);

  const {
    data: userAdminRoleData,
    isLoading: isLoadingUserAdminRoleData,
    refetch: refetchAdminRoleData,
  } = useQuery(["roles", token, language], () => getRoles({ token, language }));

  const { isLoading: isLoadingGetRoles, mutate: mutateGetRoles } =
    useMutation(getRoles);

  const { isLoading: isLoadingDeleteRole, mutate: mutateDeleteRole } =
    useMutation(deleteRole);

  const { mutate: mutateGetRole } = useMutation(getRole);

  const { isLoading: isLoadingGetRoleSearch, mutate: mutateGetRoleSearch } =
    useMutation(getRoleSearch);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalFormResetOpen, setIsModalFormResetOpen] = useState(false);
  const [isVisibleDeleteUserRole, setIsVisibleDeleteUserRole] = useState(false);
  const [isVisibleDeleteInfoUserRole, setIsVisibleDeleteInfoUserRole] =
    useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [guid, setGuid] = useState();

  const [newUserAdminRoleSearch, setNewUserAdminRoleSearch] = useState();

  const [newUserAdminRoleData, setNewUserAdminRoleData] = useState();
  const [userRoleId, setUserRoleId] = useState();
  const [access, setAccess] = useState([]);

  let newRoleData = [];

  const [isGetAccessAdminRole, setIsGetAccessAdminRole] = useState();
  const [isDeleteAccessAdminRole, setIsDeleteAccessAdminRole] = useState();
  const [isUpdateAccessAdminRole, setIsUpdateAccessAdminRole] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRoleAccess } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRoleAccess(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //AdminRole
          const getAccessAdminRole = data?.data?.access
            ?.filter((data) => data?.page === "role")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessAdminRole(getAccessAdminRole?.length);

          const deleteAccessAdminRole = data?.data?.access
            ?.filter((data) => data?.page === "role")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessAdminRole(deleteAccessAdminRole?.length);

          const updateAccessAdminRole = data?.data?.access
            ?.filter((data) => data?.page === "role")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessAdminRole(updateAccessAdminRole?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Modal
  // -------------------------------
  const formRoleModal = useForm();
  const resetformRoleModal = (newForm) => {
    if (newForm) {
      formRoleModal.reset({
        page: newForm?.page,
        key: newForm?.key,
        name: newForm?.name,
        // level: newForm?.level,
        access: newForm?.access,
        is_all_access: newForm?.is_all_access,
      });
    }
  };

  // -------------------------------
  // Form Search Filter
  // -------------------------------
  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
      });
    }
  };

  const handleChangeAccess = (payload) => {
    setAccess(payload);
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsView(isView);
    setGuid(guid);
    isEdit &&
      mutateGetRole(
        { guid, token, language },
        {
          onSuccess: (data) => {
            resetformRoleModal(data?.data);
          },
        }
      );
    isCreate && resetformRoleModal({});
  };

  const handleDialogDeleteUserRole = (isOpen, guid) => {
    setIsVisibleDeleteUserRole(isOpen);
    setUserRoleId(guid);
    setIsVisibleDeleteInfoUserRole(false);
  };

  const handleDeleteUserRole = (guid) => {
    mutateDeleteRole(
      { guid, token, language },
      {
        onSuccess: () => {
          refetchAdminRoleData();
          setIsVisibleDeleteUserRole(false);
          setIsVisibleDeleteInfoUserRole(true);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isName = keyword?.keyword_filter === "1" && true;

    let isName =
      keyword?.keyword_filter?.value === "1" ||
      (keyword?.keyword_filter === "1" && true);
    let searchKeyword = !keyword?.value?.keyword_search
      ? ""
      : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-user-role", searchKeyword);
    } else {
      localStorage.removeItem("search-user-role");
    }

    setIsSearch(true);

    let payload = {
      isName: isName,
      search: searchKeyword,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    mutateGetRoleSearch(
      { payload },
      {
        onSuccess: (data) => {
          newRoleData = [];

          data?.data?.map((value) => {
            setNewUserAdminRoleSearch(data);
            newRoleData.push({
              username: value?.name,
              jabatan: value?.name,
              level:
                value?.access?.map(
                  (newData) => newData?.page + " " + newData?.access + " || "
                ) || "All Access",
              action: (
                <div className="flex items-center justify-center gap-2">
                  {/* {(isGetAccessAdminRole === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id, true)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  {/* )} */}
                  {/* <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF6627]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleModalFormOpen(false, true, true, value?.id)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Edit
                      </div>
                    </Button> */}
                  {/* {(isDeleteAccessAdminRole === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleDialogDeleteUserRole(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewUserAdminRoleData(newRoleData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    mutateGetRoles(
      { limit, token },
      {
        onSuccess: (data) => {
          newRoleData = [];

          data?.data?.map((value) => {
            newRoleData.push({
              username: value?.name,
              jabatan: value?.name,
              level:
                value?.access?.map(
                  (newData) => newData?.page + " " + newData?.access + " || "
                ) || "All Access",
              action: (
                <div className="flex items-center justify-center gap-2">
                  {/* {(isGetAccessAdminRole === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id, true)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  {/* )} */}
                  {/* <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF6627]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleModalFormOpen(false, true, true, value?.id)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Edit
                      </div>
                    </Button> */}
                  {/* {(isDeleteAccessAdminRole === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleDialogDeleteUserRole(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewUserAdminRoleData(newRoleData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    mutateGetRoles(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newRoleData = [];

          data?.data?.map((value) => {
            newRoleData.push({
              username: value?.name,
              jabatan: value?.name,
              level:
                value?.access?.map(
                  (newData) => newData?.page + " " + newData?.access + " || "
                ) || "All Access",
              action: (
                <div className="flex items-center justify-center gap-2">
                  {/* {(isGetAccessAdminRole === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.id, true)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  {/* )} */}
                  {/* <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF6627]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleModalFormOpen(false, true, true, value?.id)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Edit
                      </div>
                    </Button> */}
                  {/* {(isDeleteAccessAdminRole === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleDialogDeleteUserRole(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewUserAdminRoleData(newRoleData);
        },
      }
    );
  };

  useEffect(() => {
    newRoleData = [];

    userAdminRoleData?.data?.map((value) => {
      newRoleData.push({
        username: value?.name,
        jabatan: value?.name,
        level:
          value?.access?.map(
            (newData) => newData?.page + " " + newData?.access + " || "
          ) || "All Access",
        action: (
          <div className="flex items-center justify-center gap-2">
            {/* {(isGetAccessAdminRole === 1 || isViewAllAccess === true) && ( */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleModalFormOpen(false, true, true, value?.id, true)
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.VIEW")}
              </div>
            </Button>
            {/* )} */}
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF6627]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleModalFormOpen(false, true, true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Edit
              </div>
            </Button> */}
            {/* {(isDeleteAccessAdminRole === 1 || isViewAllAccess === true) && ( */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF0F0F]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleDialogDeleteUserRole(true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.DELETE")}
              </div>
            </Button>
            {/* )} */}
          </div>
        ),
      });
    });
    setNewUserAdminRoleData(newRoleData);
  }, [
    userAdminRoleData,
    token,
    isGetAccessAdminRole,
    isDeleteAccessAdminRole,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formRoleModal,
        resetformRoleModal,

        isModalFormOpen,
        isModalFormResetOpen,
        isVisibleDeleteUserRole,
        isVisibleDeleteInfoUserRole,
        isEdit,
        setIsEdit,
        isView,
        setIsView,
        guid,
        setGuid,

        userAdminRoleData,
        userRoleId,
        newUserAdminRoleData,

        isLoadingUserAdminRoleData,
        isLoadingGetRoles,
        isLoadingDeleteRole,

        access,
        handleChangeAccess,
        handleModalFormOpen,
        handleDialogDeleteUserRole,
        handleDeleteUserRole,
        handlePaginationTable,
        handleShowEntriesTable,

        refetchAdminRoleData,
        handleSearch,

        isGetAccessAdminRole,
        isDeleteAccessAdminRole,
        isUpdateAccessAdminRole,
        isViewAllAccess,

        isSearch,
        newUserAdminRoleSearch,
        formKeywordSearch,
      }}
    >
      {children}
    </Provider>
  );
};

export default UserAdminRoleController;
