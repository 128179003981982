// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
import id from "../i18n/id.json";
import gb from "../i18n/gb.json";
import cn from "../i18n/cn.json";

// i18n.use(initReactI18next).init({
//   resources: {
//     gb: {
//       translation: gb,
//     },
//     id: {
//       translation: id,
//     },
//     cn: {
//       translation: cn,
//     },
//   },
//   fallbackLng: "gb",
// });

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  getCnLanguage,
  getEnLanguage,
  getIdLanguage,
} from "../controllers/public/api/i18nApi";

// const gb = { LANGUAGE: "EN" };
// const id = { LANGUAGE: "ID" };
// const cn = { LANGUAGE: "CN" };

getEnLanguage().then((data) =>
  data?.data?.forEach((row) => (gb[row?.constant] = row?.value))
);
getIdLanguage().then((data) =>
  data?.data?.forEach((row) => (id[row?.constant] = row?.value))
);
getCnLanguage().then((data) =>
  data?.data?.forEach((row) => (cn[row?.constant] = row?.value))
);

i18n.use(initReactI18next).init({
  resources: {
    gb: {
      translation: gb,
    },
    id: {
      translation: id,
    },
    cn: {
      translation: cn,
    },
  },
  fallbackLng: "gb",
});
