import { api } from "../../../../../../utils/axios";

//Create FAQ
export const setFaq = (payload, token) =>
  api
    .post("/backoffice/faq", payload?.payloadPublish?.[0], {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Get List FAQ
export const getFaqList = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/faq/list",
      {
        filter: {
          set_question: false,
          question: "",
          set_answer: false,
          answer: "",
        },
        order: "order_number",
        sort: "ASC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
          "is-all-language": 0,
        },
      }
    )
    .then((res) => res?.data);

//Get FAQ
export const getFaq = ({ guid, token, lang }) =>
  api
    .get(`/backoffice/faq/${guid}`, {
      headers: {
        language: lang,
        "nihao-token": token,
        "is-all-language": 1,
      },
    })
    .then((res) => res?.data);

//Delete FAQ
export const deleteFaq = ({ guid, token, lang }) =>
  api
    .delete(`/backoffice/faq/${guid}`, {
      headers: {
        language: lang,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

// Update Home Section
export const updateFaq = (payload, token) =>
  api
    .put(
      `/backoffice/faq/${payload?.payloadPublish?.[0]?.id}`,
      payload?.payloadPublish?.[0],
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
          "is-all-language": 0,
        },
      }
    )
    .then((res) => res?.data);

//Update Order Faq
export const updateOrderFaq = (payload, token) =>
  api
    .put("/backoffice/faq/order-number", payload?.payloadOrder, {
      headers: {
        language: payload?.language,
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

// //Set Home Section Active
// export const setHomeSectionActive = ({ guid, token }) =>
//   api
//     .put(
//       `/backoffice/home-section/is-active/${guid}`,
//       {},
//       {
//         headers: {
//           language: "EN",
//           "nihao-token": token,
//         },
//       }
//     )
//     .then((res) => res?.data);

// //Create Insert Ads Home Section
// export const setInsertAdsHomeSection = ({
//   selectedHomeSectionId,
//   advertisingId,
//   token,
//   lang,
// }) =>
//   api
//     .post(
//       "/backoffice/home-section/advertising",
//       {
//         home_section_id: selectedHomeSectionId,
//         advertising_id: advertisingId,
//       },
//       {
//         headers: {
//           language: lang,
//           "nihao-token": token,
//         },
//       }
//     )
//     .then((res) => res?.data);

// //Get Search Insert Ads Advertising List
// export const getInsertAdsAdvertisingSearch = ({ payload }) =>
//   api
//     .post(
//       "/backoffice/advertising/list",
//       {
//         filter: {
//           set_title: payload?.isTitle === true ? true : false,
//           title: payload?.isTitle ? payload?.search : "",
//         },
//         limit: 1000,
//         page: 1,
//         order: "created_at",
//         sort: "DESC",
//       },
//       {
//         headers: {
//           language: payload?.lang,
//           "nihao-token": payload?.token,
//         },
//       }
//     )
//     .then((res) => res?.data);

//Get Search Faq
export const getFaqSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/faq/list",
      {
        filter: {
          set_question: payload?.isQuestion === true ? true : false,
          question: payload?.isQuestion ? payload?.search : "",
          set_answer: payload?.isAnswer === true ? true : false,
          answer: payload?.isAnswer ? payload?.search : "",
        },
        order: "order_number",
        sort: "ASC",
      },
      {
        headers: {
          language: payload?.lang,
          "nihao-token": payload?.token,
          "is-all-language": 0,
        },
      }
    )
    .then((res) => res?.data);
