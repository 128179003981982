import { api } from "../../../utils/axios";

export const getEnLanguage = () =>
  api
    .post(
      "/backoffice/mapping-language-admin/list",
      {
        set_constant: false,
        constant: "",
      },
      {
        headers: {
          language: "EN",
          "is-all-language": 0,
        },
      }
    )
    .then((res) => res?.data);

export const getIdLanguage = () =>
  api
    .post(
      "/backoffice/mapping-language-admin/list",
      {
        set_constant: false,
        constant: "",
      },
      {
        headers: {
          language: "ID",
          "is-all-language": 0,
        },
      }
    )
    .then((res) => res?.data);

export const getCnLanguage = () =>
  api
    .post(
      "/backoffice/mapping-language-admin/list",
      {
        set_constant: false,
        constant: "",
      },
      {
        headers: {
          language: "CN",
          "is-all-language": 0,
        },
      }
    )
    .then((res) => res?.data);
