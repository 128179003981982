import { api } from "../../../utils/axios";

export const setUploadImageWebsite = (formData) =>
  api
    .post("/backoffice/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        language: "EN",
        "nihao-token": formData.get("token"),
      },
    })
    .then((res) => res?.data);

export const setUploadImageMobile = (formData) =>
  api
    .post("/backoffice/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        language: "EN",
        "nihao-token": formData.get("token"),
      },
    })
    .then((res) => res?.data);

export const setUploadBanner = (formData) =>
  api
    .post("/backoffice/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        language: "EN",
        "nihao-token": formData.get("token"),
      },
    })
    .then((res) => res?.data);

export const setUploadHomeSection = (formData) =>
  api
    .post("/backoffice/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        language: "EN",
        "nihao-token": formData.get("token"),
      },
    })
    .then((res) => res?.data);

export const setUploadProfilePicture = (formData) =>
  api
    .post("/backoffice/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        language: "EN",
        "nihao-token": formData.get("token"),
      },
    })
    .then((res) => res?.data);

export const setUploadQrCodeAlipay = (formData) =>
  api
    .post("/backoffice/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        language: "EN",
        "nihao-token": formData.get("token"),
      },
    })
    .then((res) => res?.data);

export const setUploadQrCodeWechat = (formData) =>
  api
    .post("/backoffice/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        language: "EN",
        "nihao-token": formData.get("token"),
      },
    })
    .then((res) => res?.data);

export const setUploadLogoUrl = (formData) =>
  api
    .post("/backoffice/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        language: "EN",
        "nihao-token": formData.get("token"),
      },
    })
    .then((res) => res?.data);
