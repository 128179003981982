import { api } from "../../../../../../utils/axios";

//Get List Role
export const getRoles = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/user-backoffice/role/list",
      {
        filter: {
          set_name: false,
          name: "",
          set_level: false,
          level: 0,
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Role By ID
export const getRole = ({ guid, token, language }) =>
  api
    .get(`/backoffice/user-backoffice/role/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Set Role Edit
export const setRoleEdit = (payload, guid, token) =>
  api
    .put(
      `/backoffice/user-backoffice/role/${payload?.guid}`,
      payload?.newPayloadAccess,
      {
        headers: {
          language: "EN",
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Create Role
export const setRole = (payload, token) =>
  api
    .post("/backoffice/user-backoffice/role", payload?.isAllAccessPayload, {
      headers: {
        language: "EN",
        "nihao-token": payload?.token,
      },
    })
    .then((res) => res?.data);

//Delete Role
export const deleteRole = ({ guid, token, language }) =>
  api
    .delete(`/backoffice/user-backoffice/role/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Search Role
export const getRoleSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/user-backoffice/role/list",
      {
        filter: {
          set_name: payload?.isName === true ? true : false,
          name: payload?.isName ? payload?.search : "",
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get List Access Role by Login
export const getListAccessRole = ({ token, language }) =>
  api
    .get(`/backoffice/user-backoffice/role/list-access`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get List Role sidebar
export const getRolesSidebar = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/user-backoffice/role/list",
      {
        filter: {
          set_name: true,
          name: "superadmin2",
          set_level: false,
          level: 0,
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
