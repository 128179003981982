import { api } from "../../../../../../utils/axios";

//Get Confirmation Approval List
export const getConfirmationApproval = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/transaction-confirmation/list",
      {
        filter: {
          set_payment_method: false,
          payment_method: "",
          set_payment_channel_guid: false,
          payment_channel_guid: "",
          set_payment_channel_bank_code: false,
          payment_channel_bank_code: "",
          set_payment_channel_bank_name: false,
          payment_channel_bank_name: "",
          set_payment_channel_account_number: false,
          payment_channel_account_number: "",
          set_payment_channel_account_name: false,
          payment_channel_account_name: "",
          set_transaction_type: false,
          transaction_type: "",
          set_message: false,
          message: "",
          set_status: true,
          status: "waiting approve payment",
        },
        limit: limit || 10,
        page: page || 1,
        is_all_data: false,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Confirmation Approval Search
export const getConfirmationApprovalSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/transaction-confirmation/list",
      {
        filter: {
          set_payment_method: payload?.isPaymentMethod === true ? true : false,
          payment_method: payload?.isPaymentMethod ? payload?.search : "",
          set_payment_channel_guid:
            payload?.isPaymentChannelGuid === true ? true : false,
          payment_channel_guid: payload?.isPaymentChannelGuid
            ? payload?.search
            : "",
          set_payment_channel_bank_code:
            payload?.isPaymentChannelBankCode === true ? true : false,
          payment_channel_bank_code: payload?.isPaymentChannelBankCode
            ? payload?.search
            : "",
          set_payment_channel_bank_name:
            payload?.isPaymentChannelBankName === true ? true : false,
          payment_channel_bank_name: payload?.isPaymentChannelBankName
            ? payload?.search
            : "",
          set_payment_channel_account_number:
            payload?.isPaymentChannelAccountNumber === true ? true : false,
          payment_channel_account_number: payload?.isPaymentChannelAccountNumber
            ? payload?.search
            : "",
          set_payment_channel_account_name:
            payload?.isPaymentChannelAccountName === true ? true : false,
          payment_channel_account_name: payload?.isPaymentChannelAccountName
            ? payload?.search
            : "",
          set_transaction_type:
            payload?.isTransactionType === true ? true : false,
          transaction_type: payload?.isTransactionType ? payload?.search : "",
          set_message: payload?.isMessage === true ? true : false,
          message: payload?.isMessage ? payload?.search : "",
          set_status: true,
          status: "waiting approve payment",
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        is_all_data: false,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get Transaction
export const getTransaction = ({ guid, token, language }) =>
  api
    .get(
      `/backoffice/transaction-confirmation/${guid}`,      
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Approve Transaction
export const approveTransaction = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/transaction-confirmation/approve/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Reject Transaction
export const rejectTransaction = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/transaction-confirmation/reject/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
