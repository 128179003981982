import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cookiesVars } from "../../utils/cookies";
import { privateSlug } from "../../utils/privateSlug";
import { AuthContext } from "./AuthController";

const PublicAuth = () => {
  const navigate = useNavigate();
  const { isLogin , userData } = useContext(AuthContext);
  const _islogin = Cookies.get(cookiesVars._islogin);

  useEffect(() => {
    if (isLogin === true && _islogin ) {
      navigate(privateSlug.DASHBOARD);
    }
  }, [isLogin, _islogin, userData]);

  return <></>;
};

export default PublicAuth;
