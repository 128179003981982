import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import {
  deleteAdvertisingRow,
  getAdvertisingRow,
  getAdvertisingsRow,
  getAdvertisingsRowSearch,
  // setAdvertisingRow,
  updatePublishAdvertisingRow,
} from "./api/AdvertisingRowApi";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";

export const ClassifiedAdsContext = createContext();
const { Provider } = ClassifiedAdsContext;

const ClassifiedAdsController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  // const toast = useToast();

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);

  const {
    data: AdvertisingRowData,
    isLoading: isLoadingAdvertisingRowData,
    refetch: refetchAdvertisingRowData,
  } = useQuery(["classifiedads", token, language], () =>
    getAdvertisingsRow({ token, language })
  );

  const { mutate: mutateGetAdvertisingsRow } = useMutation(getAdvertisingsRow);
  const { mutate: mutateGetAdvertisingRow } = useMutation(getAdvertisingRow);

  const {
    isLoading: isLoadingSetAdvertisingRowSearch,
    mutate: mutateSetAdvertisingRowSearch,
  } = useMutation(getAdvertisingsRowSearch);

  const {
    isLoading: isLoadingDeleteAdvertisingRow,
    mutate: mutateDeleteAdvertisingRow,
  } = useMutation(deleteAdvertisingRow);

  const {
    isLoading: isLoadingPublishAdvertisingRow,
    mutate: mutatePublishAdvertisingRow,
  } = useMutation(updatePublishAdvertisingRow);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [isVisibleClassifiedDialog, setIsVisibleClassifiedDialog] =
    useState(false);
  const [
    isVisibleDeleteAdvertisingClassified,
    setIsVisibleDeleteAdvertisingClassified,
  ] = useState(false);
  const [
    isVisibleDeleteInfoAdvertisingClassified,
    setIsVisibleDeleteInfoAdvertisingClassified,
  ] = useState(false);

  const [
    isVisiblePublishAdvertisingClassified,
    setIsVisiblePublishAdvertisingClassified,
  ] = useState(false);
  const [
    isVisiblePublishInfoAdvertisingClassified,
    setIsVisiblePublishInfoAdvertisingClassified,
  ] = useState(false);

  const [isPublishClassifiedAdsOpen, setIsPublishClassifiedAdsOpen] =
    useState(false);
  const [newAdvertisingRow, setNewAdvertisingRow] = useState();
  const [newAdvertisingRowSearch, setNewAdvertisingRowSearch] =
  useState();

  const [advertisingRowId, setAdvertisingRowId] = useState();
  const [payloadClassifiedAdsEdit, setPayloadClassifiedAdsEdit] = useState();
  const [payloadPublishClassifiedAdsEdit, setPublishPayloadClassifiedAdsEdit] =
    useState();
  const [indexTabActive, setIndexTabActive] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isShowDraft, setIsShowDraft] = useState(false);
  let newAdvertisingRowData = [];

  const [isGetAccessPengumuman, setIsGetAccessPengumuman] = useState();
  const [isDeleteAccessPengumuman, setIsDeleteAccessPengumuman] = useState();
  const [isUpdateAccessPengumuman, setIsUpdateAccessPengumuman] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Pengumuman
          const getAccessPengumuman = data?.data?.access
            ?.filter((data) => data?.page === "classified")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessPengumuman(getAccessPengumuman?.length);

          const deleteAccessPengumuman = data?.data?.access
            ?.filter((data) => data?.page === "classified")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessPengumuman(deleteAccessPengumuman?.length);

          const updateAccessPengumuman = data?.data?.access
            ?.filter((data) => data?.page === "classified")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessPengumuman(updateAccessPengumuman?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadClassifiedAdsEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && !isEdit) {
      formEn.reset({
        date: defaultValue,
        title: newForm?.title?.en,
        description: newForm?.description?.en,
      });
    }

    if (newForm && isEdit) {
      formEn.reset({
        date: dateEdit,
        title: payloadClassifiedAdsEdit?.title?.en,
        description: payloadClassifiedAdsEdit?.description?.en,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, [isEdit, payloadClassifiedAdsEdit]);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadClassifiedAdsEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && !isEdit) {
      formId.reset({
        date: defaultValue,
        title: newForm?.title?.id,
        description: newForm?.description?.id,
      });
    }

    if (newForm && isEdit) {
      formId.reset({
        date: dateEdit,
        title: payloadClassifiedAdsEdit?.title?.id,
        description: payloadClassifiedAdsEdit?.description?.id,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, [isEdit, payloadClassifiedAdsEdit]);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadClassifiedAdsEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && !isEdit) {
      formCn.reset({
        date: defaultValue,
        title: newForm?.title?.cn,
        description: newForm?.description?.cn,
      });
    }

    if (newForm && isEdit) {
      formCn.reset({
        date: dateEdit,
        title: payloadClassifiedAdsEdit?.title?.cn,
        description: payloadClassifiedAdsEdit?.description?.cn,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, [isEdit, payloadClassifiedAdsEdit]);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewEn.reset({
        date: defaultValue,
        title: newForm?.en?.title?.[0]?.en,
        description: newForm?.en?.description?.[0]?.en,
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewId.reset({
        date: defaultValue,
        title: newForm?.id?.title?.[0]?.id,
        description: newForm?.id?.description?.[0]?.id,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewCn.reset({
        date: defaultValue,
        title: newForm?.cn?.title?.[0]?.cn,
        description: newForm?.cn?.description?.[0]?.cn,
      });
    }
  };

  // -------------------------------
  // Form Publish
  // -------------------------------
  const formPublish = useForm();
  const resetFormPublish = (newForm, isPublished) => {
    const dateFrom = new Date();
    const dateTo = new Date();
    const publishFrom = dateFrom.getDate() + 1;
    const publishTo = dateTo.getDate() + 2;
    dateFrom.setDate(publishFrom);
    dateTo.setDate(publishTo);
    const defaultDatePublishFrom = moment(dateFrom).format("YYYY-MM-DDTHH:mm");
    const defaultDatePublishTo = moment(dateTo).format("YYYY-MM-DDTHH:mm");

    const publishFromEdit = moment(newForm?.publish_from).format(
      "YYYY-MM-DDTHH:mm"
    );
    const publishToEdit = moment(newForm?.publish_to).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm?.publish_from === null && isPublished) {
      formPublish.reset({
        publish_from: defaultDatePublishFrom,
        publish_to: defaultDatePublishTo,
        status: 1,
      });
    }

    if (newForm && isPublished) {
      // console.log(newForm);
      formPublish.reset({
        publish_from: publishFromEdit,
        publish_to: publishToEdit,
        status: 1,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
        keyword_draft: newForm?.keyword_draft,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    setIndexTabActive(0);
    isOpen === false && handlePublishClassifiedAdsOpen(false);

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});
    }

    isEdit &&
      mutateGetAdvertisingRow(
        { guid, token, language },
        {
          onSuccess: (data) => {
            setPayloadClassifiedAdsEdit(data?.data);
          },
        }
      );
  };

  const handlePublishClassifiedAdsOpen = (isOpen) => {
    setIsPublishClassifiedAdsOpen(isOpen);
    // resetFormPublish({});
  };

  const handleDialogDeleteAdvertisingClassified = (isOpen, guid) => {
    setIsVisibleDeleteAdvertisingClassified(isOpen);
    setAdvertisingRowId(guid);
    setIsVisibleDeleteInfoAdvertisingClassified(false);
  };

  const handleDeleteAdvertisingClassified = (guid) => {
    mutateDeleteAdvertisingRow(
      { guid, token, language },
      {
        onSuccess: () => {
          refetchAdvertisingRowData();
          setIsVisibleDeleteAdvertisingClassified(false);
          setIsVisibleDeleteInfoAdvertisingClassified(true);
        },
      }
    );
  };

  const handleDialogPublishAdvertisingClassified = (
    isOpen,
    guid,
    isPublish
  ) => {
    // console.log(isPublish)
    setIsVisiblePublishAdvertisingClassified(isOpen);
    setAdvertisingRowId(guid);
    setIsPublish(isPublish);
    setIsVisiblePublishInfoAdvertisingClassified(false);

    mutateGetAdvertisingRow(
      { guid, token, language },
      {
        onSuccess: (data) => {
          resetFormPublish(data?.data, isPublish);
          setPublishPayloadClassifiedAdsEdit(data?.data);
        },
      }
    );
  };

  const handlePublishAdvertisingClassified = (payload) => {
    // const dateFrom = new Date();
    // const dateTo = new Date();
    // const publishFrom = dateFrom.getDate() + 1;
    // const publishTo = dateTo.getDate() + 2;
    // dateFrom.setDate(publishFrom);
    // dateTo.setDate(publishTo);
    const defaultDatePublishFrom = moment(payload?.publish_from).format();
    const defaultDatePublishTo = moment(payload?.publish_to).format();

    const payloadPublish = {
      publish_from: defaultDatePublishFrom,
      publish_to: defaultDatePublishTo,
      status: payload?.status,
    };

    const guid = advertisingRowId;

    // console.log(payloadPublish);
    mutatePublishAdvertisingRow(
      { guid, payloadPublish, token, language },
      {
        onSuccess: () => {
          refetchAdvertisingRowData();
          setIsVisiblePublishAdvertisingClassified(false);
          setIsVisiblePublishInfoAdvertisingClassified(true);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isTitle = keyword?.keyword_filter === "1" && true;
    // let isStatus = keyword?.keyword_filter === "2" && true;
    // let isDraft = keyword?.keyword_filter === "3" && true;

    let isTitle        = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isStatus       = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isDraft        = keyword?.keyword_filter?.value === "3" || (keyword?.keyword_filter === "3" && true);
    let isSearchStatus = keyword?.keyword_status === "1" ? 1 : 0;
    let isSearchDraft  = keyword?.keyword_draft === "0" ? false : true
    let searchKeyword  = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    setIsShowStatus(isStatus);
    setIsShowDraft(isDraft);
    setIsSearch(true);

    let payload = {
      isTitle: isTitle,
      isStatus: isStatus,
      isDraft: isDraft,
      search: searchKeyword,
      searchStatus: isSearchStatus,
      searchDraft: isSearchDraft,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    mutateSetAdvertisingRowSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setNewAdvertisingRowSearch(data)
            newAdvertisingRowData.push({
              title: value?.title,
              description: value?.description,
              draft:
                value?.is_draft === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              status:
                value?.status === "Accepted" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACCEPTED")}
                  </div>
                ) : value?.status === "Waiting Approval" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.WAITING_APPROVAL")}
                  </div>
                ) : value?.status === "Draft" ? (
                  <div className="bg-[#fdba74] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.DRAFT")}
                  </div>
                ) : (
                  ""
                ),
              action: (
                <div className="flex  items-center justify-center gap-2">
                  {!value?.is_draft && (
                    <Button
                      size="md"
                      colorScheme="teal"
                      // className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogPublishAdvertisingClassified(
                          true,
                          value?.id,
                          true
                        )
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.PUBLISH")}
                      </div>
                    </Button>
                  )}
                  {/* {(isGetAccessPengumuman === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(false, true, true, value?.id, true)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF6627]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleModalFormOpen(false, true, true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Edit
                    </div>
                  </Button> */}
                  {/* {(isDeleteAccessPengumuman === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteAdvertisingClassified(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#18AB56]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Approve
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#1FB3A7]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Decline
                    </div>
                  </Button> */}
                </div>
              ),
            });
          });
          setNewAdvertisingRow(newAdvertisingRowData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    setIsSearch(false);
    mutateGetAdvertisingsRow(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newAdvertisingRowData = [];

          data?.data?.map((value) => {
            newAdvertisingRowData.push({
              title: value?.title,
              description: value?.description,
              draft:
                value?.is_draft === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              // is_active: (
              //   <Switch
              //     colorScheme={value?.is_active === true ? "green" : "red"}
              //     isChecked={value?.is_active === true ? true : false}
              //     // onChange={() => handlePublishAdvertising(value?.id)}
              //   />
              // ),
              status:
                value?.status === "Accepted" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACCEPTED")}
                  </div>
                ) : value?.status === "Waiting Approval" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.WAITING_APPROVAL")}
                  </div>
                ) : value?.status === "Draft" ? (
                  <div className="bg-[#fdba74] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.DRAFT")}
                  </div>
                ) : (
                  ""
                ),
              action: (
                <div className="flex items-center justify-center gap-2">
                  {!value?.is_draft && (
                    <Button
                      size="md"
                      colorScheme="teal"
                      // className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogPublishAdvertisingClassified(
                          true,
                          value?.id,
                          true
                        )
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.PUBLISH")}
                      </div>
                    </Button>
                  )}
                  {/* {(isGetAccessPengumuman === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(false, true, true, value?.id, true)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF6627]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleModalFormOpen(false, true, true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Edit
                    </div>
                  </Button> */}
                  {/* {(isDeleteAccessPengumuman === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteAdvertisingClassified(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#18AB56]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Approve
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#1FB3A7]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Decline
                    </div>
                  </Button> */}
                </div>
              ),
            });
          });
          setNewAdvertisingRow(newAdvertisingRowData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    setIsSearch(false);
    mutateGetAdvertisingsRow(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newAdvertisingRowData = [];

          data?.data?.map((value) => {
            newAdvertisingRowData.push({
              title: value?.title,
              description: value?.description,
              draft:
                value?.is_draft === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              // is_active: (
              //   <Switch
              //     colorScheme={value?.is_active === true ? "green" : "red"}
              //     isChecked={value?.is_active === true ? true : false}
              //     // onChange={() => handlePublishAdvertising(value?.id)}
              //   />
              // ),
              status:
                value?.status === "Accepted" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACCEPTED")}
                  </div>
                ) : value?.status === "Waiting Approval" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.WAITING_APPROVAL")}
                  </div>
                ) : value?.status === "Draft" ? (
                  <div className="bg-[#fdba74] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.DRAFT")}
                  </div>
                ) : (
                  ""
                ),
              action: (
                <div className="flex items-center justify-center gap-2">
                  {!value?.is_draft && (
                    <Button
                      size="md"
                      colorScheme="teal"
                      // className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogPublishAdvertisingClassified(
                          true,
                          value?.id,
                          true
                        )
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.PUBLISH")}
                      </div>
                    </Button>
                  )}
                  {/* {(isGetAccessPengumuman === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(false, true, true, value?.id, true)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF6627]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleModalFormOpen(false, true, true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Edit
                    </div>
                  </Button> */}
                  {/* {(isDeleteAccessPengumuman === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogDeleteAdvertisingClassified(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#18AB56]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Approve
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#1FB3A7]"
                    style={{ borderRadius: "10px", height: "23px" }}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Decline
                    </div>
                  </Button> */}
                </div>
              ),
            });
          });
          setNewAdvertisingRow(newAdvertisingRowData);
        },
      }
    );
  };

  useEffect(() => {
    newAdvertisingRowData = [];

    setIsSearch(false);
    AdvertisingRowData?.data?.map((value) => {
      newAdvertisingRowData.push({
        title: value?.title,
        description: value?.description,
        draft:
          value?.is_draft === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.YES")}
            </div>
          ) : (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NO")}
            </div>
          ),
        // is_active: (
        //   <Switch
        //     colorScheme={value?.is_active === true ? "green" : "red"}
        //     isChecked={value?.is_active === true ? true : false}
        //     // onChange={() => handlePublishAdvertising(value?.id)}
        //   />
        // ),
        status:
          value?.status === "Accepted" ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.ACCEPTED")}
            </div>
          ) : value?.status === "Waiting Approval" ? (
            <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.WAITING_APPROVAL")}
            </div>
          ) : value?.status === "Draft" ? (
            <div className="bg-[#fdba74] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.DRAFT")}
            </div>
          ) : (
            ""
          ),
        action: (
          <div className="flex items-center justify-center gap-2">
            {!value?.is_draft && (
              <Button
                size="md"
                colorScheme="teal"
                // className="bg-[#FF0F0F]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleDialogPublishAdvertisingClassified(
                    true,
                    value?.id,
                    true
                  )
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.PUBLISH")}
                </div>
              </Button>
            )}
            {/* {(isGetAccessPengumuman === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleModalFormOpen(false, true, true, value?.id, true)
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.VIEW")}
                </div>
              </Button>
            {/* )} */}
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF6627]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleModalFormOpen(false, true, true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Edit
              </div>
            </Button> */}
            {/* {(isDeleteAccessPengumuman === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#FF0F0F]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleDialogDeleteAdvertisingClassified(true, value?.id)
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.DELETE")}
                </div>
              </Button>
            {/* )} */}
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#18AB56]"
              style={{ borderRadius: "10px", height: "23px" }}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Approve
              </div>
            </Button>
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#1FB3A7]"
              style={{ borderRadius: "10px", height: "23px" }}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Decline
              </div>
            </Button> */}
          </div>
        ),
      });
    });
    setNewAdvertisingRow(newAdvertisingRowData);
  }, [
    AdvertisingRowData,
    token,
    isGetAccessPengumuman,
    isDeleteAccessPengumuman,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,
        formPublish,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,
        resetFormPublish,

        advertisingRowId,
        isEdit,
        isCreate,
        isVisibleClassifiedDialog,
        setIsVisibleClassifiedDialog,
        isVisibleDeleteAdvertisingClassified,
        setIsVisibleDeleteAdvertisingClassified,
        isVisibleDeleteInfoAdvertisingClassified,
        setIsVisibleDeleteInfoAdvertisingClassified,

        // advertisingId,
        isModalFormOpen,

        // isLoadingDeleteAdvertising,
        isLoadingAdvertisingRowData,
        // isLoadingGetAdvertisings,

        AdvertisingRowData,
        newAdvertisingRow,
        newAdvertisingRowData,
        handleModalFormOpen,
        handleShowEntriesTable,
        handlePaginationTable,
        refetchAdvertisingRowData,
        // handleDeleteAdvertising,
        handleSearch,
        payloadClassifiedAdsEdit,
        setPayloadClassifiedAdsEdit,
        isView,
        setIsView,

        indexTabActive,
        setIndexTabActive,
        handlePublishClassifiedAdsOpen,
        isPublishClassifiedAdsOpen,
        setIsPublishClassifiedAdsOpen,

        handleDialogDeleteAdvertisingClassified,
        handleDeleteAdvertisingClassified,

        isVisiblePublishAdvertisingClassified,
        setIsVisiblePublishAdvertisingClassified,

        isVisiblePublishInfoAdvertisingClassified,
        setIsVisiblePublishInfoAdvertisingClassified,
        isLoadingPublishAdvertisingRow,
        handleDialogPublishAdvertisingClassified,
        handlePublishAdvertisingClassified,

        isGetAccessPengumuman,
        isDeleteAccessPengumuman,
        isUpdateAccessPengumuman,
        isViewAllAccess,

        isShowStatus,
        isShowDraft,
        // selectedCategorySearch,
        // setSelectedCategorySearch,
        isSearch,

        payloadPublishClassifiedAdsEdit,
        setIsShowStatus,
        setIsShowDraft,
        newAdvertisingRowSearch,
        formKeywordSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default ClassifiedAdsController;
