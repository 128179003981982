import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import Logo from "../../../../assets/img/logo.png";
import { BiLogOut, BiSearch, BiUser } from "react-icons/bi";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineDown } from "react-icons/ai";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";

import SelectedCountries from "../../../../components/Translations/SelectedCountries";
import OptionCountries from "../../../../components/Translations/OptionCountries";
import { ListCountries } from "../../../../components/Translations/ListCountries";
import NotifSign from "../../../../assets/svg/glyph/notif-sign.svg";
import Avatar from "../../../../assets/svg/glyph/avatar.svg";
import { AuthContext } from "../../../../controllers/public/AuthController";
import { privateSlug } from "../../../../utils/privateSlug";
import { Link } from "react-router-dom";

const Navbar = () => {
  const { i18n, t } = useTranslation();
  const handleChangeLanguage = (lang) => i18n.changeLanguage(lang);
  const { handleLogout, isLoadingProfileData, profileData } =
    useContext(AuthContext);

  return (
    <div className="bg-white p-3 flex justify-between items-center w-full fixed border-b-2 z-10">
      {/* Left side: Logo */}
      <div className="flex items-center justify-center w-60 gap-2">
        <img src={Logo} alt="" />
        <span className="leading-[27px] text-[18px] flex items-center font-[700] text-[#081B56]">
          NIHAO ID
        </span>
      </div>

      {/* Center side: for column search and notif */}
      {/* <div className="flex items-center w-1/2 gap-2">
        <InputGroup>
          <Input
            placeholder="Search"
            size="sm"
            variant="filled"
            borderRadius={5}
          />
          <InputRightElement>
            <BiSearch className="text-gray-400 pb-2 h-8" />
          </InputRightElement>
        </InputGroup>
      </div>
      <div className="flex items-center justify-center p-3 lg:p-1">
        <img src={NotifSign} alt="" />
      </div> */}

      {/* Right side: User Login and Language Switcher */}
      <div className="flex items-center space-x-4">
        {/* User Login */}
        <img
          src={profileData?.data?.profile_picture_image_url}
          style={{ width: 32, height: 32, objectFit: "contain" }}
          alt=""
        />
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<AiOutlineDown className="text-xs" />}
            variant="ghost"
            _hover={{ bg: "none" }}
            _active={{
              bg: "none",
              borderColor: "none",
            }}
            _focus={{
              borderColor: "none",
            }}
            padding={{ sm: 2, xl: 2 }}
            className="bg-none text-gray-700 active:bg-none"
          >
            {isLoadingProfileData && (
              <Spinner size="sm" className="text-[#302D69]" />
            )}
            {profileData?.data?.name}
          </MenuButton>
          <MenuList>
            <Link
              to={privateSlug.PROFILE}
              className="hover:no-underline hover:text-slate-800"
            >
              <MenuItem icon={<BiUser />}>{t("LABEL.PROFILE")}</MenuItem>
            </Link>
            <Link
              to={privateSlug.CHANGEPASSWORD}
              className="hover:no-underline hover:text-slate-800"
            >
              <MenuItem icon={<RiLockPasswordLine />}>
                {t("LABEL.CHANGE_PASSWORD")}
              </MenuItem>
            </Link>
            <MenuItem icon={<BiLogOut />} onClick={() => handleLogout()}>
              {t("LABEL.LOGOUT")}
            </MenuItem>
          </MenuList>
        </Menu>

        <div className="relative">
          <div className="lg:flex lg:items-center lg:justify-center lg:p-0 xl:flex xl:items-center xl:justify-center">
            <Dropdown
              className={`p-0 poppins`}
              style={{ borderColor: "#fff", boxShadow: "none" }}
              value={i18n.language}
              options={ListCountries.map((language) => ({
                label: language?.label,
                value: language?.code,
              }))}
              onChange={(e) => handleChangeLanguage(e.value)}
              valueTemplate={SelectedCountries}
              itemTemplate={OptionCountries}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
