import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Switch, Tag, TagLabel, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import {
  deleteTermsCondition,
  getTermsCondition,
  getTermsConditionList,
  getTermsConditionSearch,
  updateOrderTermsCondition,
} from "./api/TermsConditionApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/public/AuthController";
// import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";

export const TermsConditionContext = createContext();
const { Provider } = TermsConditionContext;

const TermsConditionController = ({ children }) => {
  const { t } = useTranslation();
  const { token } = useContext(TokenContext);

  let language = t("LANGUAGE");
  const toast = useToast();

  const {
    data: TermsConditionData,
    isLoading: isLoadingTermsConditionData,
    refetch: refetchTermsConditionData,
  } = useQuery(["faq", token, language], () =>
    getTermsConditionList({ language, token })
  );

  const { mutate: mutateGetTermsCondition } = useMutation(getTermsCondition);

  const {
    isLoading: isLoadingSetHomeSectionSearch,
    mutate: mutateSetTermsConditionSearch,
  } = useMutation(getTermsConditionSearch);

  const {
    isLoading: isLoadingDeleteTermsCondition,
    mutate: mutateDeleteTermsCondition,
  } = useMutation(deleteTermsCondition);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleTermsConditionDialog, setIsVisibleTermsConditionDialog] =
    useState(false);
  const [isVisibleDeleteTermsCondition, setIsVisibleDeleteTermsCondition] =
    useState(false);
  const [
    isVisibleDeleteInfoTermsCondition,
    setIsVisibleDeleteInfoTermsCondition,
  ] = useState(false);
  const [isPublishHomeSectionOpen, setIsPublishHomeSectionOpen] =
    useState(false);
  const {
    isLoading: isLoadingUpdateOrderTermsCondition,
    mutate: mutateUpdateOrderTermsCondition,
  } = useMutation(updateOrderTermsCondition);
  const [newTermsCondition, setnewTermsCondition] = useState();

  const [faqId, setTermsConditionId] = useState();

  const [payloadTermsConditionEdit, setPayloadTermsConditionEdit] = useState();
  const [isActiveSubmitOrder, setIsActiveSubmitOrder] = useState(false);
  const [isActiveDrag, setIsActiveDrag] = useState(false);

  const [indexTabActive, setIndexTabActive] = useState();

  const [orderSection, setOrderSection] = useState();

  const [newAdvertisings, setNewAdvertisings] = useState();
  const [isOpenInsertModalAds, setIsOpenInsertModalAds] = useState(false);
  const [selectedAdsId, setSelectedAdsId] = useState([]);
  const [isPublishTermsConditionOpen, setIsPublishTermsConditionOpen] =
    useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [newTncSearch, setNewTncSearch] = useState();

  let newTermsConditionData = [];

  const [isGetAccessTermsCondition, setIsGetAccessTermsCondition] = useState();
  const [isDeleteAccessTermsCondition, setIsDeleteAccessTermsCondition] =
    useState();
  const [isUpdateAccessTermsCondition, setIsUpdateAccessTermsCondition] =
    useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Terms Condition
          const getAccessTermsCondition = data?.data?.access
            ?.filter((data) => data?.page === "termscondition")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessTermsCondition(getAccessTermsCondition?.length);

          const deleteAccessTermsCondition = data?.data?.access
            ?.filter((data) => data?.page === "termscondition")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessTermsCondition(deleteAccessTermsCondition?.length);

          const updateAccessTermsCondition = data?.data?.access
            ?.filter((data) => data?.page === "termscondition")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessTermsCondition(updateAccessTermsCondition?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    if (newForm) {
      formEn.reset({
        title: newForm?.title,
        description: newForm?.description,
      });
    }

    if (newForm && isEdit) {
      formEn.reset({
        title: payloadTermsConditionEdit?.title?.en,
        description: payloadTermsConditionEdit?.descriptions?.en,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, [isEdit, payloadTermsConditionEdit]);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    if (newForm) {
      formId.reset({
        title: newForm?.title,
        description: newForm?.description,
      });
    }

    if (newForm && isEdit) {
      formId.reset({
        title: payloadTermsConditionEdit?.title?.id,
        description: payloadTermsConditionEdit?.descriptions?.id,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, [isEdit, payloadTermsConditionEdit]);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    if (newForm) {
      formCn.reset({
        title: newForm?.title,
        description: newForm?.description,
      });
    }

    if (newForm && isEdit) {
      formCn.reset({
        title: payloadTermsConditionEdit?.title?.cn,
        description: payloadTermsConditionEdit?.descriptions?.cn,

        // image_thumbnail_url: thumbnailHomeSection,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, [isEdit, payloadTermsConditionEdit]);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    if (newForm) {
      formPreviewEn.reset({
        title: newForm?.en?.title?.[0]?.en,
        description: newForm?.en?.description?.[0]?.en,
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    if (newForm) {
      formPreviewId.reset({
        title: newForm?.id?.title?.[0]?.id,
        description: newForm?.id?.description?.[0]?.id,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    if (newForm) {
      formPreviewCn.reset({
        title: newForm?.cn?.title?.[0]?.cn,
        description: newForm?.cn?.description?.[0]?.cn,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    setIndexTabActive(0);
    isOpen === false && handlePublishHomeSectionOpen(false);
    let lang = t("LANGUAGE");

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});
    }

    isEdit &&
      mutateGetTermsCondition(
        { guid, token, lang },
        {
          onSuccess: (data) => {
            setPayloadTermsConditionEdit(data?.data);
          },
        }
      );
  };

  const handlePublishHomeSectionOpen = (isOpen) => {
    setIsPublishHomeSectionOpen(isOpen);
    // resetFormPublish({});
  };

  const handleDialogDeleteTermsCondition = (isOpen, guid) => {
    setIsVisibleDeleteTermsCondition(isOpen);
    setTermsConditionId(guid);
    setIsVisibleDeleteInfoTermsCondition(false);
  };

  const handleDeleteTermsCondition = (guid) => {
    let lang = t("LANGUAGE");
    mutateDeleteTermsCondition(
      { guid, token, lang },
      {
        onSuccess: () => {
          refetchTermsConditionData();
          setIsVisibleDeleteTermsCondition(false);
          setIsVisibleDeleteInfoTermsCondition(true);
        },
      }
    );
  };

  const handleActiveSubmitOrder = (isActive, isDrag) => {
    setIsActiveSubmitOrder(isActive);
    setIsActiveDrag(isDrag);
  };

  const handleSubmitOrder = (isDrag) => {
    setIsActiveSubmitOrder(isDrag);
    setIsActiveDrag(isDrag);
    // let lang = t("LANGUAGE");

    let payloadOrderSection = [];
    orderSection?.map((newOrder) => {
      payloadOrderSection.push({
        id: newOrder?.uid,
      });
    });

    let payloadOrder = {
      orders: payloadOrderSection,
    };
    // console.log(payloadOrder);

    mutateUpdateOrderTermsCondition(
      { payloadOrder, token, language },
      {
        onSuccess: (data) => {
          toast({
            description: "Data Order Updated",
            position: "top-right",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        },
      }
    );
  };

  const handlePublishTermsConditionOpen = (isOpen) => {
    setIsPublishTermsConditionOpen(isOpen);
    // resetFormPublish({});
  };

  // const handleHomeSectionActive = (guid) => {
  //   // mutateSetHomeSectionActive(
  //   //   { guid, token },
  //   //   {
  //   //     onSuccess: (data) => {
  //   //       refetchTermsConditionData();
  //   //       toast({
  //   //         description: data?.message?.response_message,
  //   //         position: "top-right",
  //   //         status: "success",
  //   //         duration: 1500,
  //   //         isClosable: true,
  //   //       });
  //   //     },
  //   //   }
  //   // );
  // };

  const handleSearch = (keyword, page, limit) => {
    // let isTitle = keyword?.keyword_filter === "1" && true;

    let isTitle       = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isDescription = keyword?.keyword_filter === "2" && true;
    let searchKeyword = !keyword?.value?.keyword_search
      ? ""
      : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-tnc", searchKeyword);
    } else {
      localStorage.removeItem("search-tnc");
    }

    setIsSearch(true);

    let lang = t("LANGUAGE");

    let payload = {
      isTitle: isTitle,
      isDescription: isDescription,
      search: searchKeyword,
      lang: lang,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    mutateSetTermsConditionSearch(
      { payload },
      {
        onSuccess: (data) => {
          newTermsConditionData = [];

          data?.data?.map((value) => {
            setNewTncSearch(data);
            newTermsConditionData.push({
              uid: value?.guid,
              id: value?.no,
              title: value?.title,
              description: value?.descriptions,

              action: (
                <div className="flex items-center justify-center gap-2">
                  {/* {(isGetAccessTermsCondition === 1 ||
                    isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(false, true, true, value?.guid, true)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  {/* )} */}

                  {/* {(isDeleteAccessTermsCondition === 1 ||
                    isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleDialogDeleteTermsCondition(true, value?.guid)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          // console.log(newTermsConditionData);
          setnewTermsCondition(newTermsConditionData);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem("search-tnc");
    newTermsConditionData = [];

    TermsConditionData?.data?.map((value) => {
      newTermsConditionData.push({
        uid: value?.guid,
        id: value?.no,
        title: value?.title,
        description: value?.descriptions,

        action: (
          <div className="flex items-center justify-center gap-2">
            {/* {(isGetAccessTermsCondition === 1 || isViewAllAccess === true) && ( */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleModalFormOpen(false, true, true, value?.guid, true)
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.VIEW")}
              </div>
            </Button>
            {/* )} */}

            {/* {(isDeleteAccessTermsCondition === 1 ||
              isViewAllAccess === true) && ( */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF0F0F]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleDialogDeleteTermsCondition(true, value?.guid)
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.DELETE")}
              </div>
            </Button>
            {/* )} */}
          </div>
        ),
      });
    });
    setnewTermsCondition(newTermsConditionData);
  }, [
    TermsConditionData,
    token,
    isGetAccessTermsCondition,
    isDeleteAccessTermsCondition,
    isUpdateAccessTermsCondition,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,

        faqId,
        isEdit,
        isCreate,
        isModalFormOpen,
        isVisibleTermsConditionDialog,
        setIsVisibleTermsConditionDialog,
        isVisibleDeleteTermsCondition,
        setIsVisibleDeleteTermsCondition,
        isVisibleDeleteInfoTermsCondition,
        setIsVisibleDeleteInfoTermsCondition,

        isLoadingDeleteTermsCondition,

        isLoadingTermsConditionData,
        // isLoadingGetAdvertisings,

        TermsConditionData,
        newTermsCondition,
        newTermsConditionData,
        handleDialogDeleteTermsCondition,
        handleModalFormOpen,
        // handleShowEntriesTable,
        // handlePaginationTable,
        refetchTermsConditionData,
        handleDeleteTermsCondition,
        handleSearch,
        handleSubmitOrder,
        orderSection,
        setOrderSection,

        handleActiveSubmitOrder,
        isActiveSubmitOrder,
        setIsActiveSubmitOrder,
        isActiveDrag,
        setIsActiveDrag,

        payloadTermsConditionEdit,
        setPayloadTermsConditionEdit,
        isView,
        setIsView,

        isOpenInsertModalAds,
        setIsOpenInsertModalAds,
        newAdvertisings,
        setNewAdvertisings,

        selectedAdsId,
        setSelectedAdsId,

        indexTabActive,
        setIndexTabActive,

        handlePublishHomeSectionOpen,
        isPublishHomeSectionOpen,
        setIsPublishHomeSectionOpen,

        isGetAccessTermsCondition,
        isDeleteAccessTermsCondition,
        isUpdateAccessTermsCondition,
        isViewAllAccess,
        handlePublishTermsConditionOpen,
        isPublishTermsConditionOpen,
        setIsPublishTermsConditionOpen,
        newTncSearch,
        setNewTncSearch,
        isSearch,
        setIsSearch,
        formKeywordSearch,
      }}
    >
      {children}
    </Provider>
  );
};

export default TermsConditionController;
