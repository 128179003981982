import React from "react";
import { privateSlug } from "../utils/privateSlug";
import Advertising from "../pages/content/private/Ads/Advertising";
import ClassifiedAds from "../pages/content/private/Ads/ClassifiedAds";
import BannerAds from "../pages/content/private/Banner";
import ChangePassword from "../pages/content/private/ChangePassword";
import Dashboard from "../pages/content/private/Dashboard";
import HomeSection from "../pages/content/private/HomeSection";
// import Bahasa from "../pages/content/private/Language/Bahasa";
import TransactionPrabayar from "../pages/content/private/Transaction/Prabayar";
import Profile from "../pages/content/private/Profile";
import UserAdmin from "../pages/content/private/User/UserAdmin";
import UserAdminRole from "../pages/content/private/User/UserAdminRole";
import UserAgent from "../pages/content/private/User/UserAgent";
import UserHandheld from "../pages/content/private/User/UserHandheld";
import PrivateTemplate from "../pages/templates/private";
import Faq from "../pages/content/private/CMS/Faq";
import TermsCondition from "../pages/content/private/CMS/TermsConditions";
import PrivacyPolicy from "../pages/content/private/CMS/PrivacyPolicy";
import AboutUs from "../pages/content/private/CMS/AboutUs";
import Prabayar from "../pages/content/private/PPOB/Prabayar";
import Pascabayar from "../pages/content/private/PPOB/Pascabayar";
// import YuanConversion from "../pages/content/private/YuanConversion";
import BankAccount from "../pages/content/private/BankAccount";
import ConfirmationApproval from "../pages/content/private/Confirmation/Approval";
import ConfirmationTransaction from "../pages/content/private/Confirmation/Transaction";
import CustomsDeclarationApproval from "../pages/content/private/CustomsDeclaration/Approval";
import CustomsDeclarationTransaction from "../pages/content/private/CustomsDeclaration/Transaction";
import Point from "../pages/content/private/Point";
// import SupportChat from "../pages/content/private/SupportChat";
import TransactionSH from "../pages/content/private/TransactionSH";
import Config from "../pages/content/private/Config";
import Voucher from "../pages/content/private/Voucher";
import Topic from "../pages/content/private/Master/Topic";
import TopicCategory from "../pages/content/private/Master/TopicCategory";
import QnaMaster from "../pages/content/private/Qna/QnaMaster";
import QnaTicket from "../pages/content/private/Qna/QnaTicket";
import Info from "../pages/content/private/Info";

const PrivateRoutes = [
  {
    path: privateSlug.HOME,
    element: <PrivateTemplate />,
    children: [
      {
        path: privateSlug.DASHBOARD,
        element: <Dashboard />,
      },
      {
        path: privateSlug.BANNER,
        element: <BannerAds />,
      },
      {
        path: privateSlug.HOME + privateSlug.CMS,
        children: [
          {
            path: privateSlug.CMS + privateSlug.TERMSCONDITION,
            element: <TermsCondition />,
          },
          {
            path: privateSlug.CMS + privateSlug.FAQ,
            element: <Faq />,
          },
          {
            path: privateSlug.CMS + privateSlug.PRIVACYPOLICY,
            element: <PrivacyPolicy />,
          },
          {
            path: privateSlug.CMS + privateSlug.ABOUTUS,
            element: <AboutUs />,
          },
        ],
      },
      {
        path: privateSlug.SECTION,
        element: <HomeSection />,
      },
      {
        path: privateSlug.HOME + privateSlug.ADS,
        children: [
          {
            path: privateSlug.ADS + privateSlug.ADVERTISING,
            element: <Advertising />,
          },
          {
            path: privateSlug.ADS + privateSlug.CLASSIFIED,
            element: <ClassifiedAds />,
          },
        ],
      },
      {
        path: privateSlug.HOME + privateSlug.PRODUCTS,
        children: [
          {
            path: privateSlug.PRODUCTS + privateSlug.PRABAYAR,
            element: <Prabayar />,
          },
          {
            path: privateSlug.PRODUCTS + privateSlug.PASCABAYAR,
            element: <Pascabayar />,
          },
        ],
      },
      {
        path: privateSlug.BANKACCOUNT,
        element: <BankAccount />,
      },
      {
        path: privateSlug.HOME + privateSlug.CONFIRMATION,
        children: [
          {
            path: privateSlug.CONFIRMATION + privateSlug.APPROVAL,
            element: <ConfirmationApproval />,
          },
          {
            path:
              privateSlug.CONFIRMATION + privateSlug.CONFIRMATIONTRANSACTION,
            element: <ConfirmationTransaction />,
          },
        ],
      },
      {
        path: privateSlug.HOME + privateSlug.TRANSACTION,
        children: [
          {
            path: privateSlug.TRANSACTION + privateSlug.PRABAYAR,
            // element: <Prabayar />,
            element: <TransactionPrabayar />,
          },
        ],
      },
      {
        path: privateSlug.HOME + privateSlug.CUSTOMSDECLARATION,
        children: [
          {
            path: privateSlug.CUSTOMSDECLARATION + privateSlug.APPROVE,
            element: <CustomsDeclarationApproval />,
          },
          {
            path: privateSlug.CUSTOMSDECLARATION + privateSlug.TRANSACTION,
            element: <CustomsDeclarationTransaction />,
          },
        ],
      },
      {
        path: privateSlug.POINT,
        element: <Point />,
      },
      {
        path: privateSlug.TRANSACTIONSH,
        element: <TransactionSH />,
      },
      {
        path: privateSlug.CONFIG,
        element: <Config />,
      },
      {
        path: privateSlug.VOUCHER,
        element: <Voucher />,
      },
      {
        path: privateSlug.HOME + privateSlug.MASTER,
        children: [
          {
            path: privateSlug.MASTER + privateSlug.TOPIC,
            element: <Topic />,
          },
          {
            path: privateSlug.MASTER + privateSlug.TOPICCATEGORY,
            element: <TopicCategory />,
          },
        ],
      },
      {
        path: privateSlug.HOME + privateSlug.MASTER,
        children: [
          {
            path: privateSlug.MASTER + privateSlug.QNA,
            element: <QnaMaster />,
          },
          {
            path: privateSlug.MASTER + privateSlug.QNATICKET,
            element: <QnaTicket />,
          },
        ],
      },
      {
        path: privateSlug.INFO,
        element: <Info />,
      },
      {
        path: privateSlug.HOME + privateSlug.USER,
        children: [
          {
            path: privateSlug.USER + privateSlug.ADMIN,
            element: <UserAdmin />,
          },
          {
            path: privateSlug.USER + privateSlug.HANDHELD,
            element: <UserHandheld />,
          },
          {
            path: privateSlug.USER + privateSlug.ROLE,
            element: <UserAdminRole />,
          },
          {
            path: privateSlug.USER + privateSlug.AGENT,
            element: <UserAgent />,
          },
        ],
      },

      //Dropdown User
      {
        path: privateSlug.PROFILE,
        element: <Profile />,
      },
      {
        path: privateSlug.CHANGEPASSWORD,
        element: <ChangePassword />,
      },
      {
        path: privateSlug.HOME + privateSlug.CUSTOM,
        children: [
          {
            path: privateSlug.CUSTOM + privateSlug.BANNER,
            element: <ClassifiedAds />,
          },
        ],
      },

      //Route yang tidak digunakan
      // {
      //   path: privateSlug.HOME + privateSlug.LANGUAGE,
      //   children: [
      //     {
      //       path: privateSlug.LANGUAGE + privateSlug.BAHASA,
      //       element: <Bahasa />,
      //     },
      //   ],
      // },

      // {
      //   path: privateSlug.YUANCONVERSION,
      //   element: <YuanConversion />,
      // },
    ],
  },
];

export default PrivateRoutes;
