import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TokenContext } from "../../../../controllers/public/TokenController";

import { Button, Switch, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import {
  getInfos,
  getAdvertisingBannerSearch,
  deleteInfo,
  getInfo,
  getInfoSearch,
} from "./api/InfoApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/public/AuthController";
import { getRole } from "../User/UserAdminRole/api/RoleApi";
import Cookies from "js-cookie";

export const InfoContext = createContext();
const { Provider } = InfoContext;

const InfoController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: InfosData,
    isLoading: isLoadingInfosData,
    refetch: refetchInfosData,
  } = useQuery(["topics", token, language], () =>
    getInfos({ token, language })
  );

  const { mutate: mutateGetInfos } = useMutation(getInfos);

  const { mutate: mutateGetInfo } = useMutation(getInfo);

  const { isLoading: isLoadingSetInfoSearch, mutate: mutateSetInfoSearch } =
    useMutation(getInfoSearch);

  const { isLoading: isLoadingDeleteInfo, mutate: mutateDeleteInfo } =
    useMutation(deleteInfo);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleInfoDialog, setIsVisibleInfoDialog] = useState(false);
  const [isVisibleDeleteInfo, setIsVisibleDeleteInfo] = useState(false);
  const [isVisibleDeleteInfoInfo, setIsVisibleDeleteInfoInfo] =
    useState(false);

  const [isPublishInfoOpen, setIsPublishInfoOpen] = useState(false);
  const [newInfo, setnewInfo] = useState();

  const [topicId, setInfoId] = useState();

  const [urlBannerThumbnail, setUrlBannerThumbnail] = useState();
  const [urlBannerThumbnailWebsite, setUrlBannerThumbnailWebsite] = useState();
  const [urlBannerThumbnailEdit, setUrlBannerThumbnailEdit] = useState();
  const [urlBannerThumbnailWebsiteEdit, setUrlBannerThumbnailWebsiteEdit] =
    useState();

  const [urlBannerThumbnailEn, setUrlBannerThumbnailEn] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [urlBannerThumbnailEnEdit, setUrlBannerThumbnailEnEdit] = useState();
  const [logoUrlEdit, setLogoUrlEdit] = useState();

  const [urlBannerThumbnailId, setUrlBannerThumbnailId] = useState();
  const [urlBannerThumbnailWebsiteId, setUrlBannerThumbnailWebsiteId] =
    useState();
  const [urlBannerThumbnailIdEdit, setUrlBannerThumbnailIdEdit] = useState();
  const [urlBannerThumbnailWebsiteIdEdit, setUrlBannerThumbnailWebsiteIdEdit] =
    useState();

  const [urlBannerThumbnailCn, setUrlBannerThumbnailCn] = useState();
  const [urlBannerThumbnailWebsiteCn, setUrlBannerThumbnailWebsiteCn] =
    useState();
  const [urlBannerThumbnailCnEdit, setUrlBannerThumbnailCnEdit] = useState();
  const [urlBannerThumbnailWebsiteCnEdit, setUrlBannerThumbnailWebsiteCnEdit] =
    useState();

  const [payloadInfoEdit, setPayloadInfoEdit] = useState();
  const [indexTabActive, setIndexTabActive] = useState();

  const [enDescription, setEnDescription] = useState("");
  const [idDescription, setIdDescription] = useState("");
  const [cnDescription, setCnDescription] = useState("");
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isActiveInfo, setIsActiveInfo] = useState(false);
  const [newInfoSearch, setNewInfoSearch] =  useState();

  let newInfosData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessBanner, setIsGetAccessBanner] = useState();
  const [isDeleteAccessBanner, setIsDeleteAccessBanner] = useState();
  const [isUpdateAccessBanner, setIsUpdateAccessBanner] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const [isFileBanner, setIsFileBanner] = useState(true);
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Banner
          const getAccessInfo = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessBanner(getAccessInfo?.length);

          const deleteAccessInfo = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessBanner(deleteAccessInfo?.length);

          const updateAccessInfo = data?.data?.access
            ?.filter((data) => data?.page === "topic")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessBanner(updateAccessInfo?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadInfoEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formEn.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,
      });
    }

    if (newForm && isEdit === true) {
      formEn.reset({
        id: payloadInfoEdit?.guid,
        date: dateEdit,
        title: payloadInfoEdit?.title?.en,
        description: payloadInfoEdit?.descriptions?.en,
        is_active: payloadInfoEdit?.is_active,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, [isEdit, payloadInfoEdit]);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadInfoEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formId.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,
      });
    }

    const thumbnailBanner = urlBannerThumbnailEdit
      ? urlBannerThumbnailEdit
      : payloadInfoEdit?.banner?.thumbnail;

    // console.log(thumbnailBanner)

    if (newForm && isEdit === true) {
      formId.reset({
        id: payloadInfoEdit?.guid,
        date: dateEdit,
        title: payloadInfoEdit?.title?.id,
        description: payloadInfoEdit?.descriptions?.id,
        is_active: payloadInfoEdit?.is_active,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, [isEdit, payloadInfoEdit, urlBannerThumbnailEdit]);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    const dateEdit = moment(payloadInfoEdit?.created_at).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm && isEdit === false) {
      formCn.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,        
      });
    }

    const thumbnailBanner = urlBannerThumbnailEdit
      ? urlBannerThumbnailEdit
      : payloadInfoEdit?.banner?.thumbnail;

    if (newForm && isEdit === true) {
      formCn.reset({
        id: payloadInfoEdit?.guid,
        date: dateEdit,
        title: payloadInfoEdit?.title?.cn,
        description: payloadInfoEdit?.descriptions?.cn,
        is_active: payloadInfoEdit?.is_active,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, [isEdit, payloadInfoEdit, urlBannerThumbnailEdit]);

  // -------------------------------
  // Form Upload
  // -------------------------------
  const formUpload = useForm();
  const resetFormUpload = (newForm) => {
    if (newForm && !isEdit) {
      formUpload.reset({
        banner_thumbnail: newForm?.banner?.[0]?.thumbnail,
        banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_web,
        banner_image: newForm?.banner?.[0]?.image,
      });
    }

    if (newForm && isEdit) {
      formUpload.reset({
        banner_thumbnail: payloadInfoEdit?.banner?.thumbnail,
        banner_thumbnail_website: payloadInfoEdit?.banner?.thumbnail_web,
        banner_image: payloadInfoEdit?.banner?.image,
      });
    }
  };

  useEffect(() => {
    resetFormUpload({});
  }, [isEdit, payloadInfoEdit]);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      console.log(newForm);
      // console.log(newForm?.en?.banner?.thumbnail_web?.en)
      formPreviewEn.reset({
        date: defaultValue,
        title: newForm?.en?.title?.[0]?.en,
        description: newForm?.en?.description?.[0]?.en,        
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewId.reset({
        date: defaultValue,
        title: newForm?.id?.title?.[0]?.id,
        description: newForm?.id?.description?.[0]?.id,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date)
      .add(5, "minutes")
      .format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewCn.reset({
        date: defaultValue,
        title: newForm?.cn?.title?.[0]?.cn,
        description: newForm?.cn?.description?.[0]?.cn,
      });
    }
  };

  const formPreviewUpload = useForm();
  const resetFormPreviewUpload = (newForm) => {
    if (newForm) {
      formPreviewUpload.reset({
        banner_thumbnail_website: newForm?.banner?.[0]?.thumbnail_website,
        banner_thumbnail: newForm?.banner?.[0]?.thumbnail,
        banner_image: newForm?.banner?.[0]?.image,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    setIndexTabActive(0);
    isOpen === false && handlePublishInfoOpen(false);

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormUpload({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});
      resetFormPreviewUpload({});

      setLogoUrl([]);
      setIsFileBanner(false);

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieen"));
        index++
      ) {
        Cookies.remove("keyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieid"));
        index++
      ) {
        Cookies.remove("keyid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookiecn"));
        index++
      ) {
        Cookies.remove("keycn" + index);
      }

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieediten"));
        index++
      ) {
        Cookies.remove("keyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditid"));
        index++
      ) {
        Cookies.remove("keyeditid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditcn"));
        index++
      ) {
        Cookies.remove("keyeditcn" + index);
      }
    }

    if (isEdit === true) {
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieen"));
        index++
      ) {
        Cookies.remove("keyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieid"));
        index++
      ) {
        Cookies.remove("keyid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookiecn"));
        index++
      ) {
        Cookies.remove("keycn" + index);
      }

      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieediten"));
        index++
      ) {
        Cookies.remove("keyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditid"));
        index++
      ) {
        Cookies.remove("keyeditid" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxcookieeditcn"));
        index++
      ) {
        Cookies.remove("keyeditcn" + index);
      }
    }

    isEdit &&
      mutateGetInfo(
        { guid, token, language },
        {
          onSuccess: (data) => {
            setEnDescription(data?.data?.descriptions);
            setIdDescription(data?.data?.descriptions);
            setCnDescription(data?.data?.descriptions);
            setPayloadInfoEdit(data?.data);
            setIsActiveInfo(data?.data?.is_active)
          },
        }
      );
  };

  const handlePublishInfoOpen = (isOpen) => {
    setIsPublishInfoOpen(isOpen);
    // resetFormPublish({});
  };

  const handleDialogDeleteInfo = (isOpen, guid) => {
    setIsVisibleDeleteInfo(isOpen);
    setInfoId(guid);
    setIsVisibleDeleteInfoInfo(false);
  };

  const handleDeleteInfo = (guid) => {
    mutateDeleteInfo(
      { guid, token, language },
      {
        onSuccess: () => {
          refetchInfosData();
          setIsVisibleDeleteInfo(false);
          setIsVisibleDeleteInfoInfo(true);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isTitle = keyword?.keyword_filter === "1" && true;    
    // let isStatus = keyword?.keyword_filter === "2" && true;

    let isTitle   = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isStatus  = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isSearchStatus = keyword?.keyword_status === "1" && true;
    let searchKeyword  = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-info", searchKeyword);
    } else {
      localStorage.removeItem("search-info");
    }

    setIsShowStatus(isStatus);
    setIsSearch(true);

    let payload = {
      isTitle: isTitle,
      isStatus: isStatus,
      search: searchKeyword,
      searchStatus: isSearchStatus,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(10);
    mutateSetInfoSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            // console.log(value);
            setNewInfoSearch(data)
            newInfosData.push({
              title: value?.title_lang,
              is_read:
                value?.is_read === true ? (
                  <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.READ")}
                  </div>
                ) : (
                  <div className="bg-[#faf089] text-gray-800 leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.UNREAD")}
                  </div>
                ),
              is_active:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ),

              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(
                          false,
                          true,
                          true,
                          value?.guid,
                          true
                        )
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleDialogDeleteInfo(true, value?.guid)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewInfo(newInfosData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetInfos(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newInfosData = [];

          data?.data?.map((value) => {
            newInfosData.push({
              title: value?.title_lang,
              is_read:
                value?.is_read === true ? (
                  <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.READ")}
                  </div>
                ) : (
                  <div className="bg-[#faf089] text-gray-800 leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.UNREAD")}
                  </div>
                ),
              is_active:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ),

              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(
                          false,
                          true,
                          true,
                          value?.guid,
                          true
                        )
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleDialogDeleteInfo(true, value?.guid)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewInfo(newInfosData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetInfos(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newInfosData = [];

          data?.data?.map((value) => {
            newInfosData.push({
              title: value?.title_lang,
              is_read:
                value?.is_read === true ? (
                  <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.READ")}
                  </div>
                ) : (
                  <div className="bg-[#faf089] text-gray-800 leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.UNREAD")}
                  </div>
                ),
              is_active:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ),

              action: (
                <div className="flex gap-2 items-center justify-center">
                  {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalFormOpen(
                          false,
                          true,
                          true,
                          value?.guid,
                          true
                        )
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                  {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#FF0F0F]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleDialogDeleteInfo(true, value?.guid)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setnewInfo(newInfosData);
        },
      }
    );
  };

  useEffect(() => {    
    localStorage.removeItem("search-info");
    newInfosData = [];

    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    InfosData?.data?.map((value) => {
      newInfosData.push({
        title: value?.title_lang,
        is_read:
          value?.is_read === true ? (
            <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.READ")}
            </div>
          ) : (
            <div className="bg-[#faf089] text-gray-800 leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.UNREAD")}
            </div>
          ),
        is_active:
          value?.is_active === true ? (
            <div className="bg-[#BAE6CC] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.ACTIVE")}
            </div>
          ) : (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NOT_ACTIVE")}
            </div>
          ),

        action: (
          <div className="flex gap-2 items-center justify-center">
            {/* {(isGetAccessBanner === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleModalFormOpen(false, true, true, value?.guid, true)
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.VIEW")}
                </div>
              </Button>
            {/* )} */}
            {/* {(isDeleteAccessBanner === 1 || isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#FF0F0F]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() => handleDialogDeleteInfo(true, value?.guid)}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.DELETE")}
                </div>
              </Button>
            {/* )} */}
          </div>
        ),
      });
    });
    setnewInfo(newInfosData);
  }, [
    InfosData,
    token,
    isGetAccessBanner,
    isDeleteAccessBanner,
    isViewAllAccess,
    t("LANGUAGE"),
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,
        formUpload,

        resetFormEn,
        resetFormUpload,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        formPreviewUpload,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,
        resetFormPreviewUpload,

        topicId,
        isEdit,
        setIsEdit,
        isCreate,
        isModalFormOpen,
        isVisibleInfoDialog,
        setIsVisibleInfoDialog,
        isVisibleDeleteInfo,
        setIsVisibleDeleteInfo,
        isVisibleDeleteInfoInfo,
        setIsVisibleDeleteInfoInfo,

        isLoadingDeleteInfo,

        isLoadingInfosData,
        // isLoadingGetAdvertisings,

        InfosData,
        newInfo,
        newInfosData,
        handleDialogDeleteInfo,
        handleModalFormOpen,
        handleShowEntriesTable,
        handlePaginationTable,
        refetchInfosData,
        handleDeleteInfo,
        handleSearch,

        payloadInfoEdit,
        setPayloadInfoEdit,
        urlBannerThumbnail,
        setUrlBannerThumbnail,
        urlBannerThumbnailEdit,
        setUrlBannerThumbnailEdit,
        isView,
        setIsView,

        indexTabActive,
        setIndexTabActive,
        isPublishInfoOpen,
        setIsPublishInfoOpen,
        handlePublishInfoOpen,

        enDescription,
        idDescription,
        cnDescription,

        isUpdateAccessBanner,
        isGetAccessBanner,
        isDeleteAccessBanner,
        isViewAllAccess,

        isShowStatus,
        isSearch,
        isFileBanner,
        setIsFileBanner,

        urlBannerThumbnailWebsite,
        setUrlBannerThumbnailWebsite,
        urlBannerThumbnailWebsiteEdit,
        setUrlBannerThumbnailWebsiteEdit,

        logoUrl,
        setLogoUrl,
        logoUrlEdit,
        setLogoUrlEdit,
        urlBannerThumbnailEn,
        setUrlBannerThumbnailEn,
        urlBannerThumbnailEnEdit,
        setUrlBannerThumbnailEnEdit,

        urlBannerThumbnailWebsiteId,
        setUrlBannerThumbnailWebsiteId,
        urlBannerThumbnailWebsiteIdEdit,
        setUrlBannerThumbnailWebsiteIdEdit,
        urlBannerThumbnailId,
        setUrlBannerThumbnailId,
        urlBannerThumbnailIdEdit,
        setUrlBannerThumbnailIdEdit,

        urlBannerThumbnailWebsiteCn,
        setUrlBannerThumbnailWebsiteCn,
        urlBannerThumbnailWebsiteCnEdit,
        setUrlBannerThumbnailWebsiteCnEdit,
        urlBannerThumbnailCn,
        setUrlBannerThumbnailCn,
        urlBannerThumbnailCnEdit,
        setUrlBannerThumbnailCnEdit, 
        isActiveInfo,
        setIsActiveInfo,
        newInfoSearch, 
        setNewInfoSearch ,
        formKeywordSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default InfoController;
