import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { Button, Switch, Tag, TagLabel, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  deleteAdvertising,
  getAdvertising,
  getAdvertisings,
  getAdvertisingSearch,
  setPublishAdvertising,
  updateToggleAdvertising,
} from "./api/AdvertisingApi";

import {
  getAdvertisingCategory,
  getAdvertisingCategoryId,
} from "./api/AdvertisingCategoryApi";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import Cookies from "js-cookie";

export const AdvertisingContext = createContext();
const { Provider } = AdvertisingContext;

const AdvertisingController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: advertisingCategoryData,
    isLoading: isLoadingAdvertisingCategoryData,
    refetch,
  } = useQuery(["category", token, language], () =>
    getAdvertisingCategory({ token, language })
  );
  const { mutate: mutateGetAdvertisingCategoryId } = useMutation(
    getAdvertisingCategoryId
  );

  const {
    data: AdvertisingsData,
    isLoading: isLoadingAdvertisingsData,
    refetch: refetchAdvertisingData,
  } = useQuery(["advertisings", token, language], () =>
    getAdvertisings({ token, language })
  );

  const {
    isLoading: isLoadingGetAdvertisings,
    mutate: mutateGetAdvertisings,
    refetch: refetchAdvertisingPagination,
  } = useMutation(getAdvertisings);

  const { mutate: mutateSetPublishAdvertising } = useMutation(
    setPublishAdvertising
  );

  const {
    isLoading: isLoadingSetAdvertisingSearch,
    mutate: mutateSetAdvertisingSearch,
  } = useMutation(getAdvertisingSearch);

  const {
    isLoading: isLoadingDeleteAdvertising,
    mutate: mutateDeleteAdvertising,
  } = useMutation(deleteAdvertising);

  const {
    isLoading: isLoadingUpdateToggleAdvertising,
    mutate: mutateUpdateToggleAdvertising,
  } = useMutation(updateToggleAdvertising);

  const { mutate: mutateGetAdvertising } = useMutation(getAdvertising);

  const [newAdvertisings, setNewAdvertisings] = useState();

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isShowCategory, setIsShowCategory] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleGlobalDialog, setIsVisibleGlobalDialog] = useState(false);
  const [isVisiblePropertyDialog, setIsVisiblePropertyDialog] = useState(false);
  const [isVisibleVehicleDialog, setIsVisibleVehicleDialog] = useState(false);
  const [isVisibleBannerDialog, setIsVisibleBannerDialog] = useState(false);
  const [isVisibleDeleteAdvertising, setIsVisibleDeleteAdvertising] =
    useState(false);
  const [isVisibleDeleteInfoAdvertising, setIsVisibleDeleteInfoAdvertising] =
    useState(false);
  const [isAdvertisingCategorySelected, setIsAdvertisingCategorySelected] =
    useState(false);
  const [isGetAccessAdvertising, setIsGetAccessAdvertising] = useState();
  const [isDeleteAccessAdvertising, setIsDeleteAccessAdvertising] = useState();
  const [isUpdateAccessAdvertising, setIsUpdateAccessAdvertising] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();

  const [categoryAdsSelected, setCategoryAdsSelected] = useState([]);
  const [payloadPropertyEdit, setPayloadPropertyEdit] = useState();
  const [payloadVehicleEdit, setPayloadVehicleEdit] = useState();
  const [payloadGlobalEdit, setPayloadGlobalEdit] = useState();
  const [advertisingId, setAdvertisingId] = useState();
  const [indexTabActive, setIndexTabActive] = useState();

  const [globalEnDescription, setGlobalEnDescription] = useState("");
  const [globalIdDescription, setGlobalIdDescription] = useState("");
  const [globalCnDescription, setGlobalCnDescription] = useState("");

  const [propertyEnDescription, setPropertyEnDescription] = useState("");
  const [propertyIdDescription, setPropertyIdDescription] = useState("");
  const [propertyCnDescription, setPropertyCnDescription] = useState("");

  const [vehicleEnDescription, setVehicleEnDescription] = useState("");
  const [vehicleIdDescription, setVehicleIdDescription] = useState("");
  const [vehicleCnDescription, setVehicleCnDescription] = useState("");
  const [selectedCategorySearch, setSelectedCategorySearch] = useState(null);  
  const [newAdvertisingSearch, setNewAdvertisingSearch] = useState();

  let newAdvertisingsData = [];
  let currentPage = [];
  let currentLimit = [];

  let adsCategory = [];
  advertisingCategoryData?.data?.map((value) =>
    adsCategory?.push({
      advertisingCategory: value?.title,
      code: value?.id,
    })
  );

  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Advertising
          const getAccessAdvertising = data?.data?.access
            ?.filter((data) => data?.page === "advertising")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessAdvertising(getAccessAdvertising?.length);

          const deleteAccessAdvertising = data?.data?.access
            ?.filter((data) => data?.page === "advertising")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessAdvertising(deleteAccessAdvertising?.length);

          const updateAccessAdvertising = data?.data?.access
            ?.filter((data) => data?.page === "advertising")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessAdvertising(updateAccessAdvertising?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formEn.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, []);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formId.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, []);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formCn.reset({
        date: defaultValue,
        title: newForm?.title,
        description: newForm?.description,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, []);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewEn.reset({
        date: defaultValue,
        title: newForm?.en?.title?.[0]?.en,
        description: newForm?.en?.description?.[0]?.en,
        banner: newForm?.en?.banner?.[0]?.thumbnail,
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewId.reset({
        date: defaultValue,
        title: newForm?.id?.title?.[0]?.id,
        description: newForm?.id?.description?.[0]?.id,
        banner: newForm?.id?.banner?.[0]?.thumbnail,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = moment(date).format("YYYY-MM-DDTHH:mm");

    if (newForm) {
      formPreviewCn.reset({
        date: defaultValue,
        title: newForm?.cn?.title?.[0]?.cn,
        description: newForm?.cn?.description?.[0]?.cn,
        banner: newForm?.cn?.banner?.[0]?.thumbnail,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  const handleModalFormOpen = (
    isCreate,
    isOpen,
    isEdit,
    guid,
    categoryId,
    isView
  ) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    setAdvertisingId(guid);
    setIndexTabActive(0);
    handleAdvertisingCategorySelected(categoryId, isEdit);

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});

      //Global Image Lainnya
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxglobalen"));
        index++
      ) {
        Cookies.remove("keyglobalen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxglobalen"));
        index++
      ) {
        Cookies.remove("keyglobalediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxglobalmobileen"));
        index++
      ) {
        Cookies.remove("keyglobalmobileen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxglobalmobileen"));
        index++
      ) {
        Cookies.remove("keyglobalmobileediten" + index);
      }

      //Property Image Lainnya
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxpropertyen"));
        index++
      ) {
        Cookies.remove("keypropertyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxpropertyen"));
        index++
      ) {
        Cookies.remove("keypropertyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxpropertymobileen"));
        index++
      ) {
        Cookies.remove("keypropertymobileen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxpropertymobileen"));
        index++
      ) {
        Cookies.remove("keypropertymobileediten" + index);
      }

      //Vehicle Image Lainnya
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxvehicleen"));
        index++
      ) {
        Cookies.remove("keyvehicleen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxvehicleen"));
        index++
      ) {
        Cookies.remove("keyvehicleediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxvehiclemobileen"));
        index++
      ) {
        Cookies.remove("keyvehiclemobileen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxvehiclemobileen"));
        index++
      ) {
        Cookies.remove("keyvehiclemobileediten" + index);
      }
    }

    if (isEdit === true) {
      //Global Image Lainnya
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxglobalen"));
        index++
      ) {
        Cookies.remove("keyglobalen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxglobalen"));
        index++
      ) {
        Cookies.remove("keyglobalediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxglobalmobileen"));
        index++
      ) {
        Cookies.remove("keyglobalmobileen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxglobalmobileen"));
        index++
      ) {
        Cookies.remove("keyglobalmobileediten" + index);
      }

      //Property Image Lainnya
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxpropertyen"));
        index++
      ) {
        Cookies.remove("keypropertyen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxpropertyen"));
        index++
      ) {
        Cookies.remove("keypropertyediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxpropertymobileen"));
        index++
      ) {
        Cookies.remove("keypropertymobileen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxpropertymobileen"));
        index++
      ) {
        Cookies.remove("keypropertymobileediten" + index);
      }

      //Vehicle Image Lainnya
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxvehicleen"));
        index++
      ) {
        Cookies.remove("keyvehicleen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxvehicleen"));
        index++
      ) {
        Cookies.remove("keyvehicleediten" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxvehiclemobileen"));
        index++
      ) {
        Cookies.remove("keyvehiclemobileen" + index);
      }
      for (
        let index = 0;
        index < parseInt(Cookies.get("idxvehiclemobileen"));
        index++
      ) {
        Cookies.remove("keyvehiclemobileediten" + index);
      }
    }

    isEdit &&
      mutateGetAdvertising(
        { guid, token, language },
        {
          onSuccess: (data) => {
            if (data?.data?.category_name?.en === "Property") {
              setPropertyEnDescription(data?.data?.descriptions);
              setPropertyIdDescription(data?.data?.descriptions);
              setPropertyCnDescription(data?.data?.descriptions);

              setPayloadPropertyEdit(data?.data);
            } else if (data?.data?.category_name?.en === "Global") {
              setGlobalEnDescription(data?.data?.descriptions);
              setGlobalIdDescription(data?.data?.descriptions);
              setGlobalCnDescription(data?.data?.descriptions);

              setPayloadGlobalEdit(data?.data);
            } else if (data?.data?.category_name?.en === "Vehicle") {
              setVehicleEnDescription(data?.data?.descriptions);
              setVehicleIdDescription(data?.data?.descriptions);
              setVehicleCnDescription(data?.data?.descriptions);

              setPayloadVehicleEdit(data?.data);
            }
          },
        }
      );
  };

  const handleAdvertisingCategorySelected = (dataSelected, isOpen) => {
    const selectedCategory = adsCategory
      ?.filter((code) => code?.code === dataSelected)
      .map((data) => data);
    setCategoryAdsSelected(selectedCategory?.[0]);
    setIsAdvertisingCategorySelected(isOpen);
  };

  const handlePublishAdvertising = (payload) => {
    mutateSetPublishAdvertising(
      { payload, token },
      {
        onSuccess: (data) => {
          refetchAdvertisingData();
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
        },
      }
    );
  };

  const handleDialogDeleteAdvertising = (isOpen, guid) => {
    setIsVisibleDeleteAdvertising(isOpen);
    setAdvertisingId(guid);
    setIsVisibleDeleteInfoAdvertising(false);
  };

  const handleDeleteAdvertising = (guid) => {
    mutateDeleteAdvertising(
      { guid, token, language },
      {
        onSuccess: () => {
          refetchAdvertisingData();
          setIsVisibleDeleteAdvertising(false);
          setIsVisibleDeleteInfoAdvertising(true);
        },
      }
    );
  };

  const handleUpdateToggleAdvertising = (guid) => {
    mutateUpdateToggleAdvertising(
      { guid, token, language },
      {
        onSuccess: (data) => {
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
          handlePaginationTable(currentPage?.[0], currentLimit?.[0]);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isTitle = keyword?.keyword_filter === "1" && true;
    // let isCategory = keyword?.keyword_filter === "2" && true;
    // let isStatus = keyword?.keyword_filter === "3" && true;

    let isTitle         = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isCategory      = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isStatus        = keyword?.keyword_filter?.value === "3" || (keyword?.keyword_filter === "3" && true);
    let isSearchStatus  = keyword?.keyword_status === "1" && true;
    let searchKeyword   = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    setIsShowStatus(isStatus);
    setIsShowCategory(isCategory);
    setIsSearch(true);

    let payload = {
      isTitle: isTitle,
      isCategory: isCategory,
      isStatus: isStatus,
      search: searchKeyword,
      searchStatus: isSearchStatus,
      searchCategory: selectedCategorySearch?.map((newData) => newData?.id),
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    // console.log(keyword)
    // console.log(payload)

    currentPage.push(1);
    currentLimit.push(100);
    mutateSetAdvertisingSearch(
      { payload },
      {
        onSuccess: (data) => {
          setNewAdvertisingSearch(data)
          data?.data?.map((value) => {
            newAdvertisingsData.push({
              title: value?.title,
              type: value?.category_name,
              category: value?.category_name,
              show_at: "",
              tags:
                value?.tags &&
                value?.tags?.map((data) => (
                  <Tag
                    size={"md"}
                    borderRadius="full"
                    variant="solid"
                    colorScheme="global"
                    className="bg-[#504178] ml-2"
                  >
                    <TagLabel>{data}</TagLabel>
                  </Tag>
                )),
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              // author: value?.author_id,
              publish_from: moment(value?.publish_from).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              publish_to: moment(value?.publish_to).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              seen: value?.views,
              likes: value?.likes,
              image: (
                <img
                  src={value?.image_website?.thumbnail}
                  alt=""
                  className="w-24"
                />
              ),
              draft:
                value?.is_draft === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUpdateToggleAdvertising(value?.id)}
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  {/* {(isGetAccessAdvertising === 1 ||
                    isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(
                        false,
                        true,
                        true,
                        value?.id,
                        value?.category_id,
                        true
                      )
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  {/* )} */}

                  {/* {(isDeleteAccessAdvertising === 1 ||
                    isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleDialogDeleteAdvertising(true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewAdvertisings(newAdvertisingsData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetAdvertisings(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newAdvertisingsData = [];

          data?.data?.map((value) => {
            newAdvertisingsData.push({
              title: value?.title,
              type: value?.category_name,
              category: value?.category_name,
              show_at: "",
              tags:
                value?.tags &&
                value?.tags?.map((data) => (
                  <Tag
                    size={"md"}
                    borderRadius="full"
                    variant="solid"
                    colorScheme="global"
                    className="bg-[#504178] ml-2"
                  >
                    <TagLabel>{data}</TagLabel>
                  </Tag>
                )),
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              // author: value?.author_id,
              publish_from: moment(value?.publish_from).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              publish_to: moment(value?.publish_to).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              seen: value?.views,
              likes: value?.likes,
              image: (
                <img
                  src={value?.image_website?.thumbnail}
                  alt=""
                  className="w-24"
                />
              ),
              draft:
                value?.is_draft === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUpdateToggleAdvertising(value?.id)}
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  {/* {(isGetAccessAdvertising === 1 ||
                    isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(
                        false,
                        true,
                        true,
                        value?.id,
                        value?.category_id,
                        true
                      )
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  {/* )} */}

                  {/* {(isDeleteAccessAdvertising === 1 ||
                    isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleDialogDeleteAdvertising(true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewAdvertisings(newAdvertisingsData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetAdvertisings(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newAdvertisingsData = [];

          data?.data?.map((value) => {
            newAdvertisingsData.push({
              title: value?.title,
              type: value?.category_name,
              category: value?.category_name,
              show_at: "",
              tags:
                value?.tags &&
                value?.tags?.map((data) => (
                  <Tag
                    size={"md"}
                    borderRadius="full"
                    variant="solid"
                    colorScheme="global"
                    className="bg-[#504178] ml-2"
                  >
                    <TagLabel>{data}</TagLabel>
                  </Tag>
                )),
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              // author: value?.author_id,
              publish_from: moment(value?.publish_from).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              publish_to: moment(value?.publish_to).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              seen: value?.views,
              likes: value?.likes,
              image: (
                <img
                  src={value?.image_website?.thumbnail}
                  alt=""
                  className="w-24"
                />
              ),
              draft:
                value?.is_draft === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              is_active: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUpdateToggleAdvertising(value?.id)}
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  {/* {(isGetAccessAdvertising === 1 ||
                    isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(
                        false,
                        true,
                        true,
                        value?.id,
                        value?.category_id,
                        true
                      )
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                  {/* )} */}

                  {/* {(isDeleteAccessAdvertising === 1 ||
                    isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleDialogDeleteAdvertising(true, value?.id)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewAdvertisings(newAdvertisingsData);
        },
      }
    );
  };

  useEffect(() => {
    newAdvertisingsData = [];
    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    AdvertisingsData?.data?.map((value) => {
      newAdvertisingsData.push({
        title: value?.title,
        type: value?.category_name,
        category: value?.category_name,
        show_at: "",
        tags:
          value?.tags &&
          value?.tags?.map((data) => (
            <Tag
              size={"md"}
              borderRadius="full"
              variant="solid"
              colorScheme="global"
              className="bg-[#504178] ml-2"
            >
              <TagLabel>{data}</TagLabel>
            </Tag>
          )),
        created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
        // author: value?.author_id,
        publish_from: moment(value?.publish_from).format(
          "MMMM Do YYYY, HH:mm:ss"
        ),
        publish_to: moment(value?.publish_to).format("MMMM Do YYYY, HH:mm:ss"),
        seen: value?.views,
        likes: value?.likes,
        image: (
          <img src={value?.image_website?.thumbnail} alt="" className="w-24" />
        ),
        draft:
          value?.is_draft === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.YES")}
            </div>
          ) : (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NO")}
            </div>
          ),
        is_active: (
          <Switch
            colorScheme={value?.is_active === true ? "green" : "red"}
            isChecked={value?.is_active === true ? true : false}
            onChange={() => handleUpdateToggleAdvertising(value?.id)}
          />
        ),
        status:
          value?.is_active === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.ACTIVE")}
            </div>
          ) : (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NOT_ACTIVE")}
            </div>
          ),
        action: (
          <div className="flex gap-2">
            {/* {(isGetAccessAdvertising === 1 || isViewAllAccess === true) && ( */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleModalFormOpen(
                  false,
                  true,
                  true,
                  value?.id,
                  value?.category_id,
                  true
                )
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.VIEW")}
              </div>
            </Button>
            {/* )} */}

            {/* {(isDeleteAccessAdvertising === 1 || isViewAllAccess === true) && ( */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF0F0F]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleDialogDeleteAdvertising(true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.DELETE")}
              </div>
            </Button>
            {/* )} */}
          </div>
        ),
      });
    });
    setNewAdvertisings(newAdvertisingsData);
  }, [
    AdvertisingsData,
    token,
    isGetAccessAdvertising,
    isDeleteAccessAdvertising,
    isViewAllAccess,
    t("LANGUAGE"),
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,

        advertisingId,
        isEdit,
        isCreate,
        isModalFormOpen,
        isVisibleDeleteAdvertising,
        isVisibleDeleteInfoAdvertising,
        isVisibleBannerDialog,
        setIsVisibleBannerDialog,

        isLoadingDeleteAdvertising,
        isLoadingAdvertisingsData,
        isLoadingGetAdvertisings,
        isVisibleGlobalDialog,
        setIsVisibleGlobalDialog,
        isVisiblePropertyDialog,
        setIsVisiblePropertyDialog,
        isVisibleVehicleDialog,
        setIsVisibleVehicleDialog,
        isAdvertisingCategorySelected,

        categoryAdsSelected,
        adsCategory,
        advertisingCategoryData,
        AdvertisingsData,
        newAdvertisings,
        handleDialogDeleteAdvertising,
        handleAdvertisingCategorySelected,
        handleModalFormOpen,
        handleShowEntriesTable,
        handlePaginationTable,
        refetchAdvertisingData,
        handleDeleteAdvertising,
        handleSearch,

        payloadPropertyEdit,
        setPayloadPropertyEdit,
        payloadVehicleEdit,
        setPayloadVehicleEdit,
        payloadGlobalEdit,
        setPayloadGlobalEdit,

        isView,
        setIsView,
        indexTabActive,
        setIndexTabActive,

        globalEnDescription,
        globalIdDescription,
        globalCnDescription,

        propertyEnDescription,
        propertyIdDescription,
        propertyCnDescription,

        vehicleEnDescription,
        vehicleIdDescription,
        vehicleCnDescription,

        isGetAccessAdvertising,
        isDeleteAccessAdvertising,
        isUpdateAccessAdvertising,
        isViewAllAccess,

        isShowStatus,
        isShowCategory,
        selectedCategorySearch,
        setSelectedCategorySearch,
        isSearch,

        setIsShowStatus,
        formKeywordSearch,

        newAdvertisingSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default AdvertisingController;
