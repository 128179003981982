import { Button, Switch, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import {
  getCustomsDeclarationList,
  getCustomsDeclarationListSearch,
  getCustomsDeclarationById,
  approveCustomsDeclaration,
  rejectCustomsDeclaration,
  // getTransactionById,
  // getTransactionList,
  // getTransactionListExport,
  // getTransactionListSearch,
} from "./api/CustomsDeclarationApi";
// import {
//   getBrand,
//   getCategoryPpob,
//   getPricelist,
//   getPricelistId,
//   getPricelistSearch,
//   getSyncPriceList,
//   getTypes,
//   updateSellPrice,
// } from "./api/ppobApi";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const CustomsDeclarationTransactionContext = createContext();
const { Provider } = CustomsDeclarationTransactionContext;

const CustomsDeclarationTransactionController = ({ children }) => {
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  let language = t("LANGUAGE");

  const toast = useToast();

  const [newCustomsDeclarationLists, setNewCustomsDeclarationLists] =
    useState();
  const [newFamily, setFamily] = useState();
  const [newInformationGoodsAnimals, setInformationGoodsAnimals] = useState();
  const [newInformationGoodsNarcotics, setInformationGoodsNarcotics] =
    useState();
  const [newInformationGoodsCurrency, setInformationGoodsCurrency] = useState();
  const [newInformationGoodsForeign, setInformationGoodsForeign] = useState();
  const [newInformationGoodsCigarettes, setInformationGoodsCigarettes] =
    useState();
  const [newInformationGoodsPurchased, setInformationGoodsPurchased] =
    useState();
  const [newInformationGoodsImport, setInformationGoodsImport] = useState();
  const [newInformationGoodsCarrying, setInformationGoodsCarrying] = useState();
  const [newImei, setImei] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isModalFormUpdateSellPriceOpen, setIsModalFormUpdateSellPriceOpen] =
    useState(false);
  const [customsDeclarationId, setCustomsDeclarationId] = useState();
  const [isVisibleUpdateSellPriceInfo, setIsVisibleUpdateSellPriceInfo] =
    useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isVisibleDownloadTransaction, setIsVisibleDownloadTransaction] =
    useState(false);
  
  const [newTransactionCustomDeclarationsSearch, setNewTransactionCustomDeclarationsSearch] = useState();

  const [
    isVisibleDownloadInfoTransaction,
    setIsVisibleDownloadInfoTransaction,
  ] = useState(false);

  const [
    isVisibleApproveCustomsDeclaration,
    setIsVisibleApproveCustomsDeclaration,
  ] = useState(false);
  const [
    isVisibleApproveCustomsDeclarationInfo,
    setIsVisibleApproveCustomsDeclarationInfo,
  ] = useState(false);
  const [
    isVisibleRejectCustomsDeclaration,
    setIsVisibleRejectCustomsDeclaration,
  ] = useState(false);
  const [
    isVisibleRejectCustomsDeclarationInfo,
    setIsVisibleRejectCustomsDeclarationInfo,
  ] = useState(false);

  const [isVisibleFilterRange, setIsVisibleFilterRange] = useState(false);
  const [isVisibleFilterRangeInfo, setIsVisibleFilterRangeInfo] =
    useState(false);

  const {
    isLoading: isLoadingApproveCustomsDeclaration,
    mutate: mutateApproveCustomsDeclaration,
  } = useMutation(approveCustomsDeclaration);

  const {
    isLoading: isLoadingRejectCustomsDeclaration,
    mutate: mutateRejectCustomsDeclaration,
  } = useMutation(rejectCustomsDeclaration);

  const [pres, setPres] = useState();

  const {
    data: customsDeclarationData,
    isLoading: isLoadingcustomsDeclarationData,
    refetch: refecthCustomsDeclarationData,
  } = useQuery(["transactionlist", token, language], () =>
    getCustomsDeclarationList({ token, language })
  );

  // const {
  //   data: ppobBrandData,
  //   isLoading: isLoadingppobBrandData,
  //   refetch: brandData,
  // } = useQuery(["brand", token], () => getBrand({ token }));

  // const {
  //   data: ppobTypesData,
  //   isLoading: isLoadingppobTypesData,
  //   refetch: typesData,
  // } = useQuery(["types", token], () => getTypes({ token }));

  // const {
  //   data: PpobData,
  //   isLoading: isLoadingPpobData,
  //   refetch: refetchPpobData,
  // } = useQuery(["ppob", token], () => getPricelist({ token }));

  const {
    isLoading: isLoadingSetCustomsDeclarationListDataSearch,
    mutate: mutateSetCustomsDeclarationListDataSearch,
  } = useMutation(getCustomsDeclarationListSearch);

  const {
    isLoading: isLoadingGetCustomsDeclarationList,
    mutate: mutateGetCustomsDeclarationList,
    refetch: refetchGetCustomsDeclarationList,
  } = useMutation(getCustomsDeclarationList);

  // const {
  //   isLoading: isLoadingGetTransactionListExport,
  //   mutate: mutateGetCustomsDeclarationListExport,
  //   refetch: refetchGetTransactionListExport,
  // } = useMutation(getTransactionListExport);

  // const { isLoading: isLoadingUpdateSellPrice, mutate: mutateUpdateSellPrice } =
  //   useMutation(updateSellPrice);

  const { mutate: mutateGetCustomsDeclarationId } = useMutation(
    getCustomsDeclarationById
  );

  // const {
  //   isLoading: isLoadingGetSyncPriceList,
  //   mutate: mutateGetSyncPriceList,
  // } = useMutation(getSyncPriceList);

  let newCustomsDeclarationListsData = [];
  let newFamilyData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessPrabayarTransaction, setIsGetAccessPrabayarTransaction] =
    useState();
  const [
    isDeleteAccessPrabayarTransaction,
    setIsDeleteAccessPrabayarTransaction,
  ] = useState();
  const [
    isUpdateAccessPrabayarTransaction,
    setIsUpdateAccessPrabayarTransaction,
  ] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Prabayar Transaction
          const getAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessPrabayarTransaction(
            getAccessPrabayarTransaction?.length
          );

          const deleteAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessPrabayarTransaction(
            deleteAccessPrabayarTransaction?.length
          );

          const updateAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessPrabayarTransaction(
            updateAccessPrabayarTransaction?.length
          );
        },
      }
    );
  }, [roleIdUser]);

  const handleDialogFilterRange = (isOpen, guid) => {
    // console.log(isPublish)
    setIsVisibleFilterRange(isOpen);
    // setAdvertisingRowId(guid);
    // setIsPublish(isPublish);
    setIsVisibleFilterRangeInfo(false);

    // mutateGetAdvertisingRow(
    //   { guid, token, language },
    //   {
    //     onSuccess: (data) => {
    // resetFormPublish({});
    //       setPublishPayloadClassifiedAdsEdit(data?.data);
    //     },
    //   }
    // );
  };

  const handleFilterRange = (payload) => {
    const defaultDatePublishFrom = moment(payload?.publish_from).format();
    const defaultDatePublishTo = moment(payload?.publish_to).format();

    const payloadPublish = {
      publish_from: defaultDatePublishFrom,
      publish_to: defaultDatePublishTo,
    };

    console.log(payloadPublish);
    // mutateGetTransactionListExport(
    //   { token, language, payloadPublish },
    //   {
    //     onSuccess: (data) => {
    //       console.log(data);
    //     },
    //   }
    // );
  };

  // // -------------------------------
  // // Form Modal Update Sell Price
  // // -------------------------------
  // const formUpdateSellPrice = useForm();
  // const resetformUpdateSellPrice = (newForm) => {
  //   if (newForm) {
  //     formUpdateSellPrice.reset({
  //       slug_name: newForm?.slug_name,
  //       sell_price: newForm?.sell_price,
  //     });
  //   }
  // };

  // -------------------------------
  // Form Publish
  // -------------------------------
  const formPublish = useForm();
  const resetFormPublish = (newForm) => {
    const dateFrom = new Date();
    const dateTo = new Date();
    const publishFrom = dateFrom.getDate() + 1;
    const publishTo = dateTo.getDate() + 2;
    dateFrom.setDate(publishFrom);
    dateTo.setDate(publishTo);
    const defaultDatePublishFrom = moment(dateFrom).format("YYYY-MM-DDTHH:mm");
    const defaultDatePublishTo = moment(dateTo).format("YYYY-MM-DDTHH:mm");

    const publishFromEdit = moment(newForm?.publish_from).format(
      "YYYY-MM-DDTHH:mm"
    );
    const publishToEdit = moment(newForm?.publish_to).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formPublish.reset({
        publish_from: newForm?.publish_from,
        publish_to: newForm?.publish_to,
        status: 1,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        // keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
      });
    }
  };

  const handleDialogDownloadTransaction = (isOpen, guid) => {
    // console.log(isPublish)
    setIsVisibleDownloadTransaction(isOpen);
    // setAdvertisingRowId(guid);
    // setIsPublish(isPublish);
    setIsVisibleDownloadInfoTransaction(false);

    // mutateGetAdvertisingRow(
    //   { guid, token, language },
    //   {
    //     onSuccess: (data) => {
    resetFormPublish({});
    //       setPublishPayloadClassifiedAdsEdit(data?.data);
    //     },
    //   }
    // );
  };

  // const handleDownloadTransaction = (payload) => {
  //   // const dateFrom = new Date();
  //   // const dateTo = new Date();
  //   // const publishFrom = dateFrom.getDate() + 1;
  //   // const publishTo = dateTo.getDate() + 2;
  //   // dateFrom.setDate(publishFrom);
  //   // dateTo.setDate(publishTo);
  //   const defaultDatePublishFrom = moment(payload?.publish_from).format();
  //   const defaultDatePublishTo = moment(payload?.publish_to).format();

  //   const payloadPublish = {
  //     publish_from: defaultDatePublishFrom,
  //     publish_to: defaultDatePublishTo,
  //   };

  //   // const guid = advertisingRowId;

  //   // console.log(payloadPublish);

  //   mutateGetCustomsDeclarationListExport(
  //     { token, language, payloadPublish },
  //     {
  //       onSuccess: (data) => {
  //         // refetchAdvertisingRowData();
  //         // setIsVisiblePublishAdvertisingClassified(false);
  //         // setIsVisiblePublishInfoAdvertisingClassified(true);
  //         // console.log(data?.data);

  //         const fileType =
  //           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //         const fileExtension = ".xlsx";

  //         const customHeadings = data?.data?.map((item) => ({
  //           "Transaksi ID": item?.transaction_id,
  //           Tanggal: moment(item?.created_at).format("MMMM Do YYYY, h:mm:ss a"),
  //           "Nama Produk": item?.product_name,
  //           "Buyer Transaksi ID": item?.buyer_sku_code,
  //           "Kode Buyer": item?.buyer_sku_code,
  //           "No Pelanggan": item?.user_phone,
  //           "Nama Pelanggan": item?.user_full_name,
  //           "Tagihan Pelanggan": new Intl.NumberFormat("id-ID", {
  //             style: "currency",
  //             currency: "IDR",
  //           }).format(item?.sell_price),
  //           Harga: new Intl.NumberFormat("id-ID", {
  //             style: "currency",
  //             currency: "IDR",
  //           }).format(item?.price),
  //           SN: "serial_number",
  //           "Seller Name": item.seller_name,
  //           "Seller Transaksi ID": item.biller_ref_id,
  //           "Kode Seller": item.buyer_sku_code,
  //           Info: item.payment_status,
  //           Status: item.status,
  //         }));

  //         const ws = XLSX.utils.json_to_sheet(customHeadings);
  //         const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  //         const excelBuffer = XLSX.write(wb, {
  //           bookType: "xlsx",
  //           type: "array",
  //         });
  //         const datas = new Blob([excelBuffer], { type: fileType });

  //         FileSaver.saveAs(datas, "Transaction" + fileExtension);
  //       },
  //     }
  //   );
  // };

  // const handleModalFormOpen = (isOpen, guid) => {
  //   setIsModalFormOpen(isOpen);
  //   setIsEdit(isEdit);
  //   setCustomsDeclarationId(guid);
  //   mutateGetPricelistId(
  //     { guid, token },
  //     {
  //       onSuccess: (data) => {
  //         console.log(data);
  //         resetformUpdateSellPrice(data?.data);
  //       },
  //     }
  //   );
  //   // isCreate && resetformModal({});
  // };

  // const handleDialogUpdateSellPrice = (isOpen, guid) => {
  //   setIsModalFormOpen(isOpen);
  //   setPriceId(guid);
  //   setIsVisibleUpdateSellPriceInfo(false);
  // };

  // const handleUpdateSellPrice = (payload) => {
  //   const newPayload = {
  //     slug_name: payload?.slug_name,
  //     sell_price: payload?.sell_price,
  //   };
  //   // console.log(newPayload);
  //   mutateUpdateSellPrice(
  //     { newPayload, token },
  //     {
  //       onSuccess: (data) => {
  //         if (data?.message?.response_code !== "00") {
  //           toast({
  //             description: "Update sell price belum lengkap",
  //             position: "top-right",
  //             status: "warning",
  //             duration: 2000,
  //             isClosable: true,
  //           });
  //         } else {
  //           refetchPpobData();
  //           setIsModalFormOpen(false);
  //           setIsVisibleUpdateSellPriceInfo(true);
  //         }
  //       },
  //     }
  //   );
  // };

  // const handleSyncPriceList = () => {
  //   mutateGetSyncPriceList(
  //     { token },
  //     {
  //       onSuccess: (data) => {
  //         if (data?.message?.response_code !== "00") {
  //           toast({
  //             description: "Sync Price Gagal",
  //             position: "top-right",
  //             status: "warning",
  //             duration: 2000,
  //             isClosable: true,
  //           });
  //         } else {
  //           refetchPpobData();
  //           toast({
  //             description: data?.message?.response_message,
  //             position: "top-right",
  //             status: "success",
  //             duration: 2000,
  //             isClosable: true,
  //           });
  //         }
  //       },
  //     }
  //   );
  // };

  const handleDialogApproveCustomsDeclaration = (isOpen, guid) => {
    setIsModalFormOpen(false);
    setIsVisibleApproveCustomsDeclaration(isOpen);
    setCustomsDeclarationId(guid);
    setIsVisibleApproveCustomsDeclarationInfo(false);
  };

  const handleApproveCustomsDeclaration = (guid) => {
    mutateApproveCustomsDeclaration(
      { guid, token, language },
      {
        onSuccess: () => {
          // refetch();
          refecthCustomsDeclarationData();
          handlePaginationTable();
          setIsVisibleApproveCustomsDeclaration(false);
          setIsVisibleApproveCustomsDeclarationInfo(true);
        },
      }
    );
  };

  const handleDialogRejectCustomsDeclaration = (isOpen, guid) => {
    setIsModalFormOpen(false);
    setIsVisibleRejectCustomsDeclaration(isOpen);
    setCustomsDeclarationId(guid);
    setIsVisibleRejectCustomsDeclarationInfo(false);
  };

  const handleRejectCustomsDeclaration = (guid) => {
    mutateRejectCustomsDeclaration(
      { guid, token, language },
      {
        onSuccess: () => {
          // refetch();
          refecthCustomsDeclarationData();
          handlePaginationTable();
          setIsVisibleRejectCustomsDeclaration(false);
          setIsVisibleRejectCustomsDeclarationInfo(true);
        },
      }
    );
  };

  // -------------------------------
  // Form Modal Transaction
  // -------------------------------
  const formCustomsDeclarationDetail = useForm();
  const resetformCustomsDeclarationDetail = (newForm) => {
    if (newForm) {
      formCustomsDeclarationDetail.reset({
        id: newForm?.id,
        status: newForm?.status,
        full_name: newForm?.full_name,
        email: newForm?.email,
        passport_number: newForm?.passport_number,
        nationality: newForm?.nationality,
        date_of_birth: newForm?.date_of_birth,
        occupation_job: newForm?.occupation_job,
        address_in_indonesia: newForm?.address_in_indonesia,
        place_of_arrival: newForm?.place_of_arrival,
        flight_voyage_number: newForm?.flight_voyage_number,
        date_of_arrival: newForm?.date_of_arrival,

        number_of_accompained_baggage: newForm?.number_of_accompained_baggage,
        number_of_unaccompained_baggage:
          newForm?.number_of_unaccompained_baggage,
        number_of_family: newForm?.number_of_family,

        created_at: newForm?.created_at,
        approved_at: newForm?.approved_at,
        rejected_at: newForm?.rejected_at,
        updated_at: newForm?.updated_at,
      });
    }
  };

  const handleDialogCustomsDeclaration = (isOpen, guid) => {
    setIsModalFormOpen(isOpen);
    setCustomsDeclarationId(guid);
    // setIsVisibleTopUpBalanceInfo(false);
    // setIsShowType(true);

    // newFamilyData = [];
    mutateGetCustomsDeclarationId(
      { guid, token },
      {
        onSuccess: (data) => {
          setFamily(data?.data?.family);
          setInformationGoodsAnimals(
            data?.data?.information_of_goods
              ?.animals_fish_and_plants_including_their_products?.data
          );
          setInformationGoodsNarcotics(
            data?.data?.information_of_goods
              ?.narcotics_psychotropic_substances_precursors_drugs_fire_arms_air_guns_sharp_objects_ammunitions_explosives_pornography_objects
              ?.data
          );
          setInformationGoodsCurrency(
            data?.data?.information_of_goods
              ?.currency_bearer_negotiable_instrument_in_rupiah?.data
          );
          setInformationGoodsForeign(
            data?.data?.information_of_goods
              ?.foreign_bank_notes_which_equal_to_the_amount_of_1_billion_rupiah
              ?.data
          );
          setInformationGoodsCigarettes(
            data?.data?.information_of_goods
              ?.more_than_200_cigarettes_or_25_cigars_or_100_grams_of_sliced_tobacco
              ?.data
          );
          setInformationGoodsPurchased(
            data?.data?.information_of_goods
              ?.goods_purchased_obtained_abroard_and_will_remain_in_indonesia
              ?.data
          );
          setInformationGoodsImport(
            data?.data?.information_of_goods
              ?.import_goods_that_are_not_considered_as_personal_effect?.data
          );
          setInformationGoodsCarrying(
            data?.data?.information_of_goods?.carrying_goods_from_indonesia
              ?.data
          );
          setImei(data?.data?.imei);

          resetformCustomsDeclarationDetail(data.data);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    let isWaitingApprovePayment = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isProcess               = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isPaymentReject         = keyword?.keyword_filter?.value === "3" || (keyword?.keyword_filter === "3" && true);
    let isSuccess               = keyword?.keyword_filter?.value === "4" || (keyword?.keyword_filter === "4" && true);
    let isProcessFailed         = keyword?.keyword_filter?.value === "5" || (keyword?.keyword_filter === "5" && true);
    let isRange = formPublish?.getValues("publish_from")?.[1] ? true : false;

    // let isProduct       = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);

    const defaultDatePublishFrom = moment(
      formPublish?.getValues("publish_from")?.[0]
    ).format();
    const defaultDatePublishTo = moment(
      formPublish?.getValues("publish_from")?.[1]
    ).format();

    setIsSearch(true);

    let payload = {
      isWaitingApprovePayment: isWaitingApprovePayment,
      isProcess: isProcess,
      isPaymentReject: isPaymentReject,
      isSuccess: isSuccess,
      isProcessFailed: isProcessFailed,
      isRange: isRange,
      // search: keyword?.keyword_search,
      publish_from:
        defaultDatePublishFrom === "Invalid date"
          ? null
          : defaultDatePublishFrom,
      publish_to:
        defaultDatePublishTo === "Invalid date" ? null : defaultDatePublishTo,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(100);
    mutateSetCustomsDeclarationListDataSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setNewTransactionCustomDeclarationsSearch(data)
            newCustomsDeclarationListsData.push({
              full_name: value?.full_name,
              // created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
              email: value?.email,
              we_chat_number: value?.we_chat_number,
              passport_number: value?.passport_number,
              nationality: value?.nationality,
              number_of_accompained_baggage:
                value?.number_of_accompained_baggage,
              number_of_unaccompained_baggage:
                value?.number_of_unaccompained_baggage,
              number_of_family: value?.number_of_family,
              status:
                value?.status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              // sell_price: (
              //   <CurrencyFormat
              //     value={value?.sell_price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // price: (
              //   <CurrencyFormat
              //     value={value?.price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // serial_number: "serial_number",
              // seller_name: value?.seller_name,
              // biller_ref_id: value?.biller_ref_id,
              // buyer_sku_code: value?.buyer_sku_code,
              // payment_status: value?.payment_status,
              // status: value?.status,

              action: (
                <div className="flex gap-2">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogCustomsDeclaration(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewCustomsDeclarationLists(newCustomsDeclarationListsData);
          handleDialogFilterRange(false);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetCustomsDeclarationList(
      { limit, token },
      {
        onSuccess: (data) => {
          newCustomsDeclarationListsData = [];

          data?.data?.map((value) => {
            newCustomsDeclarationListsData.push({
              full_name: value?.full_name,
              // created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
              email: value?.email,
              we_chat_number: value?.we_chat_number,
              passport_number: value?.passport_number,
              nationality: value?.nationality,
              number_of_accompained_baggage:
                value?.number_of_accompained_baggage,
              number_of_unaccompained_baggage:
                value?.number_of_unaccompained_baggage,
              number_of_family: value?.number_of_family,
              status:
                value?.status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              // sell_price: (
              //   <CurrencyFormat
              //     value={value?.sell_price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // price: (
              //   <CurrencyFormat
              //     value={value?.price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // serial_number: "serial_number",
              // seller_name: value?.seller_name,
              // biller_ref_id: value?.biller_ref_id,
              // buyer_sku_code: value?.buyer_sku_code,
              // payment_status: value?.payment_status,
              // status: value?.status,

              action: (
                <div className="flex gap-2">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogCustomsDeclaration(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewCustomsDeclarationLists(newCustomsDeclarationListsData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);

    mutateGetCustomsDeclarationList(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newCustomsDeclarationListsData = [];

          data?.data?.map((value) => {
            newCustomsDeclarationListsData.push({
              full_name: value?.full_name,
              // created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
              email: value?.email,
              we_chat_number: value?.we_chat_number,
              passport_number: value?.passport_number,
              nationality: value?.nationality,
              number_of_accompained_baggage:
                value?.number_of_accompained_baggage,
              number_of_unaccompained_baggage:
                value?.number_of_unaccompained_baggage,
              number_of_family: value?.number_of_family,
              status:
                value?.status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
              // sell_price: (
              //   <CurrencyFormat
              //     value={value?.sell_price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // price: (
              //   <CurrencyFormat
              //     value={value?.price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // serial_number: "serial_number",
              // seller_name: value?.seller_name,
              // biller_ref_id: value?.biller_ref_id,
              // buyer_sku_code: value?.buyer_sku_code,
              // payment_status: value?.payment_status,
              // status: value?.status,

              action: (
                <div className="flex gap-2">
                  {/* {(isGetAccessPrabayarTransaction === 1 ||
                    isViewAllAccess === true) && ( */}
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDialogCustomsDeclaration(true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.VIEW")}
                      </div>
                    </Button>
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewCustomsDeclarationLists(newCustomsDeclarationListsData);
        },
      }
    );
  };

  useEffect(() => {    
    localStorage.removeItem("search-customs-declaration");
    newCustomsDeclarationListsData = [];
    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    customsDeclarationData?.data?.map((value) => {
      newCustomsDeclarationListsData.push({
        full_name: value?.full_name,
        // created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
        email: value?.email,
        we_chat_number: value?.we_chat_number,
        passport_number: value?.passport_number,
        nationality: value?.nationality,
        number_of_accompained_baggage: value?.number_of_accompained_baggage,
        number_of_unaccompained_baggage: value?.number_of_unaccompained_baggage,
        number_of_family: value?.number_of_family,
        status:
          value?.status === "approved" ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "payment reject" ||
            value?.status === "rejected" ? (
            <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "paid" ? (
            <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : (
            <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ),
        // sell_price: (
        //   <CurrencyFormat
        //     value={value?.sell_price}
        //     displayType={"text"}
        //     thousandSeparator={true}
        //     prefix={"Rp. "}
        //   />
        // ),
        // price: (
        //   <CurrencyFormat
        //     value={value?.price}
        //     displayType={"text"}
        //     thousandSeparator={true}
        //     prefix={"Rp. "}
        //   />
        // ),
        // serial_number: "serial_number",
        // seller_name: value?.seller_name,
        // biller_ref_id: value?.biller_ref_id,
        // buyer_sku_code: value?.buyer_sku_code,
        // payment_status: value?.payment_status,
        // status: value?.status,

        action: (
          <div className="flex gap-2">
            {/* {(isGetAccessPrabayarTransaction === 1 ||
              isViewAllAccess === true) && ( */}
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() => handleDialogCustomsDeclaration(true, value?.id)}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.VIEW")}
                </div>
              </Button>
            {/* )} */}
          </div>
        ),
      });
    });
    setNewCustomsDeclarationLists(newCustomsDeclarationListsData);
  }, [
    customsDeclarationData,
    token,
    isGetAccessPrabayarTransaction,
    isDeleteAccessPrabayarTransaction,
    isUpdateAccessPrabayarTransaction,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formCustomsDeclarationDetail,

        customsDeclarationData,
        isLoadingcustomsDeclarationData,
        refecthCustomsDeclarationData,
        newCustomsDeclarationListsData,
        newCustomsDeclarationLists,

        // newFamilyData,
        newFamily,
        newInformationGoodsAnimals,
        newInformationGoodsNarcotics,
        newInformationGoodsCurrency,
        newInformationGoodsForeign,
        newInformationGoodsCigarettes,
        newInformationGoodsPurchased,
        newInformationGoodsImport,
        newInformationGoodsCarrying,
        newImei,

        // ppobCategoryData,
        // isLoadingppobCategoryData,
        // ppobBrandData,
        // isLoadingppobBrandData,
        // ppobTypesData,
        // isLoadingppobTypesData,
        // PpobData,
        // newCustomsDeclarationLists,
        // isLoadingPpobData,
        isLoadingSetCustomsDeclarationListDataSearch,
        isSearch,
        setIsSearch,
        handleSearch,
        isLoadingGetCustomsDeclarationList,
        handleShowEntriesTable,
        handlePaginationTable,
        // handleDialogUpdateSellPrice,
        // handleUpdateSellPrice,
        // isLoadingUpdateSellPrice,
        // formUpdateSellPrice,
        // resetformUpdateSellPrice,
        // isVisibleUpdateSellPriceInfo,
        // // isModalFormUpdateSellPriceOpen,
        // priceId,
        isModalFormOpen,
        // isEdit,
        // handleModalFormOpen,
        handleDialogCustomsDeclaration,
        // handleSyncPriceList,
        // isLoadingGetSyncPriceList,
        isGetAccessPrabayarTransaction,
        isDeleteAccessPrabayarTransaction,
        isUpdateAccessPrabayarTransaction,
        isViewAllAccess,
        isVisibleDownloadTransaction,
        isVisibleDownloadInfoTransaction,
        handleDialogDownloadTransaction,
        // handleDownloadTransaction,
        formPublish,
        // isLoadingGetTransactionListExport,
        // exportFile,
        handleDialogApproveCustomsDeclaration,
        handleApproveCustomsDeclaration,
        handleDialogRejectCustomsDeclaration,
        handleRejectCustomsDeclaration,
        isLoadingRejectCustomsDeclaration,
        isLoadingApproveCustomsDeclaration,
        isVisibleApproveCustomsDeclaration,
        isVisibleApproveCustomsDeclarationInfo,
        isVisibleRejectCustomsDeclaration,
        isVisibleRejectCustomsDeclarationInfo,
        customsDeclarationId,
        handleFilterRange,
        handleDialogFilterRange,
        isVisibleFilterRange,
        isVisibleFilterRangeInfo,

        newTransactionCustomDeclarationsSearch,
        formKeywordSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default CustomsDeclarationTransactionController;
