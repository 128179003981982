import { api } from "../../../../../utils/axios";

//Get List Voucher
export const getVouchers = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/voucher/list",
      {
        filter: {
          set_code: false,
          code: "",
          set_status: false,
          status: "",
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Voucher By ID
export const getVoucher = ({ guid, token, language }) =>
  api
    .get(`/backoffice/voucher/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Voucher By Code
export const getVoucherCode = ({ code, token, language }) =>
  api
    .get(`/backoffice/voucher/code/${code}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

// //Set Bank Account Active
// export const setBankAccountActive = ({ guid, isActive, token, language }) =>
//   api
//     .put(
//       `/backoffice/bank-account/is-active/${guid}`,
//       {
//         // guid: guid,
//         // active: isActive,
//       },
//       {
//         headers: {
//           language: language,
//           "nihao-token": token,
//         },
//       }
//     )
//     .then((res) => res?.data);

//Create Bank Account
export const setVoucher = ({ payloadPublish, token, language }) =>
  api
    .post("/backoffice/voucher", payloadPublish, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

// //Delete Bank Account
// export const deleteBankAccount = ({ guid, token, language }) =>
//   api
//     .delete(`/backoffice/bank-account/${guid}`, {
//       headers: {
//         language: language,
//         "nihao-token": token,
//       },
//     })
//     .then((res) => res?.data);

//Get Search Voucher
export const getVoucherSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/voucher/list",
      {
        filter: {
          set_code: payload?.isCode === true ? true : false,
          code: payload?.isCode ? payload?.search : "",
          set_status: payload.isStatus === true ? true : false,
          status: payload?.isStatus ? payload?.search : "",
        },
        limit: 1000,
        page: 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.lang,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

// //Set Bank Account Edit
// export const setBankAccountEdit = ({ payloadEditPublish, guid, token, language }) =>
//   api
//     .put(`/backoffice/bank-account/${guid}`, payloadEditPublish, {
//       headers: {
//         language: language,
//         "nihao-token": token,
//       },
//     })
//     .then((res) => res?.data);
