import { Button, Switch, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import {
  getConfirmationApproval,
  getConfirmationApprovalSearch,
  approveTransaction,
  rejectTransaction,
  getTransaction,
} from "./api/TransactionApi";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Dropdown, IconButton, Popover, Whisper } from "rsuite";
import { FiArrowDown, FiColumns, FiEdit2 } from "react-icons/fi";

export const ConfirmationApprovalContext = createContext();
const { Provider } = ConfirmationApprovalContext;

const ConfirmationApprovalController = ({ children }) => {
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  let language = t("LANGUAGE");

  const toast = useToast();

  const [newConfirmationApproval, setNewConfirmationApproval] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isModalFormUpdateSellPriceOpen, setIsModalFormUpdateSellPriceOpen] =
    useState(false);
  const [transactionId, setTransactionId] = useState();
  const [isVisibleUpdateSellPriceInfo, setIsVisibleUpdateSellPriceInfo] =
    useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isVisibleDownloadTransaction, setIsVisibleDownloadTransaction] =
    useState(false);
  const [
    isVisibleDownloadInfoTransaction,
    setIsVisibleDownloadInfoTransaction,
  ] = useState(false);

  const [transaksiId, setTransaksiId] = useState();
  const [isVisibleApproveTransaksi, setIsVisibleApproveTransaksi] =
    useState(false);
  const [isVisibleApproveTransaksiInfo, setIsVisibleApproveTransaksiInfo] =
    useState(false);
  const [isVisibleRejectTransaksi, setIsVisibleRejectTransaksi] =
    useState(false);
  const [isVisibleRejectTransaksiInfo, setIsVisibleRejectTransaksiInfo] =
    useState(false);
    
  const [newConfirmationApprovalSearch, setNewConfirmationApprovalSearch] = useState();

  const [pres, setPres] = useState();
  const [uniqueNumber, setUniqueNumber] = useState();

  const {
    data: confirmationApprovalList,
    isLoading: isLoadingConfirmationApprovalList,
    refetch: refecthConfirmationApprovalData,
  } = useQuery(["confirmationapproval", token, language], () =>
    getConfirmationApproval({ token, language })
  );

  const {
    isLoading: isLoadingSetConfirmationApprovalSearch,
    mutate: mutateSetConfirmationApprovalSearch,
  } = useMutation(getConfirmationApprovalSearch);

  const {
    isLoading: isLoadingGetConfirmationApprovalList,
    mutate: mutateGetConfirmationApproval,
    refetch: refetchGetConfirmationApprovalList,
  } = useMutation(getConfirmationApproval);

  const {
    isLoading: isLoadingApproveTransaksi,
    mutate: mutateApproveTransaksi,
  } = useMutation(approveTransaction);

  const { isLoading: isLoadingGetTransaksi, mutate: mutateGetTransaksi } =
    useMutation(getTransaction);

  const { isLoading: isLoadingRejectTransaksi, mutate: mutateRejectTransaksi } =
    useMutation(rejectTransaction);

  // const {
  //   isLoading: isLoadingGetTransactionListExport,
  //   mutate: getConfirmationApprovalExport,
  //   refetch: refetchGetTransactionListExport,
  // } = useMutation(getTransactionListExport);

  // const { mutate: mutateGetTransactionId } = useMutation(getTransactionById);

  let newConfirmationApprovalListsData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessPrabayarTransaction, setIsGetAccessPrabayarTransaction] =
    useState();
  const [
    isDeleteAccessPrabayarTransaction,
    setIsDeleteAccessPrabayarTransaction,
  ] = useState();
  const [
    isUpdateAccessPrabayarTransaction,
    setIsUpdateAccessPrabayarTransaction,
  ] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Prabayar Transaction
          const getAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessPrabayarTransaction(
            getAccessPrabayarTransaction?.length
          );

          const deleteAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessPrabayarTransaction(
            deleteAccessPrabayarTransaction?.length
          );

          const updateAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessPrabayarTransaction(
            updateAccessPrabayarTransaction?.length
          );
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Publish
  // -------------------------------
  const formPublish = useForm();
  const resetFormPublish = (newForm) => {
    const dateFrom = new Date();
    const dateTo = new Date();
    const publishFrom = dateFrom.getDate() + 1;
    const publishTo = dateTo.getDate() + 2;
    dateFrom.setDate(publishFrom);
    dateTo.setDate(publishTo);
    const defaultDatePublishFrom = moment(dateFrom).format("YYYY-MM-DDTHH:mm");
    const defaultDatePublishTo = moment(dateTo).format("YYYY-MM-DDTHH:mm");

    const publishFromEdit = moment(newForm?.publish_from).format(
      "YYYY-MM-DDTHH:mm"
    );
    const publishToEdit = moment(newForm?.publish_to).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formPublish.reset({
        publish_from: newForm?.publish_from,
        publish_to: newForm?.publish_to,
        status: 1,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
      });
    }
  };

  const handleDialogDownloadTransaction = (isOpen, guid) => {
    // console.log(isPublish)
    setIsVisibleDownloadTransaction(isOpen);
    // setAdvertisingRowId(guid);
    // setIsPublish(isPublish);
    setIsVisibleDownloadInfoTransaction(false);

    // mutateGetAdvertisingRow(
    //   { guid, token, language },
    //   {
    //     onSuccess: (data) => {
    resetFormPublish({});
    //       setPublishPayloadClassifiedAdsEdit(data?.data);
    //     },
    //   }
    // );
  };

  const handleDialogApproveTransaction = (isOpen, guid) => {
    setIsVisibleApproveTransaksi(isOpen);
    setTransaksiId(guid);
    setIsVisibleApproveTransaksiInfo(false);

    mutateGetTransaksi(
      { guid, token, language },
      {
        onSuccess: (data) => {
          setUniqueNumber(data?.data?.unique_number_account);
          // refetch();
          // refecthConfirmationApprovalData();
          // handlePaginationTable();
          // setIsVisibleApproveTransaksi(false);
          // setIsVisibleApproveTransaksiInfo(true);
        },
      }
    );
  };

  const handleApproveTransaction = (guid) => {
    mutateApproveTransaksi(
      { guid, token, language },
      {
        onSuccess: () => {
          // refetch();
          refecthConfirmationApprovalData();
          handlePaginationTable();
          setIsVisibleApproveTransaksi(false);
          setIsVisibleApproveTransaksiInfo(true);
        },
      }
    );
  };

  const handleDialogRejectTransaction = (isOpen, guid) => {
    setIsVisibleRejectTransaksi(isOpen);
    setTransaksiId(guid);
    setIsVisibleRejectTransaksiInfo(false);
  };

  const handleRejectTransaction = (guid) => {
    mutateRejectTransaksi(
      { guid, token, language },
      {
        onSuccess: () => {
          // refetch();
          refecthConfirmationApprovalData();
          handlePaginationTable();
          setIsVisibleRejectTransaksi(false);
          setIsVisibleRejectTransaksiInfo(true);
        },
      }
    );
  };

  // -------------------------------
  // Form Modal Transaction
  // -------------------------------
  const formTransactionDetail = useForm();
  const resetformTransactionDetail = (newForm) => {
    if (newForm) {
      formTransactionDetail.reset({
        transaction_id: newForm?.transaction_id,
        user_guid: newForm?.user_guid,
        user_phone: newForm?.user_phone,
        user_email: newForm?.user_email,
        user_full_name: newForm?.user_full_name,
        category: newForm?.category,
        brand: newForm?.brand,
        type: newForm?.type,
        seller_name: newForm?.seller_name,
        buyer_sku_code: newForm?.buyer_sku_code,
        slug_name: newForm?.slug_name,
        product_name: newForm?.product_name,
        description: newForm?.description,
        admin: newForm?.admin,
        commision: newForm?.commision,
        price: newForm?.price,
        sell_price: newForm?.sell_price,
        internal_fee: newForm?.internal_fee,
        amount: newForm?.amount,
        biller_ref_id: newForm?.biller_ref_id,
        biller_rc: newForm?.biller_rc,
        biller_status: newForm?.biller_status,
        biller_data: newForm?.biller_data,

        payment_status: newForm?.payment_status,
        status: newForm?.status,
        is_inquiry: newForm?.is_inquiry,
        inquiry_at: newForm?.inquiry_at,
        is_payment: newForm?.is_payment,
        payment_at: newForm?.payment_at,
        payment_method_id: newForm?.payment_method_id,
        payment_method_data: newForm?.payment_method_data,
        payment_channel: newForm?.payment_channel,
        payment_channel_data: newForm?.payment_channel_data,
        created_at: newForm?.created_at,
        confirm_payment_at: newForm?.confirm_payment_at,
        approved_at: newForm?.approved_at,
        rejected_at: newForm?.rejected_at,
        updated_at: newForm?.updated_at,
      });
    }
  };

  // const handleDialogTransaction = (isOpen, guid) => {
  //   setIsModalFormOpen(isOpen);
  //   setTransactionId(guid);
  //   // setIsVisibleTopUpBalanceInfo(false);
  //   // setIsShowType(true);

  //   mutateGetTransactionId(
  //     { guid, token },
  //     {
  //       onSuccess: (data) => {
  //         // resetformTransaction(data?.data)
  //         resetformTransactionDetail(data.data);
  //       },
  //     }
  //   );
  // };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isPaymentMethod = keyword?.keyword_filter === "1" && true;
    // let isPaymentChannelGuid = keyword?.keyword_filter === "2" && true;
    // let isPaymentChannelBankCode = keyword?.keyword_filter === "3" && true;
    // let isPaymentChannelBankName = keyword?.keyword_filter === "4" && true;
    // let isPaymentChannelAccountNumber = keyword?.keyword_filter === "5" && true;
    // let isPaymentChannelAccountName = keyword?.keyword_filter === "6" && true;
    // let isTransactionType = keyword?.keyword_filter === "7" && true;
    // let isMessage = keyword?.keyword_filter === "8" && true;
    // let isStatus = keyword?.keyword_filter === "9" && true;

    let isPaymentMethod               = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isPaymentChannelGuid          = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isPaymentChannelBankCode      = keyword?.keyword_filter?.value === "3" || (keyword?.keyword_filter === "3" && true);
    let isPaymentChannelBankName      = keyword?.keyword_filter?.value === "4" || (keyword?.keyword_filter === "4" && true);
    let isPaymentChannelAccountNumber = keyword?.keyword_filter?.value === "5" || (keyword?.keyword_filter === "5" && true);
    let isPaymentChannelAccountName   = keyword?.keyword_filter?.value === "6" || (keyword?.keyword_filter === "6" && true);
    let isTransactionType             = keyword?.keyword_filter?.value === "7" || (keyword?.keyword_filter === "7" && true);
    let isMessage                     = keyword?.keyword_filter?.value === "8" || (keyword?.keyword_filter === "8" && true);
    let isStatus                      = keyword?.keyword_filter?.value === "9" || (keyword?.keyword_filter === "9" && true);
    let searchKeyword                 = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-approval-payment", searchKeyword);
    } else {
      localStorage.removeItem("search-approval-payment");
    }

    setIsSearch(true);

    let payload = {
      isPaymentMethod: isPaymentMethod,
      isPaymentChannelGuid: isPaymentChannelGuid,
      isPaymentChannelBankCode: isPaymentChannelBankCode,
      isPaymentChannelBankName: isPaymentChannelBankName,
      isPaymentChannelAccountNumber: isPaymentChannelAccountNumber,
      isPaymentChannelAccountName: isPaymentChannelAccountName,
      isTransactionType: isTransactionType,
      isMessage: isMessage,
      isStatus: isStatus,
      search: searchKeyword,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(100);
    mutateSetConfirmationApprovalSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setNewConfirmationApprovalSearch(data);
            newConfirmationApprovalListsData.push({
              transaction_id: value?.transaction_id,
              tanggal: moment(value?.created_at).format("DD/MM/YYYY"),
              jam: moment(value?.created_at).format("HH:mm:ss"),
              transaction_type: value?.transaction_type,
              payment_channel_bank_logo_url: (
                <img
                  src={value?.payment_channel_bank_logo_url}
                  alt=""
                  width={200}
                  height={200}
                />
              ),
              payment_channel_bank_code: value?.payment_channel_bank_code,
              payment_channel_bank_name: value?.payment_channel_bank_name,
              payment_type:
                value?.is_snap_bca === false && value?.is_moota === false ? (
                  <div className="font-bold flex items-center justify-start">
                    Semi Auto
                  </div>
                ) : value?.is_snap_bca === false && value?.is_moota === true ? (
                  <div className="font-bold flex items-center justify-start">
                    Moota
                  </div>
                ) : value?.is_snap_bca === true && value?.is_moota === false ? (
                  <div className="font-bold flex items-center justify-start">
                    API
                  </div>
                ) : (
                  ""
                ),
              payment_channel_account_number:
                value?.payment_channel_account_number,
              payment_channel_account_name: value?.payment_channel_account_name,
              message: value?.message,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              unique_number_account: value?.unique_number_account,
              total_amount: (
                <CurrencyFormat
                  value={value?.total_amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              // sell_price: (
              //   <CurrencyFormat
              //     value={value?.sell_price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // price: (
              //   <CurrencyFormat
              //     value={value?.price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // serial_number: "serial_number",
              // seller_name: value?.seller_name,
              // biller_ref_id: value?.biller_ref_id,
              // buyer_sku_code: value?.buyer_sku_code,
              // payment_status: value?.payment_status,
              status:
                value?.status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              action: (
                <div>
                  <Whisper
                    placement="bottomStart"
                    trigger="click"
                    speaker={(props, ref) => {
                      const { className, left, top, onClose } = props;
                      return (
                        <Popover
                          ref={ref}
                          className={className}
                          style={{ left, top }}
                          full
                        >
                          <Dropdown.Menu>
                            <ul className="h-8 px-3 pb-3 text-sm text-gray-700">
                              <Button
                                size="md"
                                colorScheme="new"
                                className="bg-[#BAE6CC]"
                                style={{ borderRadius: "10px", height: "23px" }}
                                onClick={() =>
                                  handleDialogApproveTransaction(
                                    true,
                                    value?.id
                                  )
                                }
                              >
                                <div className=" text-[#18AB56] leading-[15px] font-[600] text-[12px]">
                                  {t("LABEL.APPROVE")}
                                </div>
                              </Button>
                            </ul>
                            <ul className="h-8 px-3 pb-3 text-sm text-gray-700">
                              <Button
                                size="md"
                                colorScheme="new"
                                className="bg-[#FF0F0F]"
                                style={{ borderRadius: "10px", height: "23px" }}
                                onClick={() =>
                                  handleDialogRejectTransaction(true, value?.id)
                                }
                              >
                                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                                  {t("LABEL.REJECT")}
                                </div>
                              </Button>
                            </ul>
                          </Dropdown.Menu>
                        </Popover>
                      );
                    }}
                  >
                    <IconButton icon={<FiEdit2 />} circle />
                  </Whisper>
                </div>
              ),
            });
          });
          setNewConfirmationApproval(newConfirmationApprovalListsData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    // console.log(limit)
    mutateGetConfirmationApproval(
      { limit, token },
      {
        onSuccess: (data) => {
          newConfirmationApprovalListsData = [];

          data?.data?.map((value) => {
            newConfirmationApprovalListsData.push({
              transaction_id: value?.transaction_id,
              tanggal: moment(value?.created_at).format("DD/MM/YYYY"),
              jam: moment(value?.created_at).format("HH:mm:ss"),
              transaction_type: value?.transaction_type,
              payment_channel_bank_logo_url: (
                <img
                  src={value?.payment_channel_bank_logo_url}
                  alt=""
                  width={200}
                  height={200}
                />
              ),
              payment_channel_bank_code: value?.payment_channel_bank_code,
              payment_channel_bank_name: value?.payment_channel_bank_name,
              payment_type:
                value?.is_snap_bca === false && value?.is_moota === false ? (
                  <div className="font-bold flex items-center justify-start">
                    Semi Auto
                  </div>
                ) : value?.is_snap_bca === false && value?.is_moota === true ? (
                  <div className="font-bold flex items-center justify-start">
                    Moota
                  </div>
                ) : value?.is_snap_bca === true && value?.is_moota === false ? (
                  <div className="font-bold flex items-center justify-start">
                    API
                  </div>
                ) : (
                  ""
                ),
              payment_channel_account_number:
                value?.payment_channel_account_number,
              payment_channel_account_name: value?.payment_channel_account_name,
              message: value?.message,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              unique_number_account: value?.unique_number_account,
              total_amount: (
                <CurrencyFormat
                  value={value?.total_amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              // sell_price: (
              //   <CurrencyFormat
              //     value={value?.sell_price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // price: (
              //   <CurrencyFormat
              //     value={value?.price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // serial_number: "serial_number",
              // seller_name: value?.seller_name,
              // biller_ref_id: value?.biller_ref_id,
              // buyer_sku_code: value?.buyer_sku_code,
              // payment_status: value?.payment_status,
              status:
                value?.status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              action: (
                <div>
                  <Whisper
                    placement="bottomStart"
                    trigger="click"
                    speaker={(props, ref) => {
                      const { className, left, top, onClose } = props;
                      return (
                        <Popover
                          ref={ref}
                          className={className}
                          style={{ left, top }}
                          full
                        >
                          <Dropdown.Menu>
                            <ul className="h-8 px-3 pb-3 text-sm text-gray-700">
                              <Button
                                size="md"
                                colorScheme="new"
                                className="bg-[#BAE6CC]"
                                style={{ borderRadius: "10px", height: "23px" }}
                                onClick={() =>
                                  handleDialogApproveTransaction(
                                    true,
                                    value?.id
                                  )
                                }
                              >
                                <div className=" text-[#18AB56] leading-[15px] font-[600] text-[12px]">
                                  {t("LABEL.APPROVE")}
                                </div>
                              </Button>
                            </ul>
                            <ul className="h-8 px-3 pb-3 text-sm text-gray-700">
                              <Button
                                size="md"
                                colorScheme="new"
                                className="bg-[#FF0F0F]"
                                style={{ borderRadius: "10px", height: "23px" }}
                                onClick={() =>
                                  handleDialogRejectTransaction(true, value?.id)
                                }
                              >
                                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                                  {t("LABEL.REJECT")}
                                </div>
                              </Button>
                            </ul>
                          </Dropdown.Menu>
                        </Popover>
                      );
                    }}
                  >
                    <IconButton icon={<FiEdit2 />} circle />
                  </Whisper>
                </div>
              ),
            });
          });
          setNewConfirmationApproval(newConfirmationApprovalListsData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);

    mutateGetConfirmationApproval(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newConfirmationApprovalListsData = [];

          data?.data?.map((value) => {
            newConfirmationApprovalListsData.push({
              transaction_id: value?.transaction_id,
              tanggal: moment(value?.created_at).format("DD/MM/YYYY"),
              jam: moment(value?.created_at).format("HH:mm:ss"),
              transaction_type: value?.transaction_type,
              payment_channel_bank_logo_url: (
                <img
                  src={value?.payment_channel_bank_logo_url}
                  alt=""
                  width={200}
                  height={200}
                />
              ),
              payment_channel_bank_code: value?.payment_channel_bank_code,
              payment_channel_bank_name: value?.payment_channel_bank_name,
              payment_type:
                value?.is_snap_bca === false && value?.is_moota === false ? (
                  <div className="font-bold flex items-center justify-start">
                    Semi Auto
                  </div>
                ) : value?.is_snap_bca === false && value?.is_moota === true ? (
                  <div className="font-bold flex items-center justify-start">
                    Moota
                  </div>
                ) : value?.is_snap_bca === true && value?.is_moota === false ? (
                  <div className="font-bold flex items-center justify-start">
                    API
                  </div>
                ) : (
                  ""
                ),
              payment_channel_account_number:
                value?.payment_channel_account_number,
              payment_channel_account_name: value?.payment_channel_account_name,
              message: value?.message,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              unique_number_account: value?.unique_number_account,
              total_amount: (
                <CurrencyFormat
                  value={value?.total_amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              // sell_price: (
              //   <CurrencyFormat
              //     value={value?.sell_price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // price: (
              //   <CurrencyFormat
              //     value={value?.price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // serial_number: "serial_number",
              // seller_name: value?.seller_name,
              // biller_ref_id: value?.biller_ref_id,
              // buyer_sku_code: value?.buyer_sku_code,
              // payment_status: value?.payment_status,
              status:
                value?.status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              action: (
                <div>
                  <Whisper
                    placement="bottomStart"
                    trigger="click"
                    speaker={(props, ref) => {
                      const { className, left, top, onClose } = props;
                      return (
                        <Popover
                          ref={ref}
                          className={className}
                          style={{ left, top }}
                          full
                        >
                          <Dropdown.Menu>
                            <ul className="h-8 px-3 pb-3 text-sm text-gray-700">
                              <Button
                                size="md"
                                colorScheme="new"
                                className="bg-[#BAE6CC]"
                                style={{ borderRadius: "10px", height: "23px" }}
                                onClick={() =>
                                  handleDialogApproveTransaction(
                                    true,
                                    value?.id
                                  )
                                }
                              >
                                <div className=" text-[#18AB56] leading-[15px] font-[600] text-[12px]">
                                  {t("LABEL.APPROVE")}
                                </div>
                              </Button>
                            </ul>
                            <ul className="h-8 px-3 pb-3 text-sm text-gray-700">
                              <Button
                                size="md"
                                colorScheme="new"
                                className="bg-[#FF0F0F]"
                                style={{ borderRadius: "10px", height: "23px" }}
                                onClick={() =>
                                  handleDialogRejectTransaction(true, value?.id)
                                }
                              >
                                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                                  {t("LABEL.REJECT")}
                                </div>
                              </Button>
                            </ul>
                          </Dropdown.Menu>
                        </Popover>
                      );
                    }}
                  >
                    <IconButton icon={<FiEdit2 />} circle />
                  </Whisper>
                </div>
              ),
            });
          });
          setNewConfirmationApproval(newConfirmationApprovalListsData);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem("search-approval-payment");
    newConfirmationApprovalListsData = [];
    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    confirmationApprovalList?.data?.map((value) => {
      newConfirmationApprovalListsData.push({
        transaction_id: value?.transaction_id,
        tanggal: moment(value?.created_at).format("DD/MM/YYYY"),
        jam: moment(value?.created_at).format("HH:mm:ss"),
        transaction_type: value?.transaction_type,
        payment_channel_bank_logo_url: (
          <img
            src={value?.payment_channel_bank_logo_url}
            alt=""
            width={200}
            height={200}
          />
        ),
        payment_channel_bank_code: value?.payment_channel_bank_code,
        payment_channel_bank_name: value?.payment_channel_bank_name,
        payment_type:
          value?.is_snap_bca === false && value?.is_moota === false ? (
            <div className="font-bold flex items-center justify-start">
              Semi Auto
            </div>
          ) : value?.is_snap_bca === false && value?.is_moota === true ? (
            <div className="font-bold flex items-center justify-start">
              Moota
            </div>
          ) : value?.is_snap_bca === true && value?.is_moota === false ? (
            <div className="font-bold flex items-center justify-start">API</div>
          ) : (
            ""
          ),
        payment_channel_account_number: value?.payment_channel_account_number,
        payment_channel_account_name: value?.payment_channel_account_name,
        message: value?.message,
        amount: (
          <CurrencyFormat
            value={value?.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        unique_number_account: value?.unique_number_account,
        total_amount: (
          <CurrencyFormat
            value={value?.total_amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        // sell_price: (
        //   <CurrencyFormat
        //     value={value?.sell_price}
        //     displayType={"text"}
        //     thousandSeparator={true}
        //     prefix={"Rp. "}
        //   />
        // ),
        // price: (
        //   <CurrencyFormat
        //     value={value?.price}
        //     displayType={"text"}
        //     thousandSeparator={true}
        //     prefix={"Rp. "}
        //   />
        // ),
        // serial_number: "serial_number",
        // seller_name: value?.seller_name,
        // biller_ref_id: value?.biller_ref_id,
        // buyer_sku_code: value?.buyer_sku_code,
        // payment_status: value?.payment_status,
        status:
          value?.status === "approved" ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "rejected" ? (
            <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : (
            <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ),

        action: (
          <div>
            <Whisper
              placement="bottomStart"
              trigger="click"
              speaker={(props, ref) => {
                const { className, left, top, onClose } = props;
                return (
                  <Popover
                    ref={ref}
                    className={className}
                    style={{ left, top }}
                    full
                  >
                    <Dropdown.Menu>
                      <ul className="h-8 px-3 pb-3 text-sm text-gray-700">
                        <Button
                          size="md"
                          colorScheme="new"
                          className="bg-[#BAE6CC]"
                          style={{ borderRadius: "10px", height: "23px" }}
                          onClick={() =>
                            handleDialogApproveTransaction(true, value?.id)
                          }
                        >
                          <div className=" text-[#18AB56] leading-[15px] font-[600] text-[12px]">
                            {t("LABEL.APPROVE")}
                          </div>
                        </Button>
                      </ul>
                      <ul className="h-8 px-3 pb-3 text-sm text-gray-700">
                        <Button
                          size="md"
                          colorScheme="new"
                          className="bg-[#FF0F0F]"
                          style={{ borderRadius: "10px", height: "23px" }}
                          onClick={() =>
                            handleDialogRejectTransaction(true, value?.id)
                          }
                        >
                          <div className=" text-white leading-[15px] font-[600] text-[12px]">
                            {t("LABEL.REJECT")}
                          </div>
                        </Button>
                      </ul>
                    </Dropdown.Menu>
                  </Popover>
                );
              }}
            >
              <IconButton icon={<FiEdit2 />} circle />
            </Whisper>
          </div>
        ),
      });
    });
    setNewConfirmationApproval(newConfirmationApprovalListsData);
  }, [
    confirmationApprovalList,
    token,
    isGetAccessPrabayarTransaction,
    isDeleteAccessPrabayarTransaction,
    isUpdateAccessPrabayarTransaction,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formTransactionDetail,

        confirmationApprovalList,
        isLoadingConfirmationApprovalList,
        refecthConfirmationApprovalData,
        newConfirmationApprovalListsData,
        newConfirmationApproval,

        isLoadingSetConfirmationApprovalSearch,
        isSearch,
        setIsSearch,
        handleSearch,
        handleShowEntriesTable,
        handlePaginationTable,
        isModalFormOpen,
        // handleDialogTransaction,
        isGetAccessPrabayarTransaction,
        isDeleteAccessPrabayarTransaction,
        isUpdateAccessPrabayarTransaction,
        isViewAllAccess,
        isVisibleDownloadTransaction,
        isVisibleDownloadInfoTransaction,
        handleDialogDownloadTransaction,
        // handleDownloadTransaction,
        formPublish,
        // isLoadingGetTransactionListExport,
        // exportFile,

        isVisibleApproveTransaksi,
        isVisibleApproveTransaksiInfo,
        isVisibleRejectTransaksi,
        isVisibleRejectTransaksiInfo,
        transaksiId,
        handleDialogApproveTransaction,
        handleApproveTransaction,
        isLoadingApproveTransaksi,
        isLoadingRejectTransaksi,
        handleDialogRejectTransaction,
        handleRejectTransaction,
        uniqueNumber,
        setUniqueNumber,

        newConfirmationApprovalSearch,
        formKeywordSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default ConfirmationApprovalController;
