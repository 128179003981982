import React, { lazy, Suspense } from "react";
// import YuanConversionDialog from "./components/Dialog/YuanConversionDialog";
// import YuanConversionInfoDialog from "./components/Dialog/YuanConversionInfoDialog";
// import ConversionBox from "./components/ConversionBox";
import ConfigController from "./Controller";
// import ConfigBC22 from "./components/ConfigBC22";
// import AmountBC22Dialog from "./components/Dialog/AmountBC22Dialog";
// import AmountBC22InfoDialog from "./components/Dialog/AmountBC22InfoDialog";
import { useTranslation } from "react-i18next";
// import APKVersioning from "./components/APKVersioning";
// import ApkVersioningDialog from "./components/Dialog/ApkVersioningDialog";
// import ApkVersioningInfoDialog from "./components/Dialog/ApkVersioningInfoDialog";
// import AlipayCallback from "./components/AlipayCallback";
// import Referral from "./components/Referral";
// import ReferralDialog from "./components/Dialog/ReferralDialog";
// import ReferralInfoDialog from "./components/Dialog/ReferralInfoDialog";
// import MinimumLimitPoint from "./components/MinimumLimitPoint";
// import MinimumLimitPointDialog from "./components/Dialog/MinimumLimitPointDialog";
// import MinimumLimitInfoDialog from "./components/Dialog/MinimumLimitPointInfoDialog";
// import MaximumLimitPoint from "./components/MaximumLimitPoint";
// import MaximumLimitPointDialog from "./components/Dialog/MaximumLimitPointDialog";
// import MaximumLimitInfoDialog from "./components/Dialog/MaximumLimitPointInfoDialog";

const Config = () => {
  const { t } = useTranslation();
  const YuanConversionDialog = lazy(() =>
    import("./components/Dialog/YuanConversionDialog")
  );
  const YuanConversionInfoDialog = lazy(() =>
    import("./components/Dialog/YuanConversionInfoDialog")
  );
  const ConversionBox = lazy(() => import("./components/ConversionBox"));
  const ConfigBC22 = lazy(() => import("./components/ConfigBC22"));
  const AmountBC22Dialog = lazy(() =>
    import("./components/Dialog/AmountBC22Dialog")
  );
  const AmountBC22InfoDialog = lazy(() =>
    import("./components/Dialog/AmountBC22InfoDialog")
  );
  const APKVersioning = lazy(() => import("./components/APKVersioning"));
  const ApkVersioningDialog = lazy(() =>
    import("./components/Dialog/ApkVersioningDialog")
  );
  const ApkVersioningInfoDialog = lazy(() =>
    import("./components/Dialog/ApkVersioningInfoDialog")
  );
  const AlipayCallback = lazy(() => import("./components/AlipayCallback"));
  const Referral = lazy(() => import("./components/Referral"));
  const ReferralDialog = lazy(() =>
    import("./components/Dialog/ReferralDialog")
  );
  const ReferralInfoDialog = lazy(() =>
    import("./components/Dialog/ReferralInfoDialog")
  );
  const MinimumLimitPoint = lazy(() =>
    import("./components/MinimumLimitPoint")
  );
  const MinimumLimitPointDialog = lazy(() =>
    import("./components/Dialog/MinimumLimitPointDialog")
  );
  const MinimumLimitInfoDialog = lazy(() =>
    import("./components/Dialog/MinimumLimitPointInfoDialog")
  );
  const MaximumLimitPoint = lazy(() =>
    import("./components/MaximumLimitPoint")
  );
  const MaximumLimitPointDialog = lazy(() =>
    import("./components/Dialog/MaximumLimitPointDialog")
  );
  const MaximumLimitInfoDialog = lazy(() =>
    import("./components/Dialog/MaximumLimitPointInfoDialog")
  );

  return (
    <div>
      <ConfigController>
        <Suspense
          fallback={
            <div className="h-screen flex items-center">
              <div className="mx-auto">
                <div
                  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex gap-4">
            <div className="flex justify-start">
              <span className="font-[700] text-[32px] leadind-[48px] text-[#504178]">
                {t("LABEL.CONFIG")}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-4 pt-4 gap-2">
            <div>
              <ConversionBox />
            </div>
            <div>
              <ConfigBC22 />
            </div>
            <div>
              <APKVersioning />
            </div>
            <div>
              <AlipayCallback />
            </div>
            <div>
              <Referral />
            </div>
            <div>
              <MinimumLimitPoint />
            </div>
            <div>
              <MaximumLimitPoint />
            </div>
          </div>
          <YuanConversionDialog />
          <YuanConversionInfoDialog />
          <AmountBC22Dialog />
          <AmountBC22InfoDialog />
          <ApkVersioningDialog />
          <ApkVersioningInfoDialog />
          <ReferralDialog />
          <ReferralInfoDialog />
          <MinimumLimitPointDialog />
          <MinimumLimitInfoDialog />
          <MaximumLimitPointDialog />
          <MaximumLimitInfoDialog />
        </Suspense>
      </ConfigController>
    </div>
  );
};

export default Config;
