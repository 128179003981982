import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { Button, Switch, useToast } from "@chakra-ui/react";
import { TokenContext } from "../../../../controllers/public/TokenController";
import {
  deleteBankAccount,
  getBankAccount,
  getBankAccountSearch,
  setBankAccountActive,
  getBankAccounts,
  setBankAccountEdit,
  getSyncMoota,
} from "./api/BankAccountApi";
import { getProfile } from "../../../../controllers/public/api/ProfileApi";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/public/AuthController";
import moment from "moment";
import { getRole } from "../User/UserAdminRole/api/RoleApi";
import { api } from "../../../../utils/axios";

export const BankAccountContext = createContext();
const { Provider } = BankAccountContext;

const BankAccountController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: bankAccountData,
    isLoading: isLoadingBankAccountData,
    refetch: refetchBankAccountData,
  } = useQuery(["bankaccounts", token, language], () =>
    getBankAccounts({ token, language })
  );

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isFetching: isFetchingProfileData,
    isError: errorProfileData,
  } = useQuery(["profile", token, language], () =>
    getProfile({ token, language })
  );

  const {
    isLoading: isLoadingBankAccountSearch,
    mutate: mutateSetBankCodeSearch,
  } = useMutation(getBankAccountSearch);

  const { isLoading: isLoadingGetUsers, mutate: mutateGetBankAccounts } =
    useMutation(getBankAccounts);
  const { mutate: mutateSetBankAccountActive } =
    useMutation(setBankAccountActive);

  const { isLoading: isLoadingDeleteUser, mutate: mutateDeleteBankAccount } =
    useMutation(deleteBankAccount);
  const { mutate: mutateGetBankAccount } = useMutation(getBankAccount);

  const {
    isLoading: isLoadingBankAccountEdit,
    mutate: mutateSetBankAccountEdit,
  } = useMutation(setBankAccountEdit);

  const { isLoading: isLoadingGetSyncMoota, mutate: mutateGetSyncMoota } =
    useMutation(getSyncMoota);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalFormResetOpen, setIsModalFormResetOpen] = useState(false);
  const [isModalFormTopUpOpen, setIsModalFormTopUpOpen] = useState(false);
  const [isVisibleDeleteBankAccount, setIsVisibleDeleteBankAccount] =
    useState(false);
  const [isVisibleDeleteInfoBankAccount, setIsVisibleDeleteInfoBankAccount] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isMoota, setIsMoota] = useState(false);
  const [isGuidBca, setIsGuidBca] = useState(false);
  const [bankLogoUrl, setBankLogoUrl] = useState();
  const [urlProfilePicture, setUrlProfilePicture] = useState();
  const [urlQrCode, setUrlQrCode] = useState();
  const [isVisibleTopUpBalanceInfo, setIsVisibleTopUpBalanceInfo] =
    useState(false);
  const [isVisibleSyncMoota, setIsVisibleSyncMoota] = useState(false);
  const [isVisibleSyncMootaInfo, setIsVisibleSyncMootaInfo] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isShowTemporaryClose, setIsShowTemporaryClose] = useState(false);

  const [newBankAccountData, setNewBankAccountData] = useState();
  const [bankAccountId, setBankAccountId] = useState();
  const [agentId, setAgentId] = useState();
  const [newBankCodeSearch, setNewBankCodeSearch] = useState();

  const [hoursStart, setHoursStart] = useState();
  const [minutesStart, setMinutesStart] = useState();
  
  const [hoursEnd, setHoursEnd] = useState();
  const [minutesEnd, setMinutesEnd] = useState();

  const [isShowType, setIsShowType] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(false);
  const [isQrCode, setIsQrCode] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);

  let newBankCodeData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isDeleteAccessUserAgent, setIsDeleteAccessUserAgent] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //User Agent
          const deleteAccessUserAgent = data?.data?.access
            ?.filter((data) => data?.page === "agent")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessUserAgent(deleteAccessUserAgent?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Modal
  // -------------------------------
  const formModal = useForm();
  const resetformModal = (newForm) => {    
    if (newForm) {
      formModal.reset({
        guid: newForm?.guid,
        is_qr_code: newForm?.is_qr_code,
        bank_code: newForm?.bank_code,
        bank_name: newForm?.bank_name,
        bank_logo_url: newForm?.bank_logo_url,
        bank_qr_code_url: newForm?.bank_qr_code_url,
        account_number: newForm?.account_number,
        account_name: newForm?.account_name,
        platform_fee: newForm?.platform_fee,
        platform_fee_type: newForm?.platform_fee_type,
        payment_service_fee: newForm?.payment_service_fee,
        payment_service_fee_decimal: newForm?.payment_service_fee_decimal,
        payment_service_fee_percentage: newForm?.payment_service_fee_percentage,
        payment_service_fee_type: newForm?.payment_service_fee_type,
        description: newForm?.description,
        is_active_schedule: newForm?.is_active_schedule,
        schedule_start: newForm?.schedule_start && formatScheduleTime(newForm?.schedule_start),
        schedule_end: newForm?.schedule_end && formatScheduleTime(newForm?.schedule_end)
      });
    }
  };

  // -------------------------------
  // Form Modal Reset
  // -------------------------------
  const formModalReset = useForm();
  const resetformModalReset = (newForm) => {
    if (newForm) {
      formModalReset.reset({
        newpassword: newForm?.newpassword,
        confirmpassword: newForm?.confirmpassword,
      });
    }
  };

  // -------------------------------
  // Form Modal TopUp
  // -------------------------------
  const formTopUp = useForm();
  const resetformTopUp = (newForm) => {
    if (newForm) {
      formTopUp.reset({
        amount: newForm?.amount,
      });
    }
  };

  // -------------------------------
  // Form Modal Transaction
  // -------------------------------
  const formTransaction = useForm();
  const resetformTransaction = (newForm) => {
    if (newForm) {
      formTransaction.reset({
        firstname: newForm?.firstname,
        lastname: newForm?.lastname,
        description: newForm?.description,
        address: newForm?.address,
        balance: newForm?.balance,
        last_login: newForm?.last_login,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  //Formatted for Schedule time
  const formatScheduleTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    return date;
  }


  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isMoota) => {
    // setUrlProfilePicture();
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsMoota(isMoota);
    if (guid === "a93608cd-1000-4444-b4b4-0ccea335b04b") {
      setIsGuidBca(true);
    }
    mutateGetBankAccount(
      { guid, token, language },
      {
        onSuccess: (data) => {
          isEdit && resetformModal(data?.data);
          isEdit && setBankLogoUrl(data?.data?.bank_logo_url);
          isEdit && setQrCodeUrl(data?.data?.bank_qr_code_url);
        },
      }
    );
    isCreate && resetformModal({});
  };

  const handleBankAccountActive = (guid, isActive) => {
    mutateSetBankAccountActive(
      { guid, isActive, token, language },
      {
        onSuccess: (data) => {
          refetchBankAccountData();
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
        },
      }
    );
  };

  const handleDialogDeleteBankAccount = (isOpen, guid) => {
    setIsVisibleDeleteBankAccount(isOpen);
    setBankAccountId(guid);
    setIsVisibleDeleteInfoBankAccount(false);
  };

  const handleDeleteBankAccount = (guid) => {
    mutateDeleteBankAccount(
      { guid, token, language },
      {
        onSuccess: () => {
          refetchBankAccountData();
          setIsVisibleDeleteBankAccount(false);
          setIsVisibleDeleteInfoBankAccount(true);
        },
      }
    );
  };

  const handleDialogSyncMoota = (isOpen) => {
    setIsVisibleSyncMoota(isOpen);
    // setUserId(guid);
    setIsVisibleSyncMootaInfo(false);
  };

  const handleSyncMoota = () => {
    mutateGetSyncMoota(
      { token, language },
      {
        onSuccess: (data) => {
          console.log(data);
          if (data?.message?.response_code !== "00") {
            toast({
              description: "Sync Moota Gagal",
              position: "top-right",
              status: "warning",
              duration: 2000,
              isClosable: true,
            });
          } else {
            refetchBankAccountData();
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            setIsVisibleSyncMoota(false);
            setIsVisibleSyncMootaInfo(true);
          }
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    let isBankCode =
      keyword?.keyword_filter?.value === "1" ||
      (keyword?.keyword_filter === "1" && true);
    let isBankName =
      keyword?.keyword_filter?.value === "2" ||
      (keyword?.keyword_filter === "2" && true);
    let isAccountNumber =
      keyword?.keyword_filter?.value === "3" ||
      (keyword?.keyword_filter === "3" && true);
    let isAccountName =
      keyword?.keyword_filter?.value === "4" ||
      (keyword?.keyword_filter === "4" && true);
    let isStatus =
      keyword?.keyword_filter?.value === "5" ||
      (keyword?.keyword_filter === "5" && true);
    let isSearchStatus = keyword?.keyword_status === "1" && true;
    let searchKeyword = !keyword?.value?.keyword_search
      ? ""
      : keyword?.value?.keyword_search;

    // let isTitle        = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    // let isStatus       = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    // let isSearchStatus = keyword?.keyword_status === "1" && true;
    // let searchKeyword  = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    let lang = t("LANGUAGE");

    setIsShowStatus(isStatus);
    // setIsShowTemporaryClose(isAccountName);
    setIsSearch(true);

    let payload = {
      isBankCode: isBankCode,
      isBankName: isBankName,
      isAccountNumber: isAccountNumber,
      isAccountName: isAccountName,
      isStatus: isStatus,
      search: searchKeyword,
      searchStatus: isSearchStatus,
      lang: lang,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(10);
    mutateSetBankCodeSearch(
      { payload },
      {
        onSuccess: (data) => {
          newBankCodeData = [];
          data?.data?.map((value) => {
            setNewBankCodeSearch(data);
            newBankCodeData.push({
              bank_logo_url: (
                <img src={value?.bank_logo_url} alt="" width={50} />
              ),
              bank_code: value?.bank_code,
              bank_name: value?.bank_name,
              account_number: value?.account_number,
              account_name: value?.account_name,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              created_by: value?.created_by,
              is_moota:
                value?.is_moota === true ? (
                  // <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  //   {t("LABEL.YES")}
                  // </div>
                  <div className="font-bold flex items-center justify-center">
                    Moota
                  </div>
                ) : value?.is_moota === false && value?.bank_name === "BCA" ? (
                  // <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  //   {t("LABEL.NO")}
                  // </div>
                  <div className="font-bold flex items-center justify-center">
                    API
                  </div>
                ) : value?.is_moota === false && value?.bank_name !== "BCA" ? (
                  // <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  //   {t("LABEL.NO")}
                  // </div>
                  <div className="font-bold flex items-center justify-center">
                    Semi Auto
                  </div>
                ) : (
                  ""
                ),
              is_status: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleBankAccountActive(
                      value?.guid,
                      value?.is_active === true ? false : true
                    )
                  }
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),

              action: (
                <div className="flex gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#46BC78]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(
                        false,
                        true,
                        true,
                        value?.guid,
                        value?.is_moota
                      )
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.EDIT")}
                    </div>
                  </Button>
                  {
                    value?.guid !== "a93608cd-1000-4444-b4b4-0ccea335b04b" && (
                      // ?
                      // (isDeleteAccessUserAgent === 1 ||
                      //     isViewAllAccess === true)
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleDialogDeleteBankAccount(true, value?.guid)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    )
                    // : ""
                  }
                </div>
              ),
            });
          });
          setNewBankAccountData(newBankCodeData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    setIsSearch(false);
    mutateGetBankAccounts(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newBankCodeData = [];

          data?.data?.map((value) => {
            newBankCodeData.push({
              bank_logo_url: (
                <img src={value?.bank_logo_url} alt="" width={50} />
              ),
              bank_code: value?.bank_code,
              bank_name: value?.bank_name,
              account_number: value?.account_number,
              account_name: value?.account_name,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              created_by: value?.created_by,
              is_moota:
                value?.is_moota === true ? (
                  // <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  //   {t("LABEL.YES")}
                  // </div>
                  <div className="font-bold flex items-center justify-center">
                    Moota
                  </div>
                ) : value?.is_moota === false && value?.bank_name === "BCA" ? (
                  // <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  //   {t("LABEL.NO")}
                  // </div>
                  <div className="font-bold flex items-center justify-center">
                    API
                  </div>
                ) : value?.is_moota === false && value?.bank_name !== "BCA" ? (
                  // <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  //   {t("LABEL.NO")}
                  // </div>
                  <div className="font-bold flex items-center justify-center">
                    Semi Auto
                  </div>
                ) : (
                  ""
                ),
              is_status: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleBankAccountActive(
                      value?.guid,
                      value?.is_active === true ? false : true
                    )
                  }
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),

              action: (
                <div className="flex gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#46BC78]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(
                        false,
                        true,
                        true,
                        value?.guid,
                        value?.is_moota
                      )
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.EDIT")}
                    </div>
                  </Button>
                  {
                    value?.guid !== "a93608cd-1000-4444-b4b4-0ccea335b04b" && (
                      // ?
                      // (isDeleteAccessUserAgent === 1 ||
                      //     isViewAllAccess === true)
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleDialogDeleteBankAccount(true, value?.guid)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    )
                    // : ""
                  }
                </div>
              ),
            });
          });
          setNewBankAccountData(newBankCodeData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    setIsSearch(false);
    mutateGetBankAccounts(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newBankCodeData = [];

          data?.data?.map((value) => {
            newBankCodeData.push({
              bank_logo_url: (
                <img src={value?.bank_logo_url} alt="" width={50} />
              ),
              bank_code: value?.bank_code,
              bank_name: value?.bank_name,
              account_number: value?.account_number,
              account_name: value?.account_name,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              created_by: value?.created_by,
              is_moota:
                value?.is_moota === true ? (
                  // <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  //   {t("LABEL.YES")}
                  // </div>
                  <div className="font-bold flex items-center justify-center">
                    Moota
                  </div>
                ) : value?.is_moota === false && value?.bank_name === "BCA" ? (
                  // <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  //   {t("LABEL.NO")}
                  // </div>
                  <div className="font-bold flex items-center justify-center">
                    API
                  </div>
                ) : value?.is_moota === false && value?.bank_name !== "BCA" ? (
                  // <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  //   {t("LABEL.NO")}
                  // </div>
                  <div className="font-bold flex items-center justify-center">
                    Semi Auto
                  </div>
                ) : (
                  ""
                ),
              is_status: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() =>
                    handleBankAccountActive(
                      value?.guid,
                      value?.is_active === true ? false : true
                    )
                  }
                />
              ),
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),

              action: (
                <div className="flex gap-2">
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#46BC78]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalFormOpen(
                        false,
                        true,
                        true,
                        value?.guid,
                        value?.is_moota
                      )
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.EDIT")}
                    </div>
                  </Button>
                  {
                    value?.guid !== "a93608cd-1000-4444-b4b4-0ccea335b04b" && (
                      // ?
                      // (isDeleteAccessUserAgent === 1 ||
                      //     isViewAllAccess === true)
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleDialogDeleteBankAccount(true, value?.guid)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    )
                    // : ""
                  }
                </div>
              ),
            });
          });
          setNewBankAccountData(newBankCodeData);
        },
      }
    );
  };

  useEffect(() => {
    newBankCodeData = [];
    setIsSearch(false);

    bankAccountData?.data?.map((value) => {
      newBankCodeData.push({
        bank_logo_url: <img src={value?.bank_logo_url} alt="" width={50} />,
        bank_code: value?.bank_code,
        bank_name: value?.bank_name,
        account_number: value?.account_number,
        account_name: value?.account_name,
        created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
        created_by: value?.created_by,
        is_moota:
          value?.is_moota === true ? (
            // <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
            //   {t("LABEL.YES")}
            // </div>
            <div className="font-bold flex items-center justify-center">
              Moota
            </div>
          ) : value?.is_moota === false && value?.bank_name === "BCA" ? (
            // <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
            //   {t("LABEL.NO")}
            // </div>
            <div className="font-bold flex items-center justify-center">
              API
            </div>
          ) : value?.is_moota === false && value?.bank_name !== "BCA" ? (
            // <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
            //   {t("LABEL.NO")}
            // </div>
            <div className="font-bold flex items-center justify-center">
              Semi Auto
            </div>
          ) : (
            ""
          ),
        is_status: (
          <Switch
            colorScheme={value?.is_active === true ? "green" : "red"}
            isChecked={value?.is_active === true ? true : false}
            onChange={() =>
              handleBankAccountActive(
                value?.guid,
                value?.is_active === true ? false : true
              )
            }
          />
        ),
        status:
          value?.is_active === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.ACTIVE")}
            </div>
          ) : value?.is_active === false ? (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NOT_ACTIVE")}
            </div>
          ) : (
            <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.BANNED")}
            </div>
          ),

        action: (
          <div className="flex gap-2">
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#46BC78]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleModalFormOpen(
                  false,
                  true,
                  true,
                  value?.guid,
                  value?.is_moota
                )
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.EDIT")}
              </div>
            </Button>

            {
              value?.guid !== "a93608cd-1000-4444-b4b4-0ccea335b04b" && (
                // ?
                // (isDeleteAccessUserAgent === 1 || isViewAllAccess === true)
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#FF0F0F]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() =>
                    handleDialogDeleteBankAccount(true, value?.guid)
                  }
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.DELETE")}
                  </div>
                </Button>
              )
              // : ""
            }
          </div>
        ),
      });
    });
    setNewBankAccountData(newBankCodeData);
  }, [bankAccountData, token, isDeleteAccessUserAgent, isViewAllAccess]);

  return (
    <Provider
      value={{
        formModal,
        formModalReset,
        formTopUp,
        formTransaction,

        resetformModal,
        resetformModalReset,
        resetformTopUp,
        resetformTransaction,

        isModalFormOpen,
        isModalFormResetOpen,
        isVisibleDeleteBankAccount,
        isVisibleDeleteInfoBankAccount,
        isEdit,

        bankAccountData,
        bankAccountId,
        newBankAccountData,

        isLoadingBankAccountData,
        isLoadingGetUsers,
        isLoadingDeleteUser,

        handleModalFormOpen,
        handleDialogDeleteBankAccount,

        handleDeleteBankAccount,
        handlePaginationTable,
        handleShowEntriesTable,
        handleSearch,

        urlProfilePicture,
        setUrlProfilePicture,

        isDeleteAccessUserAgent,
        isViewAllAccess,
        isSearch,
        isShowTemporaryClose,

        isLoadingBankAccountEdit,
        isModalFormTopUpOpen,
        isVisibleTopUpBalanceInfo,
        agentId,

        isShowType,
        isShowStatus,
        refetchBankAccountData,
        bankLogoUrl,
        setBankLogoUrl,
        qrCodeUrl,
        setQrCodeUrl,
        isQrCode,
        setIsQrCode,
        isSchedule, 
        setIsSchedule,
        urlQrCode,
        setUrlQrCode,

        handleDialogSyncMoota,
        handleSyncMoota,
        isVisibleSyncMoota,
        isVisibleSyncMootaInfo,
        isLoadingGetSyncMoota,
        isMoota,
        isGuidBca,

        newBankCodeSearch,
        setNewBankCodeSearch,
        setIsShowStatus,
        formKeywordSearch,
      }}
    >
      {children}
    </Provider>
  );
};

export default BankAccountController;
