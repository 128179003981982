import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { Button, Switch, useToast } from "@chakra-ui/react";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import {
  deleteUser,
  getUser,
  getUsers,
  getUserSearch,
  setUserActive,
} from "./api/UserApi";
import { getProfile } from "../../../../../controllers/public/api/ProfileApi";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { getRole, getRoles } from "../UserAdminRole/api/RoleApi";
import moment from "moment";

export const UserAdminContext = createContext();
const { Provider } = UserAdminContext;

const UserAdminController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: userAdminData,
    isLoading: isLoadingUserAdminData,
    refetch,
  } = useQuery(["users", token, language], () => getUsers({ token, language }));

  const {
    data: userAdminRoleData,
    isLoading: isLoadingUserAdminRoleData,
    refetch: refetchAdminRoleData,
  } = useQuery(["roles", token, language], () => getRoles({ token, language }));

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isFetching: isFetchingProfileData,
    isError: errorProfileData,
  } = useQuery(["profile", token, language], () =>
    getProfile({ token, language })
  );

  const { isLoading: isLoadingSetUserSearch, mutate: mutateSetUserSearch } =
    useMutation(getUserSearch);

  const { isLoading: isLoadingGetUsers, mutate: mutateGetUsers } =
    useMutation(getUsers);
  const { mutate: mutateSetUserActive } = useMutation(setUserActive);
  const { isLoading: isLoadingDeleteUser, mutate: mutateDeleteUser } =
    useMutation(deleteUser);
  const { mutate: mutateGetUser } = useMutation(getUser);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalFormResetOpen, setIsModalFormResetOpen] = useState(false);
  const [isVisibleDeleteUser, setIsVisibleDeleteUser] = useState(false);
  const [isVisibleDeleteInfoUser, setIsVisibleDeleteInfoUser] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [roleGuid, setRoleGuid] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isShowRoleId, setIsShowRoleId] = useState(false);

  const [selectedRoleSearch, setSelectedRoleSearch] = useState(null);

  const [newUserAdminData, setNewUserAdminData] = useState();
  const [userId, setUserId] = useState();
  
  const [newUserAdminSearch, setNewUserAdminSearch] = useState();

  let newUserData = [];

  const [isGetAccessUserAdmin, setIsGetAccessUserAdmin] = useState();
  const [isDeleteAccessUserAdmin, setIsDeleteAccessUserAdmin] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //User Admin
          const getAccessUserAdmin = data?.data?.access
            ?.filter((data) => data?.page === "admin")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessUserAdmin(getAccessUserAdmin?.length);

          const deleteAccessUserAdmin = data?.data?.access
            ?.filter((data) => data?.page === "admin")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessUserAdmin(deleteAccessUserAdmin?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Modal
  // -------------------------------
  const formModal = useForm();
  const resetformModal = (newForm) => {
    if (newForm) {
      formModal.reset({
        name: newForm?.name,
        lastname: newForm?.name,
        phone: newForm?.phone,
        email: newForm?.email,
        role: newForm?.role?.role_id,
      });
    }
  };

  // -------------------------------
  // Form Modal Reset
  // -------------------------------
  const formModalReset = useForm();
  const resetformModalReset = (newForm) => {
    if (newForm) {
      formModalReset.reset({
        newpassword: newForm?.newpassword,
        confirmpassword: newForm?.confirmpassword,
      });
    }
  };

  // -------------------------------
  // Form Search Filter
  // -------------------------------
  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setRoleGuid(guid);
    mutateGetUser(
      { guid, token, language },
      {
        onSuccess: (data) => {
          isEdit && resetformModal(data?.data);
        },
      }
    );
    isCreate && resetformModal({});
  };

  const handleUserActive = (guid) => {
    mutateSetUserActive(
      { guid, token, language },
      {
        onSuccess: (data) => {
          refetch();
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
        },
      }
    );
  };

  const handleDialogDeleteUser = (isOpen, guid) => {
    setIsVisibleDeleteUser(isOpen);
    setUserId(guid);
    setIsVisibleDeleteInfoUser(false);
  };

  const handleDeleteUser = (guid) => {
    mutateDeleteUser(
      { guid, token, language },
      {
        onSuccess: () => {
          refetch();
          setIsVisibleDeleteUser(false);
          setIsVisibleDeleteInfoUser(true);
        },
      }
    );
  };

  const handleResetPasswordUser = (isOpen, guid) => {
    setIsModalFormResetOpen(isOpen);
    console.log(guid);
    // mutateDeleteUser(
    //   { guid, token },
    //   {
    //     onSuccess: () => {
    //       refetch();
    //       setIsVisibleDeleteUser(false);
    //       setIsVisibleDeleteInfoUser(true);
    //     },
    //   }
    // );
    // console.log(isOpen);
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isName = keyword?.keyword_filter === "1" && true;
    // let isPhone = keyword?.keyword_filter === "2" && true;
    // let isEmail = keyword?.keyword_filter === "3" && true;
    // let isRole = keyword?.keyword_filter === "4" && true;
    // let isStatus = keyword?.keyword_filter === "5" && true;
    // console.log(keyword)

    let isName      = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isPhone     = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isEmail     = keyword?.keyword_filter?.value === "3" || (keyword?.keyword_filter === "3" && true);
    let isRole      = keyword?.keyword_filter?.value === "4" || (keyword?.keyword_filter === "4" && true);
    let isStatus    = keyword?.keyword_filter?.value === "5" || (keyword?.keyword_filter === "5" && true);
    let isSearchStatus = keyword?.keyword_status === "1" && true;
    let searchKeyword = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-user-admin", searchKeyword);
    } else {
      localStorage.removeItem("search-user-admin");
    }

    

    setIsShowStatus(isStatus);
    setIsShowRoleId(isRole);
    setIsSearch(true);

    let payload = {
      isName: isName,
      isPhone: isPhone,
      isEmail: isEmail,
      isRole: isRole,
      isStatus: isStatus,
      search: searchKeyword,
      searchStatus: isSearchStatus,
      searchRole: selectedRoleSearch?.id,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    mutateSetUserSearch(
      { payload },
      {
        onSuccess: (data) => {
          newUserData = [];

          data?.data?.map((value) => {
            setNewUserAdminSearch(data)
            newUserData.push({
              username: value?.name,
              email: value?.email,
              firstname: value?.name,
              lastname: value?.name,
              phone: value?.phone,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              created_by: value?.created_by,
              ispublish: profileData?.data?.email !== value?.email && (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUserActive(value?.id)}
                />
              ),
              // banned_time: "",
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleResetPasswordUser(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Reset Password
                    </div>
                  </Button> */}
                  {
                  // (isGetAccessUserAdmin === 1 || isViewAllAccess === true) &&
                    profileData?.data?.email !== value?.email && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF6627]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleModalFormOpen(false, true, true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.EDIT")}
                        </div>
                      </Button>
                    )}

                  {
                  // (isDeleteAccessUserAdmin === 1 ||
                  //   isViewAllAccess === true) &&
                    profileData?.data?.email !== value?.email && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleDialogDeleteUser(true, value?.id)}
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    )}
                  {/* {value?.is_active !== true || value?.is_active !== false ? (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#1FB3A7]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Banned
                      </div>
                    </Button>
                  ) : (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#18AB56]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Unbanned
                      </div>
                    </Button>
                  )} */}
                </div>
              ),
            });
          });
          setNewUserAdminData(newUserData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    setIsSearch(false);
    mutateGetUsers(
      { limit, token },
      {
        onSuccess: (data) => {
          newUserData = [];

          data?.data?.map((value) => {
            newUserData.push({
              username: value?.name,
              email: value?.email,
              firstname: value?.name,
              lastname: value?.name,
              phone: value?.phone,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              created_by: value?.created_by,
              ispublish: profileData?.data?.email !== value?.email && (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUserActive(value?.id)}
                />
              ),
              // banned_time: "",
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleResetPasswordUser(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Reset Password
                    </div>
                  </Button> */}
                  {
                  // (isGetAccessUserAdmin === 1 || isViewAllAccess === true) &&
                    profileData?.data?.email !== value?.email && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF6627]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleModalFormOpen(false, true, true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.EDIT")}
                        </div>
                      </Button>
                    )}
                  {
                  // (isDeleteAccessUserAdmin === 1 ||
                  //   isViewAllAccess === true) &&
                    profileData?.data?.email !== value?.email && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleDialogDeleteUser(true, value?.id)}
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    )}
                  {/* {value?.is_active !== true || value?.is_active !== false ? (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#1FB3A7]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Banned
                      </div>
                    </Button>
                  ) : (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#18AB56]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Unbanned
                      </div>
                    </Button>
                  )} */}
                </div>
              ),
            });
          });
          setNewUserAdminData(newUserData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    setIsSearch(false);
    mutateGetUsers(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newUserData = [];

          data?.data?.map((value) => {
            newUserData.push({
              username: value?.name,
              email: value?.email,
              firstname: value?.name,
              lastname: value?.name,
              phone: value?.phone,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              created_by: value?.created_by,
              ispublish: profileData?.data?.email !== value?.email && (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUserActive(value?.id)}
                />
              ),
              // banned_time: "",
              status:
                value?.is_active === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.ACTIVE")}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NOT_ACTIVE")}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.BANNED")}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleResetPasswordUser(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Reset Password
                    </div>
                  </Button> */}
                  {
                  // (isGetAccessUserAdmin === 1 || isViewAllAccess === true) &&
                    profileData?.data?.email !== value?.email && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF6627]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleModalFormOpen(false, true, true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.EDIT")}
                        </div>
                      </Button>
                    )}
                  {
                  // (isDeleteAccessUserAdmin === 1 ||
                  //   isViewAllAccess === true) &&
                    profileData?.data?.email !== value?.email && (
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleDialogDeleteUser(true, value?.id)}
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    )}
                  {/* {value?.is_active !== true || value?.is_active !== false ? (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#1FB3A7]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Banned
                      </div>
                    </Button>
                  ) : (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#18AB56]"
                      style={{ borderRadius: "10px", height: "23px" }}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        Unbanned
                      </div>
                    </Button>
                  )} */}
                </div>
              ),
            });
          });
          setNewUserAdminData(newUserData);
        },
      }
    );
  };

  useEffect(() => {    
    localStorage.removeItem("search-user-admin");
    newUserData = [];
    setIsSearch(false);

    userAdminData?.data?.map((value) => {
      newUserData.push({
        username: value?.name,
        email: value?.email,
        firstname: value?.name,
        lastname: value?.name,
        phone: value?.phone,
        created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
        created_by: value?.created_by,
        ispublish: profileData?.data?.email !== value?.email && (
          <Switch
            colorScheme={value?.is_active === true ? "green" : "red"}
            isChecked={value?.is_active === true ? true : false}
            onChange={() => handleUserActive(value?.id)}
          />
        ),
        // banned_time: "",
        status:
          value?.is_active === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.ACTIVE")}
            </div>
          ) : value?.is_active === false ? (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NOT_ACTIVE")}
            </div>
          ) : (
            <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.BANNED")}
            </div>
          ),
        action: (
          <div className="flex gap-2">
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleResetPasswordUser(true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Reset Password
              </div>
            </Button> */}
            {
            // (isGetAccessUserAdmin === 1 || isViewAllAccess === true) &&
              profileData?.data?.email !== value?.email && (
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#FF6627]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() =>
                    handleModalFormOpen(false, true, true, value?.id)
                  }
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.EDIT")}
                  </div>
                </Button>
              )}
            {
            // (isDeleteAccessUserAdmin === 1 || isViewAllAccess === true) &&
              profileData?.data?.email !== value?.email && (
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#FF0F0F]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() => handleDialogDeleteUser(true, value?.id)}
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.DELETE")}
                  </div>
                </Button>
              )}
            {/* {value?.is_active !== true || value?.is_active !== false ? (
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#1FB3A7]"
                style={{ borderRadius: "10px", height: "23px" }}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  Banned
                </div>
              </Button>
            ) : (
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#18AB56]"
                style={{ borderRadius: "10px", height: "23px" }}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  Unbanned
                </div>
              </Button>
            )} */}
          </div>
        ),
      });
    });
    setNewUserAdminData(newUserData);
  }, [
    userAdminData,
    token,
    isGetAccessUserAdmin,
    isDeleteAccessUserAdmin,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formModal,
        formModalReset,

        resetformModal,
        resetformModalReset,

        isModalFormOpen,
        isModalFormResetOpen,
        isVisibleDeleteUser,
        isVisibleDeleteInfoUser,
        isEdit,

        userAdminData,
        userId,
        newUserAdminData,

        isLoadingUserAdminData,
        isLoadingGetUsers,
        isLoadingDeleteUser,

        handleModalFormOpen,
        handleDialogDeleteUser,
        handleResetPasswordUser,
        handleDeleteUser,
        handlePaginationTable,
        handleShowEntriesTable,
        handleSearch,
        isGetAccessUserAdmin,
        isDeleteAccessUserAdmin,
        isViewAllAccess,

        userAdminRoleData,
        isSearch,
        isShowStatus,
        isShowRoleId,
        selectedRoleSearch,
        setSelectedRoleSearch,
        roleGuid,
        setRoleGuid,
        newUserAdminSearch,
        formKeywordSearch,
        setIsShowStatus
      }}
    >
      {children}
    </Provider>
  );
};

export default UserAdminController;
