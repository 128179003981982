import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { Button, Switch, useToast } from "@chakra-ui/react";
import { TokenContext } from "../../../../controllers/public/TokenController";
import {
  getVoucher,
  getVoucherCode,
  getVoucherSearch,
  getVouchers,
} from "./api/VoucherApi";
import { getProfile } from "../../../../controllers/public/api/ProfileApi";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/public/AuthController";
import { getRole } from "../User/UserAdminRole/api/RoleApi";

export const VoucherContext = createContext();
const { Provider } = VoucherContext;

const VoucherController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: voucherData,
    isLoading: isLoadingVoucherData,
    refetch: refetchVoucherData,
  } = useQuery(["vouchers", token, language], () =>
    getVouchers({ token, language })
  );

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isFetching: isFetchingProfileData,
    isError: errorProfileData,
  } = useQuery(["profile", token, language], () =>
    getProfile({ token, language })
  );

  const { isLoading: isLoadingVoucherSearch, mutate: mutateSetVoucherSearch } =
    useMutation(getVoucherSearch);

  const { isLoading: isLoadingGetVouchers, mutate: mutateGetVouchers } =
    useMutation(getVouchers);
  const { mutate: mutateGetVoucher } = useMutation(getVoucher);
  const { isLoading: isLoadingGetVoucherCode, mutate: mutateGetVoucherCode } =
    useMutation(getVoucherCode);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalRedeemOpen, setIsModalRedeemOpen] = useState(false);
  const [isModalVoucherDownloadOpen, setIsModalVoucherDownloadOpen] =
    useState(false);
  const [isModalFormResetOpen, setIsModalFormResetOpen] = useState(false);
  const [isModalFormTopUpOpen, setIsModalFormTopUpOpen] = useState(false);
  const [isVisibleDeleteInfoBankAccount, setIsVisibleDeleteInfoBankAccount] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [bankLogoUrl, setBankLogoUrl] = useState();
  const [urlProfilePicture, setUrlProfilePicture] = useState();
  const [urlQrCode, setUrlQrCode] = useState();
  const [isVisibleTopUpBalanceInfo, setIsVisibleTopUpBalanceInfo] =
    useState(false);
  const [isVisibleInfoVoucher, setIsVisibleInfoVoucher] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isShowTemporaryClose, setIsShowTemporaryClose] = useState(false);

  const [newVoucherData, setNewVoucherData] = useState();
  const [bankAccountId, setBankAccountId] = useState();
  const [agentId, setAgentId] = useState();

  const [isShowType, setIsShowType] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(false);
  const [isQrCode, setIsQrCode] = useState(false);

  let newVoucherCodeData = [];

  const [isDeleteAccessUserAgent, setIsDeleteAccessUserAgent] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //User Agent
          const deleteAccessUserAgent = data?.data?.access
            ?.filter((data) => data?.page === "agent")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessUserAgent(deleteAccessUserAgent?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Modal
  // -------------------------------
  const formModal = useForm();
  const resetformModal = (newForm) => {
    if (newForm) {
      formModal.reset({
        guid: newForm?.guid,
        title: newForm?.title,
        code: newForm?.code,
        nominal: newForm?.nominal,
        status: newForm?.status,
        user_id: newForm?.redeemed?.user_id,
        name: newForm?.redeemed?.name,
        redeem_at: newForm?.redeemed?.redeem_at,
        created_at: newForm?.created_at,
        updated_at: newForm?.updated_at,
      });
    }
  };

  // -------------------------------
  // Form Voucher
  // -------------------------------
  const formModalInsert = useForm();
  const resetformModalInsert = (newForm) => {
    // if (newForm) {
    formModalInsert.reset({
      title: newForm?.title,
      nominal: newForm?.nominal,
    });
    // }
  };


  // const handleModalFormOpen = (isCreate, isOpen, isEdit, guid) => {
  //   setIsModalFormOpen(isOpen);
  //   resetformModalInsert(null);
  // };

  const handleModalFormOpen = (isOpen) => {
    // refetchVoucherData();
    setIsModalFormOpen(isOpen);
    resetformModalInsert(null);
  };

  const handleModalRedeemOpen = (isCreate, isOpen, isEdit, code) => {
    setIsModalRedeemOpen(isOpen);
    mutateGetVoucherCode(
      { code, token },
      {
        onSuccess: (data) => {
          // console.log(data?.data);
          isEdit && resetformModal(data?.data);
        },
      }
    );
  };

  const handleModalVoucherDownloadOpen = (isOpen, isEdit, code) => {
    setIsModalVoucherDownloadOpen(isOpen);
    mutateGetVoucherCode(
      { code, token, language },
      {
        onSuccess: (data) => {
          resetformModal(data?.data);
        },
      }
    );
    // isCreate && resetformModal({});
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword) => {
    let isCode = keyword?.keyword_filter === "1" && true;
    let isStatus = keyword?.keyword_filter === "2" && true;

    let lang = t("LANGUAGE");

    setIsShowStatus(isStatus);
    // setIsShowTemporaryClose(isAccountName);
    setIsSearch(true);

    let payload = {
      isCode: isCode,
      isStatus: isStatus,
      search: keyword?.keyword_search,
      lang: lang,
      token: token,
    };
    mutateSetVoucherSearch(
      { payload },
      {
        onSuccess: (data) => {
          newVoucherCodeData = [];

          data?.data?.map((value) => {
            newVoucherCodeData.push({
              code: value?.code,
              guid: value?.guid,
              title: value?.title,
              nominal: new Intl.NumberFormat().format(value?.nominal),
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              action: (
                <div className="flex items-center justify-center gap-2">
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalRedeemOpen(false, true, true, value?.guid)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button> */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalRedeemOpen(false, true, true, value?.code)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.REDEEM")}
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalVoucherDownloadOpen(
                        // false,
                        true,
                        true,
                        value?.code
                      )
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DOWNLOAD")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewVoucherData(newVoucherCodeData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    setIsSearch(false);
    mutateGetVouchers(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newVoucherCodeData = [];

          data?.data?.map((value) => {
            newVoucherCodeData.push({
              code: value?.code,
              guid: value?.guid,
              title: value?.title,
              nominal: new Intl.NumberFormat().format(value?.nominal),
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              action: (
                <div className="flex items-center justify-center gap-2">
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalRedeemOpen(false, true, true, value?.guid)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button> */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalRedeemOpen(false, true, true, value?.code)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.REDEEM")}
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalVoucherDownloadOpen(
                        // false,
                        true,
                        true,
                        value?.code
                      )
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DOWNLOAD")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewVoucherData(newVoucherCodeData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    setIsSearch(false);
    mutateGetVouchers(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newVoucherCodeData = [];

          data?.data?.map((value) => {
            newVoucherCodeData.push({
              code: value?.code,
              guid: value?.guid,
              title: value?.title,
              nominal: new Intl.NumberFormat().format(value?.nominal),
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              action: (
                <div className="flex items-center justify-center gap-2">
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalRedeemOpen(false, true, true, value?.guid)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button> */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalRedeemOpen(false, true, true, value?.code)
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.REDEEM")}
                    </div>
                  </Button>
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() =>
                      handleModalVoucherDownloadOpen(
                        // false,
                        true,
                        true,
                        value?.code
                      )
                    }
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DOWNLOAD")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewVoucherData(newVoucherCodeData);
        },
      }
    );
  };

  useEffect(() => {
    newVoucherCodeData = [];
    setIsSearch(false);

    voucherData?.data?.map((value) => {
      newVoucherCodeData.push({
        code: value?.code,
        guid: value?.guid,
        title: value?.title,
        nominal: new Intl.NumberFormat().format(value?.nominal),
        status:
          value?.status === "approved" || value?.status === "success" ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "payment reject" ||
            value?.status === "rejected" ? (
            <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "paid" || value?.status === "expired" ? (
            <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : (
            <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ),

        action: (
          <div className="flex items-center justify-center gap-2">
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleModalRedeemOpen(false, true, true, value?.guid)
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.VIEW")}
              </div>
            </Button> */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleModalRedeemOpen(false, true, true, value?.code)
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.REDEEM")}
              </div>
            </Button>
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() =>
                handleModalVoucherDownloadOpen(
                  // false,
                  true,
                  true,
                  value?.code
                )
              }
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.DOWNLOAD")}
              </div>
            </Button>
          </div>
        ),
      });
    });
    setNewVoucherData(newVoucherCodeData);
  }, [voucherData, token, isDeleteAccessUserAgent, isViewAllAccess]);

  return (
    <Provider
      value={{
        formModal,
        formModalInsert,

        resetformModal,
        resetformModalInsert,

        isModalFormOpen,
        isModalRedeemOpen,
        isModalVoucherDownloadOpen,
        isModalFormResetOpen,
        // isVisibleDeleteBankAccount,
        isVisibleDeleteInfoBankAccount,
        isEdit,

        voucherData,
        bankAccountId,
        newVoucherData,

        isLoadingVoucherData,
        isLoadingGetVouchers,
        // isLoadingDeleteUser,

        handleModalFormOpen,
        handleModalRedeemOpen,
        // handleDeleteBankAccount,
        handlePaginationTable,
        handleShowEntriesTable,
        handleSearch,

        urlProfilePicture,
        setUrlProfilePicture,

        isDeleteAccessUserAgent,
        isViewAllAccess,
        isSearch,
        isShowTemporaryClose,

        // isLoadingBankAccountEdit,
        isModalFormTopUpOpen,
        isVisibleTopUpBalanceInfo,
        agentId,

        isShowType,
        isShowStatus,
        refetchVoucherData,
        bankLogoUrl,
        setBankLogoUrl,
        qrCodeUrl,
        setQrCodeUrl,
        isQrCode,
        setIsQrCode,
        urlQrCode,
        setUrlQrCode,
        isLoadingVoucherSearch,
        handleModalVoucherDownloadOpen,
        isLoadingGetVoucherCode,
        isVisibleInfoVoucher,
        setIsVisibleInfoVoucher,
      }}
    >
      {children}
    </Provider>
  );
};

export default VoucherController;
