import { api } from "../../../../../utils/axios";

//Update Referral
export const updateReferral = ({ token, language, payload }) =>
  api
    .put(`/backoffice/user-app/referral/commission`, payload, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Referral
export const getReferral = ({ token, language }) =>
  api
    .get(`/backoffice/user-app/referral/commission`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
