import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { cookiesVars } from "../../utils/cookies";
import { getRefreshToken, getToken } from "./api/TokenApi";
import { useQuery } from "react-query";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { publicSlug } from "../../utils/publicSlug";
import { useTranslation } from "react-i18next";
import gb from "../../i18n/gb.json";
import id from "../../i18n/id.json";
import cn from "../../i18n/cn.json";

export const TokenContext = createContext();
const { Provider } = TokenContext;

const TokenController = ({ children }) => {
  // const [token, setToken] = useState();
  // const { t } = useTranslation();
  // const language = t("LANGUAGE");
  // const navigate = useNavigate();

  // const { data } = useQuery(["token", language], () => getToken({ language }));
  // const { data: refresh_token } = useQuery(["refresh_token", data], () =>
  //   getRefreshToken({ data, language })
  // );

  // const tokenCredentials = Cookies.get(cookiesVars._credentials);
  // const tokenExpirations = Cookies.get(cookiesVars._exp);

  // useEffect(() => {
  //   const updateCookies = () => {
  //     Cookies.remove(cookiesVars._credentials);
  //     Cookies.remove(cookiesVars._exp);
  //     Cookies.remove(cookiesVars._islogin);
  //     navigate(publicSlug.LOGIN);

  //     if (data?.data?.token && data?.data?.token_expired) {
  //       Cookies.set(cookiesVars._credentials, data?.data?.token);
  //       Cookies.set(
  //         cookiesVars._exp,
  //         moment(data?.data?.token_expired).format("L")
  //       );
  //     }
  //   };

  //   const setTokenAndPopulateTranslations = () => {
  //     setToken(tokenCredentials);
  //   };

  //   // Check if token is undefined or expired
  //   if (
  //     !tokenCredentials ||
  //     moment(tokenExpirations).isBefore(moment().format("L"))
  //   ) {
  //     updateCookies();
  //   } else {
  //     // Token is valid
  //     setTokenAndPopulateTranslations();
  //   }
  // }, [
  //   tokenCredentials,
  //   tokenExpirations,
  //   data,
  //   navigate,
  //   gb,
  //   id,
  //   cn,
  // ]);

  const [token, setToken] = useState(Cookies.get(cookiesVars._credentials) || null);
  const [refreshToken, setRefreshToken] = useState();
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPage = location.pathname === publicSlug.LOGIN; // Adjust path as needed

  const { data: tokenData, isLoading: tokenLoading } = useQuery(
    ["token", language],
    () => getToken({ language }),
    { enabled: isLoginPage && !token } // Fetch token only if on login page and token is not set
  );

  const { data: refreshTokenData, isLoading: refreshTokenLoading } = useQuery(
    ["refresh_token", tokenData],
    () => getRefreshToken({ data: tokenData, language }),
    { enabled: isLoginPage && !!tokenData?.data?.token_expired } // Fetch refresh token only if token is expired and on login page
  );

  useEffect(() => {
    const updateCookies = (newToken, newExpiration) => {
      Cookies.set(cookiesVars._credentials, newToken);
      Cookies.set(cookiesVars._exp, moment(newExpiration).format("L"));
      Cookies.set(cookiesVars._refreshCredentials, tokenData?.data?.refresh_token);
      setToken(newToken);
      setIsTokenVerified(true);
    };

    const removeCookiesAndRedirect = () => {
      Cookies.remove(cookiesVars._credentials);
      Cookies.remove(cookiesVars._exp);
      Cookies.remove(cookiesVars._refreshCredentials);
      Cookies.remove(cookiesVars._islogin);
      setToken(null);
      // setRefreshToken()
      setIsTokenVerified(true);
      navigate(publicSlug.LOGIN);
    };

    if (tokenLoading || refreshTokenLoading) {
      return; // Wait until both queries have loaded
    }

    if (isLoginPage) {
      // Only update the token on the login page
      const tokenFromApi = tokenData?.data?.token;
      const tokenExpirationFromApi = tokenData?.data?.token_expired;
      const refreshTokenFromApi = refreshTokenData?.data?.token;
      const refreshTokenExpirationFromApi = refreshTokenData?.data?.token_expired;      

      if (tokenFromApi && moment(tokenExpirationFromApi).isAfter(moment())) {            
        updateCookies(tokenFromApi, tokenExpirationFromApi);
      } else if (refreshTokenFromApi && moment(refreshTokenExpirationFromApi).isAfter(moment())) {        
        updateCookies(refreshTokenFromApi, refreshTokenExpirationFromApi);
      } else {
        removeCookiesAndRedirect();
      }      
    } else {
      // On other pages (e.g., dashboard), don't change the token
      setIsTokenVerified(true);
    }
  }, [
    tokenData,
    refreshTokenData,
    tokenLoading,
    refreshTokenLoading,
    token,
    setToken,
    setIsTokenVerified,
    refreshToken, 
    setRefreshToken,
    navigate,
    isLoginPage,
  ]);

  if (!isTokenVerified) {
    return null; // Render nothing until the token is verified
  }


  return <Provider value={{ token }}>{children}</Provider>;
  
};

export default TokenController;
