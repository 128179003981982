import React from "react";
import Forget from "../pages/content/public/Forgot";
import Login from "../pages/content/public/Login";
import Reset from "../pages/content/public/Reset";
import { publicSlug } from "../utils/publicSlug";

const PublicRoutes = [
  {
    path: publicSlug.LOGIN,
    element: <Login />,
  },
  { path: publicSlug.FORGOT, element: <Forget /> },
  { path: publicSlug.RESET, element: <Reset /> },
];

export default PublicRoutes;
