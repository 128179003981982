import React, { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import PrivateAuth from "../../../controllers/public/PrivateAuth";
import TabletNavbar from "./components/TabletNavbar";
import TabletBar from "./components/TabletBar";

const PrivateTemplate = () => {
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  return (
    <div>
      <PrivateAuth />

      {/* Desktop Version */}
      <div className="hidden lg:grid lg:grid-cols-1">
        <Navbar />
        <div className="flex flex-row">
          <div className="bg-[#F3F1F1]">
            <Sidebar />
          </div>
          <div className="w-full p-6 ml-64 mt-16 overflow-y-auto">
            <Outlet />
          </div>
        </div>
      </div>

      {/* Tablet or Mobile Version */}
      <div className="lg:hidden">
        <TabletNavbar />
        <div className="flex flex-row ">
          <div className="bg-[#F3F1F1]">
            <TabletBar />
          </div>
          <div className="w-full overflow-auto p-6">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateTemplate;
