import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
// import { setForgot, setLogin, setLogout } from "./api/AuthApi";
import { useToast } from "@chakra-ui/react";
// import { cookiesVars } from "../../utils/cookies";
import Cookies from "js-cookie";
// import { privateSlug } from "../../utils/privateSlug";
import { useNavigate } from "react-router-dom";
// import { publicSlug } from "../../utils/publicSlug";
import { TokenContext } from "../../../../controllers/public/TokenController";
import {
  getProfile,
  updatePassword,
} from "../../../../controllers/public/api/ProfileApi";
import { setUploadProfilePicture } from "../../../../controllers/public/api/MediaApi";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

export const ChangePasswordContext = createContext();
const { Provider } = ChangePasswordContext;

const ChangePasswordController = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { token } = useContext(TokenContext);

  //   const { isLoading: isLoginLoading, mutate: mutateLogin } =
  //     useMutation(setLogin);
  //   const { mutate: mutateLogout } = useMutation(setLogout);
  //   const { isLoading: isLoadingForgot, mutate: mutateForgot } =
  //     useMutation(setForgot);

  // const {
  //   data: profileData,
  //   isLoading: isLoadingProfileData,
  //   isFetching: isFetchingProfileData,
  //   isError: errorProfileData,
  //   refetch,
  // } = useQuery(["profile", token], () => getProfile({ token }));

  const {
    isLoading: isLoadingUploadBanner,
    mutate: mutateSetUploadProfilePicture,
  } = useMutation(setUploadProfilePicture);

  const { isLoading: isLoadingUpdatePassword, mutate: mutateUpdatePassword } =
    useMutation(updatePassword);

  // -------------------------------
  // Form
  // -------------------------------
  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      // console.log(newForm)
      form.reset({
        old_password: newForm?.old_password,
        new_password: newForm?.new_password,
        confirm_new_password: newForm?.confirm_new_password,
      });
    }

  };

  useEffect(() => {
    resetForm();
  }, []);

  const handleUpdatePassword = (payload) => {
    // console.log(urlProfilePicture);
    // console.log(payload);

    let lang = t("LANGUAGE");

    let newPayload = {
      old_password: payload?.old_password,
      new_password: payload?.new_password,
      confirm_new_password: payload?.confirm_new_password,
    };

    // console.log(newPayload)

    mutateUpdatePassword(
      { newPayload, token, lang },
      {
        onSuccess: (data) => {
          if (
            data?.message?.response_code !== "00" 
          ) {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "warning",
              duration: 1500,
              isClosable: true,
            });
            //   setIsVisibleBannerDialog(false);
            //   handlePublishBannerAdsOpen(true);
            //   handleModalFormOpen(false, true);
          } else {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "success",
              duration: 1500,
              isClosable: true,
            });
            //   refetchAdvertisingsBannerData();
            //   setIsVisibleBannerDialog(true);
            //   handlePublishBannerAdsOpen(false);
            //   handleModalFormOpen(true, false, true);
          }
        },
      }
    );
  };

  return (
    <Provider
      value={{
        form,
        resetForm,
        // urlProfilePicture,
        // handleUploadProfilePicture,
        handleUpdatePassword,
        isLoadingUpdatePassword,
      }}
    >
      {children}
    </Provider>
  );
};

export default ChangePasswordController;
