import React, { lazy, Suspense } from "react";
// import Filter from "./components/Filter";
// import Header from "./components/Header";
// import ModalTransactionShDetail from "./components/Modal";
// import TableTransactionSh from "./components/TableTransactionSh";
import TransactionShController from "./Controller";
// import FilterTransactionTimeDialog from "./components/Dialog/FilterTransactionTimeDialog";
// import FilterAmountDialog from "./components/Dialog/FilterAmountDialog";
// import DownloadDialog from "./components/Dialog/DownloadDialog";
// import DownloadInfoDialog from "./components/Dialog/DownloadInfoDialog";

const TransactionSh = () => {
  const Filter = lazy(() => import("./components/Filter"));
  const Header = lazy(() => import("./components/Header"));
  const ModalTransactionShDetail = lazy(() => import("./components/Modal"));
  const TableTransactionSh = lazy(() =>
    import("./components/TableTransactionSh")
  );
  const FilterTransactionTimeDialog = lazy(() =>
    import("./components/Dialog/FilterTransactionTimeDialog")
  );
  const FilterAmountDialog = lazy(() =>
    import("./components/Dialog/FilterAmountDialog")
  );
  const DownloadDialog = lazy(() =>
    import("./components/Dialog/DownloadDialog")
  );
  const DownloadInfoDialog = lazy(() =>
    import("./components/Dialog/DownloadInfoDialog")
  );

  return (
    <div>
      <TransactionShController>
        <Suspense
          fallback={
            <div className="h-screen flex items-center">
              <div className="mx-auto">
                <div
                  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex gap-4">
            {/* <ButtonNew /> */}
            <Header />
          </div>
          {/* <div className="pt-4 pb-4">
            <div className="flex gap-4">
              <div className="w-full"></div>
            </div>
          </div> */}

          <div cla ssName="border border-gray-300 p-4 rounded-xl">
            <div>
              <div className="overflow-auto h-screen">
                <div className="py-4">
                  <Filter />
                </div>
                <TableTransactionSh />
              </div>
            </div>
          </div>
          <ModalTransactionShDetail />
          <FilterTransactionTimeDialog />
          <FilterAmountDialog />
          <DownloadDialog />
          <DownloadInfoDialog />
        </Suspense>
      </TransactionShController>
    </div>
  );
};

export default TransactionSh;
