import { Button, Switch, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import {
  getConfirmationTransaction,
  getConfirmationTransactionSearch,
} from "./api/TransactionApi";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ConfirmationTransactionContext = createContext();
const { Provider } = ConfirmationTransactionContext;

const ConfirmationTransactionController = ({ children }) => {
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  let language = t("LANGUAGE");

  const toast = useToast();

  const [newConfirmationTransaction, setNewConfirmationTransaction] =
    useState();
    
  const [newConfirmationTransactionListSearch, setConfirmationNewTransactionListSearch] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isModalFormUpdateSellPriceOpen, setIsModalFormUpdateSellPriceOpen] =
    useState(false);
  const [transactionId, setTransactionId] = useState();
  const [isVisibleUpdateSellPriceInfo, setIsVisibleUpdateSellPriceInfo] =
    useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isVisibleDownloadTransaction, setIsVisibleDownloadTransaction] =
    useState(false);
  const [
    isVisibleDownloadInfoTransaction,
    setIsVisibleDownloadInfoTransaction,
  ] = useState(false);
  const [isVisibleFilterRange, setIsVisibleFilterRange] = useState(false);
  const [isVisibleFilterRangeInfo, setIsVisibleFilterRangeInfo] =
    useState(false);

  const [pres, setPres] = useState();

  const {
    data: confirmationTransactionList,
    isLoading: isLoadingConfirmationTransactionList,
    refetch: refecthConfirmationTransactionData,
  } = useQuery(["confirmationtransaction", token, language], () =>
    getConfirmationTransaction({ token, language })
  );

  const {
    isLoading: isLoadingSetConfirmationTransactionSearch,
    mutate: mutateSetConfirmationTransactionSearch,
  } = useMutation(getConfirmationTransactionSearch);

  const {
    isLoading: isLoadingGetConfirmationTransactionList,
    mutate: mutateGetConfirmationTransaction,
    refetch: refetchGetConfirmationTransactionList,
  } = useMutation(getConfirmationTransaction);

  // const {
  //   isLoading: isLoadingGetTransactionListExport,
  //   mutate: getConfirmationTransactionExport,
  //   refetch: refetchGetTransactionListExport,
  // } = useMutation(getTransactionListExport);

  // const { mutate: mutateGetTransactionId } = useMutation(getTransactionById);

  let newConfirmationTransactionListsData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isGetAccessPrabayarTransaction, setIsGetAccessPrabayarTransaction] =
    useState();
  const [
    isDeleteAccessPrabayarTransaction,
    setIsDeleteAccessPrabayarTransaction,
  ] = useState();
  const [
    isUpdateAccessPrabayarTransaction,
    setIsUpdateAccessPrabayarTransaction,
  ] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
      });
    }
  };

  const handleDialogFilterRange = (isOpen, guid) => {
    // console.log(isPublish)
    setIsVisibleFilterRange(isOpen);
    // setAdvertisingRowId(guid);
    // setIsPublish(isPublish);
    setIsVisibleFilterRangeInfo(false);

    // mutateGetAdvertisingRow(
    //   { guid, token, language },
    //   {
    //     onSuccess: (data) => {
    // resetFormPublish({});
    //       setPublishPayloadClassifiedAdsEdit(data?.data);
    //     },
    //   }
    // );
  };

  const handleFilterRange = (payload) => {
    const defaultDatePublishFrom = moment(payload?.publish_from).format();
    const defaultDatePublishTo = moment(payload?.publish_to).format();

    const payloadPublish = {
      publish_from: defaultDatePublishFrom,
      publish_to: defaultDatePublishTo,
    };

    console.log(payloadPublish);
    // mutateGetTransactionListExport(
    //   { token, language, payloadPublish },
    //   {
    //     onSuccess: (data) => {
    //       console.log(data);
    //     },
    //   }
    // );
  };

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Prabayar Transaction
          const getAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessPrabayarTransaction(
            getAccessPrabayarTransaction?.length
          );

          const deleteAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessPrabayarTransaction(
            deleteAccessPrabayarTransaction?.length
          );

          const updateAccessPrabayarTransaction = data?.data?.access
            ?.filter((data) => data?.page === "prabayar-transaction")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessPrabayarTransaction(
            updateAccessPrabayarTransaction?.length
          );
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Publish
  // -------------------------------
  const formPublish = useForm();
  const resetFormPublish = (newForm) => {
    const dateFrom = new Date();
    const dateTo = new Date();
    const publishFrom = dateFrom.getDate() + 1;
    const publishTo = dateTo.getDate() + 2;
    dateFrom.setDate(publishFrom);
    dateTo.setDate(publishTo);
    const defaultDatePublishFrom = moment(dateFrom).format("YYYY-MM-DDTHH:mm");
    const defaultDatePublishTo = moment(dateTo).format("YYYY-MM-DDTHH:mm");

    const publishFromEdit = moment(newForm?.publish_from).format(
      "YYYY-MM-DDTHH:mm"
    );
    const publishToEdit = moment(newForm?.publish_to).format(
      "YYYY-MM-DDTHH:mm"
    );

    if (newForm) {
      formPublish.reset({
        publish_from: newForm?.publish_from,
        publish_to: newForm?.publish_to,
        status: 1,
      });
    }
  };

  const handleDialogDownloadTransaction = (isOpen, guid) => {
    // console.log(isPublish)
    setIsVisibleDownloadTransaction(isOpen);
    // setAdvertisingRowId(guid);
    // setIsPublish(isPublish);
    setIsVisibleDownloadInfoTransaction(false);

    // mutateGetAdvertisingRow(
    //   { guid, token, language },
    //   {
    //     onSuccess: (data) => {
    resetFormPublish({});
    //       setPublishPayloadClassifiedAdsEdit(data?.data);
    //     },
    //   }
    // );
  };

  // -------------------------------
  // Form Modal Transaction
  // -------------------------------
  const formTransactionDetail = useForm();
  const resetformTransactionDetail = (newForm) => {
    if (newForm) {
      formTransactionDetail.reset({
        transaction_id: newForm?.transaction_id,
        user_guid: newForm?.user_guid,
        user_phone: newForm?.user_phone,
        user_email: newForm?.user_email,
        user_full_name: newForm?.user_full_name,
        category: newForm?.category,
        brand: newForm?.brand,
        type: newForm?.type,
        seller_name: newForm?.seller_name,
        buyer_sku_code: newForm?.buyer_sku_code,
        slug_name: newForm?.slug_name,
        product_name: newForm?.product_name,
        description: newForm?.description,
        admin: newForm?.admin,
        commision: newForm?.commision,
        price: newForm?.price,
        sell_price: newForm?.sell_price,
        internal_fee: newForm?.internal_fee,
        amount: newForm?.amount,
        biller_ref_id: newForm?.biller_ref_id,
        biller_rc: newForm?.biller_rc,
        biller_status: newForm?.biller_status,
        biller_data: newForm?.biller_data,

        payment_status: newForm?.payment_status,
        status: newForm?.status,
        is_inquiry: newForm?.is_inquiry,
        inquiry_at: newForm?.inquiry_at,
        is_payment: newForm?.is_payment,
        payment_at: newForm?.payment_at,
        payment_method_id: newForm?.payment_method_id,
        payment_method_data: newForm?.payment_method_data,
        payment_channel: newForm?.payment_channel,
        payment_channel_data: newForm?.payment_channel_data,
        created_at: newForm?.created_at,
        confirm_payment_at: newForm?.confirm_payment_at,
        approved_at: newForm?.approved_at,
        rejected_at: newForm?.rejected_at,
        updated_at: newForm?.updated_at,
      });
    }
  };

  // const handleDialogTransaction = (isOpen, guid) => {
  //   setIsModalFormOpen(isOpen);
  //   setTransactionId(guid);
  //   // setIsVisibleTopUpBalanceInfo(false);
  //   // setIsShowType(true);

  //   mutateGetTransactionId(
  //     { guid, token },
  //     {
  //       onSuccess: (data) => {
  //         // resetformTransaction(data?.data)
  //         resetformTransactionDetail(data.data);
  //       },
  //     }
  //   );
  // };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    // let isPaymentMethod = keyword?.keyword_filter === "1" && true;
    // let isPaymentChannelGuid = keyword?.keyword_filter === "2" && true;
    // let isPaymentChannelBankCode = keyword?.keyword_filter === "3" && true;
    // let isPaymentChannelBankName = keyword?.keyword_filter === "4" && true;
    // let isPaymentChannelAccountNumber = keyword?.keyword_filter === "5" && true;
    // let isPaymentChannelAccountName = keyword?.keyword_filter === "6" && true;
    // let isTransactionType = keyword?.keyword_filter === "7" && true;
    // let isMessage = keyword?.keyword_filter === "8" && true;
    // let isStatus = keyword?.keyword_filter === "9" && true;
    // let isRange = formPublish?.getValues("publish_to") ? true : false;

    let isPaymentMethod               = keyword?.keyword_filter?.value === "1" || (keyword?.keyword_filter === "1" && true);
    let isPaymentChannelGuid          = keyword?.keyword_filter?.value === "2" || (keyword?.keyword_filter === "2" && true);
    let isPaymentChannelBankCode      = keyword?.keyword_filter?.value === "3" || (keyword?.keyword_filter === "3" && true);
    let isPaymentChannelBankName      = keyword?.keyword_filter?.value === "4" || (keyword?.keyword_filter === "4" && true);
    let isPaymentChannelAccountNumber = keyword?.keyword_filter?.value === "5" || (keyword?.keyword_filter === "5" && true);
    let isPaymentChannelAccountName   = keyword?.keyword_filter?.value === "6" || (keyword?.keyword_filter === "6" && true);
    let isTransactionType             = keyword?.keyword_filter?.value === "7" || (keyword?.keyword_filter === "7" && true);
    let isMessage                     = keyword?.keyword_filter?.value === "8" || (keyword?.keyword_filter === "8" && true);
    let isStatus                      = keyword?.keyword_filter?.value === "9" || (keyword?.keyword_filter === "9" && true);
    let isRange                       = formPublish?.getValues("publish_from")?.[1] ? true : false;
    let searchKeyword                 = !keyword?.value?.keyword_search ? "" : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-payment", searchKeyword);
    } else {
      localStorage.removeItem("search-payment");
    }

    const defaultDatePublishFrom = moment(
      formPublish?.getValues("publish_from")?.[0]
    ).format();
    const defaultDatePublishTo = moment(
      formPublish?.getValues("publish_from")?.[1]
    ).format();

    setIsSearch(true);

    let payload = {
      isPaymentMethod: isPaymentMethod,
      isPaymentChannelGuid: isPaymentChannelGuid,
      isPaymentChannelBankCode: isPaymentChannelBankCode,
      isPaymentChannelBankName: isPaymentChannelBankName,
      isPaymentChannelAccountNumber: isPaymentChannelAccountNumber,
      isPaymentChannelAccountName: isPaymentChannelAccountName,
      isTransactionType: isTransactionType,
      isMessage: isMessage,
      isStatus: isStatus,
      isRange: isRange,
      search: searchKeyword,
      publish_from:
        defaultDatePublishFrom === "Invalid date"
          ? null
          : defaultDatePublishFrom,
      publish_to:
        defaultDatePublishTo === "Invalid date" ? null : defaultDatePublishTo,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };

    currentPage.push(1);
    currentLimit.push(100);
    mutateSetConfirmationTransactionSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            setConfirmationNewTransactionListSearch(data)
            newConfirmationTransactionListsData.push({
              transaction_id: value?.transaction_id,
              // created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
              transaction_type: value?.transaction_type,
              payment_channel_bank_logo_url: (
                <img
                  src={value?.payment_channel_bank_logo_url}
                  alt=""
                  width={50}
                />
              ),
              payment_channel_bank_code: value?.payment_channel_bank_code,
              payment_channel_bank_name: value?.payment_channel_bank_name,
              payment_channel_account_number:
                value?.payment_channel_account_number,
              payment_channel_account_name: value?.payment_channel_account_name,
              message: value?.message,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              unique_number_account: value?.unique_number_account,
              total_amount: (
                <CurrencyFormat
                  value={value?.total_amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              // sell_price: (
              //   <CurrencyFormat
              //     value={value?.sell_price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // price: (
              //   <CurrencyFormat
              //     value={value?.price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // serial_number: "serial_number",
              // seller_name: value?.seller_name,
              // biller_ref_id: value?.biller_ref_id,
              // buyer_sku_code: value?.buyer_sku_code,
              // payment_status: value?.payment_status,
              status:
                value?.status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              // action: (
              //   <div className="flex gap-2">
              //     {(isGetAccessPrabayarTransaction === 1 ||
              //       isViewAllAccess === true) && (
              //       <Button
              //         size="md"
              //         colorScheme="new"
              //         className="bg-[#504178]"
              //         style={{ borderRadius: "10px", height: "23px" }}
              //         onClick={() =>
              //           handleDialogTransaction(true, value?.transaction_id)
              //         }
              //       >
              //         <div className=" text-white leading-[15px] font-[600] text-[12px]">
              //           {t("LABEL.VIEW")}
              //         </div>
              //       </Button>
              //     )}
              //   </div>
              // ),
            });
          });
          setNewConfirmationTransaction(newConfirmationTransactionListsData);
          handleDialogFilterRange(false);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    // console.log(limit)
    mutateGetConfirmationTransaction(
      { limit, token },
      {
        onSuccess: (data) => {
          newConfirmationTransactionListsData = [];

          console.log(data?.data);

          data?.data?.map((value) => {
            newConfirmationTransactionListsData.push({
              transaction_id: value?.transaction_id,
              // created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
              transaction_type: value?.transaction_type,
              payment_channel_bank_logo_url: (
                <img
                  src={value?.payment_channel_bank_logo_url}
                  alt=""
                  width={50}
                />
              ),
              payment_channel_bank_code: value?.payment_channel_bank_code,
              payment_channel_bank_name: value?.payment_channel_bank_name,
              payment_channel_account_number:
                value?.payment_channel_account_number,
              payment_channel_account_name: value?.payment_channel_account_name,
              message: value?.message,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              unique_number_account: value?.unique_number_account,
              total_amount: (
                <CurrencyFormat
                  value={value?.total_amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              // sell_price: (
              //   <CurrencyFormat
              //     value={value?.sell_price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // price: (
              //   <CurrencyFormat
              //     value={value?.price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // serial_number: "serial_number",
              // seller_name: value?.seller_name,
              // biller_ref_id: value?.biller_ref_id,
              // buyer_sku_code: value?.buyer_sku_code,
              // payment_status: value?.payment_status,
              status:
                value?.status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              // action: (
              //   <div className="flex gap-2">
              //     {(isGetAccessPrabayarTransaction === 1 ||
              //       isViewAllAccess === true) && (
              //       <Button
              //         size="md"
              //         colorScheme="new"
              //         className="bg-[#504178]"
              //         style={{ borderRadius: "10px", height: "23px" }}
              //         onClick={() =>
              //           handleDialogTransaction(true, value?.transaction_id)
              //         }
              //       >
              //         <div className=" text-white leading-[15px] font-[600] text-[12px]">
              //           {t("LABEL.VIEW")}
              //         </div>
              //       </Button>
              //     )}
              //   </div>
              // ),
            });
          });
          console.log(newConfirmationTransactionListsData);
          setNewConfirmationTransaction(newConfirmationTransactionListsData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);

    mutateGetConfirmationTransaction(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newConfirmationTransactionListsData = [];

          data?.data?.map((value) => {
            newConfirmationTransactionListsData.push({
              transaction_id: value?.transaction_id,
              // created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
              transaction_type: value?.transaction_type,
              payment_channel_bank_logo_url: (
                <img
                  src={value?.payment_channel_bank_logo_url}
                  alt=""
                  width={50}
                />
              ),
              payment_channel_bank_code: value?.payment_channel_bank_code,
              payment_channel_bank_name: value?.payment_channel_bank_name,
              payment_channel_account_number:
                value?.payment_channel_account_number,
              payment_channel_account_name: value?.payment_channel_account_name,
              message: value?.message,
              amount: (
                <CurrencyFormat
                  value={value?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              unique_number_account: value?.unique_number_account,
              total_amount: (
                <CurrencyFormat
                  value={value?.total_amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              // sell_price: (
              //   <CurrencyFormat
              //     value={value?.sell_price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // price: (
              //   <CurrencyFormat
              //     value={value?.price}
              //     displayType={"text"}
              //     thousandSeparator={true}
              //     prefix={"Rp. "}
              //   />
              // ),
              // serial_number: "serial_number",
              // seller_name: value?.seller_name,
              // biller_ref_id: value?.biller_ref_id,
              // buyer_sku_code: value?.buyer_sku_code,
              // payment_status: value?.payment_status,
              status:
                value?.status === "approved" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),

              // action: (
              //   <div className="flex gap-2">
              //     {(isGetAccessPrabayarTransaction === 1 ||
              //       isViewAllAccess === true) && (
              //       <Button
              //         size="md"
              //         colorScheme="new"
              //         className="bg-[#504178]"
              //         style={{ borderRadius: "10px", height: "23px" }}
              //         onClick={() =>
              //           handleDialogTransaction(true, value?.transaction_id)
              //         }
              //       >
              //         <div className=" text-white leading-[15px] font-[600] text-[12px]">
              //           {t("LABEL.VIEW")}
              //         </div>
              //       </Button>
              //     )}
              //   </div>
              // ),
            });
          });
          setNewConfirmationTransaction(newConfirmationTransactionListsData);
        },
      }
    );
  };

  useEffect(() => {    
    localStorage.removeItem("search-payment");
    newConfirmationTransactionListsData = [];
    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    confirmationTransactionList?.data?.map((value) => {
      newConfirmationTransactionListsData.push({
        transaction_id: value?.transaction_id,
        // created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
        transaction_type: value?.transaction_type,
        payment_channel_bank_logo_url: (
          <img src={value?.payment_channel_bank_logo_url} alt="" width={50} />
        ),
        payment_channel_bank_code: value?.payment_channel_bank_code,
        payment_channel_bank_name: value?.payment_channel_bank_name,
        payment_channel_account_number: value?.payment_channel_account_number,
        payment_channel_account_name: value?.payment_channel_account_name,
        message: value?.message,
        amount: (
          <CurrencyFormat
            value={value?.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        unique_number_account: value?.unique_number_account,
        total_amount: (
          <CurrencyFormat
            value={value?.total_amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),

        // sell_price: (
        //   <CurrencyFormat
        //     value={value?.sell_price}
        //     displayType={"text"}
        //     thousandSeparator={true}
        //     prefix={"Rp. "}
        //   />
        // ),
        // price: (
        //   <CurrencyFormat
        //     value={value?.price}
        //     displayType={"text"}
        //     thousandSeparator={true}
        //     prefix={"Rp. "}
        //   />
        // ),
        // serial_number: "serial_number",
        // seller_name: value?.seller_name,
        // biller_ref_id: value?.biller_ref_id,
        // buyer_sku_code: value?.buyer_sku_code,
        // payment_status: value?.payment_status,
        status:
          value?.status === "approved" ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : value?.status === "rejected" ? (
            <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ) : (
            <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {value?.status}
            </div>
          ),

        // action: (
        //   <div className="flex gap-2">
        //     {(isGetAccessPrabayarTransaction === 1 ||
        //       isViewAllAccess === true) && (
        //       <Button
        //         size="md"
        //         colorScheme="new"
        //         className="bg-[#504178]"
        //         style={{ borderRadius: "10px", height: "23px" }}
        //         onClick={() =>
        //           handleDialogTransaction(true, value?.transaction_id)
        //         }
        //       >
        //         <div className=" text-white leading-[15px] font-[600] text-[12px]">
        //           {t("LABEL.VIEW")}
        //         </div>
        //       </Button>
        //     )}
        //   </div>
        // ),
      });
    });
    setNewConfirmationTransaction(newConfirmationTransactionListsData);
  }, [
    confirmationTransactionList,
    token,
    isGetAccessPrabayarTransaction,
    isDeleteAccessPrabayarTransaction,
    isUpdateAccessPrabayarTransaction,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formTransactionDetail,

        confirmationTransactionList,
        isLoadingConfirmationTransactionList,
        refecthConfirmationTransactionData,
        newConfirmationTransactionListsData,
        newConfirmationTransaction,

        isLoadingSetConfirmationTransactionSearch,
        isSearch,
        setIsSearch,
        handleSearch,
        isLoadingConfirmationTransactionList,
        handleShowEntriesTable,
        handlePaginationTable,
        isModalFormOpen,
        // handleDialogTransaction,
        isGetAccessPrabayarTransaction,
        isDeleteAccessPrabayarTransaction,
        isUpdateAccessPrabayarTransaction,
        isViewAllAccess,
        isVisibleDownloadTransaction,
        isVisibleDownloadInfoTransaction,
        handleDialogDownloadTransaction,
        // handleDownloadTransaction,
        formPublish,
        // isLoadingGetTransactionListExport,
        // exportFile,
        handleFilterRange,
        handleDialogFilterRange,
        isVisibleFilterRange,
        isVisibleFilterRangeInfo,

        formKeywordSearch,
        newConfirmationTransactionListSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default ConfirmationTransactionController;
