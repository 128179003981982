import React, { lazy, Suspense } from "react";
// import ButtonNew from "./components/ButtonNew";
// import Filter from "./components/Filter";
// import ModalUserAgent from "./components/Modal";
// import TableBankAccount from "./components/TableBankAccount";
import BankAccountController from "./Controller";
// import DeleteConfirmationDialog from "./components/Dialog/DeleteConfirmationDialog";
// import DeleteInformationDialog from "./components/Dialog/DeleteInformationDialog";
// import SyncConfirmationDialog from "./components/Dialog/SyncConfirmationDialog";
// import SyncInformationDialog from "./components/Dialog/SyncInformationDialog";

const BankAccount = () => {
  const Filter = lazy(() => import("./components/Filter"));
  const ButtonNew = lazy(() => import("./components/ButtonNew"));
  const ModalUserAgent = lazy(() => import("./components/Modal"));
  const TableBankAccount = lazy(() => import("./components/TableBankAccount"));
  const DeleteConfirmationDialog = lazy(() =>
    import("./components/Dialog/DeleteConfirmationDialog")
  );
  const DeleteInformationDialog = lazy(() =>
    import("./components/Dialog/DeleteInformationDialog")
  );
  const SyncConfirmationDialog = lazy(() =>
    import("./components/Dialog/SyncConfirmationDialog")
  );
  const SyncInformationDialog = lazy(() =>
    import("./components/Dialog/SyncInformationDialog")
  );

  return (
    <div>
      <BankAccountController>
        <Suspense
          fallback={
            <div class="h-screen flex items-center">
              <div class="mx-auto">
                <div
                  class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex gap-4">
            <ButtonNew />
          </div>
          <div className="pt-4">
            <Filter />
          </div>
          <div className="pt-4">
            <div className="overflow-auto h-screen">
              <TableBankAccount />
            </div>
          </div>
          <ModalUserAgent />
          <DeleteConfirmationDialog />
          <DeleteInformationDialog />
          <SyncConfirmationDialog />
          <SyncInformationDialog />
          {/* <TopUpInformationDialog /> */}
        </Suspense>
      </BankAccountController>
    </div>
  );
};

export default BankAccount;
