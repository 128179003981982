import React, { lazy, Suspense } from "react";
// import ButtonNew from "./components/ButtonNew";
// import Filter from "./components/Filter";
// import ModalVoucher from "./components/Modal";
// import TableVoucher from "./components/TableVoucher";
import VoucherController from "./Controller";
import VoucherInfoDialog from "./components/Dialog/VoucherInfoDialog";
import FormVoucher from "./components/Modal/components/FormVoucher";
// import DeleteConfirmationDialog from "./components/Dialog/DeleteConfirmationDialog";
// import DeleteInformationDialog from "./components/Dialog/DeleteInformationDialog";

const Voucher = () => {
  const ButtonNew = lazy(() => import("./components/ButtonNew"));
  const Filter = lazy(() => import("./components/Filter"));
  const ModalVoucher = lazy(() => import("./components/Modal"));
  const TableVoucher = lazy(() => import("./components/TableVoucher"));

  return (
    <div>
      <VoucherController>
        <Suspense
          fallback={
            <div className="h-screen flex items-center">
              <div className="mx-auto">
                <div
                  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex gap-4">
            <ButtonNew />
          </div>
          <div className="pt-4">
            <Filter />
          </div>
          <div className="pt-4">
            <div className="overflow-auto h-screen">
              <TableVoucher />
            </div>
          </div>
          <ModalVoucher />          
          <VoucherInfoDialog />
          {/* <DeleteConfirmationDialog />
      <DeleteInformationDialog /> */}
          {/* <TopUpInformationDialog /> */}
        </Suspense>
      </VoucherController>
    </div>
  );
};

export default Voucher;
