import { InputNumber as InputNumberComponent } from "primereact/inputnumber";
import { useController } from "react-hook-form";

const InputNumber = ({ control, name, controlProps = {}, ...props }) => {
  const { field } = useController({
    name,
    control,
    ...controlProps,
  });

  const { onChange, ...fieldWithoutOnChange } = field;

  return (
    <InputNumberComponent
      {...fieldWithoutOnChange}
      {...props}
      onValueChange={field.onChange}
      className={`w-full text-sm`}
      mode="decimal"
      locale="de-DE"
      // minFractionDigits={2}
      style={props?.style}
      useGrouping={true}
    />
  );
};

export default InputNumber;
