import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Switch, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import {
  deleteUserApp,
  getUserApp,
  getUsersApp,
  setUserAppActive,
  getUserSearch,
  getPoints,
} from "./api/UserAppApi";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { getRole, getRoles } from "../UserAdminRole/api/RoleApi";
import { getProfile } from "./api/ProfileApi";
import { setOverwriteSaldo } from "./api/PointApi";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const UserHandheldContext = createContext();
const { Provider } = UserHandheldContext;

const UserHandheldController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: userAppData,
    isLoading: isLoadingUserAppData,
    refetch,
  } = useQuery(["usersapp", token, language], () =>
    getUsersApp({ token, language })
  );

  const {
    data: userAdminRoleData,
    isLoading: isLoadingUserAdminRoleData,
    refetch: refetchAdminRoleData,
  } = useQuery(["roles", token], () => getRoles({ token }));

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isFetching: isFetchingProfileData,
    isError: errorProfileData,
  } = useQuery(["profile", token, language], () =>
    getProfile({ token, language })
  );

  const { isLoading: isLoadingSetUserSearch, mutate: mutateSetUserSearch } =
    useMutation(getUserSearch);

  const { mutate: mutateSetUserAppActive } = useMutation(setUserAppActive);
  const { isLoading: isLoadingDeleteUserApp, mutate: mutateDeleteUserApp } =
    useMutation(deleteUserApp);
  const {
    isLoading: isLoadingOverwriteSaldo,
    mutate: mutateSetOverwriteSaldo,
  } = useMutation(setOverwriteSaldo);
  const { isLoading: isLoadingGetUsersApp, mutate: mutateGetUsersApp } =
    useMutation(getUsersApp);
  const { mutate: mutateGetUserApp } = useMutation(getUserApp);
  const {
    data: pointsData,
    isLoading: isLoadingGetListPoint,
    mutate: mutateGetListPoint,
  } = useMutation(getPoints);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalOverwriteFormOpen, setIsModalOverwriteFormOpen] =
    useState(false);
  const [userAppGuid, setUserAppGuid] = useState();
  const [isVisibleDeleteUserApp, setIsVisibleDeleteUserApp] = useState(false);
  const [isVisibleDeleteInfoUserApp, setIsVisibleDeleteInfoUserApp] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [newUserAppData, setNewUserAppData] = useState();
  const [userAppId, setUserAppId] = useState();
  const [listPoints, setListPoints] = useState();

  const [isSearch, setIsSearch] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isShowRoleId, setIsShowRoleId] = useState(false);

  const [selectedRoleSearch, setSelectedRoleSearch] = useState(null);
  const [newUserAppSearch, setNewUserAppSearch] = useState();

  let newUserData = [];

  const [isDeleteAccessUserHandheld, setIsDeleteAccessUserHandheld] =
    useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //User Handheld
          const deleteAccessUserHandheld = data?.data?.access
            ?.filter((data) => data?.page === "handheld")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessUserHandheld(deleteAccessUserHandheld?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Modal
  // -------------------------------
  const formModal = useForm();
  const resetformModal = (newForm) => {
    if (newForm) {
      formModal.reset({
        username: newForm?.username,
        first_name: newForm?.first_name,
        last_name: newForm?.last_name,
        phone: newForm?.phone,
        email: newForm?.email,
        region: newForm?.region,
        address: newForm?.address,
        balance: newForm?.point,
        total_qty_ppob: newForm?.total_qty_ppob,
        total_amount_ppob: newForm?.total_amount_ppob,
      });
    }
  };

  // -------------------------------
  // Form Search Filter
  // -------------------------------
  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  const handleDialogDownloadUser = (isOpen, guid) => {
    let limit = 10000;
    mutateGetUsersApp(
      { limit, token, language },
      {
        onSuccess: (data) => {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";

          const customHeadings = data?.data?.map((item, key) => ({
            "Registration Date": moment(item?.created_at)
              .utc()
              .add(7, "hours")
              .format("DD/MM/YY, HH:mm:ss"),
            Username: item?.username,
            // email: item?.email,
            // first_name: item?.first_name,
            // last_name: item?.last_name,
            "Last Seen":
              item?.last_login !== null
                ? moment(item?.last_login).format("DD/MM/YY, HH:mm:ss")
                : "Belum Login",
            Location:
              item?.last_login !== null &&
              `https://www.google.com/maps/search/?api=1&query=${item?.last_location_latitude},${item?.last_location_longitude}`,
            // Phone: item?.phone,
            // ispublish: (
            //   <Switch
            //     colorScheme={item?.is_active === true ? "green" : "red"}
            //     isChecked={item?.is_active === true ? true : false}
            //     onChange={() => handleUserAppActive(item?.id)}
            //   />
            // ),
            // banned_time: "",
            Point: new Intl.NumberFormat().format(item?.point),
            "Transaction Quantity": new Intl.NumberFormat().format(
              item?.total_qty_ppob
            ),
            "Total Transaction": parseInt(item?.total_amount_ppob),
            status:
              item?.is_active === true && item?.last_login !== null
                ? // <div className="bg-[#BAE6CC] flex items-center justify-center h-4 w-4/12 rounded-[10px]">
                  t("LABEL.ACTIVE")
                : // </div>
                item?.is_active === false
                ? // <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  t("LABEL.NOT_ACTIVE")
                : // </div>
                item?.is_active === true && item?.last_login === null
                ? // <div className="bg-black flex items-center justify-center h-4 w-4/12 rounded-[10px]">
                  t("LABEL.ACTIVE")
                : // </div>
                  // <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                  t("LABEL.BANNED"),
            // </div>
          }));

          const ws = XLSX.utils.json_to_sheet(customHeadings);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const datas = new Blob([excelBuffer], { type: fileType });

          FileSaver.saveAs(datas, "Data User App" + fileExtension);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries Table Point
  // -------------------------------

  const handleShowEntriesTablePoint = (limit) => {
    setIsSearch(false);
    mutateGetUserApp(
      { guid: userAppGuid, token, language },
      {
        onSuccess: (data) => {
          isEdit && resetformModal(data?.data);
        },
      }
    );

    mutateGetListPoint(
      { token, language, guid: userAppGuid, limit },
      {
        onSuccess: (data) => {
          let newListPoints = [];

          data?.data?.map((value) => {
            newListPoints.push({
              transaction_type: value?.transaction_type,
              amount: new Intl.NumberFormat().format(value?.amount),
              message: value?.message,
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
            });
          });
          setListPoints(newListPoints);
        },
      }
    );
  };

  // -------------------------------
  // Pagination Table Point
  // -------------------------------

  const handlePaginationTablePoint = (page, limit) => {
    setIsSearch(false);
    mutateGetUserApp(
      { guid: userAppGuid, token, language },
      {
        onSuccess: (data) => {
          isEdit && resetformModal(data?.data);
        },
      }
    );

    mutateGetListPoint(
      { token, language, guid: userAppGuid, page, limit },
      {
        onSuccess: (data) => {
          let newListPoints = [];

          data?.data?.map((value) => {
            newListPoints.push({
              transaction_type: value?.transaction_type,
              amount: new Intl.NumberFormat().format(value?.amount),
              message: value?.message,
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
            });
          });
          setListPoints(newListPoints);
        },
      }
    );
  };

  const handleModalFormOpen = (isOpen, isEdit, guid) => {
    setUserAppGuid(guid);
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsSearch(false);

    mutateGetUserApp(
      { guid, token, language },
      {
        onSuccess: (data) => {
          isEdit && resetformModal(data?.data);
        },
      }
    );

    mutateGetListPoint(
      { token, language, guid },
      {
        onSuccess: (data) => {
          let newListPoints = [];

          data?.data?.map((value) => {
            newListPoints.push({
              transaction_type: value?.transaction_type,
              amount: new Intl.NumberFormat().format(value?.amount),
              message: value?.message,
              status:
                value?.status === "approved" || value?.status === "success" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "payment reject" ||
                  value?.status === "rejected" ? (
                  <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : value?.status === "paid" || value?.status === "expired" ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ) : (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {value?.status}
                  </div>
                ),
            });
          });
          setListPoints(newListPoints);
        },
      }
    );
  };

  const handleUserAppActive = (guid) => {
    mutateSetUserAppActive(
      { guid, token, language },
      {
        onSuccess: (data) => {
          refetch();
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
        },
      }
    );
  };

  const handleDialogDeleteUserApp = (isOpen, guid) => {
    setIsVisibleDeleteUserApp(isOpen);
    setUserAppId(guid);
    setIsVisibleDeleteInfoUserApp(false);
  };

  const handleDeleteUserApp = (guid) => {
    mutateDeleteUserApp(
      { guid, token, language },
      {
        onSuccess: () => {
          refetch();
          setIsVisibleDeleteUserApp(false);
          setIsVisibleDeleteInfoUserApp(true);
        },
      }
    );
  };

  // -------------------------------
  // Form Modal Overwrote
  // -------------------------------
  const formModalOverwrite = useForm();
  const resetformModalOverwrite = (newForm) => {
    if (newForm) {
      formModalOverwrite.reset({
        transaction_type: newForm?.transaction_type,
        amount: newForm?.amount,
      });
    }
  };

  const handleModalOverwriteFormOpen = (isOpen) => {
    if (isOpen === false) {
      formModalOverwrite.reset({
        transaction_type: "",
        amount: null,
      });
    }
    setIsModalOverwriteFormOpen(isOpen);
  };

  const handleSubmitOverwriteSaldo = (payload) => {
    const newPayload = {
      user_app_guid: userAppGuid,
      transaction_type: payload?.transaction_type, // debet , credit
      amount: payload?.amount.toString(),
      description: payload?.description,
    };

    mutateSetOverwriteSaldo(
      { token, language, payload: newPayload },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "error",
              duration: 1500,
              isClosable: true,
            });
          } else {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "success",
              duration: 1500,
              isClosable: true,
            });
            setIsModalOverwriteFormOpen(false);
            mutateGetListPoint(
              { token, language, guid: userAppGuid },
              {
                onSuccess: (data) => {
                  let newListPoints = [];

                  data?.data?.map((value) => {
                    newListPoints.push({
                      transaction_type: value?.transaction_type,
                      amount: new Intl.NumberFormat().format(value?.amount),
                      message: value?.message,
                      status:
                        value?.status === "approved" ||
                        value?.status === "success" ? (
                          <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                            {value?.status}
                          </div>
                        ) : value?.status === "payment reject" ||
                          value?.status === "rejected" ? (
                          <div className="bg-[#FF0F0F] text-white leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                            {value?.status}
                          </div>
                        ) : value?.status === "paid" ||
                          value?.status === "expired" ? (
                          <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                            {value?.status}
                          </div>
                        ) : (
                          <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                            {value?.status}
                          </div>
                        ),
                    });
                  });
                  setListPoints(newListPoints);
                  formModalOverwrite.reset({
                    transaction_type: "",
                    amount: null,
                  });
                },
              }
            );
          }
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  const handleSearch = (keyword, page, limit) => {
    let isFirstName =
      keyword?.keyword_filter?.value === "1" ||
      (keyword?.keyword_filter === "1" && true);
    let isLastName =
      keyword?.keyword_filter?.value === "2" ||
      (keyword?.keyword_filter === "2" && true);
    let isUsername =
      keyword?.keyword_filter?.value === "7" ||
      (keyword?.keyword_filter === "7" && true);
    let isPhone =
      keyword?.keyword_filter?.value === "3" ||
      (keyword?.keyword_filter === "3" && true);
    let isEmail =
      keyword?.keyword_filter?.value === "4" ||
      (keyword?.keyword_filter === "4" && true);
    let isRole =
      keyword?.keyword_filter?.value === "5" ||
      (keyword?.keyword_filter === "5" && true);
    let isStatus =
      keyword?.keyword_filter?.value === "6" ||
      (keyword?.keyword_filter === "6" && true);
    let isSearchStatus = keyword?.keyword_status === "1" && true;
    let searchKeyword = !keyword?.value?.keyword_search
      ? ""
      : keyword?.value?.keyword_search;

    if (searchKeyword) {
      localStorage.setItem("search-user-app", searchKeyword);
    } else {
      localStorage.removeItem("search-user-app");
    }

    console.log(keyword);
    // console.log(searchKeyword)

    setIsShowStatus(isStatus);
    setIsShowRoleId(isRole);
    setIsSearch(true);

    let payload = {
      isFirstName: isFirstName,
      isLastName: isLastName,
      isUsername: isUsername,
      isPhone: isPhone,
      isEmail: isEmail,
      isRole: isRole,
      isStatus: isStatus,
      search: searchKeyword,
      searchStatus: isSearchStatus,
      searchRole: selectedRoleSearch?.id,
      language: language,
      token: token,
      page: typeof page === "number" ? page : 1,
      limit: typeof limit === "number" ? limit : 10,
    };
    mutateSetUserSearch(
      { payload },
      {
        onSuccess: (data) => {
          newUserData = [];

          data?.data?.map((value) => {
            setNewUserAppSearch(data);
            newUserData.push({
              registration_date: moment(value?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YY, HH:mm:ss"),
              username: value?.username,
              // email: value?.email,
              // first_name: value?.first_name,
              // last_name: value?.last_name,
              last_login:
                value?.last_login !== null
                  ? moment(value?.last_login).format("DD/MM/YY, HH:mm:ss")
                  : "Belum Login",
              location:
                value?.last_login !== null ? (
                  <a
                    colorScheme="new"
                    className="bg-[#504178] focus:text-white focus:no-underline text-white leading-[15px] font-[600] text-[12px] p-2 rounded-full hover:text-white hover:no-underline"
                    href={`https://www.google.com/maps/search/?api=1&query=${value?.last_location_latitude},${value?.last_location_longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("LABEL.OPEN") + " " + t("LABEL.LOCATION")}
                  </a>
                ) : (
                  <a
                    colorScheme="new"
                    className="bg-[#9e91c1] focus:text-black focus:no-underline text-white leading-[15px] font-[600] text-[12px] p-2 rounded-full hover:text-white hover:no-underline hover:cursor-not-allowed"
                    // href={`https://www.google.com/maps/search/?api=1&query=${value?.last_location_latitude},${value?.last_location_longitude}`}
                    // target="_blank"
                    rel="noopener noreferrer"
                    // aria-disabled
                  >
                    {t("LABEL.OPEN") + " " + t("LABEL.LOCATION")}
                  </a>
                ),
              phone: value?.phone,
              created_at: value?.created_at,
              created_by: value?.created_by,
              ispublish: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUserAppActive(value?.id)}
                />
              ),
              banned_time: "",
              point: new Intl.NumberFormat().format(value?.point),
              total_qty_ppob: new Intl.NumberFormat().format(
                value?.total_qty_ppob
              ),
              total_amount_ppob: (
                <CurrencyFormat
                  value={value?.total_amount_ppob}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              status:
                value?.is_active === true && value?.last_login !== null ? (
                  <div className="bg-[#BAE6CC] flex items-center justify-center h-4 w-4/12 rounded-[10px]">
                    {/* {t("LABEL.ACTIVE")} */}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {/* {t("LABEL.NOT_ACTIVE")} */}
                  </div>
                ) : value?.is_active === true && value?.last_login === null ? (
                  <div className="bg-black flex items-center justify-center h-4 w-4/12 rounded-[10px]">
                    {/* {t("LABEL.ACTIVE")} */}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {/* {t("LABEL.BANNED")} */}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF6627]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleModalFormOpen(true, true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Edit
              </div>
            </Button> */}
                  {/* {(isDeleteAccessUserHandheld === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleDialogDeleteUserApp(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                  {/* )} */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleModalFormOpen(true, true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewUserAppData(newUserData);
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    setIsSearch(false);
    mutateGetUsersApp(
      { limit, token },
      {
        onSuccess: (data) => {
          newUserData = [];

          data?.data?.map((value) => {
            newUserData.push({
              registration_date: moment(value?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YY, HH:mm:ss"),
              username: value?.username,
              // email: value?.email,
              // first_name: value?.first_name,
              // last_name: value?.last_name,
              last_login:
                value?.last_login !== null
                  ? moment(value?.last_login).format("DD/MM/YY, HH:mm:ss")
                  : "Belum Login",
              location:
                value?.last_login !== null ? (
                  <a
                    colorScheme="new"
                    className="bg-[#504178] focus:text-white focus:no-underline text-white leading-[15px] font-[600] text-[12px] p-2 rounded-full hover:text-white hover:no-underline"
                    href={`https://www.google.com/maps/search/?api=1&query=${value?.last_location_latitude},${value?.last_location_longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("LABEL.OPEN") + " " + t("LABEL.LOCATION")}
                  </a>
                ) : (
                  <a
                    colorScheme="new"
                    className="bg-[#9e91c1] focus:text-black focus:no-underline text-white leading-[15px] font-[600] text-[12px] p-2 rounded-full hover:text-white hover:no-underline hover:cursor-not-allowed"
                    // href={`https://www.google.com/maps/search/?api=1&query=${value?.last_location_latitude},${value?.last_location_longitude}`}
                    // target="_blank"
                    rel="noopener noreferrer"
                    // aria-disabled
                  >
                    {t("LABEL.OPEN") + " " + t("LABEL.LOCATION")}
                  </a>
                ),
              phone: value?.phone,
              created_at: value?.created_at,
              created_by: value?.created_by,
              ispublish: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUserAppActive(value?.id)}
                />
              ),
              banned_time: "",
              point: new Intl.NumberFormat().format(value?.point),
              total_qty_ppob: new Intl.NumberFormat().format(
                value?.total_qty_ppob
              ),
              total_amount_ppob: (
                <CurrencyFormat
                  value={value?.total_amount_ppob}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              status:
                value?.is_active === true && value?.last_login !== null ? (
                  <div className="bg-[#BAE6CC] flex items-center justify-center h-4 w-4/12 rounded-[10px]">
                    {/* {t("LABEL.ACTIVE")} */}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {/* {t("LABEL.NOT_ACTIVE")} */}
                  </div>
                ) : value?.is_active === true && value?.last_login === null ? (
                  <div className="bg-black flex items-center justify-center h-4 w-4/12 rounded-[10px]">
                    {/* {t("LABEL.ACTIVE")} */}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {/* {t("LABEL.BANNED")} */}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF6627]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleModalFormOpen(true, true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Edit
              </div>
            </Button> */}
                  {/* {(isDeleteAccessUserHandheld === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleDialogDeleteUserApp(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                  {/* )} */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleModalFormOpen(true, true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewUserAppData(newUserData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    setIsSearch(false);
    mutateGetUsersApp(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newUserData = [];

          data?.data?.map((value) => {
            newUserData.push({
              registration_date: moment(value?.created_at)
                .utc()
                .add(7, "hours")
                .format("DD/MM/YY, HH:mm:ss"),
              username: value?.username,
              // email: value?.email,
              // first_name: value?.first_name,
              // last_name: value?.last_name,
              last_login:
                value?.last_login !== null
                  ? moment(value?.last_login).format("DD/MM/YY, HH:mm:ss")
                  : "Belum Login",
              location:
                value?.last_login !== null ? (
                  <a
                    colorScheme="new"
                    className="bg-[#504178] focus:text-white focus:no-underline text-white leading-[15px] font-[600] text-[12px] p-2 rounded-full hover:text-white hover:no-underline"
                    href={`https://www.google.com/maps/search/?api=1&query=${value?.last_location_latitude},${value?.last_location_longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("LABEL.OPEN") + " " + t("LABEL.LOCATION")}
                  </a>
                ) : (
                  <a
                    colorScheme="new"
                    className="bg-[#9e91c1] focus:text-black focus:no-underline text-white leading-[15px] font-[600] text-[12px] p-2 rounded-full hover:text-white hover:no-underline hover:cursor-not-allowed"
                    // href={`https://www.google.com/maps/search/?api=1&query=${value?.last_location_latitude},${value?.last_location_longitude}`}
                    // target="_blank"
                    rel="noopener noreferrer"
                    // aria-disabled
                  >
                    {t("LABEL.OPEN") + " " + t("LABEL.LOCATION")}
                  </a>
                ),
              phone: value?.phone,
              created_at: value?.created_at,
              created_by: value?.created_by,
              ispublish: (
                <Switch
                  colorScheme={value?.is_active === true ? "green" : "red"}
                  isChecked={value?.is_active === true ? true : false}
                  onChange={() => handleUserAppActive(value?.id)}
                />
              ),
              banned_time: "",
              point: new Intl.NumberFormat().format(value?.point),
              total_qty_ppob: new Intl.NumberFormat().format(
                value?.total_qty_ppob
              ),
              total_amount_ppob: (
                <CurrencyFormat
                  value={value?.total_amount_ppob}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              status:
                value?.is_active === true && value?.last_login !== null ? (
                  <div className="bg-[#BAE6CC] flex items-center justify-center h-4 w-4/12 rounded-[10px]">
                    {/* {t("LABEL.ACTIVE")} */}
                  </div>
                ) : value?.is_active === false ? (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {/* {t("LABEL.NOT_ACTIVE")} */}
                  </div>
                ) : value?.is_active === true && value?.last_login === null ? (
                  <div className="bg-black flex items-center justify-center h-4 w-4/12 rounded-[10px]">
                    {/* {t("LABEL.ACTIVE")} */}
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {/* {t("LABEL.BANNED")} */}
                  </div>
                ),
              action: (
                <div className="flex gap-2">
                  {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF6627]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleModalFormOpen(true, true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Edit
              </div>
            </Button> */}
                  {/* {(isDeleteAccessUserHandheld === 1 || isViewAllAccess === true) && ( */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleDialogDeleteUserApp(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.DELETE")}
                    </div>
                  </Button>
                  {/* )} */}
                  <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#504178]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    onClick={() => handleModalFormOpen(true, true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      {t("LABEL.VIEW")}
                    </div>
                  </Button>
                </div>
              ),
            });
          });
          setNewUserAppData(newUserData);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem("search-user-app");
    newUserData = [];
    setIsSearch(false);

    userAppData?.data?.map((value) => {
      newUserData.push({
        registration_date: moment(value?.created_at)
          .utc()
          .add(7, "hours")
          .format("DD/MM/YY, HH:mm:ss"),
        username: value?.username,
        // email: value?.email,
        // first_name: value?.first_name,
        // last_name: value?.last_name,
        last_login:
          value?.last_login !== null
            ? moment(value?.last_login).format("DD/MM/YY, HH:mm:ss")
            : "Belum Login",
        location:
          value?.last_login !== null ? (
            <a
              colorScheme="new"
              className="bg-[#504178] focus:text-white focus:no-underline text-white leading-[15px] font-[600] text-[12px] p-2 rounded-full hover:text-white hover:no-underline"
              href={`https://www.google.com/maps/search/?api=1&query=${value?.last_location_latitude},${value?.last_location_longitude}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("LABEL.OPEN") + " " + t("LABEL.LOCATION")}
            </a>
          ) : (
            <a
              colorScheme="new"
              className="bg-[#9e91c1] focus:text-black focus:no-underline text-white leading-[15px] font-[600] text-[12px] p-2 rounded-full hover:text-white hover:no-underline hover:cursor-not-allowed"
              // href={`https://www.google.com/maps/search/?api=1&query=${value?.last_location_latitude},${value?.last_location_longitude}`}
              // target="_blank"
              rel="noopener noreferrer"
              // aria-disabled
            >
              {t("LABEL.OPEN") + " " + t("LABEL.LOCATION")}
            </a>
          ),
        phone: value?.phone,
        created_at: value?.created_at,
        created_by: value?.created_by,
        ispublish: (
          <Switch
            colorScheme={value?.is_active === true ? "green" : "red"}
            isChecked={value?.is_active === true ? true : false}
            onChange={() => handleUserAppActive(value?.id)}
          />
        ),
        banned_time: "",
        point: new Intl.NumberFormat().format(value?.point),
        total_qty_ppob: new Intl.NumberFormat().format(value?.total_qty_ppob),
        total_amount_ppob: (
          <CurrencyFormat
            value={value?.total_amount_ppob}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        status:
          value?.is_active === true && value?.last_login !== null ? (
            <div className="bg-[#BAE6CC] flex items-center justify-center h-4 w-4/12 rounded-[10px]">
              {/* {t("LABEL.ACTIVE")} */}
            </div>
          ) : value?.is_active === false ? (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {/* {t("LABEL.NOT_ACTIVE")} */}
            </div>
          ) : value?.is_active === true && value?.last_login === null ? (
            <div className="bg-black flex items-center justify-center h-4 w-4/12 rounded-[10px]">
              {/* {t("LABEL.ACTIVE")} */}
            </div>
          ) : (
            <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {/* {t("LABEL.BANNED")} */}
            </div>
          ),
        action: (
          <div className="flex gap-2">
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF6627]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleModalFormOpen(true, true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Edit
              </div>
            </Button> */}
            {/* {(isDeleteAccessUserHandheld === 1 || isViewAllAccess === true) && ( */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF0F0F]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleDialogDeleteUserApp(true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.DELETE")}
              </div>
            </Button>
            {/* )} */}
            <Button
              size="md"
              colorScheme="new"
              className="bg-[#504178]"
              style={{ borderRadius: "10px", height: "23px" }}
              onClick={() => handleModalFormOpen(true, true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                {t("LABEL.VIEW")}
              </div>
            </Button>
          </div>
        ),
      });
    });
    setNewUserAppData(newUserData);
  }, [userAppData, token, isDeleteAccessUserHandheld, isViewAllAccess]);

  return (
    <Provider
      value={{
        formModal,

        userAppData,
        newUserAppData,
        userAppId,

        isEdit,
        isModalFormOpen,
        isVisibleDeleteUserApp,
        isVisibleDeleteInfoUserApp,

        isLoadingUserAppData,
        isLoadingGetUsersApp,
        isLoadingDeleteUserApp,

        handleUserAppActive,
        handleDialogDeleteUserApp,
        handleDeleteUserApp,
        handleModalFormOpen,
        handlePaginationTable,
        handleShowEntriesTable,

        handleSearch,
        isDeleteAccessUserHandheld,
        isViewAllAccess,

        userAdminRoleData,
        isSearch,
        isShowStatus,
        isShowRoleId,
        selectedRoleSearch,
        setSelectedRoleSearch,
        listPoints,
        setListPoints,
        isLoadingGetListPoint,
        pointsData,
        profileData,
        isModalOverwriteFormOpen,
        setIsModalOverwriteFormOpen,
        handleModalOverwriteFormOpen,
        handleSubmitOverwriteSaldo,
        setUserAppGuid,
        userAppGuid,
        formModalOverwrite,
        resetformModalOverwrite,
        isLoadingOverwriteSaldo,
        handlePaginationTablePoint,
        handleShowEntriesTablePoint,

        setIsShowStatus,
        newUserAppSearch,
        formKeywordSearch,
        handleDialogDownloadUser,
      }}
    >
      {children}
    </Provider>
  );
};

export default UserHandheldController;
