import { api } from "../../../../../utils/axios";

//Update APK Version
export const updateApkVersion = ({ token, language, payload }) =>
  api
    .put(`/backoffice/apk/version`, payload, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get APK Version
export const getApkVersion = ({ token, language }) =>
  api
    .get(`/backoffice/apk/version`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
