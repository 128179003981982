import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TokenContext } from "../../../../controllers/public/TokenController";
import {
  getGrafikTransaction,
  getTotalAdvertising,
  getTotalPurchase,
} from "./api/DashboardApi";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import moment from "moment";
import { getSummaryPoint } from "./api/SummaryPointApi";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const HomeContext = createContext();
const { Provider } = HomeContext;

const DashboardController = ({ children }) => {
  const [data, setData] = useState();
  const [tokenhome, setTokenhome] = useState();
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  const [typeDisplay, setTypeDisplay] = useState("bulan");
  const [date, setDate] = useState();
  const [dateMonth, setDateMonth] = useState();
  const [alLDataExport, setAllDataExport] = useState();

  const [dateFromPointTopupData, setDateFromFilterPointTopupData] = useState();
  const [dateEndPointTopupData, setDateEndFilterPointTopupData] = useState();
  const [dateFromPointUsageData, setDateFromFilterPointUsageData] = useState();
  const [dateEndPointUsageData, setDateEndFilterPointUsageData] = useState();

  const [rangeQtyFromSuccessData, setRangeQtyFromSuccessData] = useState();
  const [rangeQtyEndSuccessData, setRangeQtyEndSuccessData] = useState();
  const [rangeSumFromSuccessData, setRangeSumFromSuccessData] = useState();
  const [rangeSumEndSuccessData, setRangeSumEndSuccessData] = useState();
  const [rangeQtyFromFailedData, setRangeQtyFromFailedData] = useState();
  const [rangeQtyEndFailedData, setRangeQtyEndFailedsData] = useState();

  const [newUserFromSuccessData, setNewUserFromSuccessData] = useState();
  const [newUserEndSuccessData, setNewUserEndSuccessData] = useState();

  let lang = t("LANGUAGE");

  // Populate Data Grafik by Month
  const form = useForm();
  const payload = {
    group_by: "month",
    set_periode: true,
    date_from: moment().startOf("year").format(),
    date_to: moment().endOf("year").format(),
  };

  const {
    data: GrafikTransactionData,
    isLoading: isLoadingGrafikTransactionData,
    refetch: refetchGrafikTransactionData,
  } = useQuery(["grafiktransaction", lang, token, payload], () =>
    getGrafikTransaction({ lang, token, payload })
  );

  // Get Summary Point Data
  const {
    data: summaryPointData,
    isLoading: isLoadingSummaryPoint,
    refetch: refetchSummaryPoint,
  } = useQuery(["summarypoint", token, lang], () =>
    getSummaryPoint({ token, lang })
  );

  const handleChangeDateMonth = (value) => {
    const payload = {
      group_by: "month",
      set_periode: true,
      date_from: moment(value).utc().format(),
      date_to: moment(value).utc().format(),
    };

    mutateGrafikTransactionDateData(
      { lang, token, payload },
      {
        onSuccess: (data) => {
          setDateMonth(data?.data);
        },
      }
    );
  };

  // Populate Data Grafik by Date
  const {
    isLoading: isLoadingGrafikTransactionDateData,
    mutate: mutateGrafikTransactionDateData,
  } = useMutation(getGrafikTransaction);

  const handleChangeDateRange = (value) => {
    const payload = {
      group_by: "date",
      set_periode: true,
      date_from: moment(value[0]).format(),
      date_to: moment(value[1]).format(),
    };

    mutateGrafikTransactionDateData(
      { lang, token, payload },
      {
        onSuccess: (data) => {
          setDate(data?.data);
        },
      }
    );
  };

  // Populate Total Advertising
  const payloadTotalAdvertising = {
    set_periode: true,
    date_from: moment().startOf("year").format(),
    date_to: moment().endOf("year").format(),
  };

  const {
    data: TotalAdvertisingData,
    isLoading: isLoadingTotalAdvertisingData,
    refetch: refetchTotalAdvertisingData,
  } = useQuery(["totaladvertising", lang, token, payloadTotalAdvertising], () =>
    getTotalAdvertising({ lang, token, payloadTotalAdvertising })
  );

  // Populate Total Purchase
  const payloadTotalPurchase = {
    set_periode: true,
    date_from: moment().startOf("year").format(),
    date_to: moment().endOf("year").format(),
  };

  const {
    data: TotalPurchaseData,
    isLoading: isLoadingTotalPurchaseData,
    refetch: refetchTotalPurchaseData,
  } = useQuery(["totalpurchase", lang, token, payloadTotalPurchase], () =>
    getTotalPurchase({ lang, token, payloadTotalPurchase })
  );

  // Other
  useEffect(() => {
    setData("Ini Data Home");
  }, []);

  const handleChangeTypeDisplay = (payload) => {
    setDate();
    setDateMonth();
    setTypeDisplay(payload);
  };

  const handleDownloadReport = () => {
    console.log(alLDataExport?.data);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const customHeadings = alLDataExport?.data
      ? {
          "Total Registered":
            alLDataExport?.data?.user_report?.total_registered,
          "Total Login": alLDataExport?.data?.user_report?.total_login,
          "Total not Login": alLDataExport?.data?.user_report?.total_not_login,
          "New User": alLDataExport?.data?.user_report?.new_user_period,

          "Total QTY Transaction Success":
            alLDataExport?.data?.transaction?.count_success,
          "Total Sum Transaction Success":
            alLDataExport?.data?.transaction?.sum_success,
          "Total QTY Failed": alLDataExport?.data?.transaction?.count_failed,
          "New QTY Transaction Success (Today)":
            alLDataExport?.data?.transaction?.count_success_today,
          "New SUM Transaction Success (Today)":
            alLDataExport?.data?.transaction?.sum_success_today,

          "Total Point": alLDataExport?.data?.point?.total_debit,
          "Total Credit Point": alLDataExport?.data?.point?.total_credit,
          "Total Point Debit": alLDataExport?.data?.point?.total_balance,
          "Monthly Point Topup":
            alLDataExport?.data?.point?.summary_point_topup,
          "Monthly Point Usage":
            alLDataExport?.data?.point?.summary_point_usage,

          "Total Active Ads": alLDataExport?.data?.advertising?.total_active,
          "New Ads Week": alLDataExport?.data?.advertising?.total_new,
          "Due Date Ads": alLDataExport?.data?.advertising?.total_due_date,
          "Total Seen": alLDataExport?.data?.advertising?.total_seen,
          "Total Likes": alLDataExport?.data?.advertising?.total_like,
        }
      : {
          "Total Registered":
            alLDataExport?.data?.user_report?.total_registered,
          "Total Login": alLDataExport?.data?.user_report?.total_login,
          "Total not Login": alLDataExport?.data?.user_report?.total_not_login,
          "New User": alLDataExport?.data?.user_report?.new_user_period,

          "Total QTY Transaction Success":
            alLDataExport?.data?.transaction?.count_success,
          "Total Sum Transaction Success":
            alLDataExport?.data?.transaction?.sum_success,
          "Total QTY Failed": alLDataExport?.data?.transaction?.count_failed,
          "New QTY Transaction Success (Today)":
            alLDataExport?.data?.transaction?.count_success_today,
          "New SUM Transaction Success (Today)":
            alLDataExport?.data?.transaction?.sum_success_today,

          "Total Point": alLDataExport?.data?.point?.total_debit,
          "Total Credit Point": alLDataExport?.data?.point?.total_credit,
          "Total Point Debit": alLDataExport?.data?.point?.total_balance,
          "Monthly Point Topup":
            alLDataExport?.data?.point?.summary_point_topup,
          "Monthly Point Usage":
            alLDataExport?.data?.point?.summary_point_usage,

          "Total Active Ads": alLDataExport?.data?.advertising?.total_active,
          "New Ads Week": alLDataExport?.data?.advertising?.total_new,
          "Due Date Ads": alLDataExport?.data?.advertising?.total_due_date,
          "Total Seen": alLDataExport?.data?.advertising?.total_seen,
          "Total Likes": alLDataExport?.data?.advertising?.total_like,
        };

    // Convert customHeadings to an array of objects with keys as headers
    const customHeadingsArray = [customHeadings];

    const ws = XLSX.utils.json_to_sheet(customHeadingsArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const datas = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(datas, "Dashboard Report" + fileExtension);
  };

  return (
    <Provider
      value={{
        form,
        data,
        tokenhome,
        GrafikTransactionData,
        isLoadingGrafikTransactionData,

        handleChangeTypeDisplay,
        typeDisplay,

        handleChangeDateRange,
        date,
        handleChangeDateMonth,
        dateMonth,

        TotalAdvertisingData,
        isLoadingTotalAdvertisingData,

        TotalPurchaseData,
        isLoadingTotalPurchaseData,
        summaryPointData,
        isLoadingSummaryPoint,
        refetchSummaryPoint,
        alLDataExport,
        setAllDataExport,
        handleDownloadReport,
        dateFromPointTopupData,
        dateEndPointTopupData,
        dateFromPointUsageData,
        dateEndPointUsageData,
        setDateFromFilterPointTopupData,
        setDateEndFilterPointTopupData,
        setDateFromFilterPointUsageData,
        setDateEndFilterPointUsageData,

        rangeQtyFromSuccessData,
        rangeQtyEndSuccessData,
        rangeSumFromSuccessData,
        rangeSumEndSuccessData,
        rangeQtyFromFailedData,
        rangeQtyEndFailedData,
        setRangeQtyFromSuccessData,
        setRangeQtyEndSuccessData,
        setRangeSumFromSuccessData,
        setRangeSumEndSuccessData,
        setRangeQtyFromFailedData,
        setRangeQtyEndFailedsData,

        newUserFromSuccessData,
        newUserEndSuccessData,
        setNewUserFromSuccessData,
        setNewUserEndSuccessData,
      }}
    >
      {children}
    </Provider>
  );
};

export default DashboardController;
