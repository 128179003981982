import {
  osName,
  osVersion,
  fullBrowserVersion,
  browserName,
  deviceType,
  engineName,
  engineVersion,
  deviceDetect,
  getUA,
} from "react-device-detect";

const deviceDetail =
  osName +
  "/" +
  osVersion +
  " " +
  deviceType +
  " " +
  browserName +
  "/" +
  fullBrowserVersion +
  " " +
  engineName +
  "/" +
  engineVersion;

export const deviceTypeDesc = {
  deviceType: deviceDetail,
};
