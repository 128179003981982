import { api } from "../../../../../utils/axios";

//Get Transaction SH List
export const getTransactionShList = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/alipay/list",
      {
        filter: {
          set_transaction_id: false,
          transaction_id: "",
          set_external_order_no: false,
          external_order_no: "",
          set_shop_name: false,
          shop_name: "",
          set_cashier: false,
          cashier: "",
          set_type: false,
          type: "",
          set_transaction_time: false,
          transaction_time_start: null,
          transaction_time_end: null,
          set_amount: false,
          amount_start: 0,
          amount_end: 0,
        },
        limit: limit || 10,
        page: page || 1,
        order: "DESC",
        sort: "transaction_id",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get Transaction SH Search
export const getTransactionShSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/alipay/list",
      {
        filter: {
          set_transaction_id: payload?.isTransactionId === true ? true : false,
          transaction_id: payload?.isTransactionId ? payload?.search : "",
          set_external_order_no:
            payload?.isExternalOrderNo === true ? true : false,
          external_order_no: payload?.isExternalOrderNo ? payload?.search : "",
          set_shop_name: payload?.isShopName === true ? true : false,
          shop_name: payload?.isShopName ? payload?.search : "",
          set_cashier: payload?.isCashier === true ? true : false,
          cashier: payload?.isCashier ? payload?.search : "",
          set_type: payload?.isTransactionType === true ? true : false,
          type: payload?.isTransactionType ? payload?.search : "",
          set_transaction_time:
            payload?.isTransactionTime === true ? true : false,
          transaction_time_start: null,
          transaction_time_end: null,
          set_amount: payload?.isAmount === true ? true : false,
          amount_start: 0,
          amount_end: 130,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "DESC",
        sort: "transaction_id",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get Transaction SH Time Search
export const getTransactionShTimeSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/alipay/list",
      {
        filter: {
          set_transaction_id: false,
          transaction_id: "",
          set_external_order_no: false,
          external_order_no: "",
          set_shop_name: false,
          shop_name: "",
          set_cashier: false,
          cashier: "",
          set_type: false,
          type: "",
          set_transaction_time: payload?.isTransactionTime,
          transaction_time_start: payload?.transaction_time_start,
          transaction_time_end: payload?.transaction_time_end,
          set_amount: false,
          amount_start: 0,
          amount_end: 0,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "DESC",
        sort: "transaction_id",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get Transaction SH Amount Search
export const getTransactionShAmountSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/alipay/list",
      {
        filter: {
          set_transaction_id: false,
          transaction_id: "",
          set_external_order_no: false,
          external_order_no: "",
          set_shop_name: false,
          shop_name: "",
          set_cashier: false,
          cashier: "",
          set_type: false,
          type: "",
          set_transaction_time: false,
          transaction_time_start: null,
          transaction_time_end: null,
          set_amount: payload?.isAmount,
          amount_start: payload?.amount_start,
          amount_end: payload?.amount_end,
        },
        limit: payload?.limit || 10,
        page: payload?.page || 1,
        order: "DESC",
        sort: "transaction_id",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get Transaction SH
export const getTransactionSh = ({ guid, token, language }) =>
  api
    .get(`/backoffice/alipay/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
