import { api } from "../../../../../utils/axios";

//Get Grafik Transaction
export const getGrafikTransaction = ({ lang, token, payload }) =>
  api
    .post("/backoffice/dashboard/transaction", payload, {
      headers: {
        language: lang,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Count Total Advertising
export const getTotalAdvertising = ({ lang, token, payloadTotalAdvertising }) =>
  api
    .post("/backoffice/dashboard/advertising", payloadTotalAdvertising, {
      headers: {
        language: lang,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Get Count Total Purchase Product
export const getTotalPurchase = ({ lang, token, payloadTotalPurchase }) =>
  api
    .post("/backoffice/dashboard/product", payloadTotalPurchase, {
      headers: {
        language: lang,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);
