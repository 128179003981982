export const privateSlug = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  CHANGEPASSWORD: "/change-password",
  ADS: "/ads",
  ADVERTISING: "/advertising",
  ALL: "/all",
  VIP: "/vip",
  UPDATED: "/updated",
  POPULAR: "/popular",
  PRODUCTS: "/products",
  CONFIRMATION: "/confirmation",
  APPROVAL: "/approval",
  CONFIRMATIONTRANSACTION: "/waiting",
  PRABAYAR: "/prabayar",
  PASCABAYAR: "/pascabayar",
  TRANSACTION: "/transaction",
  LANGUAGE: "/lang",
  BAHASA: "/id",
  ENGLISH: "/en",
  CHINESE: "/cn",
  USER: "/user",
  ADMIN: "/admin",
  HANDHELD: "/handheld",
  ROLE: "/role",
  AGENT: "/agent",
  CUSTOM: "/custom",
  CLASSIFIED: "/classified",
  BANNER: "/banner",
  SECTION: "/section",
  BANKACCOUNT: "/bank-account",
  YUANCONVERSION: "/yuan-conversion",
  CMS: "/cms",
  FAQ: "/faq",
  TERMSCONDITION: "/termscondition",
  PRIVACYPOLICY: "/privacy-policy",
  ABOUTUS: "/about-us",
  CUSTOMSDECLARATION: "/customs-declaration",  
  APPROVE: "/approve",  
  POINT: "/point",  
  CHAT: "/support-chat",  
  TRANSACTIONSH: "/transaction-sh",  
  CONFIG: "/config",  
  VOUCHER: "/voucher",  
  MASTER: "/master",  
  TOPIC: "/topic",  
  TOPICCATEGORY: "/topic-category",  
  QNA: "/qna",
  QNATICKET: "/qna-ticket",
  INFO: "/info"
};
