import React, { lazy, Suspense } from "react";
// import ButtonNew from "./components/ButtonNew";
// import Filter from "./components/Filter";
// import ModalPoint from "./components/Modal";
// import TablePoint from "./components/TablePoint";
import PointController from "./Controller";
// import FilterRangeDialog from "./components/Dialog/DownloadDialog";
// import DownloadDialog from "./components/Dialog/DownloadDialog";
// import DownloadInfoDialog from "./components/Dialog/DownloadInfoDialog";
// // import DeleteConfirmationDialog from "./components/Dialog/DeleteConfirmationDialog";
// // import DeleteInformationDialog from "./components/Dialog/DeleteInformationDialog";

const Point = () => {
  const Filter = lazy(() => import("./components/Filter"));
  const ButtonNew = lazy(() => import("./components/ButtonNew"));
  const ModalPoint = lazy(() => import("./components/Modal"));
  const TablePoint = lazy(() => import("./components/TablePoint"));
  const FilterRangeDialog = lazy(() =>
    import("./components/Dialog/FilterRangeDialog")
  );
  const DownloadDialog = lazy(() =>
    import("./components/Dialog/DownloadDialog")
  );
  const DownloadInfoDialog = lazy(() =>
    import("./components/Dialog/DownloadInfoDialog")
  );

  return (
    <div>
      <PointController>
        <Suspense
          fallback={
            <div class="h-screen flex items-center">
              <div class="mx-auto">
                <div
                  class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex gap-4">
            <ButtonNew />
          </div>
          <div className="pt-4">
            <Filter />
          </div>
          <div className="pt-4">
            <div className="overflow-auto h-screen">
              <TablePoint />
            </div>
          </div>
          <ModalPoint />
          {/* <DeleteConfirmationDialog />
      <DeleteInformationDialog /> */}
          {/* <TopUpInformationDialog /> */}
          <DownloadDialog />
          <DownloadInfoDialog />
          <FilterRangeDialog />
        </Suspense>
      </PointController>
    </div>
  );
};

export default Point;
