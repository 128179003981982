import { api } from "../../../../../../utils/axios";

//Get List Customs Declaration
export const getCustomsDeclarationList = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/custom-declaration",
      {
        filter: {
          set_status: true,
          status: "process",
        },
        limit: limit || 10,
        page: page || 1,
        is_all_data: false,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Get List Custom Declaration Search
export const getCustomsDeclarationListSearch = ({ payload }) =>
  api
    .post(
      "/backoffice/custom-declaration",
      {
        filter: {
          set_status:
            payload?.isWaiting === true ||
            payload?.isPaid === true ||
            payload?.isReject === true ||
            payload?.isApprove === true
              ? true
              : false,
          status:
            payload?.isWaiting === true
              ? "waiting for payment"
              : payload?.isPaid === true
              ? "paid"
              : payload?.isReject === true
              ? "payment reject"
              : payload?.isApprove === true
              ? "approved"
              : "",
        },
        limit: 10000,
        page: 1,
        is_all_data: false,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: payload?.language,
          "nihao-token": payload?.token,
        },
      }
    )
    .then((res) => res?.data);

//Get Customs Declaration By ID
export const getCustomsDeclarationById = ({ guid, token, language }) =>
  api
    .get(`/backoffice/custom-declaration/${guid}`, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Approve Customs Declaration
export const approveCustomsDeclaration = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/custom-declaration/approve/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Reject Customs Declaration
export const rejectCustomsDeclaration = ({ guid, token, language }) =>
  api
    .put(
      `/backoffice/custom-declaration/reject/${guid}`,
      {},
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
