import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
// import { setForgot, setLogin, setLogout } from "./api/AuthApi";
import { useToast } from "@chakra-ui/react";
// import { cookiesVars } from "../../utils/cookies";
import Cookies from "js-cookie";
// import { privateSlug } from "../../utils/privateSlug";
import { useNavigate } from "react-router-dom";
// import { publicSlug } from "../../utils/publicSlug";
import { TokenContext } from "../../../../controllers/public/TokenController";
import {
  getProfile,
  updateProfile,
} from "../../../../controllers/public/api/ProfileApi";
import { setUploadProfilePicture } from "../../../../controllers/public/api/MediaApi";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

export const ProfileContext = createContext();
const { Provider } = ProfileContext;

const ProfileController = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { token } = useContext(TokenContext);

  //   const { isLoading: isLoginLoading, mutate: mutateLogin } =
  //     useMutation(setLogin);
  //   const { mutate: mutateLogout } = useMutation(setLogout);
  //   const { isLoading: isLoadingForgot, mutate: mutateForgot } =
  //     useMutation(setForgot);

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isFetching: isFetchingProfileData,
    isError: errorProfileData,
    refetch,
  } = useQuery(["profile", token], () => getProfile({ token }));

  const {
    isLoading: isLoadingUploadProfilePicture,
    mutate: mutateSetUploadProfilePicture,
  } = useMutation(setUploadProfilePicture);

  const { isLoading: isLoadingUpdateProfile, mutate: mutateUpdateProfile } =
    useMutation(updateProfile);

  const [urlProfilePicture, setUrlProfilePicture] = useState();
  const [urlProfilePictureEdit, setUrlProfilePictureEdit] = useState();

  // -------------------------------
  // Form
  // -------------------------------
  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      // console.log(newForm)
      form.reset({
        name: newForm?.name,
        profile_picture_image_url: newForm?.profile_picture_image_url,
        phone: newForm?.phone,
      });
    }

    // if (newForm && isEdit) {
    //   form.reset({
    //     date: dateEdit,
    //     title: payloadBannerEdit?.title?.en,
    //     description: payloadBannerEdit?.description?.en,

    //     banner_thumbnail: payloadBannerEdit?.banner?.thumbnail,
    //     banner_image: payloadBannerEdit?.banner?.image,
    //   });
    // }
  };

  useEffect(() => {
    resetForm(profileData?.data);
  }, [profileData]);

  const handleUploadProfilePicture = (payload) => {
    let lang = t("LANGUAGE");
    const profilePicture = payload[0];

    // console.log(profilePicture?.length);

    if (profilePicture?.length === 1) {
      toast({
        description: "Image not uploaded",
        position: "top-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      // console.log(profilePicture)
      // return;
    } else {
      // console.log(profilePicture)
      let dataProfilePicture = new FormData();
      dataProfilePicture.append("file", profilePicture);
      dataProfilePicture.append("old_file_url", "");
      dataProfilePicture.append("token", token);
      dataProfilePicture.append("lang", lang);

      mutateSetUploadProfilePicture(dataProfilePicture, {
        onSuccess: (data) => {
          // if (isEdit) {
          //   setUrlProfilePictureEdit(data?.data);
          //   toast({
          //     description: data?.message?.response_message,
          //     position: "top-right",
          //     status: "success",
          //     duration: 1500,
          //     isClosable: true,
          //   });
          // } else {
          setUrlProfilePicture(data?.data);
          toast({
            description: data?.message?.response_message,
            position: "top-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
          // }
        },
      });
    }
  };

  const handleUpdateProfile = (payload) => {
    // console.log(urlProfilePicture);
    // console.log(payload);

    let lang = t("LANGUAGE");

    let newPayload = {
      name: payload?.name,
      profile_picture_image_url: urlProfilePicture,
      phone: payload?.phone,
    };

    mutateUpdateProfile(
      { newPayload, token, lang },
      {
        onSuccess: (data) => {
          if (
            data?.message?.response_code === "01" ||
            data?.message?.response_code === "50"
          ) {
            toast({
              title: 'Data Profile Masih Belum Lengkap',
              description: data?.message?.response_message,
              // description: "Data Profile Belum Lengkap",
              position: "top-right",
              status: "warning",
              duration: 1500,
              isClosable: true,
            });
            //   setIsVisibleBannerDialog(false);
            //   handlePublishBannerAdsOpen(true);
            //   handleModalFormOpen(false, true);
          } else {
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "success",
              duration: 1500,
              isClosable: true,
            });
            //   refetchAdvertisingsBannerData();
            //   setIsVisibleBannerDialog(true);
            //   handlePublishBannerAdsOpen(false);
            //   handleModalFormOpen(true, false, true);
          }
        },
      }
    );
  };

  return (
    <Provider
      value={{
        form,
        resetForm,
        urlProfilePicture,
        handleUploadProfilePicture,
        handleUpdateProfile,
        isLoadingUpdateProfile,
        isLoadingUploadProfilePicture,
      }}
    >
      {children}
    </Provider>
  );
};

export default ProfileController;
