import React from "react";
import Logo from "../../../../assets/img/logo.png";

const TabletNavbar = () => {
  return (
    <div className="flex justify-center items-center gap-2 p-2 border-b">
      <img src={Logo} alt="" />
      <span className="leading-[27px] text-[18px] flex items-center font-[700] text-[#081B56]">
        NIHAO ID
      </span>
    </div>
  );
};

export default TabletNavbar;
