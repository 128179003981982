import { api } from "../../../utils/axios";

import { v4 as uuidv4 } from "uuid";
import { deviceTypeDesc } from "../../../utils/deviceType";

export const getToken = ({ language }) =>
  api
    .post(
      "/token/auth",
      {
        app_name: process.env.REACT_APP_NAME,
        app_key: process.env.REACT_APP_SITE_KEY,
        device_id: uuidv4(),
        device_type: deviceTypeDesc.deviceType,
      },
      {
        headers: {
          language: language,
        },
      }
    )
    .then((res) => res?.data);

export const getRefreshToken = ({ data, language }) =>
  api
    .get("/token/refresh", {
      headers: {
        language: language,
        "refresh-token": data?.data?.refresh_token ? data?.data?.refresh_token : data ,
      },
    })
    .then((res) => res?.data);

    
export const getRefreshAuthToken = ({ data, language }) =>
  api
    .get("/token/refresh", {
      headers: {
        language: language,
        "refresh-token": data ,
      },
    })
    .then((res) => res?.data);