import { Button, Switch, useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import {
  getBrand,
  getBrandSearch,
  getCategoryPpob,
  getPricelist,
  getPricelistId,
  getPricelistSearch,
  getSyncPriceList,
  getTypes,
  getTypesSearch,
  updateSellPrice,
} from "./api/ppobApi";

export const PascabayarContext = createContext();
const { Provider } = PascabayarContext;

const PascabayarController = ({ children }) => {
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  const language = t("LANGUAGE");

  const toast = useToast();

  const [newPpobs, setNewPpobs] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isModalFormUpdateSellPriceOpen, setIsModalFormUpdateSellPriceOpen] =
    useState(false);
  const [priceId, setPriceId] = useState();
  const [isVisibleUpdateSellPriceInfo, setIsVisibleUpdateSellPriceInfo] =
    useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isVisibleSyncPrice, setIsVisibleSyncPrice] = useState(false);
  const [isVisibleSyncPriceInfo, setIsVisibleSyncPriceInfo] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [ppobBrandDataSelected, setPpobBrandDataSelected] = useState();
  const [ppobTypesDataSelected, setPpobTypesDataSelected] = useState();

  const [valueCategory, setValueCategory] = useState();
  const [valueBrand, setValueBrand] = useState();
  const [valueTypes, setValueTypes] = useState();

  let ppobBrandDataSelect = [];
  let ppobTypesDataSelect = [];

  const {
    data: ppobCategoryData,
    isLoading: isLoadingppobCategoryData,
    refetch: categoryData,
  } = useQuery(["categoryPpob", token, language], () =>
    getCategoryPpob({ token, language })
  );

  const {
    data: ppobBrandData,
    isLoading: isLoadingppobBrandData,
    refetch: brandData,
  } = useQuery(["brand", token, language], () => getBrand({ token, language }));

  const {
    data: ppobTypesData,
    isLoading: isLoadingppobTypesData,
    refetch: typesData,
  } = useQuery(["types", token, language], () => getTypes({ token, language }));

  const {
    data: PpobData,
    isLoading: isLoadingPpobData,
    refetch: refetchPpobData,
  } = useQuery(["ppob", token, language], () =>
    getPricelist({ token, language, valueCategory: "Pascabayar", valueBrand })
  );

  const {
    isLoading: isLoadingSetPpobDataSearch,
    mutate: mutateSetPpobDataSearch,
  } = useMutation(getPricelistSearch);

  const {
    isLoading: isLoadingGetPricelist,
    mutate: mutateGetPricelist,
    refetch: refetchAdvertisingPagination,
  } = useMutation(getPricelist);

  const { isLoading: isLoadingBrandSearch, mutate: mutateSetBrandSearch } =
    useMutation(getBrandSearch);

  const { isLoading: isLoadingTypesSearch, mutate: mutateSetTypesSearch } =
    useMutation(getTypesSearch);

  const { isLoading: isLoadingUpdateSellPrice, mutate: mutateUpdateSellPrice } =
    useMutation(updateSellPrice);

  const { mutate: mutateGetPricelistId } = useMutation(getPricelistId);

  const {
    isLoading: isLoadingGetSyncPriceList,
    mutate: mutateGetSyncPriceList,
  } = useMutation(getSyncPriceList);

  const [isGetAccessProductPascabayar, setIsGetAccessProductPascabayar] =
    useState();
  const [isDeleteAccessProductPascabayar, setIsDeleteAccessProductPascabayar] =
    useState();
  const [isUpdateAccessProductPascabayar, setIsUpdateAccessProductPascabayar] =
    useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const [isPublishProductNameOpen, setIsPublishProductNameOpen] =
    useState(false);
  const [isVisibleProductNameDialog, setIsVisibleProductNameDialog] =
    useState(false);
  const [isModalProductNameFormOpen, setIsModalProductNameFormOpen] =
    useState(false);
  const [payloadProductNameEdit, setPayloadProductNameEdit] = useState();
  const [indexTabActive, setIndexTabActive] = useState();

  const [newPpobSearch, setNewPpobSearch] = useState();

  const { mutate: mutateGetRole } = useMutation(getRole);

  let newPpobData = [];
  let currentPage = [];
  let currentLimit = [];

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //Product Pascabayar
          const getAccessProductPascabayar = data?.data?.access
            ?.filter((data) => data?.page === "prabayar")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessProductPascabayar(getAccessProductPascabayar?.length);

          const deleteAccessProductPascabayar = data?.data?.access
            ?.filter((data) => data?.page === "prabayar")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessProductPascabayar(
            deleteAccessProductPascabayar?.length
          );

          const updateAccessProductPascabayar = data?.data?.access
            ?.filter((data) => data?.page === "prabayar")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessProductPascabayar(
            updateAccessProductPascabayar?.length
          );
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Modal Update Product Name
  // -------------------------------

  const handlePublishProductNameOpen = (isOpen) => {
    setIsPublishProductNameOpen(isOpen);
    // resetFormPublish({});
  };

  const handleModalProductNameFormOpen = (isOpen, isEdit, guid) => {
    setIsModalProductNameFormOpen(isOpen);
    setIsEdit(isEdit);
    setPriceId(guid);
    setIndexTabActive(0);
    mutateGetPricelistId(
      { guid, token, language },
      {
        onSuccess: (data) => {
          // console.log(data);
          setPayloadProductNameEdit(data?.data);
        },
      }
    );
    // isCreate && resetformModal({});
  };

  // -------------------------------
  // Form Modal Update Sell Price
  // -------------------------------
  const formUpdateSellPrice = useForm();
  const resetformUpdateSellPrice = (newForm) => {
    if (newForm) {
      formUpdateSellPrice.reset({
        slug_name: newForm?.slug_name,
        sell_price: newForm?.sell_price,
      });
    }
  };

  const handleModalFormOpen = (isOpen, guid) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setPriceId(guid);
    mutateGetPricelistId(
      { guid, token, language },
      {
        onSuccess: (data) => {
          console.log(data);
          resetformUpdateSellPrice(data?.data);
        },
      }
    );
    // isCreate && resetformModal({});
  };

  const handleDialogUpdateSellPrice = (isOpen, guid) => {
    setIsModalFormOpen(isOpen);
    setPriceId(guid);
    setIsVisibleUpdateSellPriceInfo(false);
  };

  const handleUpdateSellPrice = (payload) => {
    const newPayload = {
      slug_name: payload?.slug_name,
      sell_price: 0,
      admin_internal_fee: payload?.sell_price,
      type_product: "postpaid",
    };
    // console.log(newPayload);
    mutateUpdateSellPrice(
      { newPayload, token, language },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              title: "Update sell price Masih Belum Lengkap",
              description: data?.message?.response_message,
              // description: "Update sell price belum lengkap",
              position: "top-right",
              status: "warning",
              duration: 2000,
              isClosable: true,
            });
          } else {
            refetchPpobData();
            setIsModalFormOpen(false);
            setIsVisibleUpdateSellPriceInfo(true);
          }
        },
      }
    );
  };

  const handleDialogSyncPrice = (isOpen) => {
    setIsVisibleSyncPrice(isOpen);
    // setUserId(guid);
    setIsVisibleSyncPriceInfo(false);
  };

  const handleSyncPriceList = () => {
    mutateGetSyncPriceList(
      { token, language },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              description: "Sync Price Gagal",
              position: "top-right",
              status: "warning",
              duration: 2000,
              isClosable: true,
            });
          } else {
            refetchPpobData();
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            setIsVisibleSyncPrice(false);
            // setUserId(guid);
            setIsVisibleSyncPriceInfo(true);
          }
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  // const handleSearch = (keyword) => {
  //   let isProduct = keyword?.keyword_search && true;
  //   let isCategoryPasca = keyword === "Pascabayar" && "Pascabayar";
  //   let isCategoryPra = keyword === "Pulsa" && "Pulsa";

  //   let isBrandBpjs = keyword === "BPJS KESEHATAN" && "BPJS KESEHATAN";
  //   let isBrandInternet =
  //     keyword === "INTERNET PASCABAYAR" && "INTERNET PASCABAYAR";
  //   let isBrandHP = keyword === "HP PASCABAYAR" && "HP PASCABAYAR";
  //   let isBrandTV = keyword === "TV PASCABAYAR" && "TV PASCABAYAR";
  //   let isBrandPDAM = keyword === "PDAM" && "PDAM";
  //   let isBrandTELKOMSEL = keyword === "TELKOMSEL" && "TELKOMSEL";
  //   let isBrandPLN = keyword === "PLN PASCABAYAR" && "PLN PASCABAYAR";

  //   setIsSearch(true);

  //   let payload = {
  //     isProduct: isProduct,
  //     isCategoryPasca: isCategoryPasca === false ? "" : isCategoryPasca,
  //     isCategoryPra: isCategoryPra === false ? "" : isCategoryPra,
  //     isBrandBpjs: isBrandBpjs === false ? "" : isBrandBpjs,
  //     isBrandInternet: isBrandInternet === false ? "" : isBrandInternet,
  //     isBrandHP: isBrandHP === false ? "" : isBrandHP,
  //     isBrandTV: isBrandTV === false ? "" : isBrandTV,
  //     isBrandPDAM: isBrandPDAM === false ? "" : isBrandPDAM,
  //     isBrandTELKOMSEL: isBrandTELKOMSEL === false ? "" : isBrandTELKOMSEL,
  //     isBrandPLN: isBrandPLN === false ? "" : isBrandPLN,
  //     search: keyword?.keyword_search,
  //     language: language,
  //     token: token,
  //   };

  //   keyword === "Pascabayar" || keyword === "Pulsa"
  //     ? mutateSetBrandSearch(
  //         { token, language, keyword },
  //         {
  //           onSuccess: (data) => {
  //             ppobBrandDataSelect.push(data?.data);
  //             setPpobBrandDataSelected(ppobBrandDataSelect);
  //           },
  //         }
  //       )
  //     : mutateSetTypesSearch(
  //         { token, language, keyword },
  //         {
  //           onSuccess: (data) => {
  //             // console.log(data?.data)
  //             ppobTypesDataSelect.push(data?.data);
  //             setPpobTypesDataSelected(ppobTypesDataSelect);
  //           },
  //         }
  //       );

  //   currentPage.push(1);
  //   currentLimit.push(100);
  //   mutateSetPpobDataSearch(
  //     { payload },
  //     {
  //       onSuccess: (data) => {
  //         data?.data?.map((value) => {
  //           newPpobData.push({
  //             buyer_sku_code: value?.buyer_sku_code,
  //             sell_price: (
  //               <CurrencyFormat
  //                 value={value?.sell_price}
  //                 displayType={"text"}
  //                 thousandSeparator={true}
  //                 prefix={"Rp. "}
  //               />
  //             ),
  //             product_name: value?.product_name,
  //             seller_name: value?.seller_name,
  //             admin: (
  //               <CurrencyFormat
  //                 value={value?.admin}
  //                 displayType={"text"}
  //                 thousandSeparator={true}
  //                 prefix={"Rp. "}
  //               />
  //             ),
  //             commision: (
  //               <CurrencyFormat
  //                 value={value?.commission}
  //                 displayType={"text"}
  //                 thousandSeparator={true}
  //                 prefix={"Rp. "}
  //               />
  //             ),
  //             stock: value?.stock,
  //             multi:
  //               value?.multi === true ? (
  //                 <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
  //                   {t("LABEL.YES")}
  //                 </div>
  //               ) : (
  //                 <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
  //                   {t("LABEL.NO")}
  //                 </div>
  //               ),
  //             status: (
  //               <Switch
  //                 colorScheme={value?.status === true ? "green" : "red"}
  //                 isChecked={value?.status === true ? true : false}
  //                 // onChange={() => handleUpdateToggleAdvertising(value?.id)}
  //               />
  //             ),
  //             // status:
  //             //   value?.is_active === true ? (
  //             //     <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
  //             //       Active
  //             //     </div>
  //             //   ) : (
  //             //     <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
  //             //       Inactive
  //             //     </div>
  //             //   ),
  //             action: (
  //               <div className="flex gap-2">
  //                 {/* {(isUpdateAccessProductPascabayar === 1 ||
  //                   isViewAllAccess === true) && ( */}
  //                   <>
  //                     <Button
  //                       size="md"
  //                       colorScheme="new"
  //                       className="bg-[#504178]"
  //                       style={{ borderRadius: "10px", height: "23px" }}
  //                       onClick={() => handleModalFormOpen(true, value?.id)}
  //                     >
  //                       <div className=" text-white leading-[15px] font-[600] text-[12px]">
  //                         {t("LABEL.UPDATE_SELL_PRICE")}
  //                       </div>
  //                     </Button>
  //                     <Button
  //                       size="md"
  //                       colorScheme="new"
  //                       className="bg-[#504178]"
  //                       style={{ borderRadius: "10px", height: "23px" }}
  //                       onClick={() =>
  //                         handleModalProductNameFormOpen(true, true, value?.id)
  //                       }
  //                     >
  //                       <div className=" text-white leading-[15px] font-[600] text-[12px]">
  //                         {t("LABEL.UPDATE_PRODUCT_NAME")}
  //                       </div>
  //                     </Button>
  //                   </>
  //                 {/* )} */}
  //                 {/* {(isDeleteAccessAdvertising === 1 || isViewAllAccess === true) && (  */}
  //                 {/* <Button
  //                   size="md"
  //                   colorScheme="new"
  //                   className="bg-[#FF0F0F]"
  //                   style={{ borderRadius: "10px", height: "23px" }}
  //                   // onClick={() => handleDialogDeleteAdvertising(true, value?.id)}
  //                 >
  //                   <div className=" text-white leading-[15px] font-[600] text-[12px]">
  //                     Delete
  //                   </div>
  //                 </Button> */}
  //                 {/* )} */}
  //               </div>
  //             ),
  //           });
  //         });
  //         setNewPpobs(newPpobData);
  //       },
  //     }
  //   );
  // };

  const handleSearch = (keyword, typeFilter, page, limit) => {
    // let isProduct = keyword?.keyword_search && true;
    // let isCategoryPasca = keyword === "Pascabayar" && "Pascabayar";
    // let isCategoryPra = keyword === "Pulsa" && "Pulsa";

    // let isBrandBpjs = keyword === "BPJS KESEHATAN" && "BPJS KESEHATAN";
    // let isBrandInternet =
    //   keyword === "INTERNET PASCABAYAR" && "INTERNET PASCABAYAR";
    // let isBrandHP = keyword === "HP PASCABAYAR" && "HP PASCABAYAR";
    // let isBrandTV = keyword === "TV PASCABAYAR" && "TV PASCABAYAR";
    // let isBrandPDAM = keyword === "PDAM" && "PDAM";
    // let isBrandTELKOMSEL = keyword === "TELKOMSEL" && "TELKOMSEL";
    // let isBrandPLN = keyword === "PLN PASCABAYAR" && "PLN PASCABAYAR";

    setIsSearch(true);

    // let payload = {
    //   isProduct: isProduct,
    //   isCategoryPasca: isCategoryPasca === false ? "" : isCategoryPasca,
    //   isCategoryPra: isCategoryPra === false ? "" : isCategoryPra,
    //   isBrandBpjs: isBrandBpjs === false ? "" : isBrandBpjs,
    //   isBrandInternet: isBrandInternet === false ? "" : isBrandInternet,
    //   isBrandHP: isBrandHP === false ? "" : isBrandHP,
    //   isBrandTV: isBrandTV === false ? "" : isBrandTV,
    //   isBrandPDAM: isBrandPDAM === false ? "" : isBrandPDAM,
    //   isBrandTELKOMSEL: isBrandTELKOMSEL === false ? "" : isBrandTELKOMSEL,
    //   isBrandPLN: isBrandPLN === false ? "" : isBrandPLN,
    //   search: keyword?.keyword_search,
    //   language: language,
    //   token: token,
    // };

    // console.log(keyword, typeFilter)
    if (typeFilter === "searchBar") {
      localStorage.setItem("searchBar-postpaid", keyword?.keyword_search);
    }

    if (typeFilter === "brand") {
      localStorage.setItem("brand-postpaid", keyword?.keyword_filter);
      mutateSetBrandSearch(
        { token, language, keyword : keyword?.keyword_filter },
        {
          onSuccess: (data) => {
            setPpobBrandDataSelected(data?.data);
          },
        }
      );

      const payload = {
        filter: {
          set_product_name: localStorage.getItem("searchBar-postpaid") ? true : false,
          product_name: localStorage.getItem("searchBar-postpaid") ? localStorage.getItem("searchBar-postpaid") : "",
          set_category: true,
          category: "Pascabayar",
          set_brand: true,
          brand: keyword?.keyword_filter,
          set_type: false,
          type: "",
          set_seller_name: false,
          seller_name: "",
          set_is_available: false,
          is_available: false,
        },
        page: typeof page === "number" ? page : 1,
        limit: typeof limit === "number" ? limit : 10,
        order: "created_at",
        sort: "DESC",
      }

      currentPage.push(1);
      currentLimit.push(100);
      mutateSetPpobDataSearch(
        { payload, language, token },
        {
          onSuccess: (data) => {
            data?.data?.map((value) => {
              setNewPpobSearch(data)
              newPpobData.push({
                buyer_sku_code: value?.buyer_sku_code,
                sell_price: (
                  <CurrencyFormat
                    value={value?.sell_price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                product_name: value?.product_name,
                seller_name: value?.seller_name,
                admin: (
                  <CurrencyFormat
                    value={value?.admin}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                commision: (
                  <CurrencyFormat
                    value={value?.commission}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                stock: value?.stock,
                multi:
                  value?.multi === true ? (
                    <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                      {t("LABEL.YES")}
                    </div>
                  ) : (
                    <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                      {t("LABEL.NO")}
                    </div>
                  ),
                status: (
                  <Switch
                    colorScheme={value?.status === true ? "green" : "red"}
                    isChecked={value?.status === true ? true : false}
                    // onChange={() => handleUpdateToggleAdvertising(value?.id)}
                  />
                ),
                action: (
                  <div className="flex gap-2">
                     <>
                        <Button
                          size="md"
                          colorScheme="new"
                          className="bg-[#504178]"
                          style={{ borderRadius: "10px", height: "23px" }}
                          onClick={() => handleModalFormOpen(true, value?.id)}
                        >
                          <div className=" text-white leading-[15px] font-[600] text-[12px]">
                            {t("LABEL.UPDATE_SELL_PRICE")}
                          </div>
                        </Button>
                        <Button
                          size="md"
                          colorScheme="new"
                          className="bg-[#504178]"
                          style={{ borderRadius: "10px", height: "23px" }}
                          onClick={() =>
                            handleModalProductNameFormOpen(true, true, value?.id)
                          }
                        >
                          <div className=" text-white leading-[15px] font-[600] text-[12px]">
                            {t("LABEL.UPDATE_PRODUCT_NAME")}
                          </div>
                        </Button>
                      </>
                  </div>
                ),
              });
            });
            setNewPpobs(newPpobData);
          },
        }
      );
      
    } else {

      const payload = {
        filter: {
          set_product_name: localStorage.getItem("searchBar-postpaid") ? true : false,
          product_name: localStorage.getItem("searchBar-postpaid") ? localStorage.getItem("searchBar-postpaid") : "",
          set_category: true,
          category: "Pascabayar",
          set_brand: localStorage.getItem("brand-postpaid") ? true : false,
          brand: localStorage.getItem("brand-postpaid") ? localStorage.getItem("brand-postpaid") : "",
          set_type: false,
          type: "",
          set_seller_name: false,
          seller_name: "",
          set_is_available: false,
          is_available: false,
        },
        page: typeof page === "number" ? page : 1,
        limit: typeof limit === "number" ? limit : 10,
        order: "created_at",
        sort: "DESC",
      }

      currentPage.push(1);
      currentLimit.push(100);
      mutateSetPpobDataSearch(
        { payload, language, token },
        {
          onSuccess: (data) => {
            data?.data?.map((value) => {
              setNewPpobSearch(data)
              newPpobData.push({
                buyer_sku_code: value?.buyer_sku_code,
                sell_price: (
                  <CurrencyFormat
                    value={value?.sell_price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                product_name: value?.product_name,
                seller_name: value?.seller_name,
                admin: (
                  <CurrencyFormat
                    value={value?.admin}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                commision: (
                  <CurrencyFormat
                    value={value?.commission}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                stock: value?.stock,
                multi:
                  value?.multi === true ? (
                    <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                      {t("LABEL.YES")}
                    </div>
                  ) : (
                    <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                      {t("LABEL.NO")}
                    </div>
                  ),
                status: (
                  <Switch
                    colorScheme={value?.status === true ? "green" : "red"}
                    isChecked={value?.status === true ? true : false}
                    // onChange={() => handleUpdateToggleAdvertising(value?.id)}
                  />
                ),
                action: (
                  <div className="flex gap-2">
                     <>
                        <Button
                          size="md"
                          colorScheme="new"
                          className="bg-[#504178]"
                          style={{ borderRadius: "10px", height: "23px" }}
                          onClick={() => handleModalFormOpen(true, value?.id)}
                        >
                          <div className=" text-white leading-[15px] font-[600] text-[12px]">
                            {t("LABEL.UPDATE_SELL_PRICE")}
                          </div>
                        </Button>
                        <Button
                          size="md"
                          colorScheme="new"
                          className="bg-[#504178]"
                          style={{ borderRadius: "10px", height: "23px" }}
                          onClick={() =>
                            handleModalProductNameFormOpen(true, true, value?.id)
                          }
                        >
                          <div className=" text-white leading-[15px] font-[600] text-[12px]">
                            {t("LABEL.UPDATE_PRODUCT_NAME")}
                          </div>
                        </Button>
                      </>
                  </div>
                ),
              });
            });
            setNewPpobs(newPpobData);
          },
        }
      );

    }

    // keyword === "Pascabayar" || keyword === "Pulsa"
    //   ? mutateSetBrandSearch(
    //       { token, language, keyword },
    //       {
    //         onSuccess: (data) => {
    //           ppobBrandDataSelect.push(data?.data);
    //           setPpobBrandDataSelected(ppobBrandDataSelect);
    //         },
    //       }
    //     )
    //   : mutateSetTypesSearch(
    //       { token, language, keyword },
    //       {
    //         onSuccess: (data) => {
    //           // console.log(data?.data)
    //           ppobTypesDataSelect.push(data?.data);
    //           setPpobTypesDataSelected(ppobTypesDataSelect);
    //         },
    //       }
    //     );

    // currentPage.push(1);
    // currentLimit.push(100);
    // mutateSetPpobDataSearch(
    //   { payload },
    //   {
    //     onSuccess: (data) => {
    //       data?.data?.map((value) => {
    //         newPpobData.push({
    //           buyer_sku_code: value?.buyer_sku_code,
    //           sell_price: (
    //             <CurrencyFormat
    //               value={value?.sell_price}
    //               displayType={"text"}
    //               thousandSeparator={true}
    //               prefix={"Rp. "}
    //             />
    //           ),
    //           product_name: value?.product_name,
    //           seller_name: value?.seller_name,
    //           admin: (
    //             <CurrencyFormat
    //               value={value?.admin}
    //               displayType={"text"}
    //               thousandSeparator={true}
    //               prefix={"Rp. "}
    //             />
    //           ),
    //           commision: (
    //             <CurrencyFormat
    //               value={value?.commission}
    //               displayType={"text"}
    //               thousandSeparator={true}
    //               prefix={"Rp. "}
    //             />
    //           ),
    //           stock: value?.stock,
    //           multi:
    //             value?.multi === true ? (
    //               <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
    //                 {t("LABEL.YES")}
    //               </div>
    //             ) : (
    //               <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
    //                 {t("LABEL.NO")}
    //               </div>
    //             ),
    //           status: (
    //             <Switch
    //               colorScheme={value?.status === true ? "green" : "red"}
    //               isChecked={value?.status === true ? true : false}
    //               // onChange={() => handleUpdateToggleAdvertising(value?.id)}
    //             />
    //           ),
    //           action: (
    //             <div className="flex gap-2">
    //                <>
    //                   <Button
    //                     size="md"
    //                     colorScheme="new"
    //                     className="bg-[#504178]"
    //                     style={{ borderRadius: "10px", height: "23px" }}
    //                     onClick={() => handleModalFormOpen(true, value?.id)}
    //                   >
    //                     <div className=" text-white leading-[15px] font-[600] text-[12px]">
    //                       {t("LABEL.UPDATE_SELL_PRICE")}
    //                     </div>
    //                   </Button>
    //                   <Button
    //                     size="md"
    //                     colorScheme="new"
    //                     className="bg-[#504178]"
    //                     style={{ borderRadius: "10px", height: "23px" }}
    //                     onClick={() =>
    //                       handleModalProductNameFormOpen(true, true, value?.id)
    //                     }
    //                   >
    //                     <div className=" text-white leading-[15px] font-[600] text-[12px]">
    //                       {t("LABEL.UPDATE_PRODUCT_NAME")}
    //                     </div>
    //                   </Button>
    //                 </>
    //             </div>
    //           ),
    //         });
    //       });
    //       setNewPpobs(newPpobData);
    //     },
    //   }
    // );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetPricelist(
      { limit, token },
      {
        onSuccess: (data) => {
          newPpobData = [];

          data?.data?.map((value) => {
            newPpobData.push({
              buyer_sku_code: value?.buyer_sku_code,
              sell_price: (
                <CurrencyFormat
                  value={value?.sell_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              product_name: value?.product_name,
              seller_name: value?.seller_name,
              admin: (
                <CurrencyFormat
                  value={value?.admin}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              commision: (
                <CurrencyFormat
                  value={value?.commission}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              stock: value?.stock,
              multi:
                value?.multi === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              status: (
                <Switch
                  colorScheme={value?.status === true ? "green" : "red"}
                  isChecked={value?.status === true ? true : false}
                  // onChange={() => handleUpdateToggleAdvertising(value?.id)}
                />
              ),
              // status:
              //   value?.is_active === true ? (
              //     <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              //       Active
              //     </div>
              //   ) : (
              //     <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              //       Inactive
              //     </div>
              //   ),
              action: (
                <div className="flex gap-2">
                  {/* {(isUpdateAccessProductPascabayar === 1 ||
                    isViewAllAccess === true) && ( */}
                    <>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleModalFormOpen(true, value?.id)}
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.UPDATE_SELL_PRICE")}
                        </div>
                      </Button>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleModalProductNameFormOpen(true, true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.UPDATE_PRODUCT_NAME")}
                        </div>
                      </Button>
                    </>
                  {/* )} */}
                  {/* {(isDeleteAccessAdvertising === 1 || isViewAllAccess === true) && (  */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    // onClick={() => handleDialogDeleteAdvertising(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Delete
                    </div>
                  </Button> */}
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewPpobs(newPpobData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetPricelist(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newPpobData = [];

          data?.data?.map((value) => {
            newPpobData.push({
              buyer_sku_code: value?.buyer_sku_code,
              sell_price: (
                <CurrencyFormat
                  value={value?.sell_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              product_name: value?.product_name,
              seller_name: value?.seller_name,
              admin: (
                <CurrencyFormat
                  value={value?.admin}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              commision: (
                <CurrencyFormat
                  value={value?.commission}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              stock: value?.stock,
              multi:
                value?.multi === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.YES")}
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.NO")}
                  </div>
                ),
              status: (
                <Switch
                  colorScheme={value?.status === true ? "green" : "red"}
                  isChecked={value?.status === true ? true : false}
                  // onChange={() => handleUpdateToggleAdvertising(value?.id)}
                />
              ),
              // status:
              //   value?.is_active === true ? (
              //     <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              //       Active
              //     </div>
              //   ) : (
              //     <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              //       Inactive
              //     </div>
              //   ),
              action: (
                <div className="flex gap-2">
                  {/* {(isUpdateAccessProductPascabayar === 1 ||
                    isViewAllAccess === true) && ( */}
                    <>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleModalFormOpen(true, value?.id)}
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.UPDATE_SELL_PRICE")}
                        </div>
                      </Button>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleModalProductNameFormOpen(true, true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.UPDATE_PRODUCT_NAME")}
                        </div>
                      </Button>
                    </>
                  {/* )} */}
                  {/* {(isDeleteAccessAdvertising === 1 || isViewAllAccess === true) && ( */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    // onClick={() => handleDialogDeleteAdvertising(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Delete
                    </div>
                  </Button> */}
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewPpobs(newPpobData);
        },
      }
    );
  };

  useEffect(() => {    
    localStorage.removeItem("category")
    localStorage.removeItem("brand")
    localStorage.removeItem("types")
    localStorage.removeItem("brand-postpaid")
    localStorage.removeItem("ssearchBar-postpaid")

    newPpobData = [];
    let lang = t("LANGUAGE");
    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    PpobData?.data?.map((value) => {
      newPpobData.push({
        buyer_sku_code: value?.buyer_sku_code,
        sell_price: (
          <CurrencyFormat
            value={value?.sell_price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        product_name: value?.product_name,
        seller_name: value?.seller_name,
        admin: (
          <CurrencyFormat
            value={value?.admin}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        commision: (
          <CurrencyFormat
            value={value?.commission}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        stock: value?.stock,
        multi:
          value?.multi === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.YES")}
            </div>
          ) : (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NO")}
            </div>
          ),
        status: (
          <Switch
            colorScheme={value?.status === true ? "green" : "red"}
            isChecked={value?.status === true ? true : false}
            // onChange={() => handleUpdateToggleAdvertising(value?.id)}
          />
        ),
        // status:
        //   value?.is_active === true ? (
        //     <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
        //       Active
        //     </div>
        //   ) : (
        //     <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
        //       Inactive
        //     </div>
        //   ),
        action: (
          <div className="flex gap-2">
            {/* {(isUpdateAccessProductPascabayar === 1 ||
              isViewAllAccess === true) && ( */}
              <>
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#504178]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() => handleModalFormOpen(true, value?.id)}
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.UPDATE_SELL_PRICE")}
                  </div>
                </Button>
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#504178]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() =>
                    handleModalProductNameFormOpen(true, true, value?.id)
                  }
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.UPDATE_PRODUCT_NAME")}
                  </div>
                </Button>
              </>
            {/* )} */}
            {/* {(isDeleteAccessAdvertising === 1 || isViewAllAccess === true) && ( */}
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF0F0F]"
              style={{ borderRadius: "10px", height: "23px" }}
              // onClick={() => handleDialogDeleteAdvertising(true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Delete
              </div>
            </Button> */}
            {/* )} */}
          </div>
        ),
      });
    });
    setNewPpobs(newPpobData);
  }, [
    PpobData,
    token,
    isGetAccessProductPascabayar,
    isDeleteAccessProductPascabayar,
    isUpdateAccessProductPascabayar,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        ppobCategoryData,
        isLoadingppobCategoryData,
        ppobBrandData,
        isLoadingppobBrandData,
        ppobTypesData,
        isLoadingppobTypesData,

        PpobData,
        newPpobs,
        isLoadingPpobData,
        isSearch,
        setIsSearch,
        handleSearch,

        isLoadingGetPricelist,
        handleShowEntriesTable,
        handlePaginationTable,

        handleDialogUpdateSellPrice,
        handleUpdateSellPrice,
        isLoadingUpdateSellPrice,

        formUpdateSellPrice,
        resetformUpdateSellPrice,
        isVisibleUpdateSellPriceInfo,
        // isModalFormUpdateSellPriceOpen,
        priceId,
        isModalFormOpen,
        isEdit,
        handleModalFormOpen,

        handleSyncPriceList,
        isLoadingGetSyncPriceList,
        handleDialogSyncPrice,
        isVisibleSyncPrice,
        isVisibleSyncPriceInfo,
        isCategorySelected,
        setIsCategorySelected,
        ppobBrandDataSelected,
        ppobTypesDataSelected,

        isModalProductNameFormOpen,
        payloadProductNameEdit,
        handleModalProductNameFormOpen,
        indexTabActive,
        setIndexTabActive,
        isPublishProductNameOpen,
        setIsPublishProductNameOpen,
        handlePublishProductNameOpen,
        isVisibleProductNameDialog,
        setIsVisibleProductNameDialog,
        refetchPpobData,
        setIndexTabActive,
        valueCategory,
        setValueCategory,
        valueBrand,
        setValueBrand,
        valueTypes,
        setValueTypes,

        newPpobSearch, 
        setNewPpobSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default PascabayarController;
