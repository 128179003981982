import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Switch, Tag, TagLabel, useToast } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import { getAboutUs } from "./api/AboutUsApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/public/AuthController";
// import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";

export const AboutUsContext = createContext();
const { Provider } = AboutUsContext;

const AboutUsController = ({ children }) => {
  const { t } = useTranslation();
  const { token } = useContext(TokenContext);

  let language = t("LANGUAGE");
  const toast = useToast();

  const {
    data: AboutUsData,
    isLoading: isLoadingAboutUsData,
    refetch: refetchAboutUsData,
  } = useQuery(["privacypolicy", token, language], () =>
    getAboutUs({ token, language })
  );

  const { mutate: mutateGetAboutUs } = useMutation(getAboutUs);

  // const {
  //   isLoading: isLoadingSetHomeSectionSearch,
  //   mutate: mutateSetAboutUsSearch,
  // } = useMutation(getAboutUsSearch);

  // const { isLoading: isLoadingDeleteAboutUs, mutate: mutateDeleteAboutUs } =
  //   useMutation(deleteAboutUs);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleAboutUsDialog, setIsVisibleAboutUsDialog] = useState(false);
  const [isVisibleDeleteAboutUs, setIsVisibleDeleteAboutUs] = useState(false);
  const [isVisibleDeleteInfoAboutUs, setIsVisibleDeleteInfoAboutUs] =
    useState(false);
  const [isPublishHomeSectionOpen, setIsPublishHomeSectionOpen] =
    useState(false);
  // const { isLoading: isLoadingUpdateOrderAboutUs, mutate: mutateUpdateOrderAboutUs } =
  //   useMutation(updateOrderAboutUs);
  const [newAboutUs, setnewAboutUs] = useState();

  const [faqId, setAboutUsId] = useState();

  const [payloadAboutUsEdit, setPayloadAboutUsEdit] = useState();
  const [isActiveSubmitOrder, setIsActiveSubmitOrder] = useState(false);
  const [isActiveDrag, setIsActiveDrag] = useState(false);

  const [indexTabActive, setIndexTabActive] = useState();

  const [orderSection, setOrderSection] = useState();

  const [newAdvertisings, setNewAdvertisings] = useState();
  const [isOpenInsertModalAds, setIsOpenInsertModalAds] = useState(false);
  const [selectedAdsId, setSelectedAdsId] = useState([]);
  const [isPublishAboutUsOpen, setIsPublishAboutUsOpen] = useState(false);

  let newAboutUsData = [];

  const [isGetAccessAboutUs, setIsGetAccessAboutUs] = useState();
  const [isDeleteAccessAboutUs, setIsDeleteAccessAboutUs] = useState();
  const [isUpdateAccessAboutUs, setIsUpdateAccessAboutUs] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //AboutUs
          const getAccessAboutUs = data?.data?.access
            ?.filter((data) => data?.page === "about-us")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "get");
          setIsGetAccessAboutUs(getAccessAboutUs?.length);

          const deleteAccessAboutUs = data?.data?.access
            ?.filter((data) => data?.page === "about-us")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessAboutUs(deleteAccessAboutUs?.length);

          const updateAccessAboutUs = data?.data?.access
            ?.filter((data) => data?.page === "about-us")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "update");
          setIsUpdateAccessAboutUs(updateAccessAboutUs?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form En
  // -------------------------------
  const formEn = useForm();
  const resetFormEn = (newForm) => {
    if (newForm && !isEdit) {
      formEn.reset({
        title: newForm?.title?.en,
        content: newForm?.content?.en,
      });
    }

    if (newForm && isEdit) {
      formEn.reset({
        title: payloadAboutUsEdit?.title?.en,
        content: payloadAboutUsEdit?.content?.en,
      });
    }
  };

  useEffect(() => {
    resetFormEn({});
  }, [isEdit, payloadAboutUsEdit]);

  // -------------------------------
  // Form Id
  // -------------------------------
  const formId = useForm();
  const resetFormId = (newForm) => {
    if (newForm && !isEdit) {
      formId.reset({
        title: newForm?.title?.id,
        content: newForm?.content?.id,
      });
    }

    if (newForm && isEdit) {
      formId.reset({
        title: payloadAboutUsEdit?.title?.id,
        content: payloadAboutUsEdit?.content?.id,
      });
    }
  };

  useEffect(() => {
    resetFormId({});
  }, [isEdit, payloadAboutUsEdit]);

  // -------------------------------
  // Form Cn
  // -------------------------------
  const formCn = useForm();
  const resetFormCn = (newForm) => {
    if (newForm && !isEdit) {
      formCn.reset({
        title: newForm?.title,
        content: newForm?.content,
      });
    }

    if (newForm && isEdit) {
      formCn.reset({
        title: payloadAboutUsEdit?.title?.cn,
        content: payloadAboutUsEdit?.content?.cn,
      });
    }
  };

  useEffect(() => {
    resetFormCn({});
  }, [isEdit, payloadAboutUsEdit]);

  // -------------------------------
  // Form Preview
  // -------------------------------
  const formPreviewEn = useForm();
  const resetFormPreviewEn = (newForm) => {
    if (newForm) {
      formPreviewEn.reset({
        title: newForm?.en?.title?.[0]?.en,
        content: newForm?.en?.content?.[0]?.en,
      });
    }
  };

  const formPreviewId = useForm();
  const resetFormPreviewId = (newForm) => {
    if (newForm) {
      formPreviewId.reset({
        title: newForm?.id?.title?.[0]?.id,
        content: newForm?.id?.content?.[0]?.id,
      });
    }
  };

  const formPreviewCn = useForm();
  const resetFormPreviewCn = (newForm) => {
    if (newForm) {
      formPreviewCn.reset({
        title: newForm?.cn?.title?.[0]?.cn,
        content: newForm?.cn?.content?.[0]?.cn,
      });
    }
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isView) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setIsCreate(isCreate);
    setIsView(isView);
    setIndexTabActive(0);
    isOpen === false && handlePublishHomeSectionOpen(false);
    // let lang = t("LANGUAGE");

    if (isOpen === true && isCreate === true) {
      resetFormEn({});
      resetFormId({});
      resetFormCn({});
      resetFormPreviewEn({});
      resetFormPreviewId({});
      resetFormPreviewCn({});
    }

    isEdit &&
      mutateGetAboutUs(
        { token, language },
        {
          onSuccess: (data) => {
            setPayloadAboutUsEdit(data?.data);
          },
        }
      );
  };

  const handlePublishHomeSectionOpen = (isOpen) => {
    setIsPublishHomeSectionOpen(isOpen);
    // resetFormPublish({});
  };

  const handlePublishAboutUsOpen = (isOpen) => {
    setIsPublishAboutUsOpen(isOpen);
    // resetFormPublish({});
  };

  useEffect(() => {
    if (AboutUsData) {
      newAboutUsData = [];

      // AboutUsData?.data?.map((value) => {
      newAboutUsData.push({
        content: AboutUsData?.data?.content,
      });
      // });
      setnewAboutUs(newAboutUsData);
    }
  }, [
    AboutUsData,
    token,
    // isGetAccessSection,
    // isDeleteAccessSection,
    // isUpdateAccessSection,
    // isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        formEn,
        formId,
        formCn,

        formPreviewEn,
        formPreviewId,
        formPreviewCn,
        resetFormPreviewEn,
        resetFormPreviewId,
        resetFormPreviewCn,

        faqId,
        isEdit,
        isCreate,
        isModalFormOpen,
        isVisibleAboutUsDialog,
        setIsVisibleAboutUsDialog,
        isVisibleDeleteAboutUs,
        setIsVisibleDeleteAboutUs,
        isVisibleDeleteInfoAboutUs,
        setIsVisibleDeleteInfoAboutUs,

        // isLoadingDeleteAboutUs,

        isLoadingAboutUsData,
        // isLoadingGetAdvertisings,

        AboutUsData,
        newAboutUs,
        newAboutUsData,
        // handleDialogDeleteAboutUs,
        handleModalFormOpen,
        // handleShowEntriesTable,
        // handlePaginationTable,
        refetchAboutUsData,
        // handleDeleteAboutUs,
        // handleSearch,
        // handleSubmitOrder,
        orderSection,
        setOrderSection,

        // handleActiveSubmitOrder,
        isActiveSubmitOrder,
        setIsActiveSubmitOrder,
        isActiveDrag,
        setIsActiveDrag,

        payloadAboutUsEdit,
        setPayloadAboutUsEdit,
        isView,
        setIsView,

        isOpenInsertModalAds,
        setIsOpenInsertModalAds,
        newAdvertisings,
        setNewAdvertisings,

        selectedAdsId,
        setSelectedAdsId,

        indexTabActive,
        setIndexTabActive,

        handlePublishHomeSectionOpen,
        isPublishHomeSectionOpen,
        setIsPublishHomeSectionOpen,

        isGetAccessAboutUs,
        isDeleteAccessAboutUs,
        isUpdateAccessAboutUs,
        isViewAllAccess,
        handlePublishAboutUsOpen,
        isPublishAboutUsOpen,
        setIsPublishAboutUsOpen,
      }}
    >
      {children}
    </Provider>
  );
};

export default AboutUsController;
