import { api } from "../../../utils/axios";

export const getUnread = ({ language, token }) =>
  api
    .get(
      "/backoffice/question-answer/ticket/unread-total",
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
