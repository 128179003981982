import { Button, Switch, useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../../../controllers/public/AuthController";
import { TokenContext } from "../../../../../controllers/public/TokenController";
import { getRole } from "../../User/UserAdminRole/api/RoleApi";
import {
  getBrand,
  getBrandSearch,
  getCategoryPpob,
  getPricelist,
  getPricelistId,
  getPricelistSearch,
  getPricelistTypeSearch,
  getSyncPriceList,
  getTypes,
  getTypesParentSearch,
  getTypesSearch,
  getTypesSearch2,
  updateSellPrice,
} from "./api/ppobApi";

export const PrabayarContext = createContext();
const { Provider } = PrabayarContext;

const PrabayarController = ({ children }) => {
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  const language = t("LANGUAGE");

  const toast = useToast();

  const [newPpobs, setNewPpobs] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isModalFormUpdateSellPriceOpen, setIsModalFormUpdateSellPriceOpen] =
    useState(false);
  const [priceId, setPriceId] = useState();
  const [isVisibleUpdateSellPriceInfo, setIsVisibleUpdateSellPriceInfo] =
    useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalProductNameFormOpen, setIsModalProductNameFormOpen] =
    useState(false);

  const [payloadProductNameEdit, setPayloadProductNameEdit] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isVisibleSyncPrice, setIsVisibleSyncPrice] = useState(false);
  const [isVisibleSyncPriceInfo, setIsVisibleSyncPriceInfo] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [ppobBrandDataSelected, setPpobBrandDataSelected] = useState();
  const [ppobTypesDataSelected, setPpobTypesDataSelected] = useState();
  const [indexTabActive, setIndexTabActive] = useState();
  const [isPublishProductNameOpen, setIsPublishProductNameOpen] =
    useState(false);
  const [isVisibleProductNameDialog, setIsVisibleProductNameDialog] =
    useState(false);

  const [valueCategory, setValueCategory] = useState();
  const [valueBrand, setValueBrand] = useState();
  const [valueTypes, setValueTypes] = useState();

  const [newPpobSearch, setNewPpobSearch] = useState();

  let ppobBrandDataSelect = [];
  let ppobTypesDataSelect = [];

  const {
    data: ppobCategoryData,
    isLoading: isLoadingppobCategoryData,
    refetch: categoryData,
  } = useQuery(["categoryPpob", token, language], () =>
    getCategoryPpob({ token, language })
  );

  const {
    data: ppobBrandData,
    isLoading: isLoadingppobBrandData,
    refetch: brandData,
  } = useQuery(["brand", token, language], () => getBrand({ token, language }));

  const {
    data: ppobTypesData,
    isLoading: isLoadingppobTypesData,
    refetch: typesData,
  } = useQuery(["types", token, language], () => getTypes({ token, language }));

  const {
    data: PpobData,
    isLoading: isLoadingPpobData,
    refetch: refetchPpobData,
  } = useQuery(["ppob", token, language], () =>
    getPricelist({ token, language, valueCategory, valueBrand, valueTypes })
  );

  const {
    isLoading: isLoadingSetPpobDataSearch,
    mutate: mutateSetPpobDataSearch,
  } = useMutation(getPricelistSearch);

  const {
    isLoading: isLoadingSetPpobDataTypeSearch,
    mutate: mutateSetPpobDataTypeSearch,
  } = useMutation(getPricelistTypeSearch);

  const {
    isLoading: isLoadingGetPricelist,
    mutate: mutateGetPricelist,
    refetch: refetchAdvertisingPagination,
  } = useMutation(getPricelist);

  const { isLoading: isLoadingBrandSearch, mutate: mutateSetBrandSearch } =
    useMutation(getBrandSearch);

  const { isLoading: isLoadingTypesSearch, mutate: mutateSetTypesSearch } =
    useMutation(getTypesSearch);

  const { isLoading: isLoadingTypesSearch2, mutate: mutateSetTypesSearch2 } =
    useMutation(getTypesSearch2);

  const {
    isLoading: isLoadingTypesParentSearch,
    mutate: mutateSetTypesParentSearch,
  } = useMutation(getTypesParentSearch);

  const { isLoading: isLoadingUpdateSellPrice, mutate: mutateUpdateSellPrice } =
    useMutation(updateSellPrice);

  const { mutate: mutateGetPricelistId } = useMutation(getPricelistId);

  const {
    isLoading: isLoadingGetSyncPriceList,
    mutate: mutateGetSyncPriceList,
  } = useMutation(getSyncPriceList);

  const [isGetAccessProductPrabayar, setIsGetAccessProductPrabayar] =
    useState();
  const [isDeleteAccessProductPrabayar, setIsDeleteAccessProductPrabayar] =
    useState();
  const [isUpdateAccessProductPrabayar, setIsUpdateAccessProductPrabayar] =
    useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const [valCategory, setValCategory] = useState();

  const { mutate: mutateGetRole } = useMutation(getRole);

  let newPpobData = [];
  let currentPage = [];
  let currentLimit = [];

  useEffect(() => {
    const guid = roleIdUser;

    guid &&
      language &&
      mutateGetRole(
        { guid, token, language },
        {
          onSuccess: (data) => {
            setListAccess(data?.data?.access);
            setIsViewAllAccess(data?.data?.is_all_access);

            //Product Prabayar
            const getAccessProductPrabayar = data?.data?.access
              ?.filter((data) => data?.page === "prabayar")
              .map((newData) => newData)[0]
              ?.access?.filter((newAccess) => newAccess === "get");
            setIsGetAccessProductPrabayar(getAccessProductPrabayar?.length);

            const deleteAccessProductPrabayar = data?.data?.access
              ?.filter((data) => data?.page === "prabayar")
              .map((newData) => newData)[0]
              ?.access?.filter((newAccess) => newAccess === "delete");
            setIsDeleteAccessProductPrabayar(
              deleteAccessProductPrabayar?.length
            );

            const updateAccessProductPrabayar = data?.data?.access
              ?.filter((data) => data?.page === "prabayar")
              .map((newData) => newData)[0]
              ?.access?.filter((newAccess) => newAccess === "update");
            setIsUpdateAccessProductPrabayar(
              updateAccessProductPrabayar?.length
            );
          },
        }
      );
  }, [roleIdUser, language]);

  // -------------------------------
  // Form Modal Update Product Name
  // -------------------------------

  const handlePublishProductNameOpen = (isOpen) => {
    setIsPublishProductNameOpen(isOpen);
    // resetFormPublish({});
  };

  const handleModalProductNameFormOpen = (isOpen, isEdit, guid) => {
    setIsModalProductNameFormOpen(isOpen);
    setIsEdit(isEdit);
    setPriceId(guid);
    setIndexTabActive(0);
    mutateGetPricelistId(
      { guid, token, language },
      {
        onSuccess: (data) => {
          // console.log(data);
          setPayloadProductNameEdit(data?.data);
        },
      }
    );
    // isCreate && resetformModal({});
  };

  // -------------------------------
  // Form Modal Update Sell Price
  // -------------------------------
  const formUpdateSellPrice = useForm();
  const resetformUpdateSellPrice = (newForm) => {
    if (newForm) {
      formUpdateSellPrice.reset({
        slug_name: newForm?.slug_name,
        sell_price: newForm?.sell_price,
      });
    }
  };

  const handleModalFormOpen = (isOpen, guid) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    setPriceId(guid);
    mutateGetPricelistId(
      { guid, token, language },
      {
        onSuccess: (data) => {
          console.log(data);
          resetformUpdateSellPrice(data?.data);
        },
      }
    );
    // isCreate && resetformModal({});
  };

  const handleDialogUpdateSellPrice = (isOpen, guid) => {
    setIsModalFormOpen(isOpen);
    setPriceId(guid);
    setIsVisibleUpdateSellPriceInfo(false);
  };

  const handleUpdateSellPrice = (payload) => {
    const newPayload = {
      slug_name: payload?.slug_name,
      sell_price: payload?.sell_price,
      admin_internal_fee: 0,
      type_product: "prepaid",
    };
    // console.log(newPayload);
    mutateUpdateSellPrice(
      { newPayload, token, language },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              title: "Update sell price Masih Belum Lengkap",
              description: data?.message?.response_message,
              // description: "Update sell price belum lengkap",
              position: "top-right",
              status: "warning",
              duration: 2000,
              isClosable: true,
            });
          } else {
            refetchPpobData();
            setIsModalFormOpen(false);
            setIsVisibleUpdateSellPriceInfo(true);
          }
        },
      }
    );
  };

  const handleDialogSyncPrice = (isOpen) => {
    setIsVisibleSyncPrice(isOpen);
    // setUserId(guid);
    setIsVisibleSyncPriceInfo(false);
  };

  const handleSyncPriceList = () => {
    mutateGetSyncPriceList(
      { token, language },
      {
        onSuccess: (data) => {
          if (data?.message?.response_code !== "00") {
            toast({
              description: "Sync Price Gagal",
              position: "top-right",
              status: "warning",
              duration: 2000,
              isClosable: true,
            });
          } else {
            refetchPpobData();
            toast({
              description: data?.message?.response_message,
              position: "top-right",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            setIsVisibleSyncPrice(false);
            // setUserId(guid);
            setIsVisibleSyncPriceInfo(true);
          }
        },
      }
    );
  };

  // -------------------------------
  // Filter Types
  // -------------------------------

  const handleTypeSearch = (keyword) => {
    let isProduct = keyword?.keyword_search && true;

    let isTypeGopayCustomer = keyword === "Customer" && "Customer";
    let isTypeOvoUmum = keyword === "Umum" && "Umum";
    let isTypeShopeePayUmum = keyword === "Umum" && "Umum";
    let isTypePlnUmum = keyword === "Umum" && "Umum";
    let isTypeAxisUmum = keyword === "Umum" && "Umum";
    let isTypeByuUmum = keyword === "Umum" && "Umum";

    let isTypeIndosatFreedomCombo =
      keyword === "Freedom Combo" && "Freedom Combo";
    let isTypeIndosatFreedomInternet =
      keyword === "Freedom Internet" && "Freedom Internet";
    let isTypeIndosatPulsaTransfer =
      keyword === "Pulsa Transfer" && "Pulsa Transfer";
    let isTypeIndosatUmum = keyword === "Umum" && "Umum";

    let isTypeSmartfrenIon = keyword === "1ON+" && "1ON+";
    let isTypeSmartfrenPulsaTransfer =
      keyword === "Pulsa Transfer" && "Pulsa Transfer";
    let isTypeSmartfrenUmum = keyword === "Umum" && "Umum";
    let isTypeSmartfrenUnlimited = keyword === "Unlimited" && "Unlimited";
    let isTypeSmartfrenUnlimitedNonStop =
      keyword === "Unlimited Nonstop" && "Unlimited Nonstop";

    let isTypeTelkomselFlash = keyword === "Flash" && "Flash";
    let isTypeTelkomselOmg = keyword === "OMG" && "OMG";
    let isTypeTelkomselOrbit = keyword === "Orbit" && "Orbit";
    let isTypeTelkomselPulsaTransfer =
      keyword === "Pulsa Transfer" && "Pulsa Transfer";
    let isTypeTelkomselUmum = keyword === "Umum" && "Umum";

    let isTypeTriAlwaysOn = keyword === "AlwaysOn" && "AlwaysOn";
    let isTypeTriGetMore = keyword === "GetMore" && "GetMore";
    let isTypeTriHappy = keyword === "Happy" && "Happy";
    let isTypeTriUmum = keyword === "Umum" && "Umum";

    let isTypeXLHotrod = keyword === "Hotrod" && "Hotrod";
    let isTypeXLXtraCombo = keyword === "Xtra Combo" && "Xtra Combo";
    let isTypeXLUmum = keyword === "Umum" && "Umum";

    let isTypeDataIndosatFreedomCombo =
      keyword === "Freedom Combo" && "Freedom Combo";
    let isTypeDataIndosatFreedomInternet =
      keyword === "Freedom Internet" && "Freedom Internet";
    let isTypeDataIndosatPulsaTransfer =
      keyword === "Pulsa Transfer" && "Pulsa Transfer";
    let isTypeDataIndosatUmum = keyword === "Umum" && "Umum";

    let isTypeDataSmartfrenIon = keyword === "1ON+" && "1ON+";
    let isTypeDataSmartfrenPulsaTransfer =
      keyword === "Pulsa Transfer" && "Pulsa Transfer";
    let isTypeDataSmartfrenUmum = keyword === "Umum" && "Umum";
    let isTypeDataSmartfrenUnlimited = keyword === "Unlimited" && "Unlimited";
    let isTypeDataSmartfrenUnlimitedNonStop =
      keyword === "Unlimited Nonstop" && "Unlimited Nonstop";

    let isTypeDataTelkomselFlash = keyword === "Flash" && "Flash";
    let isTypeDataTelkomselOmg = keyword === "OMG" && "OMG";
    let isTypeDataTelkomselOrbit = keyword === "Orbit" && "Orbit";
    let isTypeDataTelkomselPulsaTransfer =
      keyword === "Pulsa Transfer" && "Pulsa Transfer";
    let isTypeDataTelkomselUmum = keyword === "Umum" && "Umum";

    let isTypeDataTriAlwaysOn = keyword === "AlwaysOn" && "AlwaysOn";
    let isTypeDataTriGetMore = keyword === "GetMore" && "GetMore";
    let isTypeDataTriHappy = keyword === "Happy" && "Happy";
    let isTypeDataTriUmum = keyword === "Umum" && "Umum";

    let isTypeDataXLHotrod = keyword === "Hotrod" && "Hotrod";
    let isTypeDataXLXtraCombo = keyword === "Xtra Combo" && "Xtra Combo";
    let isTypeDataXLUmum = keyword === "Umum" && "Umum";

    setIsSearch(true);

    let payload = {
      isProduct: isProduct,
      isCategory: valueCategory,
      isBrand: valueBrand,

      isTypeGopayCustomer:
        isTypeGopayCustomer === false ? "" : isTypeGopayCustomer,
      isTypeOvoUmum: isTypeOvoUmum === false ? "" : isTypeOvoUmum,
      isTypeShopeePayUmum:
        isTypeShopeePayUmum === false ? "" : isTypeShopeePayUmum,
      isTypePlnUmum: isTypePlnUmum === false ? "" : isTypePlnUmum,
      isTypeAxisUmum: isTypeAxisUmum === false ? "" : isTypeAxisUmum,
      isTypeByuUmum: isTypeByuUmum === false ? "" : isTypeByuUmum,

      isTypeIndosatFreedomCombo:
        isTypeIndosatFreedomCombo === false ? "" : isTypeIndosatFreedomCombo,
      isTypeIndosatFreedomInternet:
        isTypeIndosatFreedomInternet === false
          ? ""
          : isTypeIndosatFreedomInternet,
      isTypeIndosatPulsaTransfer:
        isTypeIndosatPulsaTransfer === false ? "" : isTypeIndosatPulsaTransfer,
      isTypeIndosatUmum: isTypeIndosatUmum === false ? "" : isTypeIndosatUmum,

      isTypeSmartfrenIon:
        isTypeSmartfrenIon === false ? "" : isTypeSmartfrenIon,
      isTypeSmartfrenPulsaTransfer:
        isTypeSmartfrenPulsaTransfer === false
          ? ""
          : isTypeSmartfrenPulsaTransfer,
      isTypeSmartfrenUmum:
        isTypeSmartfrenUmum === false ? "" : isTypeSmartfrenUmum,
      isTypeSmartfrenUnlimited:
        isTypeSmartfrenUnlimited === false ? "" : isTypeSmartfrenUnlimited,
      isTypeSmartfrenUnlimitedNonStop:
        isTypeSmartfrenUnlimitedNonStop === false
          ? ""
          : isTypeSmartfrenUnlimitedNonStop,

      isTypeTelkomselFlash:
        isTypeTelkomselFlash === false ? "" : isTypeTelkomselFlash,
      isTypeTelkomselOmg:
        isTypeTelkomselOmg === false ? "" : isTypeTelkomselOmg,
      isTypeTelkomselOrbit:
        isTypeTelkomselOrbit === false ? "" : isTypeTelkomselOrbit,
      isTypeTelkomselPulsaTransfer:
        isTypeTelkomselPulsaTransfer === false
          ? ""
          : isTypeTelkomselPulsaTransfer,
      isTypeTelkomselUmum:
        isTypeTelkomselUmum === false ? "" : isTypeTelkomselUmum,

      isTypeTriAlwaysOn: isTypeTriAlwaysOn === false ? "" : isTypeTriAlwaysOn,
      isTypeTriGetMore: isTypeTriGetMore === false ? "" : isTypeTriGetMore,
      isTypeTriHappy: isTypeTriHappy === false ? "" : isTypeTriHappy,
      isTypeTriUmum: isTypeTriUmum === false ? "" : isTypeTriUmum,

      isTypeXLHotrod: isTypeXLHotrod === false ? "" : isTypeXLHotrod,
      isTypeXLXtraCombo: isTypeXLXtraCombo === false ? "" : isTypeXLXtraCombo,
      isTypeXLUmum: isTypeXLUmum === false ? "" : isTypeXLUmum,

      isTypeDataIndosatFreedomCombo:
        isTypeDataIndosatFreedomCombo === false
          ? ""
          : isTypeDataIndosatFreedomCombo,
      isTypeDataIndosatFreedomInternet:
        isTypeDataIndosatFreedomInternet === false
          ? ""
          : isTypeDataIndosatFreedomInternet,
      isTypeDataIndosatPulsaTransfer:
        isTypeDataIndosatPulsaTransfer === false
          ? ""
          : isTypeDataIndosatPulsaTransfer,
      isTypeDataIndosatUmum:
        isTypeDataIndosatUmum === false ? "" : isTypeDataIndosatUmum,

      isTypeDataSmartfrenIon:
        isTypeDataSmartfrenIon === false ? "" : isTypeDataSmartfrenIon,
      isTypeDataSmartfrenPulsaTransfer:
        isTypeDataSmartfrenPulsaTransfer === false
          ? ""
          : isTypeDataSmartfrenPulsaTransfer,
      isTypeDataSmartfrenUmum:
        isTypeDataSmartfrenUmum === false ? "" : isTypeDataSmartfrenUmum,
      isTypeDataSmartfrenUnlimited:
        isTypeDataSmartfrenUnlimited === false
          ? ""
          : isTypeDataSmartfrenUnlimited,
      isTypeDataSmartfrenUnlimitedNonStop:
        isTypeDataSmartfrenUnlimitedNonStop === false
          ? ""
          : isTypeDataSmartfrenUnlimitedNonStop,

      isTypeDataTelkomselFlash:
        isTypeDataTelkomselFlash === false ? "" : isTypeDataTelkomselFlash,
      isTypeDataTelkomselOmg:
        isTypeDataTelkomselOmg === false ? "" : isTypeDataTelkomselOmg,
      isTypeDataTelkomselOrbit:
        isTypeDataTelkomselOrbit === false ? "" : isTypeDataTelkomselOrbit,
      isTypeDataTelkomselPulsaTransfer:
        isTypeDataTelkomselPulsaTransfer === false
          ? ""
          : isTypeDataTelkomselPulsaTransfer,
      isTypeDataTelkomselUmum:
        isTypeDataTelkomselUmum === false ? "" : isTypeDataTelkomselUmum,

      isTypeDataTriAlwaysOn:
        isTypeDataTriAlwaysOn === false ? "" : isTypeDataTriAlwaysOn,
      isTypeDataTriGetMore:
        isTypeDataTriGetMore === false ? "" : isTypeDataTriGetMore,
      isTypeDataTriHappy:
        isTypeDataTriHappy === false ? "" : isTypeDataTriHappy,
      isTypeDataTriUmum: isTypeDataTriUmum === false ? "" : isTypeDataTriUmum,

      isTypeDataXLHotrod:
        isTypeDataXLHotrod === false ? "" : isTypeDataXLHotrod,
      isTypeDataXLXtraCombo:
        isTypeDataXLXtraCombo === false ? "" : isTypeDataXLXtraCombo,
      isTypeDataXLUmum: isTypeDataXLUmum === false ? "" : isTypeDataXLUmum,

      search: keyword?.keyword_search,
      language: language,
      token: token,
    };

    keyword === "E-Money" ||
    keyword === "PLN" ||
    keyword === "Pulsa" ||
    keyword === "Data"
      ? mutateSetBrandSearch(
          { token, language, keyword },
          {
            onSuccess: (data) => {
              ppobBrandDataSelect.push(data?.data);
              setPpobBrandDataSelected(ppobBrandDataSelect);

              // mutateSetTypesParentSearch(
              //   { token, language, valueBrand, valueCategory },
              //   {
              //     onSuccess: (data) => {
              //       ppobTypesDataSelect.push(data?.data);
              //       setPpobTypesDataSelected(ppobTypesDataSelect);
              //     },
              //   }
              // );
            },
          }
        )
      : mutateSetTypesParentSearch(
          { token, language, valueBrand, valueCategory },
          {
            onSuccess: (data) => {
              ppobTypesDataSelect.push(data?.data);
              setPpobTypesDataSelected(ppobTypesDataSelect);
            },
          }
        );

    currentPage.push(1);
    currentLimit.push(100);
    mutateSetPpobDataTypeSearch(
      { payload },
      {
        onSuccess: (data) => {
          data?.data?.map((value) => {
            newPpobData.push({
              buyer_sku_code: value?.buyer_sku_code,
              sell_price: (
                <CurrencyFormat
                  value={value?.sell_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              product_name: value?.product_name,
              seller_name: value?.seller_name,
              price: (
                <CurrencyFormat
                  value={value?.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              stock: value?.stock,
              multi:
                value?.multi === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    Ya
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    Tidak
                  </div>
                ),
              status: (
                <Switch
                  colorScheme={value?.status === true ? "green" : "red"}
                  isChecked={value?.status === true ? true : false}
                  // onChange={() => handleUpdateToggleAdvertising(value?.id)}
                />
              ),
              // status:
              //   value?.is_active === true ? (
              //     <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              //       Active
              //     </div>
              //   ) : (
              //     <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              //       Inactive
              //     </div>
              //   ),
              action: (
                <div className="flex gap-2">
                  {/* {(isUpdateAccessProductPrabayar === 1 ||
                    isViewAllAccess === true) && ( */}
                  <>
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleModalFormOpen(true, value?.id)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.UPDATE_SELL_PRICE")}
                      </div>
                    </Button>
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalProductNameFormOpen(true, true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.UPDATE_PRODUCT_NAME")}
                      </div>
                    </Button>
                  </>
                  {/* )} */}
                  {/* {(isDeleteAccessAdvertising === 1 || isViewAllAccess === true) && (  */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    // onClick={() => handleDialogDeleteAdvertising(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Delete
                    </div>
                  </Button> */}
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewPpobs(newPpobData);
        },
      }
    );
  };

  // -------------------------------
  // Filter
  // -------------------------------

  // const handleSearch = (keyword) => {
  //   setValCategory(keyword);
  //   let isProduct = keyword?.keyword_search && true;
  //   let isCategoryPra = keyword === "Pulsa" && "Pulsa";
  //   let isCategoryEMoney = keyword === "E-Money" && "E-Money";
  //   let isCategoryPln = keyword === "PLN" && "PLN";
  //   let isCategoryData = keyword === "Data" && "Data";

  //   let isBrandGopay = keyword === "GO PAY" && "GO PAY";
  //   let isBrandOvo = keyword === "OVO" && "OVO";
  //   let isBrandShopeePay = keyword === "SHOPEE PAY" && "SHOPEE PAY";
  //   let isBrandPLN = keyword === "PLN" && "PLN";

  //   let isBrandAXIS = keyword === "AXIS" && "AXIS";
  //   let isBrandBYU = keyword === "by.U" && "by.U";
  //   let isBrandINDOSAT = keyword === "INDOSAT" && "INDOSAT";
  //   let isBrandSMARTFREN = keyword === "SMARTFREN" && "SMARTFREN";
  //   let isBrandTELKOMSEL = keyword === "TELKOMSEL" && "TELKOMSEL";
  //   let isBrandTRI = keyword === "TRI" && "TRI";
  //   let isBrandXL = keyword === "XL" && "XL";

  //   let isBrandDataIndosat = keyword === "INDOSAT" && "INDOSAT";
  //   let isBrandDataSmartfren = keyword === "SMARTFREN" && "SMARTFREN";
  //   let isBrandDataTelkomsel = keyword === "TELKOMSEL" && "TELKOMSEL";
  //   let isBrandDataTri = keyword === "TRI" && "TRI";
  //   let isBrandDataXL = keyword === "XL" && "XL";

  //   let isTypeGopayCustomer = keyword === "Customer" && "Customer";
  //   let isTypeOvoUmum = keyword === "Umum" && "Umum";
  //   let isTypeShopeePayUmum = keyword === "Umum" && "Umum";
  //   let isTypePlnUmum = keyword === "Umum" && "Umum";
  //   let isTypeAxisUmum = keyword === "Umum" && "Umum";
  //   let isTypeByuUmum = keyword === "Umum" && "Umum";

  //   let isTypeIndosatFreedomCombo =
  //     keyword === "Freedom Combo" && "Freedom Combo";
  //   let isTypeIndosatFreedomInternet =
  //     keyword === "Freedom Internet" && "Freedom Internet";
  //   let isTypeIndosatPulsaTransfer =
  //     keyword === "Pulsa Transfer" && "Pulsa Transfer";
  //   let isTypeIndosatUmum = keyword === "Umum" && "Umum";

  //   let isTypeSmartfrenIon = keyword === "1ON+" && "1ON+";
  //   let isTypeSmartfrenPulsaTransfer =
  //     keyword === "Pulsa Transfer" && "Pulsa Transfer";
  //   let isTypeSmartfrenUmum = keyword === "Umum" && "Umum";
  //   let isTypeSmartfrenUnlimited = keyword === "Unlimited" && "Unlimited";
  //   let isTypeSmartfrenUnlimitedNonStop =
  //     keyword === "Unlimited Nonstop" && "Unlimited Nonstop";

  //   let isTypeTelkomselFlash = keyword === "Flash" && "Flash";
  //   let isTypeTelkomselOmg = keyword === "OMG" && "OMG";
  //   let isTypeTelkomselOrbit = keyword === "Orbit" && "Orbit";
  //   let isTypeTelkomselPulsaTransfer =
  //     keyword === "Pulsa Transfer" && "Pulsa Transfer";
  //   let isTypeTelkomselUmum = keyword === "Umum" && "Umum";

  //   let isTypeTriAlwaysOn = keyword === "AlwaysOn" && "AlwaysOn";
  //   let isTypeTriGetMore = keyword === "GetMore" && "GetMore";
  //   let isTypeTriHappy = keyword === "Happy" && "Happy";
  //   let isTypeTriUmum = keyword === "Umum" && "Umum";

  //   let isTypeXLHotrod = keyword === "Hotrod" && "Hotrod";
  //   let isTypeXLXtraCombo = keyword === "Xtra Combo" && "Xtra Combo";
  //   let isTypeXLUmum = keyword === "Umum" && "Umum";

  //   let isTypeDataIndosatFreedomCombo =
  //     keyword === "Freedom Combo" && "Freedom Combo";
  //   let isTypeDataIndosatFreedomInternet =
  //     keyword === "Freedom Internet" && "Freedom Internet";
  //   let isTypeDataIndosatPulsaTransfer =
  //     keyword === "Pulsa Transfer" && "Pulsa Transfer";
  //   let isTypeDataIndosatUmum = keyword === "Umum" && "Umum";

  //   let isTypeDataSmartfrenIon = keyword === "1ON+" && "1ON+";
  //   let isTypeDataSmartfrenPulsaTransfer =
  //     keyword === "Pulsa Transfer" && "Pulsa Transfer";
  //   let isTypeDataSmartfrenUmum = keyword === "Umum" && "Umum";
  //   let isTypeDataSmartfrenUnlimited = keyword === "Unlimited" && "Unlimited";
  //   let isTypeDataSmartfrenUnlimitedNonStop =
  //     keyword === "Unlimited Nonstop" && "Unlimited Nonstop";

  //   let isTypeDataTelkomselFlash = keyword === "Flash" && "Flash";
  //   let isTypeDataTelkomselOmg = keyword === "OMG" && "OMG";
  //   let isTypeDataTelkomselOrbit = keyword === "Orbit" && "Orbit";
  //   let isTypeDataTelkomselPulsaTransfer =
  //     keyword === "Pulsa Transfer" && "Pulsa Transfer";
  //   let isTypeDataTelkomselUmum = keyword === "Umum" && "Umum";

  //   let isTypeDataTriAlwaysOn = keyword === "AlwaysOn" && "AlwaysOn";
  //   let isTypeDataTriGetMore = keyword === "GetMore" && "GetMore";
  //   let isTypeDataTriHappy = keyword === "Happy" && "Happy";
  //   let isTypeDataTriUmum = keyword === "Umum" && "Umum";

  //   let isTypeDataXLHotrod = keyword === "Hotrod" && "Hotrod";
  //   let isTypeDataXLXtraCombo = keyword === "Xtra Combo" && "Xtra Combo";
  //   let isTypeDataXLUmum = keyword === "Umum" && "Umum";

  //   setIsSearch(true);

  //   let payload = {
  //     isProduct: isProduct,
  //     isCategoryPra: isCategoryPra === false ? "" : isCategoryPra,
  //     isCategoryEMoney: isCategoryEMoney === false ? "" : isCategoryEMoney,
  //     isCategoryPln: isCategoryPln === false ? "" : isCategoryPln,
  //     isCategoryData: isCategoryData === false ? "" : isCategoryData,

  //     isBrandGopay: isBrandGopay === false ? "" : isBrandGopay,
  //     isBrandOvo: isBrandOvo === false ? "" : isBrandOvo,
  //     isBrandShopeePay: isBrandShopeePay === false ? "" : isBrandShopeePay,
  //     isBrandPLN: isBrandPLN === false ? "" : isBrandPLN,

  //     isBrandAXIS: isBrandAXIS === false ? "" : isBrandAXIS,
  //     isBrandBYU: isBrandBYU === false ? "" : isBrandBYU,
  //     isBrandINDOSAT: isBrandINDOSAT === false ? "" : isBrandINDOSAT,
  //     isBrandSMARTFREN: isBrandSMARTFREN === false ? "" : isBrandSMARTFREN,
  //     isBrandTELKOMSEL: isBrandTELKOMSEL === false ? "" : isBrandTELKOMSEL,
  //     isBrandTRI: isBrandTRI === false ? "" : isBrandTRI,
  //     isBrandXL: isBrandXL === false ? "" : isBrandXL,

  //     isBrandDataIndosat:
  //       isBrandDataIndosat === false ? "" : isBrandDataIndosat,
  //     isBrandDataSmartfren:
  //       isBrandDataSmartfren === false ? "" : isBrandDataSmartfren,
  //     isBrandDataTelkomsel:
  //       isBrandDataTelkomsel === false ? "" : isBrandDataTelkomsel,
  //     isBrandDataTri: isBrandDataTri === false ? "" : isBrandDataTri,
  //     isBrandDataXL: isBrandDataXL === false ? "" : isBrandDataXL,

  //     isTypeGopayCustomer:
  //       isTypeGopayCustomer === false ? "" : isTypeGopayCustomer,
  //     isTypeOvoUmum: isTypeOvoUmum === false ? "" : isTypeOvoUmum,
  //     isTypeShopeePayUmum:
  //       isTypeShopeePayUmum === false ? "" : isTypeShopeePayUmum,
  //     isTypePlnUmum: isTypePlnUmum === false ? "" : isTypePlnUmum,
  //     isTypeAxisUmum: isTypeAxisUmum === false ? "" : isTypeAxisUmum,
  //     isTypeByuUmum: isTypeByuUmum === false ? "" : isTypeByuUmum,

  //     isTypeIndosatFreedomCombo:
  //       isTypeIndosatFreedomCombo === false ? "" : isTypeIndosatFreedomCombo,
  //     isTypeIndosatFreedomInternet:
  //       isTypeIndosatFreedomInternet === false
  //         ? ""
  //         : isTypeIndosatFreedomInternet,
  //     isTypeIndosatPulsaTransfer:
  //       isTypeIndosatPulsaTransfer === false ? "" : isTypeIndosatPulsaTransfer,
  //     isTypeIndosatUmum: isTypeIndosatUmum === false ? "" : isTypeIndosatUmum,

  //     isTypeSmartfrenIon:
  //       isTypeSmartfrenIon === false ? "" : isTypeSmartfrenIon,
  //     isTypeSmartfrenPulsaTransfer:
  //       isTypeSmartfrenPulsaTransfer === false
  //         ? ""
  //         : isTypeSmartfrenPulsaTransfer,
  //     isTypeSmartfrenUmum:
  //       isTypeSmartfrenUmum === false ? "" : isTypeSmartfrenUmum,
  //     isTypeSmartfrenUnlimited:
  //       isTypeSmartfrenUnlimited === false ? "" : isTypeSmartfrenUnlimited,
  //     isTypeSmartfrenUnlimitedNonStop:
  //       isTypeSmartfrenUnlimitedNonStop === false
  //         ? ""
  //         : isTypeSmartfrenUnlimitedNonStop,

  //     isTypeTelkomselFlash:
  //       isTypeTelkomselFlash === false ? "" : isTypeTelkomselFlash,
  //     isTypeTelkomselOmg:
  //       isTypeTelkomselOmg === false ? "" : isTypeTelkomselOmg,
  //     isTypeTelkomselOrbit:
  //       isTypeTelkomselOrbit === false ? "" : isTypeTelkomselOrbit,
  //     isTypeTelkomselPulsaTransfer:
  //       isTypeTelkomselPulsaTransfer === false
  //         ? ""
  //         : isTypeTelkomselPulsaTransfer,
  //     isTypeTelkomselUmum:
  //       isTypeTelkomselUmum === false ? "" : isTypeTelkomselUmum,

  //     isTypeTriAlwaysOn: isTypeTriAlwaysOn === false ? "" : isTypeTriAlwaysOn,
  //     isTypeTriGetMore: isTypeTriGetMore === false ? "" : isTypeTriGetMore,
  //     isTypeTriHappy: isTypeTriHappy === false ? "" : isTypeTriHappy,
  //     isTypeTriUmum: isTypeTriUmum === false ? "" : isTypeTriUmum,

  //     isTypeXLHotrod: isTypeXLHotrod === false ? "" : isTypeXLHotrod,
  //     isTypeXLXtraCombo: isTypeXLXtraCombo === false ? "" : isTypeXLXtraCombo,
  //     isTypeXLUmum: isTypeXLUmum === false ? "" : isTypeXLUmum,

  //     isTypeDataIndosatFreedomCombo:
  //       isTypeDataIndosatFreedomCombo === false
  //         ? ""
  //         : isTypeDataIndosatFreedomCombo,
  //     isTypeDataIndosatFreedomInternet:
  //       isTypeDataIndosatFreedomInternet === false
  //         ? ""
  //         : isTypeDataIndosatFreedomInternet,
  //     isTypeDataIndosatPulsaTransfer:
  //       isTypeDataIndosatPulsaTransfer === false
  //         ? ""
  //         : isTypeDataIndosatPulsaTransfer,
  //     isTypeDataIndosatUmum:
  //       isTypeDataIndosatUmum === false ? "" : isTypeDataIndosatUmum,

  //     isTypeDataSmartfrenIon:
  //       isTypeDataSmartfrenIon === false ? "" : isTypeDataSmartfrenIon,
  //     isTypeDataSmartfrenPulsaTransfer:
  //       isTypeDataSmartfrenPulsaTransfer === false
  //         ? ""
  //         : isTypeDataSmartfrenPulsaTransfer,
  //     isTypeDataSmartfrenUmum:
  //       isTypeDataSmartfrenUmum === false ? "" : isTypeDataSmartfrenUmum,
  //     isTypeDataSmartfrenUnlimited:
  //       isTypeDataSmartfrenUnlimited === false
  //         ? ""
  //         : isTypeDataSmartfrenUnlimited,
  //     isTypeDataSmartfrenUnlimitedNonStop:
  //       isTypeDataSmartfrenUnlimitedNonStop === false
  //         ? ""
  //         : isTypeDataSmartfrenUnlimitedNonStop,

  //     isTypeDataTelkomselFlash:
  //       isTypeDataTelkomselFlash === false ? "" : isTypeDataTelkomselFlash,
  //     isTypeDataTelkomselOmg:
  //       isTypeDataTelkomselOmg === false ? "" : isTypeDataTelkomselOmg,
  //     isTypeDataTelkomselOrbit:
  //       isTypeDataTelkomselOrbit === false ? "" : isTypeDataTelkomselOrbit,
  //     isTypeDataTelkomselPulsaTransfer:
  //       isTypeDataTelkomselPulsaTransfer === false
  //         ? ""
  //         : isTypeDataTelkomselPulsaTransfer,
  //     isTypeDataTelkomselUmum:
  //       isTypeDataTelkomselUmum === false ? "" : isTypeDataTelkomselUmum,

  //     isTypeDataTriAlwaysOn:
  //       isTypeDataTriAlwaysOn === false ? "" : isTypeDataTriAlwaysOn,
  //     isTypeDataTriGetMore:
  //       isTypeDataTriGetMore === false ? "" : isTypeDataTriGetMore,
  //     isTypeDataTriHappy:
  //       isTypeDataTriHappy === false ? "" : isTypeDataTriHappy,
  //     isTypeDataTriUmum: isTypeDataTriUmum === false ? "" : isTypeDataTriUmum,

  //     isTypeDataXLHotrod:
  //       isTypeDataXLHotrod === false ? "" : isTypeDataXLHotrod,
  //     isTypeDataXLXtraCombo:
  //       isTypeDataXLXtraCombo === false ? "" : isTypeDataXLXtraCombo,
  //     isTypeDataXLUmum: isTypeDataXLUmum === false ? "" : isTypeDataXLUmum,

  //     search: keyword?.keyword_search,
  //     language: language,
  //     token: token,
  //   };

  //   keyword === "E-Money" ||
  //   keyword === "PLN" ||
  //   keyword === "Pulsa" ||
  //   keyword === "Data"
  //     ? mutateSetBrandSearch(
  //         { token, language, keyword },
  //         {
  //           onSuccess: (data) => {
  //             // console.log(data?.data)
  //             ppobBrandDataSelect.push(data?.data);
  //             setPpobBrandDataSelected(ppobBrandDataSelect);

  //             // if (valueBrand === undefined || valueBrand === "") {
  //             mutateSetTypesSearch(
  //               { token, language, keyword, valueCategory },
  //               {
  //                 onSuccess: (data) => {
  //                   ppobTypesDataSelect.push(data?.data);
  //                   setPpobTypesDataSelected(ppobTypesDataSelect);
  //                 },
  //               }
  //             );
  //             // } else {
  //             //   mutateSetTypesSearch2(
  //             //     { token, language, keyword, valueBrand },
  //             //     {
  //             //       onSuccess: (data) => {
  //             //         ppobTypesDataSelect.push(data?.data);
  //             //         setPpobTypesDataSelected(ppobTypesDataSelect);
  //             //       },
  //             //     }
  //             //   );
  //             // }
  //           },
  //         }
  //       )
  //     : mutateSetTypesSearch(
  //         { token, language, keyword, valueCategory },
  //         {
  //           onSuccess: (data) => {
  //             // console.log(data?.data)
  //             ppobTypesDataSelect.push(data?.data);
  //             setPpobTypesDataSelected(ppobTypesDataSelect);
  //           },
  //         }
  //       );

  // currentPage.push(1);
  // currentLimit.push(100);
  // mutateSetPpobDataSearch(
  //   { payload },
  //   {
  //     onSuccess: (data) => {
  //       data?.data?.map((value) => {
  //         newPpobData.push({
  //           buyer_sku_code: value?.buyer_sku_code,
  //           sell_price: (
  //             <CurrencyFormat
  //               value={value?.sell_price}
  //               displayType={"text"}
  //               thousandSeparator={true}
  //               prefix={"Rp. "}
  //             />
  //           ),
  //           product_name: value?.product_name,
  //           seller_name: value?.seller_name,
  //           price: (
  //             <CurrencyFormat
  //               value={value?.price}
  //               displayType={"text"}
  //               thousandSeparator={true}
  //               prefix={"Rp. "}
  //             />
  //           ),
  //           stock: value?.stock,
  //           multi:
  //             value?.multi === true ? (
  //               <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
  //                 Ya
  //               </div>
  //             ) : (
  //               <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
  //                 Tidak
  //               </div>
  //             ),
  //           status: (
  //             <Switch
  //               colorScheme={value?.status === true ? "green" : "red"}
  //               isChecked={value?.status === true ? true : false}
  //               // onChange={() => handleUpdateToggleAdvertising(value?.id)}
  //             />
  //           ),
  //           // status:
  //           //   value?.is_active === true ? (
  //           //     <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
  //           //       Active
  //           //     </div>
  //           //   ) : (
  //           //     <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
  //           //       Inactive
  //           //     </div>
  //           //   ),
  //           action: (
  //             <div className="flex gap-2">
  //               {/* {(isUpdateAccessProductPrabayar === 1 ||
  //                 isViewAllAccess === true) && ( */}
  //                 <>
  //                   <Button
  //                     size="md"
  //                     colorScheme="new"
  //                     className="bg-[#504178]"
  //                     style={{ borderRadius: "10px", height: "23px" }}
  //                     onClick={() => handleModalFormOpen(true, value?.id)}
  //                   >
  //                     <div className=" text-white leading-[15px] font-[600] text-[12px]">
  //                       {t("LABEL.UPDATE_SELL_PRICE")}
  //                     </div>
  //                   </Button>
  //                   <Button
  //                     size="md"
  //                     colorScheme="new"
  //                     className="bg-[#504178]"
  //                     style={{ borderRadius: "10px", height: "23px" }}
  //                     onClick={() =>
  //                       handleModalProductNameFormOpen(true, true, value?.id)
  //                     }
  //                   >
  //                     <div className=" text-white leading-[15px] font-[600] text-[12px]">
  //                       {t("LABEL.UPDATE_PRODUCT_NAME")}
  //                     </div>
  //                   </Button>
  //                 </>
  //               {/* )} */}
  //               {/* {(isDeleteAccessAdvertising === 1 || isViewAllAccess === true) && (  */}
  //               {/* <Button
  //                 size="md"
  //                 colorScheme="new"
  //                 className="bg-[#FF0F0F]"
  //                 style={{ borderRadius: "10px", height: "23px" }}
  //                 // onClick={() => handleDialogDeleteAdvertising(true, value?.id)}
  //               >
  //                 <div className=" text-white leading-[15px] font-[600] text-[12px]">
  //                   Delete
  //                 </div>
  //               </Button> */}
  //               {/* )} */}
  //             </div>
  //           ),
  //         });
  //       });
  //       setNewPpobs(newPpobData);
  //     },
  //   }
  //   );
  // };

  const handleSearch = (keyword, typeFilter, page, limit) => {
    setIsSearch(true)

    if (typeFilter === "category") {
      localStorage.setItem("category", keyword?.keyword_filter);
      mutateSetBrandSearch(
        { token, language, keyword : keyword?.keyword_filter },
        {
          onSuccess: (data) => {
            setPpobTypesDataSelected([]);
            setPpobBrandDataSelected(data?.data);
          },
        }
      );

      const payload = {
        filter: {
          set_product_name: typeFilter === "searchBar" ? true : false,
          product_name: typeFilter === "searchBar" ? keyword?.keyword_search : "",
          set_category: true,
          category: keyword?.keyword_filter,
          set_brand: false,
          brand: "",
          set_type: false,
          type: "",
          set_seller_name: false,
          seller_name: "",
          set_is_available: false,
          is_available: false,
        },
        page: typeof page === "number" ? page : 1,
        limit: typeof limit === "number" ? limit : 10,
        order: "created_at",
        sort: "DESC",
      }

      currentPage.push(1);
      currentLimit.push(100);
      mutateSetPpobDataSearch(
        { payload, language, token },
        {
          onSuccess: (data) => {
            data?.data?.map((value) => {
              setNewPpobSearch(data)
              newPpobData.push({
                buyer_sku_code: value?.buyer_sku_code,
                sell_price: (
                  <CurrencyFormat
                    value={value?.sell_price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                product_name: value?.product_name,
                seller_name: value?.seller_name,
                price: (
                  <CurrencyFormat
                    value={value?.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                stock: value?.stock,
                multi:
                  value?.multi === true ? (
                    <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                      Ya
                    </div>
                  ) : (
                    <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                      Tidak
                    </div>
                  ),
                status: (
                  <Switch
                    colorScheme={value?.status === true ? "green" : "red"}
                    isChecked={value?.status === true ? true : false}
                  />
                ),
                action: (
                  <div className="flex gap-2">
                    <>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleModalFormOpen(true, value?.id)}
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.UPDATE_SELL_PRICE")}
                        </div>
                      </Button>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleModalProductNameFormOpen(true, true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.UPDATE_PRODUCT_NAME")}
                        </div>
                      </Button>
                    </>
                  </div>
                ),
              });
            });
            setNewPpobs(newPpobData);
          },
        }
      );
    } else if (typeFilter === "brand") {
      localStorage.setItem("brand", keyword?.keyword_filter);
      mutateSetTypesSearch(
        { token, language, keyword: keyword?.keyword_filter },
        {
          onSuccess: (data) => {
            setPpobTypesDataSelected(data?.data);
          },
        }
      );

      const payload = {
        filter: {
          set_product_name: typeFilter === "searchBar" ? true : false,
          product_name: typeFilter === "searchBar" ? keyword?.keyword_search : "",
          set_category: localStorage.getItem("category") ? true : false,
          category: localStorage.getItem("category") ? localStorage.getItem("category") : "",
          set_brand: true,
          brand: keyword?.keyword_filter,
          set_type: false,
          type: "",
          set_seller_name: false,
          seller_name: "",
          set_is_available: false,
          is_available: false,
        },
        page: typeof page === "number" ? page : 1,
        limit: typeof limit === "number" ? limit : 10,
        order: "created_at",
        sort: "DESC",
      }
      
      currentPage.push(1);
      currentLimit.push(100);
      mutateSetPpobDataSearch(
        { payload, language, token },
        {
          onSuccess: (data) => {
            data?.data?.map((value) => {
              setNewPpobSearch(data)
              newPpobData.push({
                buyer_sku_code: value?.buyer_sku_code,
                sell_price: (
                  <CurrencyFormat
                    value={value?.sell_price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                product_name: value?.product_name,
                seller_name: value?.seller_name,
                price: (
                  <CurrencyFormat
                    value={value?.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                stock: value?.stock,
                multi:
                  value?.multi === true ? (
                    <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                      Ya
                    </div>
                  ) : (
                    <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                      Tidak
                    </div>
                  ),
                status: (
                  <Switch
                    colorScheme={value?.status === true ? "green" : "red"}
                    isChecked={value?.status === true ? true : false}
                  />
                ),
                action: (
                  <div className="flex gap-2">
                    <>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleModalFormOpen(true, value?.id)}
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.UPDATE_SELL_PRICE")}
                        </div>
                      </Button>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleModalProductNameFormOpen(true, true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.UPDATE_PRODUCT_NAME")}
                        </div>
                      </Button>
                    </>
                  </div>
                ),
              });
            });
            setNewPpobs(newPpobData);
          },
        }
      );
    } else {
      localStorage.setItem("types", keyword?.keyword_filter);

      const payload = {
        filter: {
          set_product_name: typeFilter === "searchBar" ? true : false,
          product_name: typeFilter === "searchBar" ? keyword?.keyword_search : "",
          set_category: localStorage.getItem("category") ? true : false,
          category: localStorage.getItem("category") ? localStorage.getItem("category") : "",
          set_brand: localStorage.getItem("brand") ? true : false,
          brand: localStorage.getItem("brand") ? localStorage.getItem("brand") : "",
          set_type: keyword?.keyword_filter ? true : false,
          type: keyword?.keyword_filter ? keyword?.keyword_filter : "",
          set_seller_name: false,
          seller_name: "",
          set_is_available: false,
          is_available: false,
        },
        page: typeof page === "number" ? page : 1,
        limit: typeof limit === "number" ? limit : 10,
        order: "created_at",
        sort: "DESC",
      }
      
      currentPage.push(1);
      currentLimit.push(100);
      mutateSetPpobDataSearch(
        { payload, language, token },
        {
          onSuccess: (data) => {
            data?.data?.map((value) => {
              setNewPpobSearch(data)
              newPpobData.push({
                buyer_sku_code: value?.buyer_sku_code,
                sell_price: (
                  <CurrencyFormat
                    value={value?.sell_price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                product_name: value?.product_name,
                seller_name: value?.seller_name,
                price: (
                  <CurrencyFormat
                    value={value?.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                  />
                ),
                stock: value?.stock,
                multi:
                  value?.multi === true ? (
                    <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                      Ya
                    </div>
                  ) : (
                    <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                      Tidak
                    </div>
                  ),
                status: (
                  <Switch
                    colorScheme={value?.status === true ? "green" : "red"}
                    isChecked={value?.status === true ? true : false}
                  />
                ),
                action: (
                  <div className="flex gap-2">
                    <>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() => handleModalFormOpen(true, value?.id)}
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.UPDATE_SELL_PRICE")}
                        </div>
                      </Button>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#504178]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleModalProductNameFormOpen(true, true, value?.id)
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.UPDATE_PRODUCT_NAME")}
                        </div>
                      </Button>
                    </>
                  </div>
                ),
              });
            });
            setNewPpobs(newPpobData);
          },
        }
      );
    }
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    console.log(limit)
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetPricelist(
      { limit, token },
      {
        onSuccess: (data) => {
          newPpobData = [];

          data?.data?.map((value) => {
            newPpobData.push({
              buyer_sku_code: value?.buyer_sku_code,
              sell_price: (
                <CurrencyFormat
                  value={value?.sell_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              product_name: value?.product_name,
              seller_name: value?.seller_name,
              price: (
                <CurrencyFormat
                  value={value?.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              stock: value?.stock,
              multi:
                value?.multi === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    Ya
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    Tidak
                  </div>
                ),
              status: (
                <Switch
                  colorScheme={value?.status === true ? "green" : "red"}
                  isChecked={value?.status === true ? true : false}
                  // onChange={() => handleUpdateToggleAdvertising(value?.id)}
                />
              ),
              // status:
              //   value?.is_active === true ? (
              //     <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              //       Active
              //     </div>
              //   ) : (
              //     <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              //       Inactive
              //     </div>
              //   ),
              action: (
                <div className="flex gap-2">
                  {/* {(isUpdateAccessProductPrabayar === 1 ||
                    isViewAllAccess === true) && ( */}
                  <>
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleModalFormOpen(true, value?.id)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.UPDATE_SELL_PRICE")}
                      </div>
                    </Button>
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalProductNameFormOpen(true, true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.UPDATE_PRODUCT_NAME")}
                      </div>
                    </Button>
                  </>
                  {/* )} */}
                  {/* {(isDeleteAccessAdvertising === 1 || isViewAllAccess === true) && (  */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    // onClick={() => handleDialogDeleteAdvertising(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Delete
                    </div>
                  </Button> */}
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewPpobs(newPpobData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    currentPage.push(page);
    currentLimit.push(limit);
    setIsSearch(false);
    mutateGetPricelist(
      { page, limit, token },
      {
        onSuccess: (data) => {
          newPpobData = [];

          data?.data?.map((value) => {
            newPpobData.push({
              buyer_sku_code: value?.buyer_sku_code,
              sell_price: (
                <CurrencyFormat
                  value={value?.sell_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              product_name: value?.product_name,
              seller_name: value?.seller_name,
              price: (
                <CurrencyFormat
                  value={value?.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp. "}
                />
              ),
              stock: value?.stock,
              multi:
                value?.multi === true ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    Ya
                  </div>
                ) : (
                  <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    Tidak
                  </div>
                ),
              status: (
                <Switch
                  colorScheme={value?.status === true ? "green" : "red"}
                  isChecked={value?.status === true ? true : false}
                  // onChange={() => handleUpdateToggleAdvertising(value?.id)}
                />
              ),
              // status:
              //   value?.is_active === true ? (
              //     <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              //       Active
              //     </div>
              //   ) : (
              //     <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              //       Inactive
              //     </div>
              //   ),
              action: (
                <div className="flex gap-2">
                  {/* {(isUpdateAccessProductPrabayar === 1 ||
                    isViewAllAccess === true) && ( */}
                  <>
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() => handleModalFormOpen(true, value?.id)}
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.UPDATE_SELL_PRICE")}
                      </div>
                    </Button>
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleModalProductNameFormOpen(true, true, value?.id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.UPDATE_PRODUCT_NAME")}
                      </div>
                    </Button>
                  </>
                  {/* )} */}
                  {/* {(isDeleteAccessAdvertising === 1 || isViewAllAccess === true) && ( */}
                  {/* <Button
                    size="md"
                    colorScheme="new"
                    className="bg-[#FF0F0F]"
                    style={{ borderRadius: "10px", height: "23px" }}
                    // onClick={() => handleDialogDeleteAdvertising(true, value?.id)}
                  >
                    <div className=" text-white leading-[15px] font-[600] text-[12px]">
                      Delete
                    </div>
                  </Button> */}
                  {/* )} */}
                </div>
              ),
            });
          });
          setNewPpobs(newPpobData);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem("category")
    localStorage.removeItem("brand")
    localStorage.removeItem("types")

    newPpobData = [];
    let lang = t("LANGUAGE");
    currentPage.push(1);
    currentLimit.push(10);
    setIsSearch(false);

    PpobData?.data?.map((value) => {
      newPpobData.push({
        buyer_sku_code: value?.buyer_sku_code,
        sell_price: (
          <CurrencyFormat
            value={value?.sell_price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        product_name: value?.product_name,
        seller_name: value?.seller_name,
        price: (
          <CurrencyFormat
            value={value?.price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        ),
        stock: value?.stock,
        multi:
          value?.multi === true ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.YES")}
            </div>
          ) : (
            <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.NO")}
            </div>
          ),
        status: (
          <Switch
            colorScheme={value?.status === true ? "green" : "red"}
            isChecked={value?.status === true ? true : false}
            // onChange={() => handleUpdateToggleAdvertising(value?.id)}
          />
        ),
        // status:
        //   value?.is_active === true ? (
        //     <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
        //       Active
        //     </div>
        //   ) : (
        //     <div className="bg-[#FFCFCF] text-[#FF0F0F] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
        //       Inactive
        //     </div>
        //   ),
        action: (
          <div className="flex gap-2">
            {/* {(isUpdateAccessProductPrabayar === 1 ||
              isViewAllAccess === true) && ( */}
            <>
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() => handleModalFormOpen(true, value?.id)}
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.UPDATE_SELL_PRICE")}
                </div>
              </Button>
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleModalProductNameFormOpen(true, true, value?.id)
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.UPDATE_PRODUCT_NAME")}
                </div>
              </Button>
            </>
            {/* )} */}
            {/* {(isDeleteAccessAdvertising === 1 || isViewAllAccess === true) && ( */}
            {/* <Button
              size="md"
              colorScheme="new"
              className="bg-[#FF0F0F]"
              style={{ borderRadius: "10px", height: "23px" }}
              // onClick={() => handleDialogDeleteAdvertising(true, value?.id)}
            >
              <div className=" text-white leading-[15px] font-[600] text-[12px]">
                Delete
              </div>
            </Button> */}
            {/* )} */}
          </div>
        ),
      });
    });
    setNewPpobs(newPpobData);
  }, [
    PpobData,
    token,
    isGetAccessProductPrabayar,
    isDeleteAccessProductPrabayar,
    isUpdateAccessProductPrabayar,
    isViewAllAccess,
  ]);

  return (
    <Provider
      value={{
        ppobCategoryData,
        isLoadingppobCategoryData,
        ppobBrandData,
        isLoadingppobBrandData,
        ppobTypesData,
        isLoadingppobTypesData,

        PpobData,
        newPpobs,
        isLoadingPpobData,
        isSearch,
        setIsSearch,
        handleSearch,

        isLoadingGetPricelist,
        handleShowEntriesTable,
        handlePaginationTable,

        handleDialogUpdateSellPrice,
        handleUpdateSellPrice,
        isLoadingUpdateSellPrice,

        formUpdateSellPrice,
        resetformUpdateSellPrice,
        isVisibleUpdateSellPriceInfo,
        // isModalFormUpdateSellPriceOpen,
        priceId,
        isModalFormOpen,
        isEdit,
        handleModalFormOpen,

        handleSyncPriceList,
        isLoadingGetSyncPriceList,
        handleDialogSyncPrice,
        isVisibleSyncPrice,
        isVisibleSyncPriceInfo,
        isCategorySelected,
        setIsCategorySelected,
        ppobBrandDataSelected,
        ppobTypesDataSelected,

        handleTypeSearch,
        valueCategory,
        setValueCategory,
        valueBrand,
        setValueBrand,

        isModalProductNameFormOpen,
        payloadProductNameEdit,
        handleModalProductNameFormOpen,
        indexTabActive,
        setIndexTabActive,
        isPublishProductNameOpen,
        setIsPublishProductNameOpen,
        handlePublishProductNameOpen,
        isVisibleProductNameDialog,
        setIsVisibleProductNameDialog,
        refetchPpobData,
        setIndexTabActive,

        setPpobBrandDataSelected,
        valueTypes,
        setValueTypes,

        isLoadingBrandSearch,
        isLoadingTypesSearch,
        valCategory,
        setValCategory,

        setNewPpobSearch,
        newPpobSearch
      }}
    >
      {children}
    </Provider>
  );
};

export default PrabayarController;
